import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[codereOnlyNumbers]'
})
export class OnlyNumbersDirective {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Enter', 'Escape'];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    const regex = new RegExp('^[0-9]+$');
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }
}
