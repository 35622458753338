import { C_Sport } from './C_Sport';
import { C_Country } from './C_Country';
import { C_League } from './C_League';
export interface SidebarState {
  collapsed: boolean;
}

export class SidebarSportItem {
  countries: C_Country[];
  highlight: any[];

  constructor(countries, highlight) {
    this.countries = countries;
    this.highlight = highlight;
  }
}

// export class SidebarCountry extends C_Country {
//     constructor(
//         leagues: Array<C_League>,
//         countryCode: string,
//         nombre: string,
//         nId: string,
//         parentNodeId: string,
//         eventNodeTypeId: number,
//         priority: number,
//         sporthandle: string,
//         childrenCount: number,
//         selected: boolean
//     ) {
//         super(leagues, countryCode, nombre, nId, parentNodeId, eventNodeTypeId, priority, sporthandle, childrenCount,true);
//     }
// }

// export class SidebarSport extends C_Sport {

//     constructor(
//         BookmakerID: number,
//         Name: string,
//         NodeId: string,
//         ParentNodeId: string,
//         EventNodeTypeId: number,
//         Priority: number,
//         SportHandle: string,
//         ChildrenCount: number,
//         isnew: boolean,
//         countries?: SidebarCountry[],
//         highlight?: any[]

//     ) {
//         super(BookmakerID, Name, NodeId, ParentNodeId, EventNodeTypeId, Priority, SportHandle, ChildrenCount, isnew);
//     }
// }
