<button
  class="sb-ticket-selection--delete"
  tappable
  (click)="remove()"
  *ngIf="!isTicketClosed"
  [disabled]="keyboard || disabled"
>
  <i class="codere-icon icon-close color-light"></i>
</button>

<div class="sb-ticket-selection--content border-color-light sb-ticket-any">
  <div class="sb-ticket-selection--content-left">
    <div class="d-flex w-100">
      <span class="sb-ticket-selection--title m-right-1 color-light"> {{ lineItem?.ResultName }}</span>
      <!-- added +"" to convert the value to string -->
      <span class="m-h-1 sb-ticket-selection--subtitle-profit color-grey-500">{{ lineItem?.Odd+"" | formatOdd }}</span>
      <ion-icon
        *ngIf="showOddArrows() && lineItem?.downOdd"
        name="caret-down-outline"
        class="caret-down-outline"
        color="danger"
        [ngClass]="{'sb-button--animation': lineItem?.upOdd || lineItem?.downOdd}"
      >
      </ion-icon>
      <ion-icon
        *ngIf="showOddArrows() && lineItem?.upOdd"
        name="caret-up-outline"
        class="caret-up-outline"
        color="success"
        [ngClass]="{'sb-button--animation': lineItem?.upOdd || lineItem?.downOdd}"
      >
      </ion-icon>
    </div>
    <span class="sb-ticket-selection--subtitle color-grey-500">
      {{ lineItem?.GameName | getNameGame:[lineItem?.Place, license] | translate }}
    </span>
    <span class="sb-ticket-selection--subtitle color-grey-500"> {{ lineItem?.EventName | translate }} </span>
    <span class="sb-ticket-selection--subtitle color-grey-500" *ngIf="isTicketClosed && lineItem?.TicketNumber">
      {{ 'compapuestaP3' | translate }} # {{ lineItem?.TicketNumber }}
    </span>
  </div>
  <div class="sb-ticket-selection--content-right" *ngIf="multiSingleActivated">
    <button
      *ngIf="!isDesktop"
      class="sb-ticket-selection--button color-dark background-color-regular"
      [ngClass]="{ 'is-selected': lineItem?.Selected && keyboard }"
      tappable
      (click)="selectAmount()"
    >
      <span class="sb-ticket--button-title"> {{ lineItem?.AmountLocal | parseStakeMoney:[1] }} </span>
    </button>
    <sb-ticket-stake
      *ngIf="isDesktop && !getIsProcessing()"
      [minBetValue]="minBetValue"
      [ticket]="ticket"
      [lineItem]="lineItem"
      [stakeAmount]="lineItem?.Amount?.toString()"
      (update)="updateStake($event)"
      [currency]="currency"
    >
      <!-- updateStake second arg: 'single' (not used) -->
    </sb-ticket-stake>
    <span class="sb-ticket-selection--subtitle color-primary" *ngIf="!getIsProcessing()">
      {{'hDetailsGan' | translate}}: {{ lineItem?.TotalPotentialWin | parseStakeMoney:[1] }}
    </span>
  </div>
</div>
