import { C_CategoryInfo } from '@models/C_CategoryInfo';
import { C_Event } from '@models/C_Event';
import { C_Horses } from '@models/C_Horses';
import { SbNavbarItem } from '@models/sportbook';
import { C_TodayLeagues } from '@models/sports/C_TodayLeagues';

export interface homeDataModel {
  marquee: any[];
  homeLiveEvents: {
    LiveEventsCount: number;
    LiveSport: any[];
  };
  highlightsEvents: homeHighlightsEvents;
  betBuilder: any[];
}

export interface homeHighlightsEvents {
  events: any[];
  sports: any[];
  selectedSport: null;
}

export interface sportCountriesDataModel {
  parentId: string;
  highlights: any[];
  countries: any[];
}

export interface eventDataModel {
  eventMainCategories: any[];
  eventMarkesCategories: any[];
  games: any[];
  specialMarkets: C_Event;
  leagueName: string;
  event: any;
  parentId: string;
  selectedEventMainCategory: any;
  selectedMarketsCategory: any;
}

export interface marketDataModel {
  categories: any[];
  marketBets: any[];
  leagueName: string;
  market: any;
  parentId: string;
  selectedCategory: any;
}

export interface todayDataModel {
  fiveDays: any[];
  selectedDay: any;
  selectedSport: any;
  sportsFilters: any[];
  sportsEvents: C_TodayLeagues[];
}

export class M_SportCountriesData {
  parentId: string;
  highlights: any[];
  countries: any[];
  constructor(parentId, highlights, countries) {
    this.parentId = parentId;
    this.highlights = highlights;
    this.countries = countries;
  }
}

export interface liveDataModel {
  sportsNav: SbNavbarItem[];
  selectedNav: SbNavbarItem;
  sportCategories: C_CategoryInfo[];
  selectedSportCategory: C_CategoryInfo;
  selectedSport: any;
  events: any[];
}

export interface raceDataModel {
  filters: any[];
  selectedFilter: any;
  next: C_Horses[];
  today: C_Horses[];
  tomorrow: C_Horses[];
}
