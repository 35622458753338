/* eslint-disable eqeqeq*/
import { LineItem } from './sports/B_LineItem.model';
import { C_TicketEnumMode } from './ticket';
import { SmartLineItem } from './C_SmartLineItem';
import { C_OveraskData, OveraskDataMethods } from './sports/C_Overask-Data';
import { C_FreeBetTicket } from './C_FreeBetTicket';
import { BSfreeBetVouchers } from './sports/ticketBetSlip.model';

/*
enum enum_PipelineExecutionResult { Success = 0, Warning = 1, Failure = 2 }
enum enum_BetType { Simple = 0, Accumulated = 1, Multiple = 2, MultipleChoice = 3 }
enum enum_cdr_bettingactivity_cdr_tickettype { Caballos = 754310000, Deportes = 754310001, Galgos = 754310002 }
enum enum_cdr_bonus_cdr_bonustype { BonusAmount = 754310000, DepositRatio = 754310001, BetRatio = 754310002 }
enum enum_FreebetConditionType { MaxOdds = 0, MinOdds = 1, MinLegs = 2 }
enum enum_Place { AnyOrder = -1, NotDefined = 0, Place1 = 1, Place2 = 2, Place3 = 3 }
enum enum_LineItemStatus { Ok = 0, PendingRemove = 1 }
enum enum_GreyhoundHorseBetStatus { Ok = 0, PendingRemove = 1 }
enum enum_GreyhoundHorseExecutionResult { Success = 0, Warning = 1, Failure = 2 }
*/

export interface ApplicableBonus {
  BonusId: string; //guid
  /// <summary>
  /// Id of the relation between the bonus and the user, when the bonus can be applied to the user
  /// </summary>
  PlayerBonusId: string; //guid
  Applied: boolean;
  BonusDescription: string;
  AppliedAmount: number; //decimal
  PendingAmount: number; //decimal
  //this is the id of the transaction to be used to rollback/confirm bonus transaction when bet is failed/completed
  BonusTransactionId: string; //es un guid
  Priority: number;
  BonusType: number; //guid
}

export interface GreyhoundHorseBet {
  Amount?: number;
  AmountLocal?: number;
  BetType?: any;
  Date?: string; //
  downOdd: boolean;
  GameId?: string;
  GameLocked?: boolean;
  GreyhoundHorseBetType?: number; //es un enum bettype
  Handicap?: boolean;
  Id?: string;
  Index?: number;
  IsBet?: boolean;
  IsSelected?: boolean;
  ItemId?: number;
  LineErrorMessages?: Array<string>;
  LineErrors: string; // corregir TODO
  NotAvailable?: boolean;
  Ordered?: boolean;
  Odd: number; //decimal
  Paddock?: string;
  Participants?: Array<GreyhoundParticipant>;
  PendingCheck?: boolean;
  PipelineExecutionResult?: number; // enum_GreyhoundHorseExecutionResult
  ResultsNr: string; // corregir TODO 'codigo BAU'
  Selected: boolean;
  SportHandle?: string;
  Status?: number; //enum_GreyhoundHorseBetStatus { get; set; }
  Tax?: number;
  TicketNumber?: string;
  TotalPotentialWin?: any;
  upOdd: boolean;
}

export interface FreebetInstance {
  Amount: number; //decimal
  BonusOfferId: number; //decimal { get; set; }
  Conditions: Array<FreeBetCondition>;
  ExpirationDate: any;
  IsRedeemable: boolean;
  VoucherCode: string;
  Name: string;
  Validation: {
    systems: boolean;
    singles: boolean;
  };
}

export class FreebetInstanceMethods {
  public static newFreebetInstance = (): FreebetInstance => ({
    Amount: 0,
    BonusOfferId: 0,
    Conditions: [],
    ExpirationDate: '',
    IsRedeemable: false,
    VoucherCode: '',
    Name: '',
    Validation: {
      systems: true,
      singles: true
    }
  });

  public static mapFreebetInstance = (FBData: C_FreeBetTicket, betSlipFB: BSfreeBetVouchers): FreebetInstance => ({
    Amount: FBData.Amount,
    BonusOfferId: FBData.BonusOfferId,
    Conditions: [],
    ExpirationDate: FBData.ExpirationDate,
    IsRedeemable: FBData.IsRedeemable,
    Name: FBData.Name,
    VoucherCode: FBData.VoucherCode,
    Validation: {
      singles: betSlipFB.validation.singles[0].vouchers[0].validationCode !== 'NotYetValidated',
      systems:
        betSlipFB.validation.systems[betSlipFB.validation.systems.length - 1].vouchers[0].validationCode !==
        'NotYetValidated'
    }
  });
}

export class FreeBetCondition {
  //    public FreeBetCondition();
  constructor() {}
  Condition: number; // es un enum FreebetConditionType
  ConditionValueDecimal: number; //decimal
  ConditionValueInt: number;
}

export interface GreyhoundParticipant {
  OpenPrice: boolean;
  Id: string;
  Place: number; //es un enum
  Name: string;
}

export interface ApplicableAccaBonus {
  BonusId: number;
  maxBonusRate: number;
  BonusAmount: number;
}

/**
 * ApplicableAccaBonus Methods
 */
export class AABonusMethods {
  public static newApplicableAccaBonus = (): ApplicableAccaBonus => ({
    BonusId: 0,
    maxBonusRate: 0,
    BonusAmount: 0
  });

  public static mapBonus = (bonusData): ApplicableAccaBonus => ({
    BonusId: bonusData.bonusOfferId || 0,
    maxBonusRate: bonusData.maxBonusRate || 0,
    BonusAmount: bonusData.maxBonusReturnNet || 0
  });
}

export interface StakeGroups {
  Singles: {
    totalAmount?: number;
    totalPotentialWin?: number;
  };
  Accumulator: {
    totalAmount?: number;
    totalPotentialWin?: number;
    systemId?: number;
    bonus?: any;
  };
  System: {
    totalAmount?: number;
    totalPotentialWin?: number;
    systemId?: number;
    possibleSystems?: systemData[];
    bonus?: any;
    selectedGroup?: string;
  };
}

export interface systemData {
  canBeEachWay: boolean;
  numberOfBetWays: number;
  numberOfEachWayBetWays: number;
  systemId: number;
  systemType: string;
  text?: string;
  groupName: string;
}

export interface C_TicketApuestasNew {
  // MultipleChoice: boolean;
  //NBN - new betslip needed
  //TicketTree: SBSBetTicketTO; //siempre viene a null y hacer la estructura de clases que lo componen es muy laborioso. De momento no lo voy a usar a ver si depsues hace falta.
  ApplicableAccaBonus: ApplicableAccaBonus;
  BetType: number; //NBN - new betslip needed
  BonusChecked: any; //es un datetime { get; set; }  //NBN - new betslip needed
  Bonuses?: Array<ApplicableBonus>;
  CombiCount?: number;
  CombinationMulticast: GreyhoundHorseBet[]; // LineItem | GreyhoundHorseBet  //NBN - new betslip needed
  Combinations: number; //NBN - new betslip needed
  Created: any; //es un datetime { get; set; }  //NBN - new betslip needed
  Default: boolean;
  FreebetInstance?: FreebetInstance;
  FreeBetVoucherId?: string;
  GamesEvent?: boolean;
  hasChangesInOdds: boolean;
  HasPrice: boolean;
  isAcceptBettingOdds?: boolean;
  isPlacingbet?: boolean; //NBN - new betslip needed
  keepSelection: boolean; //NBN - new betslip needed
  LanguageCode: string;
  LineItems: Array<LineItem>; //NBN - new betslip needed
  MaxCombinations?: number;
  MaxMult?: number; //es decimal { get; set; }
  mode: C_TicketEnumMode; //NBN - new betslip needed
  MultisingleError?: string;
  NumberOfBanks?: number;
  NumberOfResults?: number;
  Odd: number;
  Opened: boolean; //NBN - new betslip needed
  OrderBonusMessages: Array<string>; //NBN - new betslip needed
  OrderErrorMessages: Array<string>; //NBN - new betslip needed
  OrderErrors: any;
  OrderFormId?: string; //es un GUID
  OveraskInfo?: C_OveraskData;
  OveraskCloseBet?: any;
  PipelineExecutionResult?: number;
  processing?: boolean; //NBN - new betslip needed
  SmartLineItems: Array<SmartLineItem>; //NBN - new betslip needed
  StakeGroups: StakeGroups; //NBN - new betslip needed
  StraightMulticast: GreyhoundHorseBet[]; // LineItem | GreyhoundHorseBet  //NBN - new betslip needed
  SystemSelected: systemData; //NBN - new betslip needed
  SystemType: number; //NBN - new betslip needed
  Tax: number;
  TicketNumber: string; //NBN - new betslip needed
  TicketType?: number;
  Total: number; //es un decimal  //NBN - new betslip needed
  TotalBets: number;
  TotalLocal: string;
  TotalPotentialWin?: number; //es decimal  //NBN - new betslip needed
  TotalPotentialWinWithoutBoost?: number; // es decimal
}

/**
 * TICKET METHODS
 */
export class TicketMethods {
  public static newC_TicketApuestas = (): C_TicketApuestasNew => ({
    ApplicableAccaBonus: AABonusMethods.newApplicableAccaBonus(),
    BetType: 0,
    BonusChecked: new Date().getDate(),
    CombinationMulticast: [],
    Combinations: 2,
    Created: new Date().getDate(),
    Default: true,
    hasChangesInOdds: false,
    HasPrice: true,
    isPlacingbet: false,
    keepSelection: false,
    LanguageCode: 'es',
    LineItems: [],
    mode: C_TicketEnumMode.TICKET_CONFIRMATION_EXPANDED,
    Odd: 0,
    Opened: false,
    OrderBonusMessages: [],
    OrderErrorMessages: [],
    OrderErrors: {},
    OveraskInfo: OveraskDataMethods.newOveraskData(),
    OveraskCloseBet: null,
    processing: false,
    SmartLineItems: [],
    StakeGroups: { Singles: {}, Accumulator: {}, System: {} },
    StraightMulticast: [],
    SystemSelected: null,
    SystemType: 1,
    Tax: 0,
    TicketNumber: '',
    TotalLocal: '',
    Total: 1,
    TotalBets: 0,
    TotalPotentialWin: 0
  });

  public static parse(obj) {
    return Object.assign(TicketMethods.newC_TicketApuestas(), obj);
  }

  public static getAllItems(ticket: C_TicketApuestasNew): any {
    return [...ticket.LineItems, ...ticket.StraightMulticast, ...ticket.CombinationMulticast, ...ticket.SmartLineItems];
  }

  public static getItemByPBSId(ticket: any, id: number): any {
    const arrItems: any[] = this.getAllItems(ticket);
    const removedItem = arrItems.find((i) => i.ItemId == id);
    return removedItem;
  }

  public static getItemByPBSIds(ticket: any, ids: number[]): any {
    const arrItems: any[] = this.getAllItems(ticket);
    const removedItem = arrItems.find((i) => ids.includes(i.ItemId));
    return removedItem;
  }

  public static deleteError(ticket: C_TicketApuestasNew, key: string): void {
    const error = ticket.OrderErrors[key];
    if (!error) {
      return;
    }
    ticket.OrderErrorMessages = ticket.OrderErrorMessages.filter((e) => e.toLowerCase() !== error.toLowerCase());
    delete ticket.OrderErrors[key];
  }

  public static getSelectedLine(ticket: C_TicketApuestasNew) {
    return [
      ...ticket.LineItems,
      ...ticket.StraightMulticast,
      ...ticket.CombinationMulticast,
      ...ticket.SmartLineItems
    ].find((lineItem) => lineItem.Selected);
  }

  public static getIsPending(ticket: C_TicketApuestasNew): boolean {
    return ticket.OveraskInfo.Status !== 'OK' && ticket.OveraskInfo.Status !== 3 ? true : false;
  }

  public static getIsConfirmed(ticket: C_TicketApuestasNew): boolean {
    if (ticket != null) {
      const lineItems = ticket.LineItems.some((line) => Boolean(line.TicketNumber));
      const straightMulticast = ticket.StraightMulticast.some((line) => Boolean(line.TicketNumber));
      const combinationMulticast = ticket.CombinationMulticast.some((line) => Boolean(line.TicketNumber));
      const smartLineItems = ticket.SmartLineItems.some((line) => Boolean(line.TicketNumber));
      return lineItems || straightMulticast || combinationMulticast || smartLineItems || Boolean(ticket.TicketNumber);
    } else {
      return false;
    }
  }

  public static getIsUpdatableTicket(ticket: C_TicketApuestasNew) {
    const hasItems = TicketMethods.getAllItems(ticket).length > 0;
    const hasChangesInOds = ticket.hasChangesInOdds;
    const isConfirmed = TicketMethods.getIsConfirmed(ticket);
    const isProcessing = ticket.processing;
    let hasErrors = false;

    if (ticket.OrderErrors['51931']) hasErrors = false; // Combinación no permitida
    if (ticket.OrderErrors['9999999']) hasErrors = true; // Player Errors
    if (ticket.OrderErrors['51937']) hasErrors = false; // Cuota ha cambiado
    if (ticket.OrderErrors['51941']) hasErrors = false; // Selección Bloqueada
    if (ticket.OrderErrors['51927']) hasErrors = false; // Mercado no disponible

    return hasItems && !hasChangesInOds && !isConfirmed && !isProcessing && !hasErrors;
  }

  public static getHasErrors(ticket: C_TicketApuestasNew) {
    return ticket.OrderErrorMessages.length > 0;
  }

  public static getHasFBErrors(ticket: C_TicketApuestasNew) {
    let hasErrors = false;
    if (ticket.FreeBetVoucherId) {
      if (ticket.BetType == 0 && !ticket.FreebetInstance.Validation.singles) {
        hasErrors = true;
      }

      if (ticket.BetType == 1 && !ticket.FreebetInstance.Validation.systems) {
        hasErrors = true;
      }
    }
    return hasErrors;
  }

  public static getAmountBet(ticket: C_TicketApuestasNew) {
    let amount: string;
    if (this != undefined) {
      if (ticket.TotalPotentialWin == 0) {
        const lineItemsAux: number = ticket.LineItems.findIndex(
          (item) => item.SportHandle != 'horse_racing' && item.SportHandle != 'greyhound_racing' && item.IsSelected
        );
        amount = lineItemsAux > -1 ? 'N/A' : 'CF';
      } else {
        if (this.getHasFreebet(ticket)) {
          amount = ((ticket.TotalPotentialWin + ticket.Total) / ticket.Total).toFixed(2).toString();
        } else {
          amount = (ticket.TotalPotentialWin / ticket.Total).toFixed(2).toString();
        }
      }
    } else {
      amount = '0';
    }
    return amount;
  }

  public static getHasFreebet(ticket: any): boolean {
    return Boolean(ticket.FreebetInstance);
  }

  public static getNumberOfSelections(data: any): number {
    let selections = 0;

    if (data.LineItems.length > 0) {
      selections += data.LineItems.length;
    }
    if (data.SmartLineItems.length > 0) {
      selections += data.SmartLineItems.length;
    }
    if (data.StraightMulticast.length > 0) {
      selections += data.StraightMulticast.length;
    }
    if (data.CombinationMulticast.length > 0) {
      selections += data.CombinationMulticast.length;
    }
    return selections;
  }
}
