import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';

import * as fromTicket from '@shared-sports-store/reducers/ticket.reducers';
import * as fromSidebar from '@shared-sports-store/reducers/sidebar.reducers';
import * as fromSportsbook from '@shared-sports-store/reducers/sportsbook.reducers';

export interface AppState {
  ticket: fromTicket.ticketState;
  sidebar: fromSidebar.sidebarState;
  sportsbook: fromSportsbook.SportsbookState;
}

export const appReducer: ActionReducerMap<AppState> = {
  ticket: fromTicket.ticketReducer as ActionReducer<fromTicket.ticketState, Action>,
  sidebar: fromSidebar.sidebarReducer as ActionReducer<fromSidebar.sidebarState, Action>,
  sportsbook: fromSportsbook.SportsbookReducer as ActionReducer<fromSportsbook.SportsbookState, Action>
};
