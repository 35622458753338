import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, map, switchMap, tap, withLatestFrom } from 'rxjs';

// Store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SportsbookActions from '../actions/sportsbook.actions';
import { SportsbookState } from '../reducers/sportsbook.reducers';

// Services
import { BufferFacade } from '../../../../providers/facades';
import { NewBaseService } from '../../../../providers/newBase.service';
import { SportService } from '../../../../providers/SportService';
import { SportBookService } from '../../../../providers/SportBookService';
import { DeviceService } from '@services/device.service';

// Models
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import * as models from '../../../../models/index';
import { homeDataModel } from '../models/sportsbook.model';

// Utils
import { SportHandleList } from '@utils/sportHandleList';
import { SportsbookUtils } from '../storeUtils/sportsbook.utils';
import { RacePageUtils } from '../storeUtils/racePageUtils.utils';

@Injectable({ providedIn: 'root' })
export class SportsbookEffects {
  newBaseService = inject(NewBaseService);
  bufferFacade = inject(BufferFacade);
  sportService = inject(SportService);
  sportBookService = inject(SportBookService);
  sportBookUtils = inject(SportsbookUtils);
  racePageUtils = inject(RacePageUtils);
  deviceService = inject(DeviceService);
  store = inject(Store<SportsbookState>);

  globalVars!: GlobalVarsModel;

  isDesktop: boolean;
  isMobile: boolean;

  polling$ = new BehaviorSubject(true);

  timer = {
    live: 'live',
    regular: 'regular',
    highlights: 'highlights'
  };

  constructor(private actions$: Actions) {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  // HOME
  initHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.initHome),
      map(() => SportsbookActions.setHomeHighlighsSelectedSport({ sport: 'soccer' }))
    )
  );

  setHomeHighlighsSelectedSport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setHomeHighlighsSelectedSport),
      switchMap((selectedSport) => {
        this.polling$.next(true);
        const params = {
          countHomeLiveEvents: 5,
          gameTypesHomeLiveEvents: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getAll()),
          sportHandle: selectedSport.sport,
          countHighlightsEvents: 5,
          gameTypesHighlightsEvents: this.globalVars.gameTypes.serialize(
            this.globalVars.gameTypes.getSport(selectedSport.sport)
          )
        };
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'getHomeData', params, this.timer.regular, this.polling$)
          .pipe(
            map(([prevHomeData, currentHomeData]) =>
              SportsbookActions.setHome({
                prevHomeData,
                currentHomeData: this.sportBookService.setHomeData(
                  prevHomeData,
                  currentHomeData,
                  this.isMobile,
                  selectedSport.sport
                )
              })
            )
          );
      })
    )
  );

  stopHomePagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopHomePagePolling),
        tap(() => this.polling$.next(false))
      ),
    { functional: true, dispatch: false }
  );

  // SPORTS COUNTRIES PAGE
  fetchSportCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchSportCountries),
      switchMap((sport: any) =>
        this.sportService
          .getPaisLigaInfo(sport.NodeId)
          .pipe(map((data) => SportsbookActions.setSportCountries({ ...data, parentId: sport.NodeId })))
      )
    )
  );

  // EVENT PAGE
  fetchEventMainCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventMainCategories),
      switchMap((league: any) => {
        const initialEventMainCategories = this.sportBookService.setEventMainCategories(league);
        return this.sportService
          .GetCategoriesByLeague({
            idLeague: league.NodeId,
            sportHandle: league.SportHandle,
            countryCode: league.CountryCode
          })
          .pipe(
            map((eventMarkesCategories: any) => {
              const hasSixPack = models.SixPack.validate(league.SportHandle, league.CountryCode);
              eventMarkesCategories = eventMarkesCategories.map(
                (cat) => new models.C_LeagueCategory(cat.CategoryId, cat.CategoryName, cat.IsRelevant)
              );
              const gameTypes = this.globalVars.gameTypes.getSport(league.SportHandle);

              if (hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
                eventMarkesCategories.filter((category) => gameTypes.indexOf(parseInt(category.CategoryId)) === -1);
              }
              if (!this.isMobile && !hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              if (eventMarkesCategories.length <= 0) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              return SportsbookActions.fetchEventGames({
                eventMainCategories: initialEventMainCategories,
                eventMarkesCategories: eventMarkesCategories,
                games: [],
                specialMarkets: null,
                leagueName: league.Name,
                event: new models.C_EventPageLeague(league),
                parentId: league.NodeId,
                selectedEventMainCategory: initialEventMainCategories[0],
                selectedMarketsCategory: eventMarkesCategories[0]
              });
            })
          );
      })
    )
  );

  setEventMarketSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setEventMarketSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedMarketsCategory, sportsbook]) => {
        const eventPageData = sportsbook['eventData'];
        return SportsbookActions.fetchEventGames({
          eventMainCategories: eventPageData.eventMainCategories,
          eventMarkesCategories: eventPageData.eventMarkesCategories,
          games: eventPageData.games,
          specialMarkets: eventPageData.specialMarkets,
          leagueName: eventPageData.leagueName,
          event: eventPageData.event,
          parentId: eventPageData.parentId,
          selectedEventMainCategory: eventPageData.selectedEventMainCategory,
          selectedMarketsCategory
        });
      })
    )
  );

  setEventMainSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setEventMainSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedEventMainCategory, sportsbook]) => {
        const eventPageData = sportsbook['eventData'];

        if (selectedEventMainCategory.index > 0) {
          return SportsbookActions.fetchEventSpecialMarketGames({
            eventMainCategories: eventPageData.eventMainCategories,
            eventMarkesCategories: eventPageData.eventMarkesCategories,
            games: [],
            specialMarkets: null,
            leagueName: eventPageData.leagueName,
            event: eventPageData.event,
            parentId: eventPageData.parentId,
            selectedEventMainCategory,
            selectedMarketsCategory: eventPageData.selectedMarketsCategory
          });
        }
        return SportsbookActions.fetchEventMarketsCategories({
          eventMainCategories: eventPageData.eventMainCategories,
          eventMarkesCategories: eventPageData.eventMarkesCategories,
          games: [],
          specialMarkets: null,
          leagueName: eventPageData.leagueName,
          event: eventPageData.event,
          parentId: eventPageData.parentId,
          selectedEventMainCategory,
          selectedMarketsCategory: eventPageData.selectedMarketsCategory
        });
      })
    )
  );

  fetchEventMarketsCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventMarketsCategories),
      switchMap((eventPageData: any) => {
        this.polling$.next(true);
        return this.sportService
          .GetCategoriesByLeague({
            idLeague: eventPageData.event.NodeId,
            sportHandle: eventPageData.event.SportHandle,
            countryCode: eventPageData.event.CountryCode
          })
          .pipe(
            map((eventMarkesCategories: any) => {
              const hasSixPack = models.SixPack.validate(
                eventPageData.event.SportHandle,
                eventPageData.event.CountryCode
              );
              eventMarkesCategories = eventMarkesCategories.map(
                (cat) => new models.C_LeagueCategory(cat.CategoryId, cat.CategoryName, cat.IsRelevant)
              );
              const gameTypes = this.globalVars.gameTypes.getSport(eventPageData.event.SportHandle);

              if (hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
                eventMarkesCategories.filter((category) => gameTypes.indexOf(parseInt(category.CategoryId)) === -1);
              }
              if (!this.isMobile && !hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              if (eventMarkesCategories.length <= 0) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              return SportsbookActions.fetchEventGames({
                eventMainCategories: eventPageData.eventMainCategories,
                eventMarkesCategories: eventMarkesCategories,
                games: [],
                specialMarkets: null,
                leagueName: eventPageData.leagueName,
                event: new models.C_EventPageLeague(eventPageData.event),
                parentId: eventPageData.parentId,
                selectedEventMainCategory: eventPageData.selectedEventMainCategory,
                selectedMarketsCategory: eventMarkesCategories[0]
              });
            })
          );
      })
    )
  );

  fetchEventSpecialMarketGames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventSpecialMarketGames),
      switchMap((eventPageData: any) => {
        const params = {
          parentId: eventPageData.selectedEventMainCategory.nodeId,
          sportHandle: eventPageData.event.SportHandle
        };
        this.polling$.next(false);
        return this.sportService.getEvents(params).pipe(
          map((specialMarketEvents: models.C_Event[]) =>
            SportsbookActions.setEventGames({
              eventMainCategories: eventPageData.eventMainCategories,
              eventMarkesCategories: [],
              games: [],
              specialMarkets: specialMarketEvents.length > 0 ? specialMarketEvents[0] : null,
              leagueName: eventPageData.leagueName,
              event: eventPageData.event,
              parentId: eventPageData.parentId,
              selectedEventMainCategory: eventPageData.selectedEventMainCategory,
              selectedMarketsCategory: eventPageData.selectedMarketsCategory
            })
          )
        );
      })
    )
  );

  fetchEventGames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventGames),
      switchMap((eventPageData: any) => {
        const params = {
          parentId: eventPageData.parentId,
          category: eventPageData.selectedMarketsCategory.CategoryId,
          sportHandle: eventPageData.event.SportHandle
        };
        this.polling$.next(true);
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'getEventsCountryBuffer', params, this.timer.regular, this.polling$)
          .pipe(
            map(([prev, current]) => {
              const gamesData = this.sportBookService.mapEvents(
                prev,
                current,
                eventPageData.selectedMarketsCategory.CategoryId,
                eventPageData.event
              );
              return SportsbookActions.setEventGames({
                eventMainCategories: eventPageData.eventMainCategories,
                eventMarkesCategories: eventPageData.eventMarkesCategories,
                games: gamesData.leagueMarkets,
                specialMarkets: eventPageData.specialMarkets,
                leagueName: eventPageData.leagueName,
                event: { ...eventPageData.event, marketNames: gamesData.marketNames },
                parentId: eventPageData.parentId,
                selectedEventMainCategory: eventPageData.selectedEventMainCategory,
                selectedMarketsCategory: eventPageData.selectedMarketsCategory
              });
            })
          );
      })
    )
  );

  stopEventPagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopEventPagePolling),
        tap(() => this.polling$.next(false))
      ),
    { functional: true, dispatch: false }
  );

  // MARKET PAGE
  fetchMarketCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchMarketCategories),
      switchMap((marketData: any) =>
        this.sportService.getMarketCategories(marketData.NodeId).pipe(
          map((categoriesData: any) => {
            const categories = this.sportBookService.setCategories(categoriesData);
            return SportsbookActions.fetchMarkets({
              parentId: marketData.NodeId,
              leagueName: marketData.LeagueName,
              market: marketData,
              categories: categories,
              selectedCategory: categories[0],
              marketBets: []
            });
          })
        )
      )
    )
  );

  setMarketSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setMarketSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedCategory, sportsbook]) => {
        const marketPageData = sportsbook['marketData'];
        return SportsbookActions.fetchMarkets({
          parentId: marketPageData.parentId,
          leagueName: marketPageData.leagueName,
          market: marketPageData.market,
          categories: marketPageData.categories,
          marketBets: marketPageData.marketBets,
          selectedCategory
        });
      })
    )
  );

  fetchMarkets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchMarkets),
      switchMap((marketPageData: any) =>
        this.sportService
          .getMarketGamesByCategory(
            marketPageData.parentId,
            marketPageData.selectedCategory.CategoryId,
            marketPageData.leagueName
          )
          .pipe(
            map((marketBetsData: any) => {
              const marketBets = this.sportBookService.setMarketBets(marketBetsData, marketPageData);
              return SportsbookActions.setMarkets({
                categories: marketPageData.categories,
                parentId: marketPageData.parentId,
                leagueName: marketPageData.leagueName,
                market: marketPageData.market,
                selectedCategory: marketPageData.selectedCategory,
                marketBets
              });
            })
          )
      )
    )
  );

  // TODAY PAGE
  fetchTodayCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchTodayCategories),
      switchMap(() =>
        this.sportService.getHighlightCountries('-1', 0).pipe(
          map((sportFiltersData: any) => {
            const sportsFilters = this.sportBookService.setTodaySportsCategories(sportFiltersData);
            const nextFiveDays = this.sportBookService.getNextFiveDays();
            return SportsbookActions.fetchTodaysDateSportLeagues({
              fiveDays: nextFiveDays,
              selectedDay: 0,
              selectedSport: sportsFilters[0],
              sportsFilters: sportsFilters,
              sportsEvents: []
            });
          })
        )
      )
    )
  );

  fetchTodaysDateSportLeagues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchTodaysDateSportLeagues),
      switchMap((todayPageData) =>
        this.sportService.getCountriesByDate(todayPageData.selectedSport.CategoryId, todayPageData.selectedDay).pipe(
          map((countriesByDateData: any) => {
            const sportsEvents: models.C_TodayLeagues[] = countriesByDateData.flatMap((country) =>
              country.Leagues.map((league) => ({
                ...league,
                CountryCode: country.CountryCode
              }))
            );
            return SportsbookActions.setToday({
              fiveDays: todayPageData.fiveDays,
              selectedDay: todayPageData.selectedDay,
              selectedSport: todayPageData.selectedSport,
              sportsFilters: todayPageData.sportsFilters,
              sportsEvents
            });
          })
        )
      )
    )
  );

  fetchTodayLeagueEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchTodayLeagueEvents),
      withLatestFrom(this.store.select('sportsbook')),
      switchMap(([selectedLeague, sportsbook]) => {
        const todayPageData = sportsbook['todayData'];
        const params = {
          parentId: selectedLeague.NodeId,
          dayDifference: todayPageData.selectedDay,
          sportHandle: todayPageData.selectedSport.CategoryId
        };
        this.polling$.next(true);
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'getEventsByDate', params, this.timer.regular, this.polling$)
          .pipe(
            map(([prev, current]) => {
              const sportsEvents = this.sportBookService.setTodayLeagueEvents(
                prev,
                current,
                selectedLeague,
                todayPageData.sportsEvents
              );
              return SportsbookActions.setToday({
                fiveDays: todayPageData.fiveDays,
                selectedDay: todayPageData.selectedDay,
                selectedSport: todayPageData.selectedSport,
                sportsFilters: todayPageData.sportsFilters,
                sportsEvents
              });
            })
          );
      })
    )
  );

  stopTodayPagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopTodayPagePolling),
        tap(() => this.polling$.next(false))
      ),
    { functional: true, dispatch: false }
  );

  setTodaySelectedDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setTodaySelectedDay),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedDay, sportsbook]) => {
        const todayPageData = sportsbook['todayData'];
        return SportsbookActions.fetchTodaysDateSportLeagues({
          fiveDays: todayPageData.fiveDays,
          selectedDay: selectedDay.selectedDay,
          selectedSport: todayPageData.selectedSport,
          sportsFilters: todayPageData.sportsFilters,
          sportsEvents: []
        });
      })
    )
  );

  setTodaySelectedSport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setTodaySelectedSport),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedSport, sportsbook]) => {
        const todayPageData = sportsbook['todayData'];
        return SportsbookActions.fetchTodaysDateSportLeagues({
          fiveDays: todayPageData.fiveDays,
          selectedDay: todayPageData.selectedDay,
          selectedSport,
          sportsFilters: todayPageData.sportsFilters,
          sportsEvents: []
        });
      })
    )
  );

  // RACE
  fetchRace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchRace),
      withLatestFrom(this.store.select('sportsbook')),
      switchMap(([raceData, sportsbook]) => {
        const racePageData = sportsbook['raceData'];
        const endOfToday = raceData.endOfToday;
        let raceSportHandle = raceData.raceType;
        if (raceSportHandle == '') raceSportHandle = 'horse_racing';
        return this.sportService.getRacesInfo(raceSportHandle).pipe(
          map((data) => {
            const nextRaces = data.nextRaces.map(
              (race: models.C_Horses) =>
                (race = new models.C_Horses(
                  race.Name,
                  race.NodeId,
                  race.ParentNodeId,
                  race.EventNodeTypeId,
                  race.Priority,
                  race.SportHandle,
                  race.ChildrenCount,
                  race.CountryCode,
                  race.IsActive,
                  race.OddsAvailability,
                  race.PaddockId,
                  race.StartDate,
                  race.GameTypeId,
                  race.Races,
                  race.Locked,
                  race.StreamingEnabled
                ))
            );
            const { today, tomorrow } = this.racePageUtils.setPaddocksByDate(data.racesbyPaddock, endOfToday);
            return SportsbookActions.setRace({
              filters: racePageData.filters,
              selectedFilter: racePageData.selectedFilter ? racePageData.selectedFilter : racePageData.filters[0],
              next: nextRaces,
              today,
              tomorrow
            });
          })
        );
      })
    )
  );

  // LIVE
  initLive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.initLive),
      withLatestFrom(this.store.select('sportsbook')),
      switchMap(([x, sportsbook]) => {
        let livePageData = sportsbook['liveData']?.currentLiveData;
        this.polling$.next(true);
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'GetLiveEventsBySportHandle', 'soccer', this.timer.live, this.polling$)
          .pipe(
            map(([prev, current]) => {
              let auxSportsNav: models.SbNavbarItem[] = [];

              const setupCurrent = current.map((currentSport) => ({
                ...currentSport,
                marketNames: models.C_League.getMarketNames(
                  currentSport.Events,
                  this.globalVars.gameTypes.getSport(currentSport.SportHandle)
                )
              }));

              // Get Navbar items
              current.forEach((sport) => {
                auxSportsNav.push(new models.SbNavbarItem(sport.Name, null, sport.SportHandle, false, sport));
              });

              // Add VideoLive Navbar item
              const videoLiveSport = current.find((sport) => sport.Events.find((ev) => ev.StreamingEnabled));
              if (videoLiveSport) {
                auxSportsNav.unshift(
                  new models.SbNavbarItem(
                    SportHandleList.videoliveLiteral,
                    null,
                    SportHandleList.videolive,
                    false,
                    null
                  )
                );
              }

              // Order Navbar items
              auxSportsNav = this.sportBookService.orderSportsNav(auxSportsNav);
              if (!livePageData?.selevtedNav) {
                livePageData = {
                  ...livePageData,
                  selectedNav: videoLiveSport ? auxSportsNav[1] : auxSportsNav[0]
                };
              }

              // Set Selected Sport
              const selectedSport = this.sportBookService.setCurrentLiveSport(
                setupCurrent.find((selected) => livePageData?.selectedNav?.icon == selected?.SportHandle)
              );

              // Set selected sportNav
              const sportsNav: models.SbNavbarItem[] = auxSportsNav.map((sportNav) => ({
                ...sportNav,
                selected: sportNav.icon == livePageData?.selectedNav.icon
              }));

              const auxSportsCategories: { categories: models.C_CategoryInfo[]; currentFilter: models.C_CategoryInfo } =
                this.sportBookService.setSportsCategories(livePageData?.selectedNav?.selectedNav);

              // if (!current.length) {
              //   return;
              // }

              // if (!prev || !data.sport) {
              //   return current;
              // }
              // const currentSport = current.find((s) => s.SportId === data.sport.SportId);

              // if (!currentSport) {
              //   return current;
              // }

              // const prevSport = prev.find((s) => s.SportId === currentSport.SportId);

              // currentSport.Events.forEach((event) => {
              //   const [prevEvent] = _.intersection(prevSport.Events, event, 'NodeId');

              //   event.Games.forEach((game) => {
              //     if (!prevEvent) {
              //       return;
              //     }
              //     const g = prevEvent.Games.find((prevEvGame) => prevEvGame['NodeId'] === game['NodeId']);
              //     this.sportBookService.updateOddChanges(g, game);
              //   });
              // });
              return SportsbookActions.setLive({
                prevLiveData: prev,
                currentLiveData: {
                  sportsNav,
                  selectedNav: livePageData?.selectedNav,
                  sportCategories: auxSportsCategories?.categories,
                  selectedSportCategory: auxSportsCategories?.currentFilter,
                  selectedSport,
                  events: setupCurrent
                }
              });
            })
          );
      })
    )
  );

  stopLivePagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopLivePagePolling),
        tap(() => this.polling$.next(false))
      ),
    { functional: true, dispatch: false }
  );
}
