import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SubMenuDirective } from '../../../directives/submenu/submenu';
import { AlertController, IonicModule, MenuController, ModalController } from '@ionic/angular';
import { C_Event } from '../../../models/C_Event';
import { LicenseTypes } from '../../../models/MasterData';
import { C_EventLive } from '../../../models/C_EventLive';
import { C_Horses } from '../../../models/C_Horses';
import { C_SearchResult } from '../../../models/C_SearchResult';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../../providers/EventsService';
import { NewBaseService } from '../../../providers/newBase.service';
import { SearchService } from '../../../providers/SearchService';
import { TicketService } from '../../../providers/TicketService';
import { TrackingService } from '../../../providers/TrackingService';
import { NavService } from '../../../providers/NavService';
import { BalancesService } from '../../../providers/BalancesService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { ParseMoney } from '../../../pipes/parseMoney';
import { UserService } from '../../../providers/UserService';
import { MSO_PATHS, CASINO_PATHS, SPORTS_PATHS, PAYMENTS_PATHS } from '../../../lib/constants/routes';
import { Utils } from '../../../utils/Utils';
import { SportService } from '../../../providers/SportService';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';

@Component({
  selector: 'codere-navbar-pc-submenu',
  templateUrl: './codere-navbar-pc-submenu.html',
  styleUrls: ['./codere-navbar-pc-submenu.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, ParseMoney, SubMenuDirective]
})
export class CodereNavbarPcSubmenu {
  //#region Inject Services
  menu = inject(MenuController);
  translate = inject(TranslateService);
  sportService = inject(SportService);
  utils = inject(Utils);
  userService = inject(UserService);
  ticketService = inject(TicketService);
  events = inject(EventsService);
  modalCtrl = inject(ModalController);
  navService = inject(NavService);
  searchService = inject(SearchService);
  balancesService = inject(BalancesService);
  trackingService = inject(TrackingService);
  router = inject(Router);
  nbs = inject(NewBaseService);

  alertCtrl = inject(AlertController);
  ticketFacade = inject(NewTicketFacade);

  //#endregion

  globalVars!: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  // public trackEvent: TrackEvent
  @ViewChild('searchInput') submenu: any;
  @Input() titulo: string;
  userSubMenuClosed = true;
  searchSubMenuClosed = true;
  searchFocus = false;
  searchFocusActive = false;
  searchInputModel = '';
  searchInput: Subject<any> = new Subject();
  searchResults: any[];
  searchResultsLoading = false;
  resultsPositionLeft: string;

  constructor() {
    this.nbs.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.searchInput.pipe(debounceTime(800), distinctUntilChanged()).subscribe((search) => this.search(search));
  }

  navigate(component: string, params?: any, left = false) {
    if (left) {
      this.navService.updateNavLeft(component);
    }

    const routeMap = {
      SlotsBonusPage: CASINO_PATHS.BASE,
      MyActivityPage: MSO_PATHS.BASE,
      MyCardPage: MSO_PATHS.BASE,
      PersonalInfoPage: MSO_PATHS.BASE,
      CuotaTypePage: MSO_PATHS.BASE,
      MisApuestasPage: SPORTS_PATHS.MisApuestasPage,
      ComunicationPage: MSO_PATHS.BASE,
      QuickExit: MSO_PATHS.BASE,
      BetslipUnlockerPage: SPORTS_PATHS.BetslipUnlockerPage
    };

    const baseRoute = routeMap[component] || '/';

    if (component === 'SlotsPage' || component === 'VideoBingoPage') {
      params = { gameType: component === 'SlotsPage' ? 'slot' : 'bingo' };
    }

    switch (component) {
      case 'BetslipUnlockerPage':
        this.router.navigate([`${SPORTS_PATHS.BetslipUnlockerPage}`]);
        break;
      case 'FreeBetsPage':
        this.router.navigate([`/${SPORTS_PATHS.BASE}/${SPORTS_PATHS.FreeBetsPage}`]);
        break;
      case 'MisApuestasPage':
        const filterParam = { dateValue: 0, typeValue: 1 };
        this.router.navigate([SPORTS_PATHS.MisApuestasPage], { state: { params: filterParam } });
        break;
      default:
        this.router.navigate([baseRoute, component], { queryParams: params });
        break;
    }
  }

  /**
   * Opens Cashier modal on desktop
   * @param section Section of Cashier to open by default
   */
  navigateCashier(section?: string) {
    this.globalVars.rootScope.openModalOrPage(
      `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
      { section },
      true,
      'modalCashier is-modal'
    );
  }

  navigateCodereCard() {
    if (
      //eslint-disable-next-line
      this.globalVars.user.codereCard == null ||
      (!this.globalVars.user.codereCard.active && this.globalVars.FEATURES.CardAssociationAllowed)
    ) {
      if (this.globalVars.FEATURES.MSO_newCardCodere_Splash) {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CodereCardPage]);
      } else {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CardRequestPage]);
      }
    }
    //eslint-disable-next-line
    if (this.globalVars.licenseType == LicenseTypes.Mexico && this.globalVars.withoutWinlineCard) {
      this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CardLinkPage]);
    }
    if (this.globalVars.user.codereCard.active) {
      this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
    }
  }

  backToHome() {
    this.globalVars.rootScope.tryToGoPage(this.globalVars.mainPageApp);
  }

  refreshBalance() {
    this.balancesService.refreshBalance();
  }

  gotohome() {
    this.router.navigate(['/']);
  }

  redirect(item: any) {
    // this.globalVars.gNav.setRoot(NowLiveDetailPage, { item: item })
  }

  doLogout() {
    //logout
    this.events.publish('user:logout');
    this.events.publish('navbarpc:refresh');
    this.userService.userLogged = false;
    // this.globalVars.rootScope.changeTicketType(1);
  }

  isActive(component: string) {
    if (component === 'HomePage') {
      const deportes = [
        'EventoPage',
        'SportCountriesPage',
        'Crea-Tu-Apuesta',
        'MercadosPage',
        'HorsesPage',
        'HorsesMarket',
        'HomePage',
        'UltimoMinutoPage',
        'EventoMejoraPage',
        'TodayPage'
      ];
      const index = deportes.findIndex((s) => s === this.router.url); //***+*** REVISAR ESTO
      //eslint-disable-next-line
      return index != -1;
    } else {
      if (component === 'VideoBingoPage' || component === 'SlotsPage') {
        return component === window.location.hash.split('/')[1];
      }
      return component === this.router.url; //***+*** REVISAR ESTO
    }
  }

  toggleSubMenu(submenu: string, toggle?: any) {
    switch (submenu) {
      case 'search':
        this.searchSubMenuClosed = toggle.value || !this.searchSubMenuClosed;
        this.resultsPositionLeft = this.submenu.nativeElement.getBoundingClientRect().right - 400 + 'px';
        break;
      case 'user':
        if (
          !!toggle.target.attributes.value &&
          !!toggle.target.attributes.value.value &&
          //eslint-disable-next-line
          toggle.target.attributes.value.value == 'false'
        ) {
          this.userSubMenuClosed = false;
        } else {
          this.userSubMenuClosed = toggle.value || !this.userSubMenuClosed;
        }
        break;
    }
    if (!this.userSubMenuClosed) {
      this.balancesService.hideBalanceDetails();
    }
  }

  toggleInputWidth(input: any, focus?: boolean) {
    this.searchFocus = !!focus;
    if (!input.value) {
      this.searchFocusActive = !this.searchFocusActive;
      this.searchResults = [];
    }
  }

  search(term: string) {
    if (term) {
      this.searchSubMenuClosed = false;
      this.searchResultsLoading = true;
      this.searchService.getResultSearch(term).subscribe((results: any) => {
        this.searchResults = results;
        this.searchResultsLoading = false;
      });
    }
  }

  btItemSearch_Click($event, item: C_SearchResult) {
    this.globalVars.SportSelected = '';
    //eslint-disable-next-line
    if (item.SportHandle == 'greyhound_racing' || item.SportHandle == 'horse_racing') {
      const objHorsesAux: C_Horses = new C_Horses(
        item.Name,
        item.NodeId,
        item.ParentNodeId,
        item.EventNodeTypeId,
        item.Priority,
        item.SportHandle,
        item.ChildrenCount,
        item.Country,
        false,
        0,
        item.PaddockId,
        item.StartDate
      );
      this.navigate('HorsesMarket', { item: objHorsesAux });
    } else {
      if (item.isLive) {
        const objEventoAuxLive: C_EventLive = new C_EventLive(
          item.ResultInfo,
          '',
          item.SportHandle,
          '',
          0,
          '',
          true,
          item.Games,
          item.LeagueName,
          item.StartDate,
          item.isLive,
          item.StatisticsId,
          item.StreamingEnabled,
          item.Name,
          item.NodeId,
          item.ParentNodeId,
          item.EventNodeTypeId,
          item.Priority,
          item.SportHandle,
          item.ChildrenCount
        );
        this.navigate('NowLiveDetailPage', { item: objEventoAuxLive });
      } else {
        const objEventoAux: C_Event = new C_Event(
          item.Games,
          item.League,
          item.StartDate,
          item.isLive,
          item.StatisticsId,
          item.StreamingEnabled,
          item.Name,
          item.NodeId,
          item.ParentNodeId,
          item.Priority,
          item.SportHandle,
          item.ChildrenCount
        );
        this.navigate('MercadosPage', { item: objEventoAux });
      }
    }
  }

  changed(ev: any) {
    this.searchResultsLoading = true;
    this.searchInput.next(ev);
  }
}
