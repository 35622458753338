<section *ngIf="auxBanners && auxBanners?.length">
  <swiper-container #swiperCSV init="false" *ngIf="visible" class="swiper-container">
    <swiper-slide
      *ngFor="let banner of auxBanners"
      [ngClass]="{'cursor-pointer': !isTeamMach(banner)}"
      (click)="clickSlide(banner)"
      class="slots-slider"
    >
      <div class="slider-banner newCarousel">
        <img [src]="!isDesktop ? banner?.bannerMobileImage : banner?.bannerDesktopImage" />
        <codere-slider-container
          *ngIf="banner?.typeOfTemplate !== 'Simple image' && banner?.typeOfTemplate !== 'Casino' &&
        banner?.typeOfTemplate !== 'Slots'"
          class="newCarouselWrapper"
          [ngClass]="{'ismobile3' : banner?.typeOfTemplate === 'One Odd' && !isDesktop}"
          [slide]="banner"
          (clickedSlide)="listenerContainerEvent($event)"
        ></codere-slider-container>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="swiper-pagination-csv"></div>
  <ng-container *ngIf="auxBanners && auxBanners?.length && visible && isDesktop">
    <button class="swiper-button-prev-csv slide-control prev" ion-button>
      <ion-icon name="chevron-back-outline"></ion-icon>
    </button>
    <button class="swiper-button-next-csv slide-control next" ion-button>
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
  </ng-container>
</section>
