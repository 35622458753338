/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform, inject } from '@angular/core';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from '../providers/newBase.service';
import { DisplayType } from '../models/sportbook/display-tipe.enum';
const VICTORYMARGIN = 6;

@Pipe({
  name: 'getNameResult',
  standalone: true
})
export class GetNameResultPipe implements PipeTransform {
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  transform(value: string, args: any[]): string {
    if (!value) {
      return;
    }
    const [type, typeName, index, isCombined, home, away, sport] = args;

    if (isCombined) {
      return;
    }

    if (this.mustParse(type, this.lisOfMarketsMustBeReplaceName)) {
      return this.replaceOddName(typeName, value, home, away, type, sport);
    }

    return this.selectDisplayType(typeName, type, index, value, home, away, sport);
  }

  private selectDisplayType(
    typeName: any,
    type: number,
    index: any,
    value: string,
    home: string,
    away: string,
    sport
  ): string {
    switch (typeName) {
      case DisplayType.THREEWAY:
      case DisplayType.THREEBUTTONLIST:
      case DisplayType.THREEBUTTONLINE:
        return this.mustParse(type, this.allowedThreePositions) ? this.threeWay(value, home, away, sport) : value;
      case DisplayType.TWOBUTTONLINE:
      case DisplayType.TWOBUTTONLIST:
      case DisplayType.TWOWAY:
      case DisplayType.SIXBUTTONLIST:
        return this.mustParse(type, this.allowedTwoPositions) ? this.twoWay(index, value, home, away, sport) : value;
      case DisplayType.GOALSCORER:
      case DisplayType.OUTRIGHTLIST:
        return value;
      default:
        return value;
    }
  }

  replaceOddName(typeName, value, home, away, type, sport): string {
    switch (typeName) {
      case DisplayType.THREEWAY:
      case DisplayType.THREEBUTTONLIST:
      case DisplayType.THREEBUTTONLINE:
        return this.threeWayReplaceNames(value, home, away, sport, type);
      case DisplayType.TWOBUTTONLINE:
      case DisplayType.TWOBUTTONLIST:
      case DisplayType.TWOWAY:
      case DisplayType.SIXBUTTONLIST:
      case DisplayType.OUTRIGHTLISTALPHABETICAL:
        return this.twoWayReplaceNames(value, home, away, type, sport);
      default:
        return value;
    }
  }
  private twoWayReplaceNames(value: any, home: string, away: string, type: number, sport: string): string {
    if (!value) {
      return;
    }
    const regexGetPitcher = /\(([^)]+)\)/g;
    const pitcherName = value.match(regexGetPitcher);
    if (this.mustParse(type, this.specialTypes)) {
      value = value.replace(new RegExp(home, 'g'), '1').replace(new RegExp(away, 'g'), '2');
    } else {
      value = value.replace(home, this.replaceHome(home)).replace(away, this.replaceAway(away));
    }

    if (pitcherName && value && sport === 'baseball') {
      value = value.replace(pitcherName, '');
    }
    return value;
  }

  private replaceHome(home: string): string {
    if (!home) {
      return '';
    }
    return home.replace(/\s/g, '').slice(0, 3).toUpperCase();
  }

  private replaceAway(away: string): string {
    if (!away) {
      return '';
    }
    return away.replace(/\s/g, '').slice(0, 3).toUpperCase();
  }

  private threeWayReplaceNames(value, home: string, away: string, sport: string, type?: any): string {
    const regexGetPitcher = /\(([^)]+)\)/g;
    const regexEmpate = /(empate)+/gi;

    if (sport !== 'baseball') {
      home = home.replace('(', '[(]').replace(')', '[)]');
      away = away.replace('(', '[(]').replace(')', '[)]');
    }
    if (type == VICTORYMARGIN) {
      return this.twoWayReplaceNames(value, home, away, type, sport);
    } else {
      let newValue = value
        .replace(new RegExp(home, 'g'), '1')
        .replace(new RegExp(away, 'g'), '2')
        .replace(regexEmpate, 'X');
      const pitcherName = newValue.match(regexGetPitcher);
      if (pitcherName && sport === 'baseball') {
        newValue = newValue.replace(pitcherName, '');
      }
      return newValue;
    }
  }

  private mustParse(gameType: number, comparisonArray?: number[]): boolean {
    return comparisonArray.indexOf(gameType) !== -1;
  }

  private replace(originalValue: string, comparison: string, result: string): string {
    return originalValue.replace(comparison, result);
  }

  private threeWay(value: string, home: string, away: string, sport: string): string {
    const regexGetPitcher = /\(([^)]+)\)/g;
    const pitcherName: string[] = value.match(regexGetPitcher);

    if (sport !== 'baseball') {
      home = home.replace('(', '[(]').replace(')', '[)]');
      away = away.replace('(', '[(]').replace(')', '[)]');
    }

    let newValue = value.replace(new RegExp(home, 'g'), '1').replace(new RegExp(away, 'g'), '2').replace('Empate', 'X');

    if (pitcherName && sport === 'baseball') {
      newValue = newValue.replace(pitcherName[0], '');
    }
    return newValue;
  }

  private twoWay(index, value, home, away, sport: string): string {
    if (index % 2 === 0) {
      index = 0;
    }
    if (!home) {
      return index === 0 ? this.replace(value, value, '1') : this.replace(value, value, '2');
    }
    return this.threeWayReplaceNames(value, home, away, sport);
  }

  /*special markets to set the team name*/
  specialTypes: number[] = [
    1728 /* football liveMarket Name: partido doble oportunidad y marcan ambos equipos */,
    1298 /* football liveMarket Name: 1ª doble oportunidad y marcan ambos equipos */,
    1299 /* football liveMarket Name: 2ª doble oportunidad y marcan ambos equipos */
  ];

  allowedTwoPositions: number[] = [
    1, 3, 13, 40, 97 /* e-SPORTS && basket winners*/, 110, 145, 154, 158, 167,
    /* 171 cambiado por el handicap de hockey, */
    172, 178, 181, 182, 184 /* ice hockey  Ganador del Partido*/, 211 /* Tenis 1º set */, 213, 219 /* Tenis 2º set */,
    227, 237, 244, 247, 250, 251, 258, 262 /* tenis/Voley ganador set X -> se cambio de la lista de short names */,
    263 /* tenis ganador del juego x set y */, 264, 278 /* Hockey Ganador 1er Periodo (sin empate) */,
    279 /* Hockey Ganador 2º Periodo (sin empate) */ /* tenis ganador del juego x set y */, 284, 291, 304, 314, 408,
    453, 458, 530, 762, 772, 828, 832, 833, 862, 870, 872, 873, 947, 948, 979, 1002, 1005, 1006, 1007, 1008, 1010, 1035,
    1059, 1089, 1195, 1204, 1206, 1303, 1304, 1305, 1335, 1336, 1365, 1368, 1373, 1383, 1391, 1392, 1409, 1413, 1466,
    1476, 1484, 1485, 1509, 1511, 1545, 1550, 1577, 1611, 1615, 1617, 1621, 1703
  ];

  allowedThreePositions: number[] = [
    0, 1, 3, 12, 15, 45, 78, 80, 80, 111, 116, 139, 156, 158, 166, 172 /* ice hockey  */,
    184 /* ice hockey  Ganador del Partido*/, 198, 204, 206, 209, 210, 211, 227, 235, 240, 245, 246, 249, 288, 296, 312,
    317, 405, 765, 771, 828, 833, 838, 872, 958, 986, 995, 1014, 1034, 1126, 1193, 1205, 1208, 1210, 1310, 1311, 1321,
    1365, 1368, 1449, 1455, 1460, 1461, 1475, 1487, 1517, 1521, 1523, 1525, 1563, 1565, 1566, 1567, 1568, 1569, 1570,
    1571, 1575, 1576, 1621, 1672, 1771, 10258, 10502, 10982, 10986
  ];

  lisOfMarketsMustBeReplaceName: number[] = [
    2, 4 /*  1x2 handicap football */, 5 /* handicap asiatico */, 6 /* margen de victoria */, 12 /* Half-time Result */,
    13 /* handicap asiatico */, 14 /* Name: "Australia Femenino / Australia Femenino" */,
    15 /* American football 2nd Half Result */, 18, 37 /* EaSports */, 88, 121, 147,
    155 /* LiveMarkets Name: "Sabah FA S21 -1" && name: en footbal soccer Australia Femenino -1 */,
    159 /* balonmano handicap sin empate */, 160 /* mal en american football  Más 24.5*/,
    171 /* Handicap 1º Periodo ice Hockey */, 177 /* ice hockey  1X2 2º periodo*/, 185 /* ice hockey Handicap */,
    193 /* futbol americano handicap 2 mitad  */, 201 /* Name: "Hándicap por Juegos" tenis */,
    204 /* Tenis handicap por sets */, 228, 238, 245, 248 /* Basket: Handicap 3er Cuarto (sin empate) */,
    259 /* Name: "Handicap (sin empate) basketball && ice hockey && handicap nfl*/,
    277 /* Baseball 1 equipo x carreras */, 284 /* Resultado Final dardos */,
    292 /* Badminton  primero en marcar 5 puntos*/, 293 /* Badminton  handicap puntos totales set 2*/,
    294 /* Badminton Primero en Marcar X Puntos en el Set Y */, 321, 366 /* Resultado final set 2 */,
    512 /* Tiebreak in match tennis */, 514 /* Tiebreak in set tennis */, 794 /* Footbal australiano */,
    798 /* ice hockey Handicap 2º Periodo*/, 799 /* ice hockey Handicap 3º Periodo*/, 821,
    825 /* Handicap (Sin empate) Primeras 5 Entradasbeisbol */, 829 /* este esta mal en basebal */,
    830 /* Handicap sin empate de la 7ª entrada beisbol */, 874, 875, 914 /* Tenis resultado final */, 959,
    1003 /* eaSports Name: "Más 12.5" */, 1011 /* eaSports Name: "Sí" */, 1012 /* eaSports Name: "Más de 4.5" */,
    1013 /* eaSports Name: "Más de 1.5" */, 1019 /* e-sports Handicapspevent */,
    1065 /* football liveMarket Name: "Rep. Checa Femenino/X y Más 1.5" */, 1192 /* Badminton Ganador del Set X */,
    1728 /* football liveMarket Name: doble oportunidad y marcan ambos equipos */,
    1298 /* football liveMarket Name: 1ª doble oportunidad y marcan ambos equipos */,
    1299 /* football liveMarket Name: 2ª doble oportunidad y marcan ambos equipos */,
    1330 /* Baseball Ganador del Partido y Más/Menos Carreras */,
    1366 /* Handicap sin empate de la 3ª entrada beisbol */, 1369 /* mal baseball */,
    1384 /* Handicap sin empate de la 1ª entrada baseball */, 1490, 1491 /* Voley puntos por Handicap */,
    1505 /* Voley Hándicap por Puntos en el Set 2 */, 1508 /* Badminton  handicap puntos totales set X*/,
    1524 /* Badminton  handicap puntos totales*/, 1702 /* Set 2 - Ganador y Total de Juegos Más/Menos tenis */,
    235 /*Table tennis handicap @ juego 1 */, 251 /*Table tennis Handicap @ juego 4 */, 1322 /*Table Tennis */,
    2433 /*Soccer Resultado final Multi */
  ];
}
