<ion-content
  class="newSlots borderRadius"
  [ngClass]="{'background-color-white': isDesktop, 'is-background-overlay': !isDesktop}"
  padding
  *ngIf="!globalVars.FEATURES.CAS_GameSessionImprovements; else sessionImprovements"
  [class.margin6rem]="!isDesktop"
>
  <div
    class="slotCofig"
    [class.slot-config--newWrapper]="isDesktop"
    [class.background-color-white]="!isDesktop"
    [class.borderRadius]="!isDesktop"
    padding
  >
    <div class="slot-config--header background-color-white">
      <button
        style="background-color: white"
        ion-button
        clear
        class="close-modal2"
        (click)="close()"
        iconOnly
        margin-top
        margin-right
      >
        <ion-icon name="close-outline"></ion-icon>
      </button>
    </div>
    <div *ngIf="lessThan60MinutesMessage" class="lessThan60MinutesMessageAlert">
      <ion-icon name="alert-circle-outline" class="lessThan60MinutesMessageAlert--icon"></ion-icon>
      <div>
        <span innerHtml="{{lessThan60MinutesMessage}}"> </span>
        <a (click)="openMoreInfo()" class="moreInfo"> {{ 'moreInfo2' | translate }} </a>
      </div>
    </div>
    <div class="slot-config--title2" [class.marginTop]="isDesktop && this.scrHeight > 768">
      <h3 class="color-primary" no-margin>Añade los límites a la sesión</h3>
    </div>
    <form [formGroup]="slotsConfigForm">
      <div class="slot-config--field marginTop">
        <p class="slot-config--section-title marginMin" no-margin margin-top>
          {{ 'slotmaxdurationSession' | translate }}
        </p>
        <div class="slot-config--radio-wrapper">
          <div
            class="slot-config--radio-buttonSpa"
            *ngFor="let session of sessionTimeOptions"
            (click)="updateSessionTime(session?.value)"
            [class.is-selected2]="
            slotsConfigForm?.get('sessionTime')?.value === session?.value"
          >
            <p>{{ session?.label }}</p>
          </div>
        </div>
        <p class="error" *ngIf="!slotsConfigForm.controls['sessionTime'].valid && clickSend">
          Selecciona un periodo de tiempo
        </p>
      </div>

      <div class="slot-config--amount">
        <div class="slot-config--amount__info">
          <p class="slot-config--section-title marginMin" no-margin margin-top>{{ 'slotSpendingLimit' | translate }}</p>
          <p *ngIf="utils.isArgentina()">{{ 'slotminreq1' | translate }}</p>
        </div>
        <div class="slot-config--amount__bet2">
          <input
            #inputFocus
            class="borderG"
            [class.is-focused]="amountFocus"
            [ngClass]="{ 'ok-input': slotsConfigForm.controls['amount'].valid }"
            inputmode="numeric"
            formControlName="amount"
            (focus)="toggleFocus()"
            (blur)="toggleFocus()"
            placeholder="{{placeholder}}"
            (change)="changeAmount()"
          />
        </div>
      </div>
      <p class="error" *ngIf="!slotsConfigForm.controls['amount'].valid && clickSend">
        Introduce un límite de gasto válido
      </p>

      <div class="slot-config--field marginTop">
        <p class="slot-config--section-title marginMin" no-margin>{{ 'slotActivityReminder' | translate }}</p>

        <div class="slot-config--radio-wrapper">
          <div
            class="slot-config--radio-buttonSpa"
            *ngFor="let time of activityOptions"
            (click)="updateActivityTime(time)"
            [class.is-selected2]="
            slotsConfigForm?.get('activityTime')?.value === time"
          >
            <p *ngIf="time !== 60">{{ time }} min</p>
            <p *ngIf="time === 60">1 hora</p>
          </div>
        </div>
        <p class="error" *ngIf="!slotsConfigForm.controls['activityTime'].valid && clickSend">
          Selecciona un periodo de tiempo
        </p>
      </div>
      <div *ngIf="isSpainOrColombia()" class="slot-config--field">
        <p class="slot-config--section-title marginMin" no-margin>{{ 'slotRestrictionTime' | translate }}</p>

        <ion-item
          lines="none"
          no-margin
          class="borderBottom selectLimits"
          [ngClass]="{ 'ok-input': slotsConfigForm.controls['restrictionTime'].touched }"
        >
          <ion-select
            class="slot-config--select"
            formControlName="restrictionTime"
            toggleIcon="chevron-down-outline"
            [(ngModel)]="selectedRestrictionTime"
            (ionChange)="trackOption('restrictionTime', selectedRestrictionTime)"
            [interfaceOptions]="{cssClass: 'restrictionSlotsSelect'}"
          >
            <ion-select-option
              *ngFor="
                  let restriction of restrictionTimeOptions;
                  let index = index"
              [value]="restriction?.value"
            >
              {{ restriction?.label }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </form>
    <div class="container__button">
      <button
        class="container__button__b button2"
        block
        (click)="sendConfig()"
        [class.margin6rem]="isDesktop && this.scrHeight > 768"
        [class.margin4rem]="!isDesktop"
      >
        <span class="is-bold">{{ 'slotStartSession' | translate }}</span>
      </button>
    </div>
  </div>
</ion-content>

<ng-template #sessionImprovements>
  <ion-content
    class="newSlots borderRadius"
    [ngClass]="{'background-color-white': isDesktop, 'is-background-overlay': !isDesktop}"
    padding
    [class.margin6rem]="!isDesktop"
  >
    <div
      class="slotCofig"
      [class.slot-config--newWrapper]="isDesktop"
      [class.background-color-white]="!isDesktop"
      [class.borderRadius]="!isDesktop"
      padding
    >
      <div class="slot-config--header background-color-white">
        <button
          style="background-color: white"
          ion-button
          clear
          class="close-modal2"
          (click)="close()"
          iconOnly
          margin-top
          margin-right
        >
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>
      <div *ngIf="lessThan60MinutesMessage" class="lessThan60MinutesMessageAlert">
        <ion-icon name="alert-circle-outline" class="lessThan60MinutesMessageAlert--icon"></ion-icon>
        <div>
          <span innerHtml="{{lessThan60MinutesMessage}}"> </span>
          <a (click)="openMoreInfo()" class="moreInfo"> {{ 'moreInfo2' | translate }} </a>
        </div>
      </div>
      <div class="slot-config--title2" [class.marginTop]="isDesktop && this.scrHeight > 768">
        <h3 class="color-primary" no-margin>{{ 'slotAddSessionLimits' | translate }}</h3>
      </div>
      <form [formGroup]="slotsConfigForm">
        <div class="slot-config--field marginTop">
          <p class="slot-config--section-title marginMin" no-margin margin-top>
            {{ 'slotmaxdurationSession' | translate }}
          </p>
          <div class="slot-config--radio-wrapper">
            <div
              class="slot-config--radio-buttonSpa"
              *ngFor="let session of sessionTimeOptions"
              (click)="updateSessionTime(session?.value)"
              [class.is-selected2]="
            slotsConfigForm?.get('sessionTime')?.value === session?.value"
            >
              <p>{{ session?.label }}</p>
            </div>
          </div>
        </div>

        <div class="slot-config--amount">
          <div class="slot-config--amount__info">
            <p class="slot-config--section-title marginMin" no-margin margin-top>
              {{ 'slotSpendingLimit' | translate }}
            </p>
          </div>
          <div *ngIf="totalBalance >= 10" class="slot-config--pre-options">
            <div
              class="pre-option"
              (click)="updateBalance(totalBalance)"
              [class.is-selected2]="
            compareWithBalance(totalBalance)"
            >
              <p class="pre-option-amount" no-margin margin-top>{{totalBalance}} {{globalVars.currencySymbol}}</p>
              <p class="pre-option-text" no-margin margin-top>{{ 'slotOneHundredPercent' | translate }}</p>
            </div>
            <div
              class="pre-option"
              (click)="updateBalance(seventyFivePercentBalance)"
              [class.is-selected2]="
            compareWithBalance(seventyFivePercentBalance)"
            >
              <p class="pre-option-amount" no-margin margin-top>
                {{seventyFivePercentBalance}} {{globalVars.currencySymbol}}
              </p>
              <p class="pre-option-text" no-margin margin-top>{{ 'slotSeventyFivePercent' | translate }}</p>
            </div>
            <div
              class="pre-option"
              (click)="updateBalance(fiftyPercentBalance)"
              [class.is-selected2]="
            compareWithBalance(fiftyPercentBalance)"
            >
              <p class="pre-option-amount" no-margin margin-top>
                {{fiftyPercentBalance}} {{globalVars.currencySymbol}}
              </p>
              <p class="pre-option-text" no-margin margin-top>{{ 'slotFiftyPercent' | translate }}</p>
            </div>
          </div>
          <div class="slot-config--amount__bet2">
            <input
              class="borderG"
              #inputFocus
              [class.is-focused]="amountFocus"
              [ngClass]="{ 'ok-input': slotsConfigForm.controls['amount'].valid }"
              inputmode="numeric"
              formControlName="amount"
              (focus)="toggleFocus()"
              (blur)="toggleFocus()"
              placeholder="{{placeholder}}"
              (change)="changeAmount()"
            />
            <div class="total-amount">
              {{ 'balancetotal' | translate }} <span class="bold"> {{totalBalance}} {{globalVars.currencySymbol}}</span>
            </div>
          </div>
        </div>

        <ion-accordion-group class="slot-config--accordion-group">
          <ion-accordion class="accordion">
            <ion-item slot="header" class="accordion-item">
              <ion-label class="accordion-item-title">
                <span class="bold">{{ 'slotCreateActivityReminder' | translate }}</span></ion-label
              >
            </ion-item>
            <div class="slot-config--accordion-content" slot="content">
              <div class="slot-config--field">
                <p class="slot-config--section-title marginMin" no-margin>{{ 'slotActivityReminder' | translate }}</p>

                <div class="slot-config--radio-wrapper">
                  <div
                    class="slot-config--radio-buttonSpa"
                    *ngFor="let time of activityOptions"
                    (click)="updateActivityTime(time)"
                    [class.is-selected2]="
                  slotsConfigForm?.get('activityTime')?.value === time"
                  >
                    <p *ngIf="time !== 60">{{ time }} min</p>
                    <p *ngIf="time === 60">1 hora</p>
                  </div>
                </div>
              </div>
              <div *ngIf="isSpainOrColombia()" class="slot-config--field">
                <p class="slot-config--section-title marginMin" no-margin>{{ 'slotRestrictionTime' | translate }}</p>

                <ion-item
                  lines="none"
                  no-margin
                  class="borderBottom selectLimits"
                  [ngClass]="{ 'ok-input': slotsConfigForm.controls['restrictionTime'].touched }"
                >
                  <ion-select
                    class="slot-config--select"
                    formControlName="restrictionTime"
                    mode="md"
                    [(ngModel)]="selectedRestrictionTime"
                    (ionChange)="trackOption('restrictionTime', selectedRestrictionTime)"
                    [interfaceOptions]="{cssClass: 'restrictionSlotsSelect', mode: undefined}"
                  >
                    <ion-select-option
                      *ngFor="
                        let restriction of restrictionTimeOptions;
                        let index = index"
                      [value]="restriction?.value"
                    >
                      {{ restriction?.label }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </div>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </form>
      <div class="container__confirm-button">
        <button
          class="container__button__b confirm-button"
          block
          (click)="sendConfig()"
          [disabled]="!slotsConfigForm.valid"
        >
          <span class="is-bold">{{ 'slotStartSession' | translate }}</span>
        </button>
      </div>
    </div>
  </ion-content>
</ng-template>
