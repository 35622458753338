import { Component, ViewChild, OnInit, inject } from '@angular/core';
import { NavController, NavParams, ModalController, IonContent } from '@ionic/angular';
import { Utils } from '../../../utils/Utils';
import { UserService } from '../../../providers/UserService';
import { TrackingService } from '../../../providers/TrackingService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NewBaseService } from '../../../providers/newBase.service';

@Component({
  selector: 'cashier-request-info-ko',
  templateUrl: './cashier-request-info-ko.html',
  styleUrls: ['./cashier-request-info-ko.scss'],
  host: {
    class: 'sb-alert cs-request-info'
  },
  standalone: true,
  imports: [TranslateModule, CommonModule]
})
export class CashierRequestInfoPageKo implements OnInit {
  @ViewChild('content') content: IonContent;

  showVer = false;
  intensivePlayer = false;
  options;
  decoder: string;
  globalVars!: GlobalVarsModel;

  nav = inject(NavController);
  translate = inject(TranslateService);
  navParams = inject(NavParams);
  modalCtrl = inject(ModalController);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  utils = inject(Utils);
  private trackingService = inject(TrackingService);

  constructor() {
    this.newBaseService.getVars.subscribe((vars: GlobalVarsModel) => {
      this.globalVars = vars;
    });

    this.options = this.navParams.data;
    if (this.options.paymentArguments.error) {
      if (this.options.paymentArguments.error.search('174') > -1) {
        this.showVer = true;
      } else if (this.options.paymentArguments.error.search('2216') > -1) {
        this.intensivePlayer = true;
        this.options.paymentArguments.title = this.translate.instant('playresponsibly').toUpperCase();
      }
    }
  }

  ngOnInit(): void {
    this.decoder = decodeURI(this.options.paymentArguments.error);
    this.userService.sendErrors(this.decoder);
  }

  ngOnDestroy() {
    this.utils.unloadContactChat();
  }

  ionViewWillEnter() {
    if (this.globalVars.FEATURES.chatEnabedToFailureDeposit) {
      this.utils.loadContactChat();
      this.initChat();
    }
    if (this.options.type === 'withdrawal') {
      this.trackingService.trackEvent([
        'CashierWithdrawalKo',
        this.options.error ? this.options.error : '',
        '',
        '',
        'event'
      ]);
    } else {
      this.trackingService.trackEvent([
        'CashierDepositKO',
        this.options.error ? this.options.error : '',
        '',
        '',
        'event'
      ]);
    }
  }

  ionViewDidLoad() {
    //eslint-disable-next-line
    if (this.globalVars.browser == 'internet') {
      //** TODO */
      // this.content.resize();
    }
  }

  initChat() {
    this.userService.getUserData().subscribe(
      (data) => {
        // window["SnapEngage"].setUserEmail(data.Direccion_Correo, true);
        // window["SnapEngage"].startLink();
        this.utils.openContactChat('normal', data.Direccion_Correo);
      },
      () => {
        // window["SnapEngage"].startLink();
        this.utils.openContactChat();
      }
    );
    this.trackingService.trackEvent(['runChat', 'CashierPaymentKo', '', '', 'event']);
  }

  acceptClick() {
    this.modalCtrl.dismiss();
  }

  goVerDoc() {
    this.modalCtrl.dismiss(this.showVer);
  }
}
