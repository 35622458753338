/* eslint-disable @typescript-eslint/no-explicit-any */
export class C_Horses {
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  GameTypeId?: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  CountryCode: string;
  IsActive: boolean;
  OddsAvailability: number;
  PaddockId: string;
  StartDate: string;
  Races?: any[];
  Selected = false;
  Locked: boolean;
  StreamingEnabled: string;
  StatisticsId: string;
  StreamingId: string;
  PlaceDisabled: any;
  WinDisabled: any;

  constructor(
    name: string,
    nodeId: string,
    parentNodeId: string,
    eventNodeTypeId: number,
    priority: number,
    sporthandle: string,
    childrenCount: number,
    countryCode: string,
    active: boolean,
    oddsAvailability: number,
    paddockId: string,
    startDate: string,
    GameTypeId?: number,
    races?: any[],
    locked?: boolean,
    streamingEnabled?: string,
    statisticsId?: string,
    streamingId?: string
  ) {
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.EventNodeTypeId = eventNodeTypeId;
    this.GameTypeId = GameTypeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;

    this.CountryCode = countryCode;
    this.IsActive = active;
    this.OddsAvailability = oddsAvailability;
    this.PaddockId = paddockId;
    this.StartDate = startDate;
    this.Races = races;
    this.Locked = locked;
    this.StreamingEnabled = streamingEnabled;
    this.StatisticsId = statisticsId;
    this.StreamingId = streamingId;
  }

  get hourName() {
    return this.hour + ' ' + this.Name;
  }

  get hour() {
    return this.extractHour(this.StartDate);
  }

  //NEEDS REFACTOR
  private extractHour(date: string) {
    const current = new Date(parseInt(date.slice(6, date.length - 2)));

    return this.formatoNumber(current.getHours(), 2) + ':' + this.formatoNumber(current.getMinutes(), 2);
  }

  private formatoNumber(numero: any, digitos: number): string {
    //devuelve el string del número con n dígitos
    let numberStr: string = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }

  static races = {
    type: {
      horse: 'horse_racing',
      greyhound: 'greyhound_racing'
    },
    name: {
      horse: 'Carrera de caballos',
      greyhound: 'Carrera de galgos'
    }
  };
}
