<div
  [ngClass]="{
  'lobby': theme === 'lobby',
  'sportbook': theme === 'sportbook',
  }"
>
  <div
    *ngIf="hasLeftSection"
    class="sb-navbar--left"
    #navbarLeft
    [ngClass]="{'sb-navbar--left-rebranding': globalVars.FEATURES?.MSO_NewReBranding}"
  >
    <ng-content select="[navbar-left]"></ng-content>
    <div *ngIf="hasBackButton" class="sb-navbar--back-button">
      <i class="sb-back-button color-light codere-icon icon-arrow-left" tappable (click)="navigate()"></i>
    </div>
  </div>

  <div class="sb-navbar--list scrollHidden" #list>
    <ng-content></ng-content>
    <div
      class="sb-filter-line fade-in"
      [hidden]="active === -1 ? true : null"
      *ngIf="bar"
      #line
      [ngClass]="{'background-color-primary': !globalVars.FEATURES?.MSO_NewReBranding,
        'sb-filter-line-rebranding': globalVars.FEATURES?.MSO_NewReBranding
      }"
    ></div>
  </div>

  <div class="sb-navbar--actions" *ngIf="hasActions">
    <ng-content select="[navbar-right]"></ng-content>
  </div>
</div>
