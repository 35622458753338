import {
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  Renderer2,
  OnInit,
  inject
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LicenseTypes } from '../../../models/MasterData';
import { Observable } from 'rxjs';
import { BankAccountMethodBehaviour } from '../../../providers/PaymentMethodService/BankTransfer';
import { CoinspaidMethodBehaviourResult } from '../../../providers/PaymentMethodService/CoinspaidDeposit';
import {
  DepositModel,
  IFramePaymentMethodBehaviourResult,
  IQRPaymentMethodBehaviour
} from '../../../providers/PaymentMethodService/PaymentMethodBase';
import { EPGMethodBehaviour } from '../../../providers/PaymentMethodService/EPG';
import { LocalCodereMethodBehaviour } from '../../../providers/PaymentMethodService/LocalCodere';
import { OxxoBankPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/OxxoBank';
import { OxxoCashPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/OxxoCash';
import {
  PaymentMethodBehaviour,
  PaymentMethodBehaviourResult,
  QRPaymentMethodBehaviourResult,
  RedirectMethodBehaviourResult,
  RedsysMethodBehaviourResult,
  TrueLayerMethodBehaviourResult,
  ValidateDepositModel,
  RedsysCardsMethodBehaviourResult,
  LotbaPaymentMethodBehaviourResult
} from '../../../providers/PaymentMethodService/PaymentMethodBase';
import {
  PaynetMethodBehaviourResult,
  PaynetPaymentMethodBehaviour
} from '../../../providers/PaymentMethodService/Paynet';
import { PaypalPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/Paypal';
import { PaysafecardMethodBehaviour } from '../../../providers/PaymentMethodService/PaysafeCard';
import { PayUPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/PayU';
import {
  PayValidaCashMethodBehaviourResult,
  EfectyMethodtBehaviourResul,
  PayValidaCashPaymentMethodBehaviour
} from '../../../providers/PaymentMethodService/PayValida';
import { PuntoPagoMethodBehaviour } from '../../../providers/PaymentMethodService/PuntoPago';
import {
  TeleingresoMethodBehaviourResult,
  TeleingresoPaymentMethodBehaviour
} from '../../../providers/PaymentMethodService/Teleingreso';
import { TelePayPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/TelePay';
import { TpagaMethodBehaviourResult } from '../../../providers/PaymentMethodService/TpagaDeposit';
import { VisaBBVAMexPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/VisaBBVAMex';
import { RedsysMethodBehaviour } from '../../../providers/PaymentMethodService/BizumRedsys';
import { RedsysCardsMethodBehaviour } from '../../../providers/PaymentMethodService/RedsysCards';
import { LotbaPaymentMethodBehaviour } from '../../../providers/PaymentMethodService/Lotba';
import {
  EfectyPaymentMethodBehaviour,
  DimonexPayValidaCashPaymentMethodBehaviour
} from '../../../providers/PaymentMethodService/PayValida';
import { EventsService } from '../../../providers/EventsService';
import { BankcvuMethodBehaviour } from '../../../providers/PaymentMethodService/BankcvuMethodBehaviour';
import { TrueLayerMethodBehaviour } from '../../../providers/PaymentMethodService/TrueLayer';
import { CrowCasinoDepositMethodBehaviour } from '../../../providers/PaymentMethodService/CrowCasinoDeposit';
import { NewBaseService } from '../../../providers/newBase.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { ParseMoney } from '../../../pipes/parseMoney';
import { UtilsCashierService } from '../../../providers/UtilsCashierService';
import { UserService } from '../../../providers/UserService';
import { BalancesService } from '../../../providers/BalancesService';
import { DeviceService } from '../../../services/device.service';
import { PersonalInfoPage } from '../../../pages/mso/personal-info/personal-info';
import { Pagos360MethodBehaviour } from '../../../providers/PaymentMethodService/Pagos360';
import { Pagos360TransBankMethodBehaviour } from '../../../providers/PaymentMethodService/Pagos360TransBank';
import { CashierRequestInfoPageOk } from '../../../pages/payments/cashier-request-info-ok/cashier-request-info-ok';
import { CashierMovement } from '../../../models/payments/cs-step-movement';
import { CsPendingPayments } from '../../../models/payments/cs-pending-payments';
import { ModalController, NavController, NavParams, Platform } from '@ionic/angular';
import { GeolocationService } from '../../../providers/GeolocationService';
import { IabService } from '../../../providers/iabService';
import { Utils } from '../../../utils/Utils';
import { SlotsBonusesService } from '../../../providers/SlotsBonusesService';
import { ErrorService } from '../../../providers/ErrorService';
import { CashierPendingPaymentsPage } from '../cashier-pending-payments/cashier-pending-payments';
import { CommonModule } from '@angular/common';
import { CsTransactionItemComponent } from '../../../components/payments/cs-transaction-item/cs-transaction-item';
import { CsCalculatorComponent } from '../../../components/payments/cs-calculator/cs-calculator';
import { CsOxxobankFormComponent } from '../../../components/payments/cs-oxxobank-form/cs-oxxobank-form';
import { CsTransferDepositComponent } from '../../../components/payments/cs-transfer-deposit/cs-transfer-deposit';
import { CsLocalCodereComponent } from '../../../components/payments/cs-local-codere/cs-local-codere';
import { CsPuntoPagoComponent } from '../../../components/payments/cs-punto-pago/cs-punto-pago';
import { CsCrownCasinoComponent } from '../../../components/payments/cs-crown-casino/cs-crown-casino';
import { CsTrueLayerComponent } from '../../../components/payments/cs-truelayer/cs-truelayer';
import { CsPaymentIframeComponent } from '../../../components/payments/cs-payment-iframe/cs-payment-iframe';
import { CsQrCodeComponent } from '../../../components/payments/cs-qr-code/cs-qr-code';
import { EventTypes } from '../../../models/TrackingEvents';
import { Capacitor } from '@capacitor/core';
import { DOCUMENT_VERIFICATION_STATES } from '../../../models/payments/DocumentVerificationStates';
import { TransactionActionResult, RISKY_PLAYER_ERROR_CODE } from '../../../models/C_Transactions';
import { NearestLocalPage } from '../../../pages/mso/nearest-local/nearest-local';
import { CsVerificationAlertModal } from '../../../components/payments/cs-verification-alert-modal/cs-verification-alert-modal';
import { Router } from '@angular/router';
import { MSO_PATHS } from '../../../lib/constants/routes';
import { KeyBoardComponent } from '../../../components/common/keyboard/keyboard';
import { ScriptService } from '../../../providers/ScriptService';
import { CsPayvalidaOkComponent } from '../../../components/payments/cs-payvalida-ok/cs-payvalida-ok';
import { VerificationAlerts } from '@providers/VerificationAlert';
import { PAYMENT_CONSTANTS } from 'libs/shared/src/constants/payment.constants';
import { Browser } from '@capacitor/browser';

/**
 * Comes from Paypal CDN script, global paypal variable with properties
 */
// eslint-disable-next-line no-var
declare var paypal;

@Component({
  selector: 'page-cashier-deposit',
  templateUrl: './cashier-deposit.html',
  styleUrls: ['./cashier-deposit.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CsTransactionItemComponent,
    CsCalculatorComponent,
    TranslateModule,
    ReactiveFormsModule,
    CsOxxobankFormComponent,
    CsTransferDepositComponent,
    CsLocalCodereComponent,
    CsPuntoPagoComponent,
    CsCrownCasinoComponent,
    CsTrueLayerComponent,
    CsPaymentIframeComponent,
    CsQrCodeComponent,
    KeyBoardComponent,
    CsPayvalidaOkComponent
  ]
})
export class CashierDepositPage extends CashierMovement implements OnInit {
  @ViewChild('content') content: ElementRef;
  @ViewChild('truelayer') truelayer;
  @Input() paymentOptions: PaymentMethodBehaviour[];
  @Input() sectionStep: number;
  @Output() keyboardOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() paymentSelection: EventEmitter<any> = new EventEmitter();
  @Output() setSectionStep: EventEmitter<number> = new EventEmitter();
  @Output() sendToDocuments: EventEmitter<number> = new EventEmitter();
  @Output() trackingEvent: EventEmitter<any[]> = new EventEmitter();
  @Output() trackingTrack: EventEmitter<any> = new EventEmitter();
  @Output() lotbaMessage: EventEmitter<any> = new EventEmitter();
  @Output() requestInfo: EventEmitter<any> = new EventEmitter();

  paymentSelected: any;
  paymentForm: FormGroup;
  lotbaCodeNumber = new FormControl();
  model: DepositModel = new DepositModel();
  documentnumber = '';
  visibleDocument = true;
  numBBAMex = 0;
  oxxoMexForm = false;
  showLotbaMessage = false;
  currentPayment: string;

  skipAmountInput: boolean;
  currentPaymentMethodBehaviour: PaymentMethodBehaviour;
  iFramePaymentMethodBehaviour: PaymentMethodBehaviour;
  iFramePaymentMethodBehaviourResult: IFramePaymentMethodBehaviourResult;
  bankAccountMethodBehaviour: BankAccountMethodBehaviour;
  BankcvuMethodBehaviour: BankcvuMethodBehaviour;
  localCodereMethodBehaviour: LocalCodereMethodBehaviour;
  openPayPaynetMethodBehaviourResult: PaynetMethodBehaviourResult;
  openPayPaynetPaymentMethodBehaviour: PaynetPaymentMethodBehaviour;
  paypalPaymentMethodBehaviour: PaypalPaymentMethodBehaviour;
  payValidaCashMethodBehaviourResult: PayValidaCashMethodBehaviourResult;
  payValidaCashPaymentMethodBehaviour: PayValidaCashPaymentMethodBehaviour;
  puntoPagoBehaviour: PuntoPagoMethodBehaviour;
  trueLayerBehaviour: TrueLayerMethodBehaviour;
  trueLayerMethodBehaviourResult: TrueLayerMethodBehaviourResult;
  qrDepositBehaviour: IQRPaymentMethodBehaviour;
  qrMethodBehaviourResult: QRPaymentMethodBehaviourResult;
  redirectMethodBehaviourResult: RedirectMethodBehaviourResult;
  lotbaPaymentMethodBehaviour: LotbaPaymentMethodBehaviour;
  lotbaPaymentMethodBehaviourResult: LotbaPaymentMethodBehaviourResult;
  efectyPaymentMethodBehaviour: EfectyPaymentMethodBehaviour;
  dimonexPayValidaCashPaymentMethodBehaviour: DimonexPayValidaCashPaymentMethodBehaviour;
  efectyMethodtBehaviourResul: EfectyMethodtBehaviourResul;

  teleingresoMethodBehaviourResult: TeleingresoMethodBehaviourResult;
  teleIngresoPaymentMethodBehaviour: TeleingresoPaymentMethodBehaviour;

  // Redsys implementation
  redsysMethodBehaviour: RedsysMethodBehaviour;
  redsysMethodBehaviourResult: RedsysMethodBehaviourResult;
  redsysCardsMethodBehaviour: RedsysCardsMethodBehaviour;
  redsysCardsMethodBehaviourResult: RedsysCardsMethodBehaviourResult;
  CrownCasinoBehaviour: CrowCasinoDepositMethodBehaviour;

  redsysCardSelectionObj: any;
  currentDeposit = false;
  showPromoColombia = true;
  override stepPosition = 0;
  stepNumber = 1;
  transition: any;

  calculatorConfig = {
    counterTitle: 'Importe de deposito',
    buttonsTitle: 'Importes',
    isDesktop: null,
    license: null,
    currency: ''
  };
  transferConfig: any = {
    iban: 'ES30 0182 3999 3402 0158 1889',
    swift: 'BBVAESMM',
    beneficiary: 'Codere Online SAU',
    tipoCuenta: 'Cuenta Corriente',
    alias: ''
  };
  transferCVU: any = {
    cvu: '',
    state: '',
    id: ''
  };

  localConfig: any = {
    amountTaxes: '',
    amountCharge: '',
    code: '',
    barcode: '',
    userName: '',
    typeDeposit: true
  };

  pendingPayments: CsPendingPayments[];
  pendingPayments$: Observable<CsPendingPayments[]>;

  errorMessageAmount = '';

  isActiveKeyboard = false;
  hidePaypalButton = true;
  hideInSiteForm = true;
  validAmount = true;
  showDepositarButton = true;
  isPaypalMethod = false;
  blockCalculator = false;
  stateDepositButton = false;
  payPalAmount = 0;
  paymentsAllowed: boolean;
  paragraphNoPayment: string;

  optionsRequestKo: any = {
    error: '',
    type: 'deposit',
    title: 'depositKOtitle',
    subtitle: 'depositKO'
  };

  geoLocation: any = {
    location: false
  };

  optionsRequestOk: any = {
    idTransaction: '',
    type: 'deposit',
    title: 'depositOK',
    subtitle: 'tDepOk',
    amount: '',
    isFirstDeposit: '',
    paymentMethod: ''
  };

  isUserVerified: boolean;
  globalVars!: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  isMobile: boolean;
  isDesktop: boolean;

  isOperationBlocked = false;

  nav = inject(NavController);
  navParams = inject(NavParams);
  newBaseService = inject(NewBaseService);
  translateService = inject(TranslateService);
  fb = inject(FormBuilder);
  viewCtrl = inject(ModalController);
  renderer = inject(Renderer2);
  parseMoney = inject(ParseMoney);
  utilsCsService = inject(UtilsCashierService);
  platform = inject(Platform);
  geolocationService = inject(GeolocationService);
  iabService = inject(IabService);
  userService = inject(UserService);
  utils = inject(Utils);
  slotsBonusesService = inject(SlotsBonusesService);
  balanacesService = inject(BalancesService);
  errorService = inject(ErrorService);
  deviceService = inject(DeviceService);
  modalCtrl = inject(ModalController);
  events = inject(EventsService);
  router = inject(Router);
  scriptService = inject(ScriptService);
  verificationAlertService = inject(VerificationAlerts);

  constructor(override _renderer: Renderer2) {
    super(_renderer);
    this.newBaseService.getVars.subscribe((vars: GlobalVarsModel) => {
      this.globalVars = vars;
    });
    this.calculatorConfig.license = this.globalVars.licenseType;
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();
    this.calculatorConfig.isDesktop = this.isDesktop;
    this.calculatorConfig.currency = this.globalVars.currencyLiteral;

    const subscription = this.utilsCsService.currentPayment.subscribe((payment) => {
      if (payment) {
        subscription.unsubscribe();
        this.selectOption(payment);
      }
    });
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      this.transferConfig.iban = '01202035978';
      this.transferConfig.swift = 'Mercantil';
      this.transferConfig.beneficiary = 'Alta Cordillera S.A';
      this.transferConfig.tipoCuenta = 'Cuenta Corriente';
    }
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      this.transferConfig.iban = ' '; //" 0290083400000501086932"
      this.transferConfig.swift = ''; //"30-64407126-6"
      this.transferConfig.beneficiary = ''; //"Iberargen, S.A. - Cuenta corriente en pesos"
      this.transferConfig.tipoCuenta = ''; // 'Cuenta corriente en pesos'
      this.transferConfig.alias = ''; //' CODERE.APUESTAS'
    }
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza) {
      this.transferConfig.iban = '2590050910248404610091'; //"2590050910248404610091"
      this.transferConfig.swift = '30718405676'; //"30718405676"
      this.transferConfig.beneficiary = 'CODERE ONLINE ARGENTINA UTE'; //"CODERE ONLINE ARGENTINA UTE"
      this.transferConfig.tipoCuenta = ''; // 'Cuenta corriente en pesos'
      this.transferConfig.alias = ''; //' CODERE.APUESTAS'
    }

    this.paymentsAllowed = true;
    this.paragraphNoPayment = 'Su usuario tiene restringida la acción de depósito.';
  }

  ngOnInit(): void {
    this.isActiveKeyboard = false;
    this.checkUserAllowed();
  }

  ngOnChanges() {
    this.step = this.sectionStep;
    if (
      this.sectionStep === 1 ||
      this.sectionStep === 3 ||
      this.currentPaymentMethodBehaviour == this.trueLayerBehaviour
    )
      this.isActiveKeyboard = false;
    if (this.sectionStep === 2) this.iFramePaymentMethodBehaviourResult = undefined;
    if (this.globalVars.extraUserData.blockDeposits && this.sectionStep === 2) {
      this.step = 1;
    }
    this.move();
  }

  ngAfterViewInit() {
    this.slotsBonusesService.storeIforiumPromoCookie();
    this.getPendingOnlinePayments();
    if (this.content?.nativeElement) {
      this.setContainer(this.content.nativeElement);
    }
    this.loadUserData();
  }

  ngOnDestroy() {
    this.setSectionStep.emit(1);
  }

  checkCurrentDepositAndLicense() {
    if (this.currentDeposit) {
      return 'background-color-dark cs-deposit-caba';
    }
    return 'cs-deposit-caba';
  }

  checkUserAllowed() {
    let geo = true;
    if (
      (this.globalVars.isGeolocalized == true && this.globalVars.isCaba == true) ||
      this.globalVars.licenseType != LicenseTypes.ArgentinaCaba
    ) {
      geo = true;
    } else {
      geo = false;
      this.paragraphNoPayment =
        'Detectamos que estas fuera de CABA. Para poder depositar y/o jugar tenés que estar dentro de la Ciudad Autónoma de Buenos Aires. Contactanos para cualquier consulta:';
    }

    if (geo) {
      this.paymentsAllowed = true;
    } else {
      this.paymentsAllowed = false;
    }
  }

  public getPendingOnlinePayments() {
    if (!this.globalVars.FEATURES.getPendingOnlinePaymentsEnabled) {
      this.pendingPayments = [];
      return;
    }
    this.userService.getPendingOnlinePayments(this.globalVars.user.username).subscribe({
      next: (data) => {
        if (!data || data.length === 0) {
          this.pendingPayments = [];
          return;
        }
        const offlinePayments = ['Hal-cash', this.translateService.instant('typeextractotarnom'), 'Tarjeta nominativa'];
        this.pendingPayments = data.filter((payment) => offlinePayments.indexOf(payment.Metodo) === -1);
      },
      error: (err) => {
        this.utils.showError(err);
      }
    });
  }

  async showPendingPayments() {
    const modal = await this.modalCtrl.create({
      component: CashierPendingPaymentsPage,
      cssClass: 'pending-payments-modal',
      componentProps: {
        pendingPayments: this.pendingPayments
      }
    });
    modal.present();
    modal.onDidDismiss().then((detail) => {
      const { data } = detail;
      this.balanacesService.refreshBalance(true);
      if (!data) this.pendingPayments = <CsPendingPayments[]>data;
    });
  }

  selectOption(payment) {
    this.verificationAlertService.checkDNIExpired();
    if (!this.globalVars.extraUserData.blockDeposits) {
      this.currentPayment = payment.name.toLowerCase();
      this.currentDeposit = true;
      this.setInputKeyboardtl(false);
      this.isActiveKeyboard = false;
      this.stateDepositButton = false;
      this.utilsCsService.setInitTruelayer();
      if (
        this.pendingPayments &&
        this.pendingPayments.length > 0 &&
        payment.name.toLowerCase() !== 'local codere' &&
        payment.name.toLowerCase() !== 'transferencia'
      ) {
        //Add Tpaga??
        this.showPendingPayments();
        return;
      }
      if (payment.name.toLowerCase() === 'local codere') {
        this.bindBarcode();
      }
      this.paymentSelected = { ...payment };
      this.paymentSelection.emit(payment);
      this.depositType(payment);
      if (this.globalVars.licenseType == LicenseTypes.Panama) {
        this.paymentSelected.amountButtons = [10, 30, 70, 100, 500, 1000];
      }
      this.setSectionStep.emit(2);
      this.globalVars.paymentMethod = 'deposit';
      this.globalVars.paymentType = payment;
    }
  }

  newDeposit() {
    if (!this.globalVars.extraUserData.blockDeposits) {
      this.setSectionStep.emit(2);
    }
  }

  closeKeyBoardTL(e) {
    this.utilsCsService.closeTrueLauerKeyboard();
    this.closeKeyBoard('0');
    this.setBlockCalculator(false);
  }

  amountSelected(amount: number) {
    this.paymentForm.get('amount').setValue(amount);
    this.currentPaymentMethodBehaviour.amount = amount;
    this.validAmount = true;
  }

  setCustomAmount(customOption: string) {
    const currentAmount = this.paymentForm.get('amount').value;
    const newAmount = this.utilsCsService.setCustomAmount(customOption, currentAmount, this.paymentSelected);
    this.paymentForm.get('amount').setValue(newAmount);
    this.currentPaymentMethodBehaviour.amount = newAmount;
  }

  inputAmount(event: number) {
    this.paymentForm.get('amount').setValue(event);
    this.currentPaymentMethodBehaviour.amount = event;

    if (!this.paymentForm.get('amount').valid) {
      this.utilsCsService.showInfoAlert(this.translateService.instant('Attention'), this.errorMessageAmount);
      this.setCustomAmount('');
      this.validAmount = false;
    } else {
      this.validAmount = true;
    }
  }

  setInputKeyboard() {
    if (!this.isDesktop) {
      this.isActiveKeyboard = !this.isActiveKeyboard;
      this.isActiveKeyboard ? this.keyboardOpen.emit(true) : this.keyboardOpen.emit(false);
    }
  }

  setInputKeyboardtl(state: boolean) {
    if (!this.isDesktop) {
      this.isActiveKeyboard = !this.isActiveKeyboard;
      state ? this.keyboardOpen.emit(true) : this.keyboardOpen.emit(false);
    }
  }

  pressInputKeyBoard(event) {
    if (this.currentPaymentMethodBehaviour == this.trueLayerBehaviour) {
      this.truelayer.pressInputKeyBoard(event);
    } else {
      const fixedValue = parseFloat(event).toFixed(2);
      const parsedValue = parseFloat(fixedValue);
      this.paymentForm.get('amount').setValue(parsedValue);
      this.currentPaymentMethodBehaviour.amount = parsedValue;
    }
  }

  closeKeyBoard(event: string) {
    if (this.currentPaymentMethodBehaviour == this.trueLayerBehaviour) {
      this.truelayer.closeKeyBoard(event);
    } else {
      if (!this.paymentForm.get('amount').valid) {
        const realValue = this.paymentForm.get('amount').value;
        this.utilsCsService.showInfoAlert(this.translateService.instant('Attention'), this.errorMessageAmount);
        realValue < this.paymentSelected.minDepositAmount
          ? this.paymentForm.get('amount').setValue(this.paymentSelected.minDepositAmount)
          : this.paymentForm.get('amount').setValue(this.paymentSelected.maxDepositAmount);
        this.currentPaymentMethodBehaviour.amount = this.paymentSelected.amount;
      }
    }
    this.isActiveKeyboard = false;
    this.keyboardOpen.emit(false);
  }

  checkOxxoDeposit() {
    this.oxxoMexForm = true;
    this.checkDeposit();
  }

  checkDeposit(setted?) {
    const isLicenseAr = this.globalVars.licenseType === LicenseTypes.ArgentinaCaba;
    const isLicenseEs =
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Madrid ||
      this.globalVars.licenseType === LicenseTypes.PaisVasco;

    if (!setted) this.currentPaymentMethodBehaviour.amount = this.paymentForm.get('amount').value;
    if (
      this.currentPaymentMethodBehaviour.documentNumberRequired &&
      this.visibleDocument &&
      this.sectionStep === 2 &&
      !this.oxxoMexForm
    ) {
      this.setSectionStep.emit(3);
      return;
    }
    if (this.step === 2 && isLicenseAr && this.currentPaymentMethodBehaviour.name === 'Lotba') {
      this.lotbaPaymentMethodBehaviour.code = this.lotbaCodeNumber.value;
    }

    this.trackingTrack.emit({
      eventType: EventTypes.DepositClickDeposit,
      additionalData: {
        userId: this.globalVars.user.username,
        paymentMethod: this.currentPaymentMethodBehaviour.name,
        currencyIsoCode: this.globalVars.currencyISOCode,
        amount: this.currentPaymentMethodBehaviour.amount
      }
    });

    this.oxxoMexForm = false;

    const isValid =
      this.currentPaymentMethodBehaviour.documentNumberRequired && this.visibleDocument
        ? this.paymentForm.get('documentNumber').valid && this.paymentForm.get('amount').valid
        : this.paymentForm.get('amount').valid;

    if (isValid) {
      if (!this.validAmount) {
        this.validAmount = true;
        return;
      }
      if (this.currentPaymentMethodBehaviour.documentNumberRequired && this.visibleDocument) {
        this.currentPaymentMethodBehaviour.documentNumber = this.paymentForm.get('documentNumber').value;
      }

      if (isLicenseEs && this.currentPaymentMethodBehaviour.amount > 150) {
        this.checkUserVerification();
        if (!this.isUserVerified) {
          return;
        }
      }

      const result: Observable<PaymentMethodBehaviourResult> =
        this.currentPaymentMethodBehaviour.paymentMetodExecution();
      this.utils.loader();
      result.subscribe(
        (paymentMethodresult) => {
          this.utils.closeLoader();
          if (paymentMethodresult.success) {
            this.trackingEvent.emit([
              'CashierDeposit',
              this.currentPaymentMethodBehaviour.track,
              this.currentPaymentMethodBehaviour.amount.toString(),
              '',
              'event'
            ]);

            if (paymentMethodresult instanceof IFramePaymentMethodBehaviourResult) {
              this.iFramePaymentMethodBehaviourResult = <IFramePaymentMethodBehaviourResult>paymentMethodresult;
              if (this.sectionStep === 2) {
                this.setSectionStep.emit(3);
              }
            } else if (paymentMethodresult instanceof TeleingresoMethodBehaviourResult) {
              this.teleingresoMethodBehaviourResult = <TeleingresoMethodBehaviourResult>paymentMethodresult;
              this.skipAmountInput = true;
              this.setSectionStep.emit(3);
            } else if (paymentMethodresult instanceof TrueLayerMethodBehaviourResult) {
              this.trueLayerMethodBehaviourResult = <TrueLayerMethodBehaviourResult>paymentMethodresult;
              this.skipAmountInput = true;
              if (this.trueLayerMethodBehaviourResult.url) {
                if (!this.globalVars.isNative) {
                  window.location.href = this.trueLayerMethodBehaviourResult.url;
                }
              }
            } else if (paymentMethodresult instanceof PayValidaCashMethodBehaviourResult) {
              this.payValidaCashMethodBehaviourResult = <PayValidaCashMethodBehaviourResult>paymentMethodresult;
              this.skipAmountInput = true;
              this.setSectionStep.emit(3);
            }

            // Redys implementation
            else if (paymentMethodresult instanceof RedsysMethodBehaviourResult) {
              this.redsysMethodBehaviourResult = <RedsysMethodBehaviourResult>paymentMethodresult;
              this.setSectionStep.emit(3);
            } else if (paymentMethodresult instanceof RedsysCardsMethodBehaviourResult) {
              this.redsysCardsMethodBehaviourResult = <RedsysCardsMethodBehaviourResult>paymentMethodresult;
              this.setSectionStep.emit(3);
            } else if (paymentMethodresult instanceof RedirectMethodBehaviourResult) {
              this.redirectMethodBehaviourResult = <RedirectMethodBehaviourResult>paymentMethodresult;
              this.openLinkExternal(this.redirectMethodBehaviourResult.url);
              // Lineas comentadas en la antigua arquitectura
              // this.launchExternalBehaviour(this.redirectMethodBehaviourResult['url']);
              // this.openLinkExternal(this.redirectMethodBehaviourResult.url);
            } else if (paymentMethodresult instanceof PaynetMethodBehaviourResult) {
              this.openPayPaynetMethodBehaviourResult = <PaynetMethodBehaviourResult>paymentMethodresult;
              this.skipAmountInput = true;
            } else if (paymentMethodresult instanceof TpagaMethodBehaviourResult) {
              this.qrMethodBehaviourResult = <QRPaymentMethodBehaviourResult>paymentMethodresult;
              this.skipAmountInput = true;
              if (this.isDesktop) {
                this.setSectionStep.emit(3);
              } else {
                this.launchExternalBehaviour(this.qrMethodBehaviourResult['url']);
              }
            } else if (paymentMethodresult instanceof CoinspaidMethodBehaviourResult) {
              this.qrMethodBehaviourResult = <QRPaymentMethodBehaviourResult>paymentMethodresult;
              this.skipAmountInput = true;
              this.setSectionStep.emit(3);
            }
            // LOTBA
            else if (paymentMethodresult instanceof LotbaPaymentMethodBehaviourResult) {
              this.lotbaPaymentMethodBehaviourResult = <LotbaPaymentMethodBehaviourResult>paymentMethodresult;
              this.listenEvents(this.lotbaPaymentMethodBehaviourResult);
            } else if (paymentMethodresult instanceof EfectyMethodtBehaviourResul) {
              this.efectyMethodtBehaviourResul = <EfectyMethodtBehaviourResul>paymentMethodresult;
              this.setSectionStep.emit(3);
            }
          } else {
            paymentMethodresult.erroMessage.subscribe(
              (erroMessage) => {
                if (paymentMethodresult.sendToDocumentVerificationPage) {
                  this.utils.confirm(
                    false,
                    this.translateService.instant('tFail'),
                    erroMessage,
                    this.translateService.instant('tCancel'),
                    () => {},
                    this.translateService.instant('check'),
                    () => {
                      setTimeout(() => {
                        this.sendToDocuments.emit();
                      }, 800);
                    }
                  );
                } else if (paymentMethodresult.sendToUserProfileForm) {
                  this.utils.confirm(
                    false,
                    this.translateService.instant('tFail'),
                    erroMessage,
                    this.translateService.instant('tCancel'),
                    () => {},
                    this.translateService.instant('tInPChange') + ' ' + this.translateService.instant('v2_phone'),
                    () => {
                      setTimeout(() => {
                        this.linkOutOfModalPage(PersonalInfoPage, {});
                      }, 800);
                    }
                  );
                } else if (paymentMethodresult.sendToProfesion) {
                  this.utils.confirm(
                    false,
                    this.translateService.instant('Attention'),
                    this.translateService.instant('V2_profesionRequest'),
                    this.translateService.instant('tCancel'),
                    null,
                    this.translateService.instant('tCont'),
                    () => {
                      this.GotoPersonaInfoPage();
                    },
                    true
                  );
                } else {
                  this.utilsCsService.showInfoAlert('Error', erroMessage);
                }
              },
              (notFound) => {
                if (paymentMethodresult.errorcode === RISKY_PLAYER_ERROR_CODE) {
                  this.utils.showError(
                    'Por la política de “Juego más seguro” el cajero se ha modificado en función de tu perfil'
                  );
                } else {
                  this.events.publish('user:serverfailmaintenace');
                }
              }
            );
          }
        },
        (error) => {
          this.utils.closeLoader();
        }
      );
    } else {
      this.utilsCsService.showInfoAlert(this.translateService.instant('Attention'), this.errorMessageAmount);
    }
  }

  GotoPersonaInfoPage(params?: any) {
    if (this.isDesktop) {
      this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.PersonalInfoPage]).then(() => {
        this.viewCtrl.dismiss();
      });
    } else {
      this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.PersonalInfoPage]).then(() => {
        this.viewCtrl.dismiss();
      });
    }
  }

  linkOutOfModalPage(link: any, paymentArguments: any) {
    if (this.isDesktop) {
      if (link == NearestLocalPage) {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.NearestLocalPage]).then(() => {
          this.modalCtrl.dismiss();
        });
      } else {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.PersonalInfoPage]).then(() => {
          this.modalCtrl.dismiss();
        });
      }
    } else {
      if (link == NearestLocalPage) {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.NearestLocalPage]);
      } else {
        this.router.navigate([link, { paymentArguments }, { animate: !this.isDesktop }]);
      }
    }
  }

  depositType(paymentMethodBehaviour) {
    const isFirstClick = !this.currentPaymentMethodBehaviour;
    this.iFramePaymentMethodBehaviour = undefined;
    this.paypalPaymentMethodBehaviour = undefined;
    this.teleIngresoPaymentMethodBehaviour = undefined;
    this.openPayPaynetPaymentMethodBehaviour = undefined;
    this.bankAccountMethodBehaviour = undefined;
    this.payValidaCashPaymentMethodBehaviour = undefined;
    this.redirectMethodBehaviourResult = undefined;
    this.localCodereMethodBehaviour = undefined;
    this.skipAmountInput = false;
    this.qrDepositBehaviour = undefined;
    this.BankcvuMethodBehaviour = undefined;
    this.puntoPagoBehaviour = undefined;
    this.redsysMethodBehaviourResult = undefined;
    this.redsysCardsMethodBehaviour = undefined;
    this.trueLayerBehaviour = undefined;
    this.showDepositarButton = true;
    this.dimonexPayValidaCashPaymentMethodBehaviour = undefined;
    const requestDocumentNumber: boolean = paymentMethodBehaviour.documentNumberRequired;
    this.CrownCasinoBehaviour = undefined;
    this.lotbaPaymentMethodBehaviour = undefined;
    this.hidePaypalButton = true;
    this.isPaypalMethod = false;

    this.currentPaymentMethodBehaviour = <PaymentMethodBehaviour>paymentMethodBehaviour;
    if (
      this.currentPaymentMethodBehaviour instanceof OxxoBankPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof OxxoCashPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof PaysafecardMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof TelePayPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof VisaBBVAMexPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof PayUPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof OxxoCashPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof OxxoBankPaymentMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof EPGMethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof Pagos360MethodBehaviour ||
      this.currentPaymentMethodBehaviour instanceof Pagos360TransBankMethodBehaviour
    ) {
      this.iFramePaymentMethodBehaviour = this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof RedsysMethodBehaviourResult) {
      this.redsysMethodBehaviourResult = <RedsysMethodBehaviourResult>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof RedsysCardsMethodBehaviour) {
      this.hidePaypalButton = true;
      this.redsysCardsMethodBehaviour = <RedsysCardsMethodBehaviour>this.currentPaymentMethodBehaviour;
      this.loadRedsysBehaviour();
    } else if (this.currentPaymentMethodBehaviour instanceof DimonexPayValidaCashPaymentMethodBehaviour) {
      this.dimonexPayValidaCashPaymentMethodBehaviour = <DimonexPayValidaCashPaymentMethodBehaviour>(
        this.currentPaymentMethodBehaviour
      );
    } else if (this.currentPaymentMethodBehaviour instanceof EfectyPaymentMethodBehaviour) {
      this.efectyPaymentMethodBehaviour = <EfectyPaymentMethodBehaviour>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof PayValidaCashPaymentMethodBehaviour) {
      this.payValidaCashPaymentMethodBehaviour = <PayValidaCashPaymentMethodBehaviour>(
        this.currentPaymentMethodBehaviour
      );
    } else if (this.currentPaymentMethodBehaviour instanceof PaypalPaymentMethodBehaviour) {
      this.paypalPaymentMethodBehaviour = <PaypalPaymentMethodBehaviour>this.currentPaymentMethodBehaviour;
      this.hidePaypalButton = false;
      this.showDepositarButton = false;
      this.isPaypalMethod = true;
    } else if (this.currentPaymentMethodBehaviour instanceof TeleingresoPaymentMethodBehaviour) {
      this.teleIngresoPaymentMethodBehaviour = <TeleingresoPaymentMethodBehaviour>this.currentPaymentMethodBehaviour;

      this.teleIngresoPaymentMethodBehaviour.skipAmountInput().subscribe((x) => {
        if (x.skip) {
          this.teleingresoMethodBehaviourResult = new TeleingresoMethodBehaviourResult();
          this.teleingresoMethodBehaviourResult.transactionCode = x.transactionCode;
          this.teleingresoMethodBehaviourResult.amount = x.amount;
          this.teleingresoMethodBehaviourResult.date = x.date;
          this.skipAmountInput = true;
        }
      });
    } else if (this.currentPaymentMethodBehaviour instanceof BankAccountMethodBehaviour) {
      this.hidePaypalButton = true;
      this.bankAccountMethodBehaviour = <BankAccountMethodBehaviour>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof LotbaPaymentMethodBehaviour) {
      this.lotbaPaymentMethodBehaviour = <LotbaPaymentMethodBehaviour>this.currentPaymentMethodBehaviour;
    }
    if (this.currentPaymentMethodBehaviour instanceof BankcvuMethodBehaviour) {
      this.hidePaypalButton = true;
      this.isActiveKeyboard = false;
      this.BankcvuMethodBehaviour = <BankcvuMethodBehaviour>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof LocalCodereMethodBehaviour) {
      this.hidePaypalButton = true;
      this.localCodereMethodBehaviour = <LocalCodereMethodBehaviour>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof CrowCasinoDepositMethodBehaviour) {
      this.hidePaypalButton = true;
      this.CrownCasinoBehaviour = <CrowCasinoDepositMethodBehaviour>this.currentPaymentMethodBehaviour;
      this.skipAmountInput = true;
    } else if (this.currentPaymentMethodBehaviour instanceof PuntoPagoMethodBehaviour) {
      this.hidePaypalButton = true;
      this.puntoPagoBehaviour = <PuntoPagoMethodBehaviour>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof TrueLayerMethodBehaviour) {
      this.hidePaypalButton = true;
      this.trueLayerBehaviour = <TrueLayerMethodBehaviour>this.currentPaymentMethodBehaviour;
    }
    //qr methods implements an interface, so instanceof will not work here, we should use type guard
    else if (this.isQr(this.currentPaymentMethodBehaviour)) {
      this.hidePaypalButton = true;
      this.qrDepositBehaviour = <IQRPaymentMethodBehaviour>this.currentPaymentMethodBehaviour;
    } else if (this.currentPaymentMethodBehaviour instanceof PaynetPaymentMethodBehaviour) {
      this.openPayPaynetPaymentMethodBehaviour = <PaynetPaymentMethodBehaviour>this.currentPaymentMethodBehaviour;
      this.openPayPaynetPaymentMethodBehaviour.skipAmountInput().subscribe({
        next: (response) => {
          if (
            response.skip &&
            response.barCodeUrl != undefined &&
            response.transactionReference != undefined &&
            response.amount != undefined
          ) {
            this.openPayPaynetMethodBehaviourResult = new PaynetMethodBehaviourResult();
            this.openPayPaynetMethodBehaviourResult.transactionCode = response.transactionCode;
            this.openPayPaynetMethodBehaviourResult.amount = response.amount;
            this.openPayPaynetMethodBehaviourResult.barCodeUrl = response.barCodeUrl;
            this.openPayPaynetMethodBehaviourResult.transactionReference = response.transactionReference;
            this.openPayPaynetMethodBehaviourResult.date = response.date;
            this.skipAmountInput = true;
          }
        },
        error: (error) => {
          console.error(error);
        }
      });
    }

    if (requestDocumentNumber && this.visibleDocument) {
      this.paymentForm = this.fb.group({
        amount: [this.currentPaymentMethodBehaviour.amount, this.amountValidator],
        documentNumber: [this.documentnumber, this.documentNumberValidation]
      });
    } else {
      this.paymentForm = this.fb.group({
        amount: [this.currentPaymentMethodBehaviour.amount, this.amountValidator]
      });
      this.currentPaymentMethodBehaviour.documentNumber = this.documentnumber;
    }

    this.trackingTrack.emit({
      eventType: EventTypes.AccessPaymentMethod,
      id: paymentMethodBehaviour.name,
      additionalData: { isFirstClick: isFirstClick }
    });
  }

  async renderPaypalButton() {
    // Load paypal script from CDN
    await this.scriptService.loadScript('paypal');
    this.paypalPaymentMethodBehaviour.buttonEnabled = true;
    this.hidePaypalButton = false;
    const { UrlTransactionsApiBase, FEATURES } = this.globalVars;
    const { Payments_Paypal_UsePaymentsv2, Payments_deposits_URL } = FEATURES;
    const amount = this.currentPaymentMethodBehaviour.amount;
    let CREATE_PAYMENT_URL = UrlTransactionsApiBase + '/Payments/CreatePaypalDeposit?amount=' + amount;
    if (Payments_Paypal_UsePaymentsv2) {
      CREATE_PAYMENT_URL = Payments_deposits_URL.replace('{0}', PAYMENT_CONSTANTS.METHOD.PAYPAL);
      CREATE_PAYMENT_URL = CREATE_PAYMENT_URL.replace('{1}', PAYMENT_CONSTANTS.VERSION.PAYPAL);
    }
    const EXECUTE_PAYMENT_URL = this.globalVars.UrlTransactionsApiBase + '/Payments/ExecutePaypalDeposit';
    const inPaypalPaymentMethodBehaviour = this.paypalPaymentMethodBehaviour;

    // Renders paypal button within #paypal-button
    paypal.Button.render(
      {
        style: {
          label: 'paypal', // checkout | credit | pay | buynow | paypal
          size: 'responsive', // small | medium | large | responsive
          shape: 'pill', // pill | rect
          color: 'blue', // gold | blue | silver
          tagline: false
        },
        env: this.globalVars.FEATURES.PaypalClientEnviorement,
        commit: true,
        payment: (_actions) => {
          const promise = Payments_Paypal_UsePaymentsv2
            ? paypal.request.post(CREATE_PAYMENT_URL, { amount })
            : paypal.request.post(CREATE_PAYMENT_URL);
          return promise.then((data) => inPaypalPaymentMethodBehaviour.onCreatePayment(data));
        },
        onAuthorize: (data, _actions) =>
          paypal['request']
            .post(EXECUTE_PAYMENT_URL, {
              paymentId: data.paymentID,
              payerID: data.payerID
            })
            .then((data2) => inPaypalPaymentMethodBehaviour.onExecutePayment(data2))
      },
      '#paypal-button'
    );
  }

  async openLinkExternal(link: string) {
    if (this.isDesktop) {
      this.modalCtrl.dismiss().then(() => {
        window.open(link, '_self');
      });
    } else {
      if (Capacitor.isNativePlatform()) {
        try {
          await Browser.open({
            url: link,
            windowName: '_self',
            presentationStyle: 'fullscreen',
            width: 500,
            height: 800
          });
        } catch (error) {
          let msg =
            'Puede que la ventana este bloqueada por el navegador, compruebelo y pulse en abrir la nueva ventana';
          if (this.numBBAMex > 3) {
            msg += "Puede abrir de todas maneras pusaldo <a href='" + link + "'>aqui</a>";
          }
          this.utils.alert(true, '', msg, this.translateService.instant('ifrFdbMinAmountAcceptBtn'));
          this.numBBAMex++;
        }
      } else {
        window.open(link, '_self');
      }
    }
  }

  private launchExternalBehaviour(url: string) {
    if (Capacitor.isNativePlatform()) {
      window.open(url, '_system');
    } else {
      if (this.isDesktop || Capacitor.getPlatform() == 'ANDROID') {
        window.open(url, '_self');
      } else {
        window.open(url, '_self');
      }
    }
  }

  loadUserData() {
    const isLicenseMx = this.globalVars.licenseType == LicenseTypes.Mexico;
    const isLicensePa = this.globalVars.licenseType == LicenseTypes.Panama;
    this.userService.getUserData().subscribe((data) => {
      this.isUserVerified = data.VerifiDocumental && data.VerifiDocumental == DOCUMENT_VERIFICATION_STATES.COMPLETED;
      if (isLicenseMx || isLicensePa) {
        if (data.NIF != null && data.NIF != '') {
          this.documentnumber = data.NIF;
        }
      }
    });
  }

  documentNumberValidation = (control: FormControl) => {
    const depositModel: DepositModel = new DepositModel();
    depositModel.documentNumber = control.value;

    let validateDepositModel: ValidateDepositModel =
      this.currentPaymentMethodBehaviour.validateFocumentNumber(depositModel);
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      validateDepositModel = this.currentPaymentMethodBehaviour.validateFocumentNumberPanama(depositModel);
    }
    if (validateDepositModel.isValid) {
      return null;
    } else {
      return { valid: false };
    }
  };

  amountValidator = (control: FormControl) => {
    const depositModel: DepositModel = new DepositModel();
    depositModel.amount = control.value;

    const validateDepositModel: ValidateDepositModel = this.currentPaymentMethodBehaviour.validateAmount(depositModel);
    if (validateDepositModel.isValid) {
      this.errorMessageAmount = '';
      return null;
    } else {
      this.errorMessageAmount = this.translateService.instant(validateDepositModel.errorLocalizableId);
      if (validateDepositModel.minMaxAmount) {
        this.errorMessageAmount += ' ' + this.parseMoney.transform(validateDepositModel.minMaxAmount.toString());
      }
      return { valid: false };
    }
  };

  bindBarcode() {
    this.userService.createDeposit(this.globalVars.user.username).subscribe(
      (data) => {
        this.localConfig.userName = this.globalVars.user.username;
        if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_PointofSaleDeposit) {
          const transactionResult = <TransactionActionResult>data;
          if (transactionResult.success) {
            this.localConfig.code = transactionResult.transactionId;
            this.localConfig.barcode =
              "<ul id='barcode'>" + this.utils.generateBarcode(transactionResult.transactionId) + '</ul>';
            this.events.publish('footer:refresh', this.content);
          } else {
            this.errorService
              .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
              .subscribe(
                (data2) => {
                  this.utils.showError(data2, null);
                },
                () => {
                  if (data.errCode === RISKY_PLAYER_ERROR_CODE) {
                    this.utils.showError(
                      'Por la política de “Juego más seguro” el cajero se ha modificado en función de tu perfil'
                    );
                  } else {
                    this.utils.showError(transactionResult.errMessage, null);
                  }
                }
              );
          }
        } else {
          this.localConfig.code = data;
          this.localConfig.barcode = "<ul id='barcode'>" + this.utils.generateBarcode(data) + '</ul>';
          this.events.publish('footer:refresh', this.content);
        }
      },
      (err) => {
        this.utils.showError(err);
      }
    );
  }

  openCodereLocals() {
    this.linkOutOfModalPage(NearestLocalPage, null);
  }

  requestIframe(event) {
    this.paymentSelected = null;
    this.setSectionStep.emit(1);
  }

  /**
   * Type Guard for qr interface
   */
  private isQr(arg: any): arg is IQRPaymentMethodBehaviour {
    return (arg as IQRPaymentMethodBehaviour).qr !== undefined;
  }

  /**
   * Create BBVA flow on the calculator step.
   */
  loadRedsysBehaviour() {
    this.redsysCardSelectionObj = {
      loadCardSelection: false,
      ListOfCards: [],
      loadInSiteForm: false
    };
    this.utils.loader();
    this.userService.getListOfCards().subscribe((resp) => {
      this.redsysCardSelectionObj.loadCardSelection = true;
      this.redsysCardSelectionObj.ListOfCards = resp.ListOfCards;
      if (resp.ListOfCards.length < 5) {
        this.redsysCardSelectionObj.ListOfCards.push({
          label: 'Agregar nueva tarjeta',
          value: '0',
          type: 'radio',
          checked: false
        });
      }
      this.redsysCardsMethodBehaviour = <RedsysCardsMethodBehaviour>this.currentPaymentMethodBehaviour;
      this.events.publish('loader:dismiss', null);
    });
  }

  setBlockCalculator(e) {
    this.blockCalculator = e;
  }

  async listenEvents(result: any) {
    if (result.success == true) {
      this.optionsRequestOk.idTransaction = result.id || result.transactionId;
      this.optionsRequestOk.amount = result.amount;
      this.showRequestOkInfoModal(this.optionsRequestOk);
    } else {
      this.optionsRequestKo.error = 'Error en el depósito';
      this.showRequestKoInfoModal(this.optionsRequestKo);
    }
  }

  async showRequestOkInfoModal(paymentArguments: any) {
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageOk,
      componentProps: {
        paymentArguments
      }
    });
    modal.present();
    this.lotbaCodeNumber = new FormControl('');
    modal.onDidDismiss().then(() => {
      this.requestInfo.emit(true);
      this.viewCtrl.dismiss();
      this.globalVars.rootScope.tryToGoPage('HomePage');
    });
  }

  async showRequestKoInfoModal(paymentArguments: any) {
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageOk,
      componentProps: {
        paymentArguments
      }
    });
    modal.present();
    modal.onDidDismiss().then((docSection) => {
      if (docSection) {
        setTimeout(() => {
          this.sendToDocuments.emit();
        }, 800);
      }
      this.globalVars.rootScope.tryToGoPage('HomePage');
      this.viewCtrl.dismiss();
      this.requestInfo.emit(true);
    });
  }

  goToPaypalDeposit() {
    this.payPalAmount = this.currentPaymentMethodBehaviour.amount;
    if (this.utils.isSpain() && this.payPalAmount > 150) {
      this.checkUserVerification();
      if (!this.isUserVerified) {
        return;
      }
    }
    this.setSectionStep.emit(3);
    setTimeout(() => {
      this.renderPaypalButton();
    }, 200);
  }

  public get selectPayValidaCashPaymentMethod() {
    if (this.dimonexPayValidaCashPaymentMethodBehaviour) {
      return this.dimonexPayValidaCashPaymentMethodBehaviour;
    } else {
      return this.payValidaCashPaymentMethodBehaviour;
    }
  }

  public get displayDepositDeclaration() {
    return (this.globalVars && this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) || false;
  }

  public get depositDeclaration() {
    return 'Pulsando el botón "Depositar" declarás y garantizás que el medio de pago seleccionado es de tu titularidad y aceptás expresamente las medidas pertinentes en caso de que tu declaración sea falsa.';
  }

  async checkUserVerification() {
    if (!this.isUserVerified) {
      const verificationModal = await this.modalCtrl.create({
        component: CsVerificationAlertModal,
        cssClass: 'cs-verification-alert-modal',
        showBackdrop: true
      });
      verificationModal.present();

      const { data } = await verificationModal.onDidDismiss();
      if (data && data.verificationAccepted) {
        if (!this.utils.isMobile) {
          await this.modalCtrl.dismiss();
        }
        // Access app.component.ts openModalOrPage method
        this.globalVars.rootScope.openModalOrPage(
          'payments/CashierPage',
          { section: 'documents' },
          true,
          'modalCashier is-modal'
        );
      }
    }
  }

  handleCalculatorOperationBlocked(ev: boolean) {
    this.isOperationBlocked = ev;
  }
}
