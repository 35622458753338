import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  forwardRef,
  ElementRef,
  ViewChild,
  Renderer2,
  OnInit,
  inject
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@providers/TrackingService';
import { ILicenseFilter } from '@providers/licenseFilter/ILicenseFilter';
import { FilterFactory } from '@providers/licenseFilter/filter.factory';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { Utils } from '@utils/Utils';
import { EventsService } from '@providers/EventsService';
import { CommonModule } from '@angular/common';
import { CasinoCardNewComponent } from '@shared-casino-components/casino-card-new/casino-card-new.component';
import { LobbyFacadeService } from '@services/casino/loobyFacade.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { CASINO_PATHS, SPORTS_PATHS } from '@shared-constants/routes';
import { EventTypes } from '@models/TrackingEvents';

@Component({
  selector: 'quick-launch-games',
  templateUrl: './quick-launch-games.html',
  styleUrls: ['./quick-launch-games.scss'],
  standalone: true,
  imports: [CommonModule, CasinoCardNewComponent]
})
export class QuickLaunchComponent implements OnInit {
  @Input() menuOpen: Promise<boolean>;
  @Input() isActive = false;
  @Output() active: EventEmitter<any> = new EventEmitter();
  text: string;
  game: any;
  gameURL: any;
  backgroundColor: string | number;
  closed = true;
  timer: any;
  sessionListener: any;
  @ViewChild('container') container: ElementRef;
  @ViewChild('textContainer') textContainer: ElementRef;
  data: any;
  activeGames: any;
  games: any;
  currentGames$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  adjust = false;
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  renderer = inject(Renderer2);
  lobbyFacadeService = inject(LobbyFacadeService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  deviceService = inject(DeviceService);

  constructor(@Inject(forwardRef(() => TrackingService)) private trackingService: TrackingService) {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.container.nativeElement.classList.remove('mini-ql-mobile--show');
        setTimeout(() => {
          this.close();
        }, 500);
        if (
          this.router.url.includes(SPORTS_PATHS.MisApuestasPage) ||
          this.router.url.includes(CASINO_PATHS.SlotsGamePage)
        ) {
          this.container.nativeElement.classList.add('invisible');
        } else {
          this.container.nativeElement.classList.remove('invisible');
        }
      }
    });

    this.isDesktop = this.deviceService.isDesktop();

    this.events.subscribe('initGameSlots', () => {
      this.close();
    });
    this.events.subscribe('finishGameSlots', () => {
      this.close();
    });
  }

  async ngOnInit() {
    if (this.globalVars.FEATURES.CasinoQuickLaunchGames && this.globalVars.FEATURES.CasinoQuickLaunchGames !== '') {
      try {
        this.data = JSON.parse(this.globalVars.FEATURES.CasinoQuickLaunchGames);
        this.activeGames = await this.lobbyFacadeService.getGames();
        this.start();
      } catch (error) {
        console.error(error);
      }
    }
  }

  start() {
    this.games = this.getGames();
    this.setCss(this.games?.length || 0);
    if (this.games.length > 0) {
      this.currentGames$.next(this.games);
      if (this.data.title != null && this.data.title != '') {
        this.setCssTxt(this.data.size, this.data.color);
      }
    }
  }

  quickClose() {
    this.container.nativeElement.style.transition = 'none';
    this.close();
    setTimeout(() => {
      this.container.nativeElement.style.transition = 'transform 0.5s ease-in';
    }, 500);
  }

  setCss(number: number) {
    switch (number) {
      case 1:
        this.renderer.setStyle(this.container.nativeElement, 'top', '55vh');
        this.adjust = true;
        break;
      case 2:
        this.renderer.setStyle(this.container.nativeElement, 'top', '45vh');
        break;
      case 3:
        this.renderer.setStyle(this.container.nativeElement, 'top', '35vh');
        break;
      default:
        this.renderer.setStyle(this.container.nativeElement, 'display', 'none');
    }
  }

  setCssTxt(size: string, color: string) {
    if (size !== null && size !== '') {
      this.renderer.setStyle(this.textContainer.nativeElement, 'font-size', size);
    }
    if (color !== null && color !== '') {
      this.renderer.setStyle(this.textContainer.nativeElement, 'color', color);
    }
  }

  getGames() {
    const returnGames = [];
    this.data.games.map((game) => {
      const setter = this.activeGames[game.gameId];
      if (setter) {
        const newGame = Object.assign({}, setter);
        newGame.GameId = game.gameId;
        newGame.gameTileSize = 'Square';
        newGame.imageType = setter.DefaultImageType;
        newGame.lobby = 'QuickLaunch';
        newGame.product = 'Casino';
        newGame.additionalData = new BehaviorSubject<any>({});
        returnGames.push(newGame);
      }
    });
    return returnGames;
  }

  async init(state?: any) {
    if (!state || !this.closed) {
      return;
    }
    this.isActive = true;
    this.closed = false;
    this.active.emit(this.isActive);

    const filter: ILicenseFilter = FilterFactory.getFilter(
      this.globalVars.licenseType,
      this.translate,
      this.newBaseService
    );
    if (filter && !filter.canPlayCasino()) {
      // Se comenta porque en Codere no funciona la localizacion en CABA
      // this.utils.alert(false, 'Error', filter.getCanPlayCasinoError(), 'OK', () => {});
      // setTimeout(() => {
      //   this.close();
      // }, 100);
      // return;
    } else {
      this.trackingService.track({
        eventType: EventTypes.OpenQuickLaunch,
        description: `Open quick launch`,
        additionalData: {
          url: window.location.href,
          platform: this.lobbyFacadeService.getPlatform()
        }
      });
      if (this.games.length === 1 && this.games[0].lobbyType === 'Casino') {
        setTimeout(() => {
          this.playGame(this.games[0]);
        }, 0);
      }
    }
  }

  close() {
    this.trackingService.track({
      eventType: EventTypes.CloseQuickLaunch,
      description: `Close quick launch`,
      additionalData: {
        url: window.location.href,
        platform: this.lobbyFacadeService.getPlatform()
      }
    });
    this.container.nativeElement.classList.remove('mini-ql-mobile--show');
    this.closed = true;
    this.isActive = false;
  }

  getCurrentPageData(actualPAge) {
    let page = '';
    switch (actualPAge) {
      case 'LobbyCasinoPage':
        page = 'Casino';
        break;
      case 'HomePage':
      case 'DirectosPage':
      case 'EventoPage':
      case 'EventoMejoraPage':
      case 'HorsesPage':
      case 'HorsesMarketPage':
      case 'MercadosPage':
      case 'NowLiveDetailPage':
      case 'SportCountriesPage':
      case 'Crea-Tu-Apuesta':
      case 'RealMadridPage':
      case 'RiverPlatePage':
      case 'SpecialTeamPage':
      case 'TodayPage':
      case 'SportEventsPage':
      case 'EventoSpecialPage':
        page = 'Sports';
        break;
      default:
        page = 'Other';
        break;
    }
    return page;
  }

  async click(event: any) {
    if (
      (!this.isActive && this.games.length > 1) ||
      (!this.isActive && this.games.length === 1 && this.games[0].lobbyType !== 'Casino')
    ) {
      this.container.nativeElement.classList.toggle('mini-ql-mobile--show');
      this.init(event);
    } else if (!this.isActive && this.games.length === 1 && this.games[0].lobbyType === 'Casino') {
      this.playGame(this.games[0]);
    } else {
      this.container.nativeElement.classList.toggle('mini-ql-mobile--show');
      setTimeout(() => {
        // Wait 500ms for the close animation to finish before making it invisible
        this.close();
      }, 500);
    }
  }

  playGame(game) {
    this.globalVars.gameFromQuickLaunch = true;
    const actualPage = this.getCurrentPageData(this.route?.component.name);
    game.game.product = actualPage;
    this.container.nativeElement.classList.toggle('mini-ql-mobile--show');
    this.close();
    this.lobbyFacadeService.playGameOutCasino(game);
  }
}
