/* eslint-disable */
import { Component, Input, ChangeDetectionStrategy, OnInit, OnDestroy, OnChanges, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TrackingService } from '../../../../providers/TrackingService';
import { LicenseTypes } from '../../../../models/MasterData';
import { HttpClient } from '@angular/common/http';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { BaseService } from '../../../../providers/BaseService';
import { CommonModule } from '@angular/common';
import { CasinoCardNewComponent } from '../../casino-card-new/casino-card-new.component';
import { LobbyFacadeService } from '../../../../services/casino/loobyFacade.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'lb-play-out',
  templateUrl: './lobby-out.html',
  standalone: true,
  styleUrls: ['./lobby-out.scss'],
  imports: [CommonModule, CasinoCardNewComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LobbyOutComponent extends BaseService implements OnInit, OnChanges {
  @Input() page: string;
  @Input() hide: boolean;
  allGames: any;
  current$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  title$: BehaviorSubject<any> = new BehaviorSubject(false);
  titleShow = 'Recomendados';
  override globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  lobbyFacadeService = inject(LobbyFacadeService);
  http = inject(HttpClient);
  trackingService = inject(TrackingService);

  constructor() {
    super();

    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = Capacitor.getPlatform() === 'web';
    this.isMobile = Capacitor.getPlatform() !== 'web';
  }

  ngOnInit() {
    if (this.isDesktop && this.globalVars.FEATURES.DynamicYieldSportsBetSlipEnabled && this.isSpain()) {
      this.startDy();
    } else if (this.isDesktop && !this.isSpain() && this.globalVars.FEATURES.CasinoBetslipGames) {
      this.start();
    }
  }

  ngOnChanges() {}

  async start() {
    try {
      this.allGames = await this.lobbyFacadeService.getGamesFromOutCasino();
      const CasinoBetslipGames = JSON.parse(this.globalVars.FEATURES.CasinoBetslipGames);
      const games = await this.getGames(CasinoBetslipGames.games, 'backoffice');
      if (games.length > 0) {
        if (CasinoBetslipGames.title !== '' && CasinoBetslipGames.title !== null) {
          this.titleShow = CasinoBetslipGames.title;
        }
        this.title$.next(true);
        this.current$.next(games);
      }
    } catch (error) {}
  }

  async startDy() {
    this.allGames = await this.lobbyFacadeService.getGamesFromOutCasino();
    let type = this.getTypeList(this.page);
    const url = this.globalVars.URLBASE_REPORTS + 'SlotGames/GetDYList?mobile=' + this.isMobile + '&list=' + type;
    const { RecommendedGames } = await this.myGet(url)
      .toPromise()
      .catch(() => {
        console.error('Fail service get games recomended for Dy');
      });
    const games = await this.getGames(RecommendedGames, 'dy');
    if (games.length > 0) {
      this.title$.next(true);
      this.current$.next(games);
    }
  }

  async getGames(games, type) {
    const returnGames = [];

    if (games) {
      if (games.length > 0) {
        games.map((game) => {
          let newGame;
          if (type === 'dy') {
            newGame = Object.assign({}, this.allGames[game.GameId]);
          } else {
            newGame = Object.assign({}, this.allGames[game.gameId]);
          }
          if (newGame.Name) {
            newGame.dyId = game.SlotId ? game.SlotId : null;
            newGame.GameId = type === 'dy' ? game.GameId : game.gameId;
            newGame.gameTileSize = 'Square';
            newGame.imageType = newGame.DefaultImageType;
            newGame.lobby = this.page === 'Sports' ? 'Betslip' : '';
            newGame.product = 'Sports';
            newGame.additionalData = new BehaviorSubject<any>({});
            returnGames.push(newGame);
          }
        });
      }
    }
    return returnGames;
  }

  getTypeList(page) {
    //only Sports for the moment
    let type = '';
    switch (page) {
      case 'Sports':
        type = 'SportsBetSlip';
        break;
      default:
        type = 'SportsBetSlip';
        break;
    }
    return type;
  }

  isSpain() {
    return (
      this.globalVars.licenseType === LicenseTypes.PaisVasco ||
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Madrid
    );
  }

  playGame(game) {
    this.trackingService.trackEvent([
      'OpenGameFromBetSlip',
      game.game.Name,
      'BetSlip',
      'Juego iniciado desde BetSlip en la pagina Sports',
      'event'
    ]);
    this.lobbyFacadeService.playGameOutCasino(game);
  }
}
