<button class="sb-ticket-betbuilder--delete" tappable (click)="remove()" *ngIf="!isTicketClosed" [disabled]="disabled">
  <i class="codere-icon icon-close color-light"></i>
</button>

<div class="sb-ticket-betbuilder--content border-color-light sb-ticket-any">
  <!-- Left side -->
  <div class="sb-ticket-betbuilder--content-left">
    <div class="sb-ticket-betbuilder-items-wrap">
      <span
        *ngFor="let item of smartLineItem?.SmartLineItems; first as isFirst; last as isLast;"
        class="sb-ticket-betbuilder--content-left-ball-container"
      >
        <img
          class="sb-ticket-betbuilder--content-left-ball"
          src="assets/global/img/sb-betbuilderItem.svg"
          [ngClass]="{ 'first-element': isFirst, 'last-element': isLast }"
        />
        <div class="sb-ticket-betbuilder--content-left-ball-item">
          <span class="sb-ticket-selection--title color-light">{{ item.Name }} </span>
          <span class="sb-ticket-selection--subtitle color-grey-500"> - {{ item.GameName }} </span>
        </div>
      </span>
    </div>
    <div class="d-flex w-100">
      <span class="sb-ticket-betbuilder--title m-right-1 color-grey-500">
        {{ smartLineItem?.EventName | translate }}
        <span class="m-left-1 sb-ticket-betbuilder--subtitle color-grey-500"
          >{{ smartLineItem?.Odd.toString() | formatOdd }}</span
        >
      </span>
      <i
        class="codere-icon"
        [ngClass]="{
        'sb-button--animation': smartLineItem?.upOdd || smartLineItem?.downOdd,
        'is-down': smartLineItem?.downOdd,
        'is-up': smartLineItem?.upOdd
      }"
      >
      </i>
    </div>
    <span class="sb-ticket-betbuilder--subtitle color-grey-500" *ngIf="isTicketClosed && smartLineItem?.TicketNumber">
      {{ 'compapuestaP3' | translate }} # {{ smartLineItem?.TicketNumber }}
    </span>
  </div>

  <!-- Right side -->
  <div class="sb-ticket-betbuilder--content-right" *ngIf="multiSingleActivated">
    <button
      *ngIf="!isDesktop"
      class="sb-ticket-betbuilder--button color-dark background-color-regular"
      [ngClass]="{ 'is-selected': smartLineItem?.Selected && keyboard }"
      tappable
      (click)="selectAmount()"
    >
      <span class="sb-ticket--button-title"> {{ smartLineItem?.AmountLocal.toString() | parseStakeMoney:[1] }} </span>
    </button>

    <sb-ticket-stake
      *ngIf="isDesktop"
      [minBetValue]="minBetValue"
      [ticket]="ticket"
      [lineItem]="smartLineItem"
      [stakeAmount]="smartLineItem?.Amount?.toString()"
      (update)="updateStake($event)"
      [currency]="currency"
    ></sb-ticket-stake>

    <span class="sb-ticket-betbuilder--subtitle color-primary">
      {{'hDetailsGan' | translate}}: {{ smartLineItem?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
    </span>
  </div>
</div>
