<!-- eslint-disable -->
<div
  *ngFor="let result of ticket?.betHistoryInfo"
  class="background-color-white"
  [ngClass]="typeCard"
  [hidden]="canShowTile(result)"
>
  <div class="sb-bet-tile--header">
    <div class="sb-bet-tile--header-title color-muted">
      <span *ngIf="MisApuestasNewModelFeatureFlagEnabled">
        {{ 'tApues' | translate }} {{ ticketBetType == betbuilderTicketType ? BetbuilderCategoryName4MisApuestas :
        ticketBetType | translate}}
      </span>
      <span *ngIf="!MisApuestasNewModelFeatureFlagEnabled">
        {{ 'tApues' | translate }} {{ result?.BetType == betbuilderTicketType ? BetbuilderCategoryName4MisApuestas :
        result?.BetType | translate}}
      </span>

      <span
        *ngIf="MisApuestasNewModelFeatureFlagEnabled && result?.BetTypeNumber != '1' && result?.X == result?.Y && ticketBetType !== betbuilderTicketType"
        >({{ result?.X }})</span
      >
      <span
        *ngIf="!MisApuestasNewModelFeatureFlagEnabled && result?.BetTypeNumber != '1' && result?.X == result?.Y && result?.BetType !== betbuilderTicketType"
        >({{ result?.X }})</span
      >
      <span *ngIf="result?.BetTypeNumber != '1' && result?.X != result?.Y">({{ result?.X }}/{{ result?.Y }})</span>
      <span *ngIf="result?.BetTypeNumber == '1' && result?.X != result?.Y">({{ result?.Y }})</span>
      <span *ngIf="!!result?.VoucherCode" class="color-secondary"> Freebet</span>
    </div>

    <div
      class="sb-bet-tile--header-text text-uppercase color-progress"
      [ngClass]="getTicketStatus(result?.TicketStatusNumber)"
      *ngIf="MisApuestasNewModelFeatureFlagEnabled && result?.TicketStatusNumber=='0'"
    >
      {{globalVars.licenseType==0?'Reembolsada':'Anulada'}}
    </div>
    <div
      class="sb-bet-tile--header-text text-uppercase"
      [ngClass]="getTicketStatus(result?.TicketStatusNumber)"
      *ngIf="MisApuestasNewModelFeatureFlagEnabled && result?.TicketStatusNumber!='0'"
    >
      {{ ticketStatus | uppercase }}
    </div>
    <div
      class="sb-bet-tile--header-text text-uppercase color-progress"
      [ngClass]="getTicketStatus(result?.TicketStatusNumber)"
      *ngIf="!MisApuestasNewModelFeatureFlagEnabled && result?.TicketStatusNumber=='0'"
    >
      {{globalVars.licenseType==0?'Reembolsada':'Anulada'}}
    </div>
    <div
      class="sb-bet-tile--header-text text-uppercase"
      [ngClass]="getTicketStatus(result?.TicketStatusNumber)"
      *ngIf="!MisApuestasNewModelFeatureFlagEnabled && result?.TicketStatusNumber!='0'"
    >
      {{ result?.TicketStatus | uppercase }}
    </div>
  </div>

  <!-- Normal Bet START -->
  <!-- New Model -->

  <div
    *ngIf="MisApuestasNewModelFeatureFlagEnabled && ticketBetType !== betbuilderTicketType"
    class="sb-bet-tile--body"
    [class.is-open]="result?.accumulatorOpened"
  >
    <sb-bet-selection
      *ngFor="let event of result?.betEvents"
      [mode]="'normal'"
      [event]="event"
      [betTypeNumber]="result?.BetTypeNumber"
      [ticketStatus]="result?.TicketStatus"
      [ticketStatusNumber]="result?.TicketStatusNumber"
      [matrixExist]="result?.X != result?.Y"
      [potentialWinnings]="result?.PotentialWinnings"
      [stake]="result?.Stake"
      tappable
      (click)="selectEvent(event)"
    >
    </sb-bet-selection>
    <a
      *ngIf="result?.BetTypeNumber != '1' ||
      (result?.BetTypeNumber == '1' && result?.Y > 1) ||
      (result?.BetTypeNumber == '1' && result?.TicketStatusNumber == '4' && !result?.TicketDetails)"
      class="sb-bet-tile--body-toggle color-secondary background-color-white border-color-grey-200"
      tappable
      (click)="openSelections(result?.TicketNumber, result)"
    >
      <span *ngIf="result?.accumulatorOpened; else accumulatorHide">
        <span class="toggle-text">Ocultar</span>
        <i class="codere-icon icon-arrow-top"></i>
      </span>
      <ng-template #accumulatorHide>
        <span *ngIf="result?.TicketStatusNumber == '4' && !result?.TicketDetails" class="toggle-text">Cobrar</span>
        <span
          *ngIf="result?.TicketStatusNumber != '4' || result?.TicketStatusNumber == '4' && result?.TicketDetails"
          class="toggle-text"
          >Ver todos</span
        >
        <i class="codere-icon icon-arrow-bottom"></i>
      </ng-template>
    </a>
  </div>

  <!-- Old Model -->
  <div
    *ngIf="!MisApuestasNewModelFeatureFlagEnabled && result?.BetType !== betbuilderTicketType"
    class="sb-bet-tile--body"
    [class.is-open]="result?.accumulatorOpened"
  >
    <sb-bet-selection
      *ngFor="let event of result?.betEvents"
      [mode]="'normal'"
      [event]="event"
      [truncateValue]="getTruncateValue(result?.accumulatorOpened)"
      [betTypeNumber]="result?.BetTypeNumber"
      [ticketStatus]="result?.TicketStatus"
      [ticketStatusNumber]="result?.TicketStatusNumber"
      [matrixExist]="result?.X != result?.Y"
      [potentialWinnings]="result?.PotentialWinnings"
      [stake]="result?.Stake"
      tappable
      (click)="selectEvent(event)"
    >
    </sb-bet-selection>
    <a
      *ngIf="result?.BetTypeNumber != '1' ||
      (result?.BetTypeNumber == '1' && result?.Y > 1) ||
      (result?.BetTypeNumber == '1' && result?.TicketStatusNumber == '4' && !result?.TicketDetails)"
      class="sb-bet-tile--body-toggle color-secondary background-color-white border-color-grey-200"
      tappable
      (click)="openSelections(result?.TicketNumber, result)"
    >
      <span *ngIf="result?.accumulatorOpened; else accumulatorHide">
        <span class="toggle-text">Ocultar</span>
        <i class="codere-icon icon-arrow-top"></i>
      </span>
      <ng-template #accumulatorHide>
        <span *ngIf="result?.TicketStatusNumber == '4' && !result?.TicketDetails" class="toggle-text">Cobrar</span>
        <span
          *ngIf="result?.TicketStatusNumber != '4' || result?.TicketStatusNumber == '4' && result?.TicketDetails"
          class="toggle-text"
          >Ver todos</span
        >
        <i class="codere-icon icon-arrow-bottom"></i>
      </ng-template>
    </a>
  </div>
  <!-- Normal Bet END -->

  <!-- Betbuilder Bet START -->
  <!-- New Model -->

  <div
    *ngIf="MisApuestasNewModelFeatureFlagEnabled && ticketBetType == betbuilderTicketType"
    class="sb-bet-tile--body"
    [class.is-open]="result?.accumulatorOpened"
  >
    <sb-bet-selection
      [mode]="betbuilderMode"
      [event]="result?.TicketDetails?.BetHistoryInfo?.betEvents || result?.betEvents"
      [betTypeNumber]="result?.BetTypeNumber"
      [ticketStatus]="ticketStatus"
      [ticketStatusNumber]="result?.TicketStatusNumber"
      [matrixExist]="result?.X != result?.Y"
      [accumulatorOpened]="result?.accumulatorOpened"
      [potentialWinnings]="result?.PotentialWinnings"
      [stake]="result?.Stake"
      tappable
      (click)="selectEvent(result?.TicketDetails?.BetHistoryInfo?.betEvents[0])"
    >
    </sb-bet-selection>
    <!-- deleted from ngIf ->  ; else showAllBetbuilderButton -->
    <a
      *ngIf="result?.TicketDetails?.BetHistoryInfo?.betEvents?.length > 2"
      class="sb-bet-tile--body-toggle color-secondary background-color-white border-color-grey-200"
      tappable
      (click)="openSelections(result?.TicketNumber, result)"
    >
      <span *ngIf="result?.accumulatorOpened; else accumulatorHide">
        <span class="toggle-text"> Ocultar </span>
        <i class="codere-icon icon-arrow-top"></i>
      </span>
      <ng-template #accumulatorHide>
        <span class="toggle-text"> Ver todos </span>
        <i class="codere-icon icon-arrow-bottom"></i>
      </ng-template>
    </a>
    <a
      class="sb-bet-tile--body-toggle color-secondary background-color-white border-color-grey-200"
      tappable
      (click)="openSelections(result?.TicketNumber, result)"
    >
      <span *ngIf="result?.accumulatorOpened; else accumulatorHideNoData">
        <span class="toggle-text"> Ocultar </span>
        <i class="codere-icon icon-arrow-top"></i>
      </span>
      <ng-template #accumulatorHideNoData>
        <span class="toggle-text"> Ver todos </span>
        <i class="codere-icon icon-arrow-bottom"></i>
      </ng-template>
    </a>
  </div>
  <!-- Old Model -->

  <div
    *ngIf="!MisApuestasNewModelFeatureFlagEnabled && result?.BetType == betbuilderTicketType"
    class="sb-bet-tile--body"
    [class.is-open]="result?.accumulatorOpened"
  >
    <sb-bet-selection
      [mode]="betbuilderMode"
      [event]="result?.TicketDetails?.BetHistoryInfo?.betEvents || result?.betEvents"
      [betTypeNumber]="result?.BetTypeNumber"
      [ticketStatus]="result?.TicketStatus"
      [ticketStatusNumber]="result?.TicketStatusNumber"
      [matrixExist]="result?.X != result?.Y"
      [accumulatorOpened]="result?.accumulatorOpened"
      [potentialWinnings]="result?.PotentialWinnings"
      [stake]="result?.Stake"
      tappable
      (click)="selectEvent(result?.TicketDetails?.BetHistoryInfo?.betEvents[0])"
    >
    </sb-bet-selection>
    <a
      *ngIf="result?.TicketDetails?.BetHistoryInfo?.betEvents?.length>1"
      class="sb-bet-tile--body-toggle color-secondary background-color-white border-color-grey-200"
      tappable
      (click)="openSelections(result?.TicketNumber, result)"
    >
      <span *ngIf="result?.accumulatorOpened; else accumulatorHide">
        <span class="toggle-text"> Ocultar </span>
        <i class="codere-icon icon-arrow-top"></i>
      </span>
      <ng-template #accumulatorHide>
        <span class="toggle-text"> Ver todos </span>
        <i class="codere-icon icon-arrow-bottom"></i>
      </ng-template>
    </a>
    <a
      class="sb-bet-tile--body-toggle color-secondary background-color-white border-color-grey-200"
      tappable
      *ngIf="result?.TicketDetails?.BetHistoryInfo?.betEvents?.length>2"
      (click)="openSelections(result?.TicketNumber, result)"
    >
      <span *ngIf="result?.accumulatorOpened; else accumulatorHideNoData">
        <span class="toggle-text"> Ocultar </span>
        <i class="codere-icon icon-arrow-top"></i>
      </span>
      <ng-template #accumulatorHideNoData>
        <span class="toggle-text"> Ver todos </span>
        <i class="codere-icon icon-arrow-bottom"></i>
      </ng-template>
    </a>
  </div>
  <!-- Betbuilder Bet END -->

  <!-- Bottom gray area START -->

  <div class="sb-bet-tile--footer background-color-muted">
    <div class="sb-bet-tile--row">
      <div class="sb-bet-tile--footer-title color-dark">Total apuestas</div>
      <div class="sb-bet-tile--footer-title text-uppercase color-dark">{{ result?.Stake | parseMoney }}</div>
    </div>

    <div *ngIf="result?.TicketStatusNumber != '5'" class="sb-bet-tile--row">
      <div class="sb-bet-tile--footer-title color-dark" *ngIf="result?.Stake!=result?.PotentialWinnings">Ganancia</div>
      <div class="sb-bet-tile--footer-title color-dark" *ngIf="result?.Stake==result?.PotentialWinnings">
        {{globalVars.licenseType==0?'Reembolsada':'Anulada'}}
      </div>
      <div class="sb-bet-tile--footer-title text-uppercase color-dark">
        {{ result?.PotentialWinnings | parseMoney }}
      </div>
    </div>
    <div *ngIf="result?.TicketStatusNumber == '5'" class="sb-bet-tile--row">
      <div class="sb-bet-tile--footer-title color-progress">Cobrada</div>

      <div class="sb-bet-tile--footer-title text-uppercase color-progress">
        {{ result?.PotentialWinnings | parseMoney }}
      </div>
    </div>

    <div *ngIf="result?.TicketStatusNumber == '4' && !!result?.TicketDetails" class="sb-bet-tile--payment">
      <button
        *ngIf="result?.TicketDetails?.StatusCode === 0"
        class="sb-bet-tile--footer-button color-light background-color-primary border-color-primary"
        tappable
        (click)="payOutTicket(result?.TicketNumber, result)"
      >
        Cobrar: {{ result?.TicketDetails?.Amount/100 | parseMoney }}
      </button>

      <span
        *ngIf="MisApuestasNewModelFeatureFlagEnabled && result?.TicketDetails?.StatusCode !== 0 && ticketBetType !== betbuilderTicketType"
        class="sb-bet-tile--footer-button color-progress cashout-not-available"
      >
        Cobrar: No Disponible
      </span>
      <span
        *ngIf="!MisApuestasNewModelFeatureFlagEnabled && result?.TicketDetails?.StatusCode !== 0 && result?.BetType !== betbuilderTicketType"
        class="sb-bet-tile--footer-button color-progress cashout-not-available"
      >
        Cobrar: No Disponible
      </span>
    </div>
    <div class="sb-bet-tile--row">
      <div *ngIf="!MisApuestasNewModelFeatureFlagEnabled" class="sb-bet-tile--footer-text color-grey-800">
        {{ result.TicketDatetime | extractDate }}
      </div>
      <div *ngIf="MisApuestasNewModelFeatureFlagEnabled" class="sb-bet-tile--footer-text color-grey-800">
        {{ convertString2Date(result.TicketDatetime) | extractDate }}
      </div>
      <div class="sb-bet-tile--footer-text color-grey-800">Ticket: {{ result.TicketNumber }}</div>
    </div>
  </div>
  <!-- Bottom gray area END -->
</div>
