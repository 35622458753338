import { Injectable, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from './newBase.service';

/**
 * Service to get urls based on features, parameters, ...
 */
@Injectable({ providedIn: 'root' })
export class UrlsService {
  playerPropsUrl: string = '';
  // playerPropsUrl: string = "https://widgets.digitalsportstech.com/?event={0}&sb=codere"
  // playerPropsUrl: string = "https://widgets.dev.digitalsportstech.com/?event={0}&sb=codere" // DEV ENVIRONMENT
  public globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  constructor(public sanitizer: DomSanitizer) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    // switch (this.globalVars.licenseType) {
    //    case LicenseTypes.Nacional:
    //       this.playerPropsUrl += '-es'
    //       break;
    //    case LicenseTypes.Mexico:
    //       this.playerPropsUrl += '-mx'
    //       break;
    // }
    if (this.globalVars.FEATURES.playerPropsEventUrl) {
      this.playerPropsUrl = this.globalVars.FEATURES.playerPropsEventUrl + '{0}';
    }
  }

  /**
   * Returns the betsense safe url for an event node identifier
   * @param nodeId The event node identifier
   * @returns The safe url
   */
  public getBetsenseUrl(nodeId: string): SafeResourceUrl {
    let url: string = this.globalVars.FEATURES.betsenseUrlTemplate;
    if (!url) return null;

    url = url.replace('{0}', nodeId);
    url = url.replace('{1}', this.globalVars.Idioma);

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public getPlayerPropsWidgetUrl(nodeId: string, widgetId: string): SafeResourceUrl {
    const url = this.playerPropsUrl.replace('{0}', nodeId);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public getVeridasIframeUrl(baseUrl: string, token: string): SafeResourceUrl {
    const url = `${baseUrl}?access_token=${token}`;
    // let url = this.playerPropsUrl.replace('{0}', nodeId);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
