/* eslint-disable eqeqeq */
import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'extractDate', standalone: true })
@Injectable({ providedIn: 'root' })
export class ExtractDatePipe implements PipeTransform {
  public translate = inject(TranslateService);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: string | Date, args: any[] = []): string {
    if (value == undefined || value == null) return '';

    let format: number;
    if (args.length > 0) {
      format = parseInt(args[0]);
    } else {
      format = 0;
    }

    const miDate = value instanceof Date ? value : new Date(parseInt(value.slice(6, value.length - 2)));
    const dd: string = miDate.getDate() >= 10 ? miDate.getDate().toString() : '0' + miDate.getDate().toString();
    const mm: string =
      miDate.getMonth() >= 9 ? (miDate.getMonth() + 1).toString() : '0' + (miDate.getMonth() + 1).toString();
    const yyyy: string = miDate.getFullYear().toString();

    switch (format) {
      case 0:
        // 2 feb 07:38
        return (
          miDate.getDate() +
          ' ' +
          this.getMonthName(miDate.getMonth()) +
          ' ' +
          this.formatoNumber(miDate.getHours(), 2) +
          ':' +
          this.formatoNumber(miDate.getMinutes(), 2)
        );

      case 1:
        // 03/05/2015

        return dd + '/' + mm + '/' + yyyy;
      case 2:
        // 2 feb 2016 07:38h
        return (
          miDate.getDate() +
          ' ' +
          this.getMonthName(miDate.getMonth()) +
          ' ' +
          miDate.getFullYear() +
          ' ' +
          this.formatoNumber(miDate.getHours(), 2) +
          ':' +
          this.formatoNumber(miDate.getMinutes(), 2) +
          'h.'
        );

      case 3:
        // 2 feb 07:38:45
        return (
          miDate.getDate() +
          ' ' +
          this.getMonthName(miDate.getMonth()) +
          ' ' +
          this.formatoNumber(miDate.getHours(), 2) +
          ':' +
          this.formatoNumber(miDate.getMinutes(), 2) +
          ':' +
          this.formatoNumber(miDate.getSeconds(), 2)
        );

      case 4:
        // 03-05-2015
        return dd + '-' + mm + '-' + yyyy;

      case 5:
        // 07:38
        return this.formatoNumber(miDate.getHours(), 2) + ':' + this.formatoNumber(miDate.getMinutes(), 2);
      case 6:
        const mi_Date = `${yyyy}/${mm}/${dd}`;
        const today = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`;
        let tomorrow = new Date().setDate(new Date().getDate() + 1);
        tomorrow = new Date(tomorrow).setHours(23, 59, 59, 59);

        const cleanMiDate = new Date(mi_Date);
        let cleanToday = new Date(today);
        cleanToday = new Date(cleanToday.setHours(23, 59, 59, 59));
        const cleanTomorrow = new Date(tomorrow);

        // Bigger than tomorrow
        if (cleanMiDate > cleanTomorrow) {
          return `${miDate.getDate()} ${this.getMonthName(miDate.getMonth())} ${this.formatoNumber(
            miDate.getHours(),
            2
          )}:${this.formatoNumber(miDate.getMinutes(), 2)}`;
        }
        // Tomorrow
        if (cleanMiDate > cleanToday) {
          return `Mañana ${this.formatoNumber(miDate.getHours(), 2)}:${this.formatoNumber(miDate.getMinutes(), 2)}`;
        } else {
          // Today
          return `Hoy ${this.formatoNumber(miDate.getHours(), 2)}:${this.formatoNumber(miDate.getMinutes(), 2)}`;
        }
    }
  }

  private getMonthName(mes: number): string {
    //devuelve el nombre del mes en formato MMM
    const meses = [
      this.translate.instant('month1'),
      this.translate.instant('month2'),
      this.translate.instant('month3'),
      this.translate.instant('month4'),
      this.translate.instant('month5'),
      this.translate.instant('month6'),
      this.translate.instant('month7'),
      this.translate.instant('month8'),
      this.translate.instant('month9'),
      this.translate.instant('month10'),
      this.translate.instant('month11'),
      this.translate.instant('month12')
    ];
    return meses[mes];
  }

  private formatoNumber(numero, digitos): string {
    //devuelve el string del número con n dígitos
    let numberStr = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }
}
