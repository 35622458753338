/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AnchorLink, EventTypesAnchor } from '../../../../models/footer/anchor-link';
import { Description } from '../../../../models/footer/description';
import { Subscription } from 'rxjs';
import { Utils } from '../../../../utils/Utils';
import { EventsService } from '../../../../providers/EventsService';
import { TrackingService } from '../../../../providers/TrackingService';
import { DeviceService } from '../../../../services/device.service';
import { LinkRelDirective } from '../../../../directives/link-rel/link-rel';
import { MSO_PATHS, PAYMENTS_PATHS } from '../../../../lib/constants/routes';
import { Router } from '@angular/router';
import { PipesModule } from '../../../../pipes/pipes.module';
import { NewBaseService } from '../../../../providers/newBase.service';
import { InformationsTypes } from '../../../../../../mso/src/lib/pages/information-page/information-page';

@Component({
  standalone: true,
  imports: [CommonModule, LinkRelDirective, TranslateModule, PipesModule],
  providers: [TitleCasePipe],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'codere-footer-information',
  templateUrl: './codere-footer-information.html',
  styleUrls: ['./../codere-footer/codere-footer.scss']
})
export class CodereFooterInformation implements OnInit, OnDestroy {
  @Input() description!: Description;
  pretitleHelp: string | null = null;
  footerEvent$!: Subscription;
  globalVars!: GlobalVarsModel;
  isDesktop!: boolean;

  titleCasePipe = inject(TitleCasePipe);
  translate = inject(TranslateService);
  eventsService = inject(EventsService);
  sanitizer = inject(DomSanitizer);
  utils = inject(Utils);
  trackingService = inject(TrackingService);
  router = inject(Router);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnDestroy(): void {
    if (this.footerEvent$) {
      this.footerEvent$.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.footerEvent$ = this.eventsService.subscribe('footerEvent:open', (data) => {
      this.openEvent(data.event, data.typeEvent);
    });
  }

  openEvent($event, typeEvent: EventTypesAnchor) {
    $event.preventDefault();
    switch (typeEvent) {
      case 'infoCookie':
        this.openInformationPage('infocookie');
        break;

      case 'infoInfoDepMex':
        this.openInformationPage('ifodeprmx');
        break;

      case 'downloadApp':
        this.DownloadApp();
        break;

      case 'infoDeposit':
        this.openInformationPage('infodeposits');
        break;

      case 'howBet':
        this.openInformationPage('howbet');
        break;

      case 'juegoResp':
        this.JuegoResponsable();
        break;

      case 'reglas':
        this.openInformationPage('reglas');
        break;

      case 'cuota':
        this.openCuotaType();
        break;

      case 'ticketPage':
        this.openCheckTicketPage();
        break;

      case 'language':
        this.openLanguage();
        break;

      case 'help':
        this.openHelp();
        break;

      case 'apostar':
        this.openInformationPage('apostarmx');
        break;

      case 'openTerms':
        this.openInformationPage('clubleatad');
        break;

      case 'PoliticaPriv':
        this.openInformationPage('politicapriv');
        break;

      case 'tarjetaCondition':
        this.openInformationPage('tarjetapremia');
        break;

      case 'contactPage':
        this.openContactPage();
        break;

      case 'webdataprotection':
        this.openInformationPage('webdataprotection');
        break;

      case 't&cweb':
        this.openInformationPage('tycweb');
        break;
      case 'selfExclusion':
        this.openSelfExclusion();
        break;
      case 'openChat':
        this.openChat();
        break;
      case 'newCodereCard':
        this.openNewCodereCard();
        break;
      case 'cashierHistory':
        this.openCashierHistory();
        break;
    }
  }

  private openSelfExclusion() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`]);
  }

  private DownloadApp() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.DownloadApp}`], {
      queryParamsHandling: 'merge'
    });
  }

  private openContactPage() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`], {
      queryParams: { animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
  }

  private JuegoResponsable() {
    switch (this.globalVars.licenseType) {
      case 0:
        this.openInformationPage('juegorespnac');
        break;
      case 21:
        this.openInformationPage('juegorespcol');
        break;
      case 22:
        this.openInformationPage('juegoresppa');
        break;
      case 33:
        this.openInformationPage('juegoresparg');
        break;
    }
  }

  private openCheckTicketPage() {
    this.router.navigate([`/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CheckTicketPage}`], {
      queryParams: { animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
  }

  private openLanguage() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.LanguagePage}`], {
      queryParams: { animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
  }

  private openCuotaType() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.CuotaTypePage}`], {
      queryParams: { animate: !this.isDesktop }
    });
  }

  private openHelp() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.HelpPage}`], {
      queryParams: { animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
  }

  private openNewCodereCard() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.CodereCardPage}`], {
      queryParams: { animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
  }

  itemTitlePipe(item: AnchorLink) {
    let pretitle = '';
    let title = item.title;
    if (item.sign && item.sign.length > 0) {
      pretitle += item.sign;
      pretitle += ' ';
      if (!this.pretitleHelp) {
        this.pretitleHelp = pretitle;
      }
    }
    if (item.preTitle && item.preTitle.length > 0) {
      if (item.translatePretitle) {
        pretitle += this.translate.instant(item.preTitle);
      } else {
        pretitle += item.preTitle;
      }
      pretitle += ' ';
    }
    if (item.translatePipe) {
      title = this.translate.instant(title);
    }
    if (item.tileCasePipe) {
      title = this.titleCasePipe.transform(title);
    }
    if (item.upperCasePipe) {
      title = title.toUpperCase();
    }

    return pretitle + title;
  }

  private openInformationPage(type: InformationsTypes) {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.InformationPage}`], {
      queryParams: { type, animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
  }

  openChat() {
    this.utils.loadContactChat(true, true);
    if (!this.globalVars.FEATURES.newChatEnabled) {
      setTimeout(() => {
        this.utils.openContactChat();
      }, 800);
    }
    this.trackingService.trackEvent(['runChat', 'footer', '', '', 'event']);
  }

  private openCashierHistory() {
    this.router.navigate([`/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`], {
      queryParams: { section: 'history' }
    });
  }
}
