/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, from, lastValueFrom, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ParseMoney } from '@pipes/parseMoney';

//Models
import { C_LobbyGame, C_Slot } from '@models/lobby';
import { CResponseSession } from '@models/CResponseSession';
import { EventTypes } from '@models/TrackingEvents';
import { CookieNames } from '@models/CookieNames';
import { LicenseTypes } from '@models/MasterData';

//Services
import { BaseService } from '@providers/BaseService';
import { BlueRibbonService } from '@providers/BlueRibbonService';
import { JackpotsService } from '@providers/JackpotsService';
import { SignalRService } from '@providers/SignalRService';
import { TrackingService } from '@providers/TrackingService';
import { CookiesService } from '@providers/CookiesService';

import { ScreenOrientation } from '@capacitor/screen-orientation';

import { StatusBar } from '@capacitor/status-bar';
import { DeviceService } from '@services/device.service';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class SlotsService extends BaseService {
  slotNavActive: string;
  slotsBanners: any[] = [];
  scores: { wins: number; bets: number; balance: number };
  isMobile: boolean;

  parseMoney = inject(ParseMoney);
  translateService = inject(TranslateService);
  trackingService = inject(TrackingService);
  cookiesService = inject(CookiesService);
  blueRibbonService = inject(BlueRibbonService);
  signalRService = inject(SignalRService);
  jackpotsService = inject(JackpotsService);
  deviceService = inject(DeviceService);

  constructor() {
    super();
    this.scores = {
      wins: 0,
      bets: 0,
      balance: 0
    };
    this.isMobile = this.deviceService.isMobile();
  }

  getSlotsGames(): Observable<C_Slot[]> {
    const playtech = this.globalVars.licenseType === LicenseTypes.Nacional ? '&includept=0' : '';
    const url =
      this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetSlotsGames?languageCode=' + this.globalVars.Idioma + playtech;
    return this.myGet(url);
  }

  getSlotsGamesByType(type: string): Observable<C_Slot[]> {
    const url =
      this.globalVars.URLBASE_REPORTS +
      'SlotsGames/GetGamesByType?type=' +
      type +
      '&languageCode=' +
      this.globalVars.Idioma;
    return this.myGet(url);
  }

  checkSession(username: string, isCasinoGame = false): Observable<CResponseSession> {
    const url =
      this.globalVars.URLBASE_REPORTS +
      'SlotsGames/CheckSession?playerName=' +
      username +
      '&isCasinoGame=' +
      isCasinoGame;
    return this.myGet(url);
  }

  closeSlotSessions(userName: string = null): Observable<any> {
    if (!userName) {
      if (this.globalVars.user && this.globalVars.user?.username) {
        userName = this.globalVars.user.username;
      } else {
        userName = this.cookiesService.getCookie(CookieNames.CodereUser);
      }
    }
    //
    const url: string = this.globalVars.URLBASE_REPORTS + 'SlotsGames/closeSession?playerName=' + userName;

    const q = new Promise((resolve, reject) => {
      this.myGet(url, true).subscribe(
        () => {
          this.globalVars.user.playingSlots = false;
          this.globalVars.user.playingCasino = false;
          resolve(true);
        },
        (err) => {
          this.globalVars.user.playingSlots = false;
          this.globalVars.user.playingCasino = false;
          resolve(true);
        }
      );
    });
    return from(q);
  }

  getGameUlr(
    providerId: string,
    gameId: string,
    occurenceId?: string,
    product?: string,
    lobby?: string,
    platform?: string
  ): Observable<any> {
    let url = this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetGameUrl?playerName=' + this.globalVars.user.username;
    url += '&providerId=' + providerId;
    url += '&gameId=' + gameId;
    url += '&mobile=' + this.isMobile;
    url += '&product=' + product;
    url += '&lobby=' + lobby;
    url += '&platform=' + platform;
    let response = null;
    if (this.platform.is('capacitor')) {
      response = 'text';
    }
    return this.myGet(url, null, null, response).pipe(
      map((data) => {
        if (data && occurenceId) {
          data += '&tournamentId=' + occurenceId;
        }
        return data;
      })
    );
  }

  getSlotHistory(days: string): Observable<any> {
    const url =
      this.globalVars.URLBASE_REPORTS +
      'SlotsReports/SlotGameHistory?daysBefore=' +
      days +
      '&login=' +
      this.globalVars.user.username;
    return this.myGet(url);
  }

  getSlotsJackPotsTicket(): Observable<any> {
    const url = this.globalVars.URLBASE_REPORTS + 'SlotGames/GetJackpots';
    return this.myGet(url, true);
  }

  userState(reason: string, sessionId: string, gameId: string): Observable<any> {
    const userCookie: string = this.cookiesService.getCookie(CookieNames.CodereUser);
    const user: string = userCookie ? userCookie : this.globalVars.user.username;
    const url =
      this.globalVars.URLBASE_REPORTS +
      'SlotsGames/UserState?userId=' +
      user +
      '&reason=' +
      reason +
      '&sessionId=' +
      sessionId +
      '&gameId=' +
      gameId;
    return this.myGet(url);
  }

  getSessionLimits(): Observable<any> {
    //como la sesión puede caducar mientras juega a slots, miramos la cookie de usuario
    const userCookie: string = this.cookiesService.getCookie(CookieNames.CodereUser);
    const user: string = userCookie ? userCookie : this.globalVars.user.username;

    const url = this.globalVars.URLBASE_REPORTS + 'SlotGames/SessionLimits?identifier=' + user;
    return this.myGet(url, true);
  }

  createSession(formData: any): Observable<any> {
    let url = this.globalVars.URLBASE_REPORTS + 'SlotsGames/CreateSession';
    url += '?languageCode=' + this.globalVars.Idioma;
    url += '&playerName=' + formData.playerName;
    url += '&providerId=' + formData.providerId;
    url += '&gameId=' + formData.gameId;
    if (formData.amount2Limit) {
      // en Argentina
      // sessionBetAmount -> gasto (amount2)
      // amountLimit -> perdida (amount)
      url += '&amountLimit=' + formData.amount2Limit;
      url += '&sessionBetAmount=' + formData.amountLimit;
    } else {
      url += '&amountLimit=' + formData.amountLimit;
    }
    url += '&timeLimit=' + formData.timeLimit;
    formData.adviceIn ? (url += '&adviceIn=' + formData.adviceIn) : (url += '&adviceIn=' + 0);
    url += '&sessionAccessIn=' + formData.sessionAccessIn;
    url += '&mobile=' + formData.mobile;

    return this.myGet(url);
  }

  // =========== || CASINO || =============

  getCasinoGames(pt: number): Observable<C_Slot[]> {
    // No vienen datos de csbgonlineUAT, por lo que se pedimos los datos csbgonline
    let url: string =
      this.globalVars.URLBASE_REPORTS + 'CasinoGames/GetCasinoGames?languageCode=' + this.globalVars.Idioma;

    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      url += '&includept=' + pt;
    }

    return this.myGet(url);
  }

  getGameUlrCasino(
    providerId: string,
    gameId: string,
    product?: string,
    lobby?: string,
    platform?: string
  ): Observable<any> {
    let url = this.globalVars.URLBASE_REPORTS + 'CasinoGames/GetGameUrl?playerName=' + this.globalVars.user.username;
    url += '&providerId=' + providerId;
    url += '&gameId=' + gameId;
    url += '&mobile=' + this.isMobile;
    url += '&product=' + product;
    url += '&lobby=' + lobby;
    url += '&platform=' + platform;
    let response = null;
    if (this.platform.is('capacitor')) {
      response = 'text';
    }
    return this.myGet(url, null, null, response);
  }

  initGame(
    provider: string,
    gameId: string,
    gameType = 'slot',
    name: string,
    queView = null,
    occuranceId?: string,
    gameCategoryName?: string,
    gameDyId?: string,
    product?: string,
    lobby?: string,
    isIframe = false
  ) {
    //new parameters for track games
    const platform = this.getPlatform();
    if (!lobby) {
      lobby = 'PromoBanner';
    }
    if (!product && lobby !== 'QuickLaunch') {
      product = 'Casino';
    }

    const backButton = this.slotsBackButtonEnabled(name);
    if (gameType === 'slot' || gameType === 'slots') {
      this.getGameUlr(provider, gameId, occuranceId, product, lobby, platform).subscribe(
        (data) => {
          if (!!data && JSON.stringify(data) != '{}') {
            this.globalVars.user.playingSlots = true;
            data += '&deviceType=';
            // cambiar manualmente DEVICE_TYPE para que la versión móvil funcione en web
            if (this.platform.is('mobileweb') && this.globalVars.DEVICE_TYPE === 'web') {
              data += 'native';
            } else {
              data += this.globalVars.DEVICE_TYPE;
            }
            if (this.isMobile) {
              //open in same window
              // cordova hide statusbar and change orientation
              if (this.platform.is('capacitor')) {
                StatusBar.hide();
                ScreenOrientation.unlock();
              }
              this.globalVars.rootScope.openSlotsGame(data, backButton);
            } else {
              this.events.publish('slots:start');
              if (!isIframe) {
                if (product === 'Sports') {
                  const screenWidth = window.innerWidth / 2;
                  data = data + '&width=' + screenWidth;
                }
                this.globalVars.slotWindow = window.open(
                  data,
                  'Game',
                  'fullscreen=1, location=0, menubar=0, resizable=0, scrollbars=0, status=0, toolbar=0, titlebar=0'
                );
              } else {
                this.events.publish('urlGame:true', data);
              }
            }
            this.trackingService.track({
              eventType: EventTypes.InitGameSlots,
              id: gameId,
              secondParameter: name,
              description: provider,
              additionalData: { gameCategoryName }
            });
            this.trackingService.track({
              eventType: EventTypes.InitGameSlots,
              description: `Slot Game Open successfull game: ${name}, provider: ${provider}, categoryGame: ${gameCategoryName}`,
              id: gameId,
              secondParameter: name,
              additionalData: {
                gameCategoryName: gameCategoryName,
                openSuccessful: true,
                platform: this.getPlatform(),
                url: window.location.href,
                lobby: lobby
              }
            });

            //New Dy info
            if (gameDyId && this.isDyActive()) {
              this.dynamicYieldData(gameDyId);
            }
          } else {
            this.utils.showError('En estos momentos el juego no está disponible');
            this.trackingService.track({
              eventType: EventTypes.InitGameSlots,
              description: `Slot Game Open error`,
              id: gameId,
              secondParameter: name,
              additionalData: {
                gameCategoryName: gameCategoryName,
                openSuccessful: false,
                errorMessage: 'En estos momentos el juego no está disponible',
                platform: this.getPlatform(),
                url: window.location.href,
                lobby: lobby
              }
            });
          }
          if (queView) {
            queView.dismiss();
          }
        },
        () => {
          if (this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza) {
            this.utils.alert(false, '', 'Para acceder a este juego es necesario tener balance.', 'OK');
            this.trackingService.track({
              eventType: EventTypes.InitGameSlots,
              description: `Slot Game Open error`,
              id: gameId,
              secondParameter: name,
              additionalData: {
                gameCategoryName: gameCategoryName,
                openSuccessful: false,
                errorMessage: 'Para acceder a este juego es necesario tener balance.',
                platform: this.getPlatform(),
                url: window.location.href,
                lobby: lobby
              }
            });
          } else {
            this.utils.showError('Error al iniciar sesión de slots');
            this.trackingService.track({
              eventType: EventTypes.InitGameSlots,
              description: `Slot Game Open error`,
              id: gameId,
              secondParameter: name,
              additionalData: {
                gameCategoryName: gameCategoryName,
                openSuccessful: false,
                errorMessage: 'Error al iniciar sesión de slots',
                platform: this.getPlatform(),
                url: window.location.href,
                lobby: lobby
              }
            });
          }
        }
      );
    } else {
      this.getGameUlrCasino(provider, gameId, product, lobby, platform).subscribe(
        (gameUrl) => {
          if (!!gameUrl && JSON.stringify(gameUrl) != '{}') {
            if (gameType == 'casino') this.globalVars.user.playingCasino = true;
            gameUrl += '&deviceType=';
            if (this.platform.is('mobileweb') && this.globalVars.DEVICE_TYPE === 'web') {
              gameUrl += 'native';
            } else {
              gameUrl += this.globalVars.DEVICE_TYPE;
            }
            if (this.isMobile) {
              //open in same window
              if (this.platform.is('capacitor')) {
                StatusBar.hide();
                ScreenOrientation.unlock();
              }
              this.globalVars.rootScope.openSlotsGame(gameUrl, backButton);
            } else {
              if (product === 'Sports') {
                const screenWidth = window.innerWidth / 2;
                gameUrl = gameUrl + '&width=' + screenWidth;
              }
              this.globalVars.slotWindow = window.open(
                gameUrl,
                'Game',
                'fullscreen=1, location=0, menubar=0, resizable=0, scrollbars=0, status=0, toolbar=0, titlebar=0'
              );
            }
            this.trackingService.track({
              eventType: EventTypes.InitGameCasino,
              description: `Casino Game Open successfull game: ${name}, provider: ${provider}, categoryGame: ${gameCategoryName}`,
              id: gameId,
              secondParameter: name,
              additionalData: {
                gameCategoryName: gameCategoryName,
                openSuccessful: true,
                platform: this.getPlatform(),
                url: window.location.href,
                lobby: lobby
              }
            });
            //New Dy info
            if (gameDyId && this.isDyActive()) {
              this.dynamicYieldData(gameDyId);
            }
          } else {
            this.utils.showError('En estos momentos el juego no está disponible');
            this.trackingService.track({
              eventType: EventTypes.InitGameCasino,
              description: `Casino Game Open error`,
              id: gameId,
              secondParameter: name,
              additionalData: {
                gameCategoryName: gameCategoryName,
                openSuccessful: false,
                errorMessage: 'En estos momentos el juego no está disponible',
                platform: this.getPlatform(),
                url: window.location.href,
                lobby: lobby
              }
            });
          }
        },
        (error) => {
          this.utils.showError('Error al iniciar sesión de casino');
          this.trackingService.track({
            eventType: EventTypes.InitGameCasino,
            description: `Casino Game Open error`,
            id: gameId,
            secondParameter: name,
            additionalData: {
              gameCategoryName: gameCategoryName,
              openSuccessful: false,
              errorMessage: error,
              platform: this.getPlatform(),
              url: window.location.href,
              lobby: lobby
            }
          });
        }
      );
    }
  }

  getPlatform() {
    this.events.publish('casinoTrackNative');
    return !this.isMobile ? 'Pc' : this.globalVars.DEVICE_TYPE === 'web' ? 'Mobile' : this.globalVars.nativeDevice;
  }
  //todo deprecated
  getGamesByCategType(gameType?: string): Observable<any> {
    //FORKJOIN REFACTOR
    //http://dm.apuestas.codere.es/csbgonlinereportsuat/SlotGames/GetGamesByCategType
    const urlCategories = this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetGamesByCategType?mobile=' + this.isMobile;
    const urlGames: string =
      this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetSlotsGames?languageCode=' + this.globalVars.Idioma;

    return forkJoin([this.myGet(urlCategories), this.myGet(urlGames)])
      .pipe(
        map((value) => {
          const arrTemp = [];
          if (value[1]) {
            for (const x in value[1]) {
              if (
                (this.isMobile && value[1][x].GameType.toLowerCase().search('mobile') != -1) ||
                (!this.isMobile && value[1][x].GameType.toLowerCase().search('pc') != -1)
              ) {
                try {
                  value[1][x].JackpotAmount = 0;
                  if (this.isMobile) {
                    //mobile
                    value[1][x].DemoMobileUrl =
                      (location.protocol != 'file:' ? location.protocol : 'https:') +
                      value[1][x].DemoMobileUrl.substr(value[1][x].DemoMobileUrl.indexOf(':') + 1);
                    value[1][x].DemoUrl = value[1][x].DemoMobileUrl;
                  } else {
                    value[1][x].DemoUrl =
                      (location.protocol != 'file:' ? location.protocol : 'https:') +
                      value[1][x].DemoUrl.substr(value[1][x].DemoUrl.indexOf(':') + 1);
                  }
                } catch (err) {
                  console.error(err);
                }

                arrTemp.push(value[1][x]);
              }
            }
          }
          const platform = !this.isMobile ? 'PC' : 'mobile';
          value[1] = arrTemp.filter((slot) => slot.GameType.toLowerCase() === (gameType + platform).toLowerCase());
          return value;
        })
      )
      .pipe(
        map((data) => {
          if (data[1].length > 0) {
            let categories: any[] = data[0];
            const slots: any[] = data[1];
            for (let i = 0; i < categories.length; i++) {
              const auxArray: any[] = [];
              categories[i].GamesInCategory.forEach((game) => {
                const slot = slots.find((slotx) => slotx.GameId === game.GameId);
                if (slot) {
                  auxArray.push(slot);
                }
              });
              categories[i].GamesInCategory = auxArray;
            }
            categories.unshift({
              Name: 'Todos',
              RegionCat: 'Nacional',
              GamesInCategory: slots
            });

            categories = categories.filter((cat) => cat.GamesInCategory.length !== 0);

            return categories;
          } else {
            return [
              {
                Name: 'Todos',
                RegionCat: 'Nacional',
                GamesInCategory: []
              }
            ];
          }
        })
      );
  }

  getCasinoGamesByCategType(gameType?: string, pt?: number): Observable<any> {
    const urlCategories = this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetGamesByCategType?mobile=' + this.isMobile;
    return forkJoin([this.getCasinoGames(pt), this.myGet(urlCategories)])
      .pipe(
        map((value) => {
          //changes the protocol url to the current protocol
          const arrTemp = [];
          for (const x in value[0]) {
            if (
              (this.isMobile && value[0][x].GameType.toLowerCase().search('mobile') != -1) ||
              (!this.isMobile && value[0][x].GameType.toLowerCase().search('pc') != -1)
            ) {
              try {
                if (this.isMobile) {
                  //mobile
                  value[x].DemoMobileUrl =
                    (location.protocol != 'file:' ? location.protocol : 'https:') +
                    value[0][x].DemoMobileUrl.substr(value[x].DemoMobileUrl.indexOf(':') + 1);
                  value[x].DemoUrl = value[0][x].DemoMobileUrl;
                } else {
                  value[0][x].DemoUrl =
                    (location.protocol != 'file:' ? location.protocol : 'https:') +
                    value[0][x].DemoUrl.substr(value[0][x].DemoUrl.indexOf(':') + 1);
                }
              } catch (err) {
                console.error(err);
              }

              /////filters only mobile or pc games

              arrTemp.push(value[0][x]);
            }
          }
          value[0] = arrTemp;
          return value;
        })
      )
      .pipe(
        map((value) => {
          //map para eliminar mini juegos //Pt Roulette Mini  //Pt Blackjack Mini
          if (this.globalVars.FEATURES.MiniGamesEnabled) {
            const arrTemp = value[0].filter(
              (game) =>
                game.Name.toLowerCase() !== 'pt roulette mini' && game.Name.toLowerCase() !== 'pt blackjack mini'
            );
            value[0] = arrTemp;
          }
          return value;
        }),
        tap((value) => {
          if (this.globalVars.FEATURES.MiniGamesEnabled) {
            const arrTemp = value[0].filter((game) => game.GameId != this.globalVars.FEATURES.VerticalRouletteGameGuid);
            value[0] = arrTemp;
          }
          return value;
        })
      )
      .pipe(
        map(
          //GAMES VS CATEGORIES
          (data) => {
            if (data[0].length > 0) {
              let categories: any[] = data[1];
              const slots: any[] = data[0];
              for (let i = 0; i < categories.length; i++) {
                const auxArray: any[] = [];
                categories[i].GamesInCategory.forEach((game: any) => {
                  const slot = slots.find((slotx) => slotx.GameId === game.GameId);
                  if (slot) {
                    auxArray.push(slot);
                  }
                });
                categories[i].GamesInCategory = auxArray;
              }
              categories.unshift({
                Name: 'Todos',
                RegionCat: 'Nacional',
                GamesInCategory: slots
              });

              categories = categories.filter((cat) => cat.GamesInCategory.length !== 0);

              return categories;
            } else {
              return [
                {
                  Name: 'Todos',
                  RegionCat: 'Nacional',
                  GamesInCategory: []
                }
              ];
            }
          }
        )
      );
  }

  setCloseSessionAlert(callback: any) {
    if (!!this.globalVars.slotWindow && !!this.globalVars.slotWindow.window) {
      this.globalVars.slotWindow.window.close();
      this.globalVars.slotWindow = null;
    }

    const alert = this.utils.alertController.create(this.setResumeAlert(this.scores, callback));
    return of(alert);
  }

  private setResumeAlert(scores: any, callback: any) {
    const title = this.translateService.instant('SessionSummary');
    let message = this.translateService.instant('hPay') + ': ' + this.parseMoney.transform(scores.bets, [1]) + '<br>';
    message += this.translateService.instant('prizes') + ': ' + this.parseMoney.transform(scores.wins, [1]) + '<br>';
    return {
      header: title,
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            callback();
          }
        }
      ],
      cssClass: 'alertResumen'
    };
  }

  //SlotsGame BackButton
  slotsBackButtonEnabled(name: string) {
    const subname: string = name.toLowerCase().split(' ')[0];
    if (this.globalVars.FEATURES.providersGamingBB) {
      const listPG = this.globalVars.FEATURES.providersGamingBB.split(';');
      for (let i = 0; i < listPG.length; i++) {
        if (subname.search(listPG[i]) > -1) return true;
      }
    } else {
      if (subname.search('hab') > -1 || subname.search('evg') > -1 || subname.search('mgs') > -1) {
        return true;
      }
    }
    return false;
  }

  // todo : deprecated
  getLobbyGames() {
    let urlLobby: string = this.globalVars.URLBASE_REPORTS + 'SlotGames/GetGamesByGroupId?mobile=' + this.isMobile;

    let urlGames: string =
      this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetSlotsGames?languageCode=' + this.globalVars.Idioma;

    //PT filtered, only in ES
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      urlGames += '&includept=0';
      urlLobby += '&onlyPt=false';
    }

    return forkJoin([this.myGet(urlLobby), this.myGet(urlGames)]).pipe(
      tap((value) => {
        const arrTemp = [];
        if (value[1]) {
          for (const x in value[1]) {
            if (
              (this.isMobile && value[1][x].GameType.toLowerCase().search('mobile') != -1) ||
              (!this.isMobile && value[1][x].GameType.toLowerCase().search('pc') != -1)
            ) {
              try {
                value[1][x].JackpotAmount = 0;
                if (this.isMobile) {
                  //mobile
                  value[1][x].DemoMobileUrl =
                    (location.protocol != 'file:' ? location.protocol : 'https:') +
                    value[1][x].DemoMobileUrl.substr(value[1][x].DemoMobileUrl.indexOf(':') + 1);
                  value[1][x].DemoUrl = value[1][x].DemoMobileUrl;
                } else {
                  value[1][x].DemoUrl =
                    (location.protocol != 'file:' ? location.protocol : 'https:') +
                    value[1][x].DemoUrl.substr(value[1][x].DemoUrl.indexOf(':') + 1);
                }
              } catch (err) {
                console.error(err);
              }

              /////filters only mobile or pc games

              arrTemp.push(value[1][x]);
            }
          }
        }
        const platform = !this.isMobile ? 'PC' : 'mobile';
        value[1] = arrTemp.filter((slot) => slot.GameType.toLowerCase() === ('Slot' + platform).toLowerCase());
        return value;
      })
    );
  }

  getCasinoLobbyGames(isCasino = false) {
    let urlLobby: string = this.globalVars.URLBASE_REPORTS + 'SlotGames/GetGamesByGroupId?mobile=' + this.isMobile;
    //CasinoGames/GetCasinoGames?languageCode=es&includept=1

    let urlCasinoGames: string =
      this.globalVars.URLBASE_REPORTS + 'CasinoGames/GetCasinoGames?languageCode=' + this.globalVars.Idioma;

    let urlSlotGames: string =
      this.globalVars.URLBASE_REPORTS + 'SlotsGames/GetSlotsGames?languageCode=' + this.globalVars.Idioma;

    const urlJackpotsTickers: string = this.globalVars.LOBBY_JACKPOTS_TICKERS + '/GetLobbyJackpots';

    //PT only, only in ES
    if (
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Colombia
    ) {
      if (isCasino) {
        urlCasinoGames += '&includept=1';
        urlSlotGames += '&includept=1';
        urlLobby += '&onlyPt=true';
      } else {
        urlCasinoGames += '&includept=0';
        urlSlotGames += '&includept=0';
        urlLobby += '&onlyPt=false';
      }
    }
    return forkJoin([
      this.myGet(urlLobby),
      this.myGet(urlCasinoGames),
      this.myGet(urlSlotGames),
      this.getSlotsJackPotsTicket()
    ]).pipe(
      map((value: any) => {
        //changes the protocol url to the current protocol
        const allGames = [];

        const [lobby, casinoGames, slotGames, jackpots] = value;
        const all = [...casinoGames, ...slotGames];
        for (const x in all) {
          if (
            (this.isMobile && all[x].GameType.toLowerCase().search('mobile') != -1) ||
            (!this.isMobile && all[x].GameType.toLowerCase().search('pc') != -1)
          ) {
            //a optimizar y quetar el try/catch
            try {
              //mapeo el protocolo
              if (this.isMobile) {
                //mobile
                all[x].DemoMobileUrl =
                  (location.protocol != 'file:' ? location.protocol : 'https:') +
                  all[x].DemoMobileUrl.substr(value[x].DemoMobileUrl.indexOf(':') + 1);
                value[x].DemoUrl = all[x].DemoMobileUrl;
              } else {
                all[x].DemoUrl =
                  (location.protocol != 'file:' ? location.protocol : 'https:') +
                  all[x].DemoUrl.substr(all[x].DemoUrl.indexOf(':') + 1);
              }
            } catch (err) {
              console.error(err);
            }

            allGames.push(all[x]);
          }
        }

        if (Array.isArray(jackpots) && jackpots.length) {
          jackpots.forEach((jackPotGame) => {
            const game = allGames.find((slot) => slot.GameId === jackPotGame.GameId);
            if (game) {
              game.JackpotAmount = jackPotGame.Jackpot || 0;
            }
          });
        }

        const _lobby: C_LobbyGame[] = lobby
          .map((lobbyGame) => {
            lobbyGame.Slot = allGames.find((slot) => slot.GameId === lobbyGame.GameId);
            C_LobbyGame.addBadges(lobbyGame, lobby);
            return lobbyGame;
          })
          .filter((lobbyGame) => Boolean(lobbyGame.Slot));

        return [_lobby];
      })
    );
  }

  getJackpotGames(isCasino = false) {
    return forkJoin([this.getCasinoLobbyGames(isCasino), this.jackpotsService.getJackpots()]).pipe(
      map(([lobby, jackpots]) => [...lobby, jackpots])
    );
  }

  //this call is outside the monolithic
  public getLiveCasino(isMobile: boolean) {
    const uri = this.globalVars.DOMAIN + '/LiveGameData/games/initialstatus?mobile=' + isMobile;
    return this.myGet(uri, true, (err: any, caught: Observable<any>): any => {
      console.error('livegamedata api error');
    });
  }

  private isDyActive() {
    return this.globalVars.FEATURES.DynamicYieldRecommendationEnabled;
  }
  private dynamicYieldData(gameDyId) {
    // Var for test
    // PROD:f7c06c8a64567a99a72d28bc335874b9ce76b055712504657f3e7c6a23b74764
    // STG:26c5956a28c7d5ce546000a0e62dfe41774820cfa9ed2c47938944227cc46e4a
    const headers = {
      'Content-Type': 'application/json',
      'DY-API-Key': this.globalVars.FEATURES.DynamicYieldClientApiKey
    };

    const dyId: string = this.cookiesService.getCookie(CookieNames.DyId);
    const dyServer: string = this.cookiesService.getCookie(CookieNames.DyServer);
    const dySession: string = this.cookiesService.getCookie(CookieNames.DySession);
    const url: string = this.globalVars.FEATURES.DynamicYieldClientEngagementUrl;
    const data: any = {
      user: {
        dyid: dyId, // _dyid cookie value
        dyid_server: dyServer || dyId, //diyd_server cookie value (in other tests, this is not sent, and it works)
        active_consent_accepted: !!dyId
      },
      session: {
        dy: dySession // _dyjsession cookie value
      },
      engagements: [
        {
          type: 'SLOT_CLICK',
          slotId: gameDyId // This is the SlotId property sent with the games (mentioned at the first line)
        }
      ]
    };

    if (dySession !== null && dySession !== undefined) {
      this.httpClient.post(url, data, { headers }).subscribe();
    }
  }

  /**
   * Gets all casino calendar sections and its games, active (Jugar ahora) and inactive (Proximamente)
   */
  async getCalendarInfo() {
    // TODO: esta petición falla
    try {
      const url = this.globalVars.URLBASE_REPORTS + 'calendar/get?mobile=' + this.isMobile;
      // If we don't return an awit the catch will not be called
      return await lastValueFrom(this.myGet(url));
    } catch (error) {
      console.error('Fail service Calendar');
      return null;
    }
  }
}
