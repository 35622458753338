<ng-container *ngIf="isDesktop; then deskTemplate; else mobileTemplate"></ng-container>
<ng-template #deskTemplate>
  <ion-content class="is-modal">
    <div class="contModal">
      <img alt="" class="logomod" src="assets/global/img/logo.svg" />
      <button class="closeModal" (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <h1 class="single-title-bar">
        <!--Configurar cookies-->
        {{'uso_cookiesES_title'|translate}}
      </h1>
      <div class="divinline">
        <ion-item lines="none">
          <ion-toggle
            #select_all
            class="margint_10 button"
            (ionChange)="selectAllChange()"
            (ionFocus)="selectAllFocus()"
            labelPlacement="end"
            justify="start"
            >{{ 'select_deleteall' | translate }}</ion-toggle
          >
        </ion-item>
      </div>
      <div>
        <div>
          <ion-item lines="none"
            ><!--Cookies técnicas-->
            <ion-checkbox disabled="true" checked="true" labelPlacement="end" justify="start">
              {{'technicalcookies'|translate}}:
            </ion-checkbox>
          </ion-item>
          <div>
            {{'technicalcookiestxt'|translate}}
            <a href="" (click)="openPolitCookies($event)" target="_blank">{{'here'|translate}} </a>
          </div>
        </div>
        <div>
          <ion-item lines="none"
            ><!--Cookies de personalización-->
            <ion-checkbox
              #select_p
              (ionChange)="selectOneChange()"
              (ionFocus)="selectOneFocus()"
              [(ngModel)]="consentOptions.customOK"
              labelPlacement="end"
              justify="start"
              >{{'personalizationcookies'|translate}}:</ion-checkbox
            >
          </ion-item>
          <div>{{'personalizationcookiestxt'|translate}}</div>
        </div>
        <div>
          <ion-item lines="none"
            ><!--Cookies analíticas-->
            <ion-checkbox
              #select_a
              (ionChange)="selectOneChange()"
              (ionFocus)="selectOneFocus()"
              [(ngModel)]="consentOptions.analyticsOK"
              labelPlacement="end"
              justify="start"
              class="ng-valid"
              >{{'analyticalcookies'|translate}}:</ion-checkbox
            >
          </ion-item>
          <div>{{'analyticalcookiestxt' | translate}}</div>
        </div>
        <div>
          <!--Cookies de publicidad-->
          <ion-item lines="none">
            <ion-checkbox
              #select_d
              (ionChange)="selectOneChange()"
              (ionFocus)="selectOneFocus()"
              [(ngModel)]="consentOptions.advertismentOK"
              labelPlacement="end"
              justify="start"
              >{{'advertisingcookies'|translate}}:</ion-checkbox
            >
          </ion-item>
          <div>{{'advertisingcookiestxt'|translate}}</div>
        </div>
        <div class="margint10 txtgrey">
          <span> {{'clickaccept'|translate}}</span>
          <span><a href="" (click)="openPolitCookies($event)" target="_blank">{{'ftCookies'|translate}}</a></span>
        </div>
        <div>
          <div class="sendForm">
            <ion-button (click)="acceptCookies()" class="btnselecc" shape="round"
              >{{'acceptselection' | translate}}</ion-button
            >
            <ion-button (click)="acceptAllCookies()" class="btnselecc" shape="round"
              >{{'acceptallcookies' | translate}}</ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</ng-template>
<ng-template #mobileTemplate>
  <ion-content class="is-modal">
    <div class="contModal">
      <img alt="" class="logomod" src="assets/global/img/logo.svg" />
      <button class="closeModal" (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <h1 class="single-title-bar">
        <!--Configurar cookies-->
        {{'uso_cookiesES_title'|translate}}
      </h1>
      <div class="divinline">
        <ion-toggle
          #select_all
          class="margint_10 marginb10 button"
          (ionChange)="slectAllChangeMobile()"
          labelPlacement="end"
          justify="start"
          mode="md"
          >{{ 'select_deleteall' | translate }}</ion-toggle
        >
      </div>
      <div>
        <div>
          <ion-item lines="none"
            ><!--Cookies técnicas-->
            <ion-checkbox disabled="true" checked="true" labelPlacement="end" justify="start"
              >{{'technicalcookies'|translate}}:</ion-checkbox
            >
          </ion-item>
          <div class="txtgrey">
            {{'technicalcookiestxt'|translate}}
            <a href="" (click)="openPolitCookies($event)" target="_blank">{{'here'|translate}} </a>
          </div>
        </div>
        <div>
          <!--Cookies de personalización-->
          <ion-item lines="none">
            <ion-checkbox
              #select_p
              (ionChange)="selectOneChange()"
              (ionFocus)="selectOneFocus()"
              [(ngModel)]="consentOptions.customOK"
              labelPlacement="end"
              justify="start"
              >{{'personalizationcookies'|translate}}:
            </ion-checkbox>
          </ion-item>
          <div class="txtgrey">{{'personalizationcookiestxt'|translate}}</div>
        </div>
        <div>
          <ion-item lines="none">
            <!--Cookies analíticas-->
            <ion-checkbox
              #select_a
              (ionChange)="selectOneChange()"
              (ionFocus)="selectOneFocus()"
              [(ngModel)]="consentOptions.analyticsOK"
              labelPlacement="end"
              justify="start"
              >{{'analyticalcookies'|translate}}:
            </ion-checkbox>
          </ion-item>
          <div class="txtgrey">{{'analyticalcookiestxt' | translate}}</div>
        </div>
        <div>
          <ion-item lines="none"
            ><!--Cookies de publicidad-->
            <ion-checkbox
              #select_d
              (ionChange)="selectOneChange()"
              (ionFocus)="selectOneFocus()"
              [(ngModel)]="consentOptions.advertismentOK"
              labelPlacement="end"
              justify="start"
            >
              {{'advertisingcookies'|translate}}:
            </ion-checkbox>
          </ion-item>
          <div class="txtgrey">{{'advertisingcookiestxt'|translate}}</div>
        </div>
        <div class="margint10 txtgrey marginb10">
          <span> {{'clickaccept'|translate}}</span>
          <span><a href="" (click)="openPolitCookies($event)" target="_blank">{{'ftCookies'|translate}}</a></span>
        </div>
        <div>
          <div class="sendForm">
            <ion-button (click)="acceptCookies()" shape="round">{{'acceptselection' | translate}}</ion-button>
            <ion-button (click)="acceptAllCookies()" shape="round">{{'acceptallcookies' | translate}}</ion-button>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</ng-template>
