<!-- eslint-disable -->
<div *ngIf="mode == 'normal'">
  <div class="sb-bet-selection--row">
    <span class="sb-bet-selection--title color-dark">
      {{ event?.ResultName | truncateString: truncateValue }}
      <span class="color-muted"> {{ event?.Odd | formatOdd }} </span>
    </span>

    <!-- New Model -->
    <span
      *ngIf="MisApuestasNewModelFeatureFlagEnabled && betTypeNumber != '1' && ticketStatusNumber != '5' || matrixExist"
      class="sb-bet-selection--text text-uppercase"
      [ngClass]="getSelectionStatus(event?.ClearingStatusNumber)"
    >
      {{ ticketClearingStatus | uppercase }}
    </span>
    <!-- Old Model -->
    <span
      *ngIf="!MisApuestasNewModelFeatureFlagEnabled && betTypeNumber != '1' && ticketStatusNumber != '5' || matrixExist"
      class="sb-bet-selection--text text-uppercase"
      [ngClass]="getSelectionStatus(event?.ClearingStatusNumber)"
    >
      {{ event?.ClearingStatus | uppercase }}
    </span>
    <span
      *ngIf="betTypeNumber != '1' && ticketStatusNumber == '5'"
      class="sb-bet-selection--text text-uppercase"
      [ngClass]="getSelectionStatus('5')"
    >
      {{ ticketStatus | uppercase }}
    </span>
  </div>

  <span class="sb-bet-selection--text color-muted"> {{ event?.MarketName }} </span>

  <span class="sb-bet-selection--text color-muted">
    {{ filterName(event?.EventName) }}
    <!-- <span *ngIf="!!event?.EventName?.split('-')[1]">
      vs {{ event?.EventName?.split('-')[1] }}
    </span> -->
    <span *ngIf="checkSportName(event?.SportName) && event?.EventDatetime">
      &nbsp;{{ event?.EventDatetime | extractDate:[5] }}
    </span>
  </span>
</div>

<div *ngIf="mode == betbuilderMode">
  <div class="sb-bet-selection-betbuilder--items-wrap">
    <span
      *ngFor="let item of event | slice: 0 : betbuilderItems; first as isFirst; last as isLast;"
      class="sb-bet-selection-betbuilder--content-left-ball-container"
    >
      <div
        class="sb-bet-selection-betbuilder--content-left-ball-wrapper"
        [ngClass]="{ 'first-element': isFirst, 'last-element': isLast }"
      >
        <img class="sb-bet-selection-betbuilder--content-left-ball" src="assets/global/img/sb-betbuilderItemDark.svg" />
      </div>
      <div class="sb-bet-selection-betbuilder--content-left-ball-item">
        <span class="sb-bet-selection--title color-muted"> {{ item['ResultName'] }}&nbsp; </span>
        <span class="sb-bet-selection--text color-muted"> - {{ item['MarketName'] }} </span>
      </div>
      <div class="sb-bet-selection-betbuilder--content-left-ball-item" *ngIf="isFirst">
        <span class="sb-bet-selection--text-odd"> &nbsp;{{ getQuota()+"" | formatOdd }} </span>
      </div>
    </span>
    <span class="sb-bet-selection--text color-muted"> {{ event[0]?.EventName}} </span>
  </div>
</div>
