import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { SbNavbarItem } from '../../../models/sportbook';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { CommonModule } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { IonicModule } from '@ionic/angular';
@Component({
  selector: 'sb-navbar-item',
  templateUrl: './sb-navbar-item.html',
  imports: [CommonModule, IonicModule],
  standalone: true,
  styleUrls: ['./sb-navbar-item.scss']
})
export class SbNavbarItemComponent implements OnInit {
  @Input() item: SbNavbarItem;
  @Input() theme = 'sportbook';
  @Input() index: number;
  //eslint-disable-next-line
  @Output() select: EventEmitter<SbNavbarItem> = new EventEmitter();
  private _ref: HTMLElement;
  globalVars!: GlobalVarsModel;
  imageSrc: string;
  icon: string;
  name: string;

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }
  ngOnInit(): void {
    const { item } = this;
    const icon = item?.icon;
    this.icon = icon ? `icon-${icon.toLowerCase()}` : null;
    const isNative = Capacitor.isNativePlatform();
    const img = item?.img;
    this.imageSrc = isNative ? img?.replace('.svg', '.png') : img;
    this.name = item?.icon;
  }
}
