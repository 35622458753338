export class SbNavbarItem {
  label: string;
  img: string;
  icon?: string;
  selected?: boolean;
  navigate?: any;
  priority?: number;
  navbarOrder?: any;
  actionData?: any;

  constructor(
    label: string,
    img: string,
    icon?: string,
    selected?: boolean,
    navigate?: any,
    priority?: number,
    navbarOrder?: any,
    actionData?: any
  ) {
    this.label = label;
    this.img = img;
    this.icon = icon;
    this.selected = selected;
    this.navigate = navigate;
    this.priority = priority;
    this.navbarOrder = navbarOrder;
    this.actionData = actionData;
  }
}
