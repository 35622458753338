<ion-grid class="margin0">
  <ion-row class="is-row-grid font-weight-bold" *ngIf="!isPanama">
    <ion-col size="6">{{ 'Withdrawable' | translate }}</ion-col>
    <ion-col size="6" class="ion-text-right">
      {{ details ? (details?.WithdrawableBalance | parseMoney:[4]) : '-' }}
    </ion-col>
  </ion-row>
  <div class="treeall" *ngIf="isCasino && !isPanama">
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="6">{{ 'CasinoBalance' | translate }}</ion-col>
      <ion-col size="6" class="ion-text-right">
        {{ details ? (details?.CasinoBalance | parseMoney:[4]) : '-' }}
      </ion-col>
    </ion-row>
    <ul>
      <li>
        <ion-row class="is-row-grid">
          <ion-col size="7">{{ 'BonusBalance' | translate }}</ion-col>
          <ion-col size="5" class="ion-text-right font-weight-bold">
            {{ details ? (details?.BonusAndWinningsBalance | parseMoney:[4]) : '-' }}
          </ion-col>
        </ion-row>
      </li>
      <li>
        <ion-row class="is-row-grid">
          <ion-col size="7">{{ 'Ringfence' | translate }}</ion-col>
          <ion-col size="5" class="ion-text-right font-weight-bold">
            {{ details ? (details?.RingfencedBalance | parseMoney:[4]) : '-' }}
          </ion-col>
        </ion-row>
      </li>
    </ul>
  </div>
  <div class="treeall" *ngIf="isPanama && !isMultiprovider">
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="6">{{ 'CasinoBalance' | translate }}</ion-col>
      <ion-col size="6" class="ion-text-right">
        {{ details ? (details?.WithdrawableBalance | parseMoney:[4]) : '-' }}
      </ion-col>
    </ion-row>
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="7" class="sub" (click)="goToLobbyAndCat()">{{ 'BonusBalance' | translate }}</ion-col>
      <ion-col size="5" class="font-weight-bold ion-text-right">
        {{ details ? (details?.Bonus | parseMoney:[4]) : '-' }}
      </ion-col>
    </ion-row>
  </div>
  <div class="treeall" *ngIf="isPanama && isMultiprovider">
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="6">{{ 'CasinoBalance' | translate }}</ion-col>
      <ion-col size="6" class="ion-text-right">
        {{ details ? (details?.WithdrawableBalance | parseMoney:[4]) : '-' }}
      </ion-col>
    </ion-row>
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="6">{{ 'typeextractobono' | translate }} Playtech</ion-col>
      <ion-col size="6" class="ion-text-right"> {{ details ? (details?.bonusPT | parseMoney:[4]) : '-' }} </ion-col>
    </ion-row>
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="6">{{ 'typeextractobono' | translate }} Habanero</ion-col>
      <ion-col size="6" class="ion-text-right"> {{ details ? (details?.bonusHab | parseMoney:[4]) : '-' }} </ion-col>
    </ion-row>
  </div>

  <div class="treeall" *ngIf="!isCasino && !isPanama">
    <ion-row class="is-row-grid font-weight-bold">
      <ion-col size="6">Free Bets</ion-col>
      <ion-col size="6" class="ion-text-right"> {{ details ? (details?.Freebets | parseMoney:[4]) : '-' }} </ion-col>
    </ion-row>
  </div>
  <ion-row class="is-row-grid bordertgrey1 font-weight-bold">
    <ion-col size="6">{{ 'balancetotal' | translate }}</ion-col>
    <ion-col size="6" class="ion-text-right"> {{ details ? (details?.TotalBalance | parseMoney:[4]) : '-' }} </ion-col>
  </ion-row>
</ion-grid>
