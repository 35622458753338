/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { LoaderInterceptor } from '@interceptors/loader.interceptor';
import { TicketInterceptor } from '@interceptors/ticket.interceptor';

import { provideRouter, withHashLocation, withRouterConfig } from '@angular/router';
import { appRoutes } from './app-routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '@providers/LanguageService';
import { CapacitorHttpProvider } from '@interceptors/http.interceptor';

import { RootStoreConfig, StoreModule } from '@ngrx/store';
import * as fromApp from 'libs/sports/src/lib/interfaces/app.reducer';

import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TicketEffects } from '@models/sports/store/effects/ticket.effects';
import { SidebarEffects } from '@models/sports/store/effects/sidebar.effects';
import { SportsbookEffects } from '@models/sports/store/effects/sportsbook.effects';
import { provideAnimations } from '@angular/platform-browser/animations';

const reduxConfig: RootStoreConfig<any> = {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictActionSerializability: false,
    strictStateSerializability: false
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload' }), withHashLocation()),
    provideHttpClient(withJsonpSupport()),
    importProvidersFrom(
      IonicModule.forRoot({
        menuType: 'reveal',
        menuIcon: 'chevron-forward',
        backButtonText: '',
        innerHTMLTemplatesEnabled: true,
        swipeBackEnabled: false
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: LanguageService
        }
      })
    ),
    importProvidersFrom(
      StoreModule.forRoot(fromApp.appReducer, reduxConfig),
      EffectsModule.forRoot([TicketEffects, SidebarEffects, SportsbookEffects]),
      StoreDevtoolsModule.instrument()
    ),
    // Se han eliminado los interceptores porque se requiere que el loader
    // se muestre manualmente activándolo y desactivándolo por código
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    // Se añade un nuevo interceptor que maneja las comunicaciones entre el ticket
    // de frontend y backend de apuestas(PBS)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TicketInterceptor,
      multi: true
    },
    CapacitorHttpProvider
  ]
};
