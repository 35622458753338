<ng-container *ngIf="!isMobile; else mobileView">
  <div class="content">
    <div class="contendiv">
      <div class="titlecontact">{{ 'contact_contact' | translate }}</div>
      <div class="titletxt">{{ 'contact_contacttxt01' | translate }}</div>
    </div>
    <div class="divcards">
      <ion-card *ngFor="let item of cards" (click)="onclickCard(item)" [ngClass]="{'pointer': item.canclick}">
        <ng-container *ngIf="!isImgCustom(item.img), else nocustom">
          <ion-icon [name]="item.img"></ion-icon>
        </ng-container>
        <ng-template #nocustom>
          <img [src]="item.img" />
        </ng-template>
        <ion-card-header>
          <ion-card-subtitle>{{item.title}}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content><span class="styleText" [innerHTML]="item.txt"></span></ion-card-content>
      </ion-card>
    </div>
  </div>
</ng-container>
<!-- Mobile -->
<ng-template #mobileView>
  <div class="content">
    <div class="contendiv">
      <div class="titlecontact">{{ 'contact_contact' | translate }}</div>
      <div class="titletxt">{{ 'contact_contacttxt01' | translate }}</div>
    </div>
    <div class="divcards">
      <ion-card *ngFor="let item of cards" (click)="onclickCard(item)">
        <ng-container *ngIf="!isImgCustom(item.img), else nocustom">
          <ion-icon [name]="item.img"></ion-icon>
        </ng-container>
        <ng-template #nocustom>
          <img [src]="item.img" />
        </ng-template>
        <div>
          <ion-card-header>
            <ion-card-subtitle>{{capitalizeFirstLetterOfEachSentence(item.title)}}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content *ngIf="item.txtmobile"><span [innerHTML]="item.txt"></span></ion-card-content>
        </div>
        <div class="codere-icon icon-arrow-right" *ngIf="item.arrow"></div>
      </ion-card>
    </div>
  </div>
</ng-template>
