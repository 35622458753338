import { Component, Input, Output, EventEmitter, inject, CUSTOM_ELEMENTS_SCHEMA, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { C_NodeTicket } from '@models/C_ResponseTickets';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { UtilsMyBetsService } from '../../services/UtilsMyBetsService';
import { SbBetTileComponent } from '../../components/sb-bet-tile/sb-bet-tile';

@Component({
  standalone: true,
  selector: 'my-bets-betslip',
  templateUrl: 'my-bets-betslip.html',
  styleUrls: ['./my-bets-betslip.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [IonicModule, CommonModule, SbBetTileComponent, ExtractDatePipe]
})
export class MyBetsBetslipComponent {
  @Input() keyboard: boolean;
  @Input() showMyBets: boolean;
  @Input() selectedFilter: any;
  @Output() showMoreBets: EventEmitter<any> = new EventEmitter();

  canShowEmpty = false;
  emptyMessage: string;
  auxArrTickets$: BehaviorSubject<C_NodeTicket[]>;

  public utilsMyBetsService = inject(UtilsMyBetsService);

  constructor() {
    this.auxArrTickets$ = new BehaviorSubject([]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['selectedFilter']) return;
    if (
      this.showMyBets ||
      (changes['selectedFilter'].currentValue &&
        changes['selectedFilter'].currentValue !== changes['selectedFilter'].previousValue)
      // eslint-disable-next-line no-empty
    ) {
    }
  }

  showMore() {
    this.showMoreBets.emit(this.selectedFilter.value);
  }

  get showMoreActive() {
    if (!this.selectedFilter) return;

    const counterTicketBets: number = this.utilsMyBetsService.counterTicketBets$.value;
    const selectedFilterName = this.selectedFilter.name.toLowerCase();

    this.emptyMessage = `No hay apuestas ${selectedFilterName} en los últimos 2 días`;

    this.canShowEmpty = counterTicketBets === -1;
    return counterTicketBets >= 8 || counterTicketBets >= 1 || this.canShowEmpty;
  }
}
