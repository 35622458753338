<!-- eslint-disable -->
<div *ngIf="allHistory$ | async as allHistory" class="cs-history background-color-dark w-100">
  <div class="cs-history--filters w-100 filter-labels">
    <div class="cs-history--filters-button p-0">
      <span>PERÍODO</span>
    </div>
    <div class="cs-history--filters-button p-0">
      <span>TIPO</span>
    </div>
    <div class="cs-history--filters-button p-0">
      <span>ESTADO</span>
    </div>
  </div>
  <div class="cs-history--filters w-100 p-1">
    <div
      *ngIf="days$ | async as days"
      class="cs-history--filters-button background-color-white"
      tappable
      (click)="setFilter(filtersDates)"
    >
      <span>{{ days?.label }}</span>
      <i class="codere-icon icon-arrow-bottom color-dark"></i>
    </div>
    <div
      *ngIf="type$ | async as type"
      class="cs-history--filters-button background-color-white"
      tappable
      (click)="setFilter(filtersTypes)"
    >
      <span>{{ type?.label }}</span>
      <i class="codere-icon icon-arrow-bottom color-dark"></i>
    </div>
    <div
      *ngIf="state$ | async as state"
      class="cs-history--filters-button background-color-white"
      tappable
      (click)="setFilter(filtersStates)"
    >
      <span>{{ state?.label }}</span>
      <i class="codere-icon icon-arrow-bottom color-dark"></i>
    </div>
  </div>

  <div *ngIf="allHistory?.length > 0" class="cs-history--container background-color-regular w-100">
    <div class="cs-history--header">
      <div *ngIf="historyFields.Referencia" class="cs-history--content item-type item-start">
        <span class="cs-history--text text-center color-dark">{{ 'tRef' | translate }}</span>
      </div>

      <div class="cs-history--content item-icon item-start">
        <span class="cs-history--text color-dark">{{ 'uPPExtractTip' | translate }}</span>
      </div>

      <div class="cs-history--content item-date item-start">
        <span class="cs-history--text text-center color-dark">{{ 'uPPExtractFec' | translate }}</span>
      </div>

      <div class="cs-history--content item-amount item-end">
        <span class="cs-history--text text-center color-dark">{{ 'uPPExtractCan' | translate }}</span>
      </div>

      <div class="cs-history--content item-type item-start">
        <span class="cs-history--text text-center color-dark"> {{ 'tMet' | translate }}</span>
      </div>

      <div *ngIf="historyFields.Cuenta" class="cs-history--content item-type item-start">
        <span class="cs-history--text text-center color-dark">{{ 'tCue' | translate }}</span>
      </div>

      <div *ngIf="historyFields.RetainedAmount" class="cs-history--content item-type item-start">
        <span class="cs-history--text text-center color-dark">Importe Retenido</span>
      </div>

      <div class="cs-history--content item-state item-start">
        <span class="cs-history--text text-center color-dark">{{ 'CodereCardState' | translate }}</span>
      </div>

      <div class="cs-history--content item-icon item-end">
        <i item-right class="codere-icon p-left-3"></i>
      </div>
    </div>
    <div class="cs-history--list">
      <!-- todo: revisar [isDesktop]="isDesktop" -->
      <cs-history-item
        *ngFor="let item of allHistory; let odd = odd"
        [history]="item"
        [options]="historyFields"
        [mode]="odd ? 'muted': 'white'"
        (navigate)="navigate($event)"
        (cancelPayment)="showCancelPendingAlert($event)"
      >
      </cs-history-item>
    </div>
  </div>
  <div *ngIf="allHistory?.length == 0 && loaded" class="cs-history--container background-color-regular w-100">
    <p class="text-center color-dark py-2">No existe historial con estos filtros</p>
  </div>
</div>
