/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LicenseTypes } from '../models/MasterData';
import { AnchorLink } from '../models/footer/anchor-link';
import { FooterImage } from '../models/footer/imagen';
import { Description } from '../models/footer/description';
import { TypeRowTextLegal } from '../models/footer/typetextlegal';
import { Item } from '../models/footer/item';
import { Aenor } from '../models/footer/aenor';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { MSO_PATHS, PAYMENTS_PATHS } from '../lib/constants/routes';
import { NewBaseService } from '../providers/newBase.service';
import { UserServiceMSO } from './UserServiceMSO';
import { firstValueFrom } from 'rxjs';
import { DeviceService } from '@services/device.service';
import { InformationsTypes } from '../../../mso/src/lib/pages/information-page/information-page';

const INFORMATIONPAGE_PATH = `#/${MSO_PATHS.BASE}/${MSO_PATHS.InformationPage}?type=`;
const CONTACTPAGE_PATH = `#/${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`;
const HELPPAGE_PAHT = `#/${MSO_PATHS.BASE}/${MSO_PATHS.HelpPage}`;
const SELFTEXCLUSION_PAHT = `#/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`;
const LANGUAGE_PAHT = `#/${MSO_PATHS.BASE}/${MSO_PATHS.LanguagePage}`;
const CUOTA_PAHT = `#/${MSO_PATHS.BASE}/${MSO_PATHS.CuotaTypePage}`;
const CODERECARDPAGE_PAHT = `#/${MSO_PATHS.BASE}/${MSO_PATHS.CodereCardPage}`;
const DOWNLOADAPP_PAHT = `#/${MSO_PATHS.BASE}/${MSO_PATHS.DownloadApp}`;
const CASHIER_PAHT = `#/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`;

const DOMAIN = location.origin + location.pathname;

@Injectable({ providedIn: 'root' })
export class FooterService {
  private generalVars: { [key: string]: string } = {};
  private licenseVars: { [key: string]: string } = null;
  private imgDeposits: FooterImage[] = [];
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = Capacitor.getPlatform() === 'web';
  isMobile: boolean = Capacitor.getPlatform() !== 'web';

  //#region Inject Services
  translateService = inject(TranslateService);
  userService = inject(UserServiceMSO);
  newBaseService = inject(NewBaseService);
  router = inject(Router);
  deviceService = inject(DeviceService);
  //#endregion

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  async loadFooter() {
    if (!this.generalVars || !this.licenseVars) {
      let promisesVar: Promise<any>[] = [];
      this.userService.getFootersConstants().forEach((o) => {
        promisesVar.push(firstValueFrom(o));
      });
      const licenseVars = await Promise.all(promisesVar);
      this.licenseVars = licenseVars[1];
      this.generalVars = licenseVars[0];
      this.imgDeposits = await firstValueFrom(this.userService.getFootersImgDeposits());
    }
    return { getFooterComplete: this.getFooterComplete, licenseVars: this.licenseVars, generalVars: this.generalVars };
  }

  private get getFooterComplete() {
    return {
      aenor: this.getAenor,
      ...this.getHeader,
      ...this.getBody,
      imagesDeposit: this.imgDeposits,
      ...this.getLegal,
      extraImgsAftDeposit: this.extraImgsAftDeposit,
      newFooterImgs: this.newImgs,
      newSocialsLinks: this.newSocialsLinks
    };
  }

  private get getAenor(): Aenor {
    return {
      href: DOMAIN + this.generalVars['URL_POLITICASEGINFO'],
      src: this.generalVars['IMG_AENOR']
    };
  }

  private get extraImgsAftDeposit(): any[] {
    let extraImgsAftDeposit = [];

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        break;
      case LicenseTypes.Colombia:
        break;
      case LicenseTypes.Panama:
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        extraImgsAftDeposit = [
          {
            event: true,
            typeEvent: 'cashierHistory',
            href: DOMAIN + CASHIER_PAHT + '?section=history',
            target: '',
            classImg: 'imglotbagif',
            src: this.licenseVars['GIF_BANNER_LOTBA'],
            altImg: 'Revisa tu estado de cuenta',
            show: true
          }
        ];
        break;
      case LicenseTypes.ArgentinaMendoza:
        break;
      case LicenseTypes.ArgentinaPBA:
        extraImgsAftDeposit = [
          {
            event: false,
            href: this.licenseVars['URL_MISAPUESTAS_ARGPBA'],
            target: '',
            classImg: 'imglotbagif',
            src: this.licenseVars['GIF_BANNER_LOTBA'],
            altImg: 'Mi historial juego',
            show: true
          }
        ];
    }

    return extraImgsAftDeposit;
  }

  private get getHeader() {
    return this.formatHeader;
  }

  public get getBody() {
    return {
      bodyClass: this.cssClassBody,
      bodyInformation: [
        this.formatDescription,
        this.formatDescripcionLinks,
        this.formatDescriptionNavSport,
        this.formatDescriptionHelp
      ]
    };
  }

  private get getDeposits() {
    return this.formatDeposits;
  }

  private get getLegal() {
    return {
      timer: this.getTimer,
      rowsLegal: this.formatLegal
    };
  }

  private get formatHeader() {
    let headerObj: any = {};
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        headerObj = {
          toolbarCss: 'footer colormxfoot footerNacional toolbar toolbar-md',
          divSocilasCss: 'iconreds marginl75-',
          addViewMobile: true,
          downloadLinksDivCss: 'download marginr75-',
          socialMobile: true,
          headersImgMobile: false,
          socialsLinks: this.socialLinks,
          headerDownloadLinks: this.headerDownloadLinks,
          imagesHeader: this.imagesHeader,
          colombiaImagesHeader: [],
          colombiaImagesHeaderMobile: [],
          downloadLinksMobileColombia: [],
          socialsLinksMobileColombia: []
        };
        break;
      case LicenseTypes.Colombia:
        headerObj = {
          toolbarCss: 'footer colormxfoot footerNacional toolbar toolbar-md',
          socialMobile: true,
          headersImgMobile: false,
          divSocilasCss: 'iconreds',
          addViewMobile: true,
          downloadLinksDivCss: 'download',
          headerDownloadLinks: this.headerDownloadLinks,
          downloadLinksMobileColombia: [
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_DOWNLOAD_COLOMBIA'],
              classImg: 'imgdownload',
              src: this.licenseVars['IMG_DOWNLOAD_IOS_COLOMBIA'],
              target: '_blank',
              show: true
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_DOWNLOAD_COLOMBIA'],
              classImg: 'imgdownload',
              src: this.licenseVars['IMG_DOWNLOAD_ANDROID_COLOMBIA'],
              target: '_blank',
              show: true
            }
          ],
          socialsLinksMobileColombia: [
            {
              external: false,
              href: DOMAIN + CONTACTPAGE_PATH,
              src: this.generalVars['IMG_ICON_CHAT'],
              event: true,
              typeEvent: 'contactPage',
              classImg: 'socialCoMob width40',
              styleImage: {},
              target: '',
              style: {},
              altImg: ''
            }
          ],
          socialsLinks: this.socialLinks,

          colombiaImagesHeaderMobile: [
            {
              alt: 'Codere-RealMadrid',
              class: 'marginl0 madridCoMob width175',
              src: this.generalVars['IMG_CODERE_PASTILLA_REALMADRID'],
              divContainerCss: 'footericondeposit--container',
              styImg: ''
            },
            {
              alt: 'LifeMiles',
              class: 'marginr25 css50',
              src: this.licenseVars['IMG_LOGO_LIFEMILES'],
              divContainerCss: 'footericondeposit--container'
            },
            {
              alt: 'Crown Casinos',
              class: 'marginr25',
              src: this.licenseVars['IMG_LOGO_CROWN'],
              divContainerCss: 'footericondeposit--container',
              styImg: {}
            },
            {
              alt: 'Stars Casino Games',
              class: 'iconpayval',
              src: this.licenseVars['IMG_LOGO_STARS_CASINO_GAMES'],
              divContainerCss: 'footericondeposit--container',
              styImg: {}
            },
            {
              alt: 'Casino Fantasia Royal',
              class: 'iconpayval',
              src: this.licenseVars['IMG_LOGO_FANTASIA_ROYAL'],
              divContainerCss: 'footericondeposit--container',
              styImg: {}
            },
            {
              alt: 'Mundo Casino',
              class: 'crown MundoCasinoFooter backgroundwhite',
              src: this.licenseVars['IMG_LOGO_MUNDO_CASINO'],
              divContainerCss: 'footericondeposit--container',
              styImg: {}
            }
          ],
          colombiaImagesHeader: [
            {
              alt: 'LifeMiles',
              class: 'marginr25 css50',
              src: this.licenseVars['IMG_LOGO_LIFEMILES'],
              divContainerCss: 'footericondeposit--container'
            },
            {
              alt: 'Crown Casinos',
              class: 'marginr25 css50',
              src: this.licenseVars['IMG_LOGO_CROWN'],
              divContainerCss: 'footericondeposit--container'
            },
            {
              alt: 'Stars Casino Games',
              class: 'iconpayval css50',
              src: this.licenseVars['IMG_LOGO_STARS_CASINO_GAMES'],
              divContainerCss: 'footericondeposit--container'
            },
            {
              alt: 'Casino Fantasia Royal',
              class: 'iconpayval margint19 css50',
              src: this.licenseVars['IMG_LOGO_FANTASIA_ROYAL'],
              divContainerCss: 'footericondeposit--container'
            },
            {
              alt: 'Mundo Casino',
              class: 'crown MundoCasinoFooter backgroundwhite css50',
              src: this.licenseVars['IMG_LOGO_MUNDO_CASINO'],
              divContainerCss: 'footericondeposit--container'
            }
          ]
        };
        break;
      case LicenseTypes.Panama:
        headerObj = {
          toolbarCss: 'footer footerNacional footer-panama toolbar toolbar-md',
          divSocilasCss: 'redsocial',
          addViewMobile: false,
          downloadLinksDivCss: '',
          socialMobile: false,
          headersImgMobile: true,
          socialsLinks: this.socialLinks,
          imagesHeader: this.imagesHeader,
          headerDownloadLinks: this.headerDownloadLinks,
          colombiaImagesHeader: [],
          colombiaImagesHeaderMobile: [],
          downloadLinksMobileColombia: [],
          socialsLinksMobileColombia: []
        };
        break;
      case LicenseTypes.ArgentinaMendoza:
        headerObj = {
          toolbarCss: 'footer footerNacional footer-panama toolbar toolbar-md',
          divSocilasCss: 'iconreds',
          addViewMobile: true,
          downloadLinksDivCss: '',
          socialMobile: false,
          headersImgMobile: true,
          socialsLinks: this.socialLinks,
          imagesHeader: this.imagesHeader,
          headerDownloadLinks: this.headerDownloadLinks,
          colombiaImagesHeader: [],
          colombiaImagesHeaderMobile: [],
          downloadLinksMobileColombia: [],
          socialsLinksMobileColombia: []
        };
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        headerObj = {
          toolbarCss: 'footer colormxfoot footerNacional footerAR toolbar toolbar-md',
          divSocilasCss: 'iconreds',
          addViewMobile: true,
          downloadLinksDivCss: '',
          socialMobile: false,
          headersImgMobile: true,
          socialsLinks: this.socialLinks,
          imagesHeader: this.imagesHeader,
          headerDownloadLinks: this.headerDownloadLinks,
          colombiaImagesHeader: [],
          colombiaImagesHeaderMobile: [],
          downloadLinksMobileColombia: [],
          socialsLinksMobileColombia: []
        };
        break;
      case LicenseTypes.ArgentinaPBA:
        headerObj = {
          toolbarCss: 'footer colormxfoot footerNacional footerAR toolbar toolbar-md',
          divSocilasCss: 'iconreds',
          addViewMobile: true,
          downloadLinksDivCss: '',
          socialMobile: false,
          headersImgMobile: true,
          socialsLinks: this.socialLinks,
          imagesHeader: this.imagesHeader,
          headerDownloadLinks: this.headerDownloadLinks,
          colombiaImagesHeader: [],
          colombiaImagesHeaderMobile: [],
          downloadLinksMobileColombia: [],
          socialsLinksMobileColombia: []
        };
        break;
    }

    return headerObj;
  }

  private get socialLinks(): AnchorLink[] {
    let socialLinks: AnchorLink[] = [];
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        socialLinks = [
          {
            external: false,
            href: DOMAIN + CONTACTPAGE_PATH,
            src: this.generalVars['IMG_ICON_CHAT'],
            event: true,
            typeEvent: 'contactPage',
            classImg: 'imgchat',
            altImg: 'Contac'
          },
          {
            external: true,
            href: this.licenseVars['URL_INSTA_CODERE'],
            src: this.generalVars['IMG_CODERE_INSTA'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Instagram'
          },
          {
            external: true,
            href: this.licenseVars['URL_TWITTER_CODERE'],
            src: this.generalVars['IMG_CODERE_TWITTER'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Twitter'
          },
          {
            external: true,
            href: this.licenseVars['URL_FACEBOOK_CODERE'],
            src: this.generalVars['IMG_CODERE_FACEBOOK'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Facebook'
          }
        ];
        break;
      case LicenseTypes.Colombia:
        socialLinks = [
          {
            external: false,
            href: DOMAIN + CONTACTPAGE_PATH,
            src: this.generalVars['IMG_ICON_CHAT'],
            event: true,
            typeEvent: 'contactPage',
            classImg: 'icon40',
            style: {},
            class: '',
            target: '',
            altImg: 'Contac'
          },
          {
            external: false,
            href: '',
            src: this.generalVars['IMG_CODERE_PASTILLA_REALMADRID'],
            event: false,
            classImg: 'imgh84',
            styleImage: {},
            class: 'nolinkimg',
            target: '',
            style: {},
            altImg: '',
            show: true
          }
        ];
        break;
      case LicenseTypes.Panama:
        socialLinks = [
          {
            external: false,
            href: DOMAIN + CONTACTPAGE_PATH,
            src: this.generalVars['IMG_ICON_CHAT'],
            event: true,
            typeEvent: 'contactPage',
            altImg: 'Contac'
          }
        ];
        break;
      case LicenseTypes.ArgentinaMendoza:
        socialLinks = [
          {
            external: false,
            href: DOMAIN + CONTACTPAGE_PATH,
            src: this.generalVars['IMG_ICON_CHAT'],
            event: true,
            classImg: 'imgchat',
            typeEvent: 'contactPage',
            altImg: 'Contac'
          }
        ];
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        socialLinks = [
          {
            external: false,
            href: DOMAIN + CONTACTPAGE_PATH,
            src: this.generalVars['IMG_ICON_CHAT'],
            event: true,
            classImg: 'imgchat',
            typeEvent: 'contactPage',
            altImg: 'Contac'
          },
          {
            external: true,
            href: this.licenseVars['URL_INSTA_CODERE'],
            src: this.generalVars['IMG_CODERE_INSTA'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Instagram'
          },
          {
            external: true,
            href: this.licenseVars['URL_TWITTER_CODERE'],
            src: this.generalVars['IMG_CODERE_TWITTER'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Twitter'
          },
          {
            external: true,
            href: this.licenseVars['URL_CODERE_FACEBOOK'],
            src: this.generalVars['IMG_CODERE_FACEBOOK'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Facebook'
          }
        ];
        break;
      case LicenseTypes.ArgentinaPBA:
        socialLinks = [
          {
            external: false,
            href: DOMAIN + CONTACTPAGE_PATH,
            src: this.generalVars['IMG_ICON_CHAT'],
            event: true,
            classImg: 'imgchat',
            typeEvent: 'contactPage',
            altImg: 'Contac'
          },
          {
            external: true,
            href: this.licenseVars['URL_INSTA_CODERE'],
            src: this.generalVars['IMG_CODERE_INSTA'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Instagram'
          },
          {
            external: true,
            href: this.licenseVars['URL_TWITTER_CODERE'],
            src: this.generalVars['IMG_CODERE_TWITTER'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Twitter'
          },
          {
            external: true,
            href: this.licenseVars['URL_CODERE_FACEBOOK'],
            src: this.generalVars['IMG_CODERE_FACEBOOK'],
            target: '_blank',
            event: false,
            classImg: 'imgchat',
            altImg: 'Facebook'
          }
        ];
        break;
    }
    return socialLinks;
  }

  private get headerDownloadLinks(): AnchorLink[] {
    let headerDownloadLinks: AnchorLink[] = [];
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        headerDownloadLinks = [
          {
            event: false,
            external: true,
            href:
              (this.isRegistro && this.deviceService.isDesktop()) || this.deviceService.isMobile()
                ? this.licenseVars['URL_DOWNLOAD_NATIONAL']
                : null,
            class: 'marginr15 ios',
            src: this.generalVars['IMG_FOOTER_DOWNLOAD_IOS'],
            target: '_blank',
            show: !this.globalVars.isNative,
            classImg: 'height40'
          },
          {
            event: false,
            external: true,
            href:
              (this.isRegistro && this.deviceService.isDesktop()) || this.deviceService.isMobile()
                ? this.licenseVars['URL_DOWNLOAD_NATIONAL']
                : null,
            class: 'android-download',
            src: this.generalVars['IMG_FOOTER_DOWNLOAD_ANDROID'],
            target: '_blank',
            show: !this.globalVars.isNative,
            classImg: 'height40'
          }
        ];
        break;
      case LicenseTypes.Colombia:
        headerDownloadLinks = [
          {
            event: false,
            external: true,
            href: this.licenseVars['URL_DOWNLOAD_COLOMBIA'],
            classImg: 'height40',
            src: this.licenseVars['IMG_DOWNLOAD_IOS_COLOMBIA'],
            target: '_blank',
            show: true
          },
          {
            event: false,
            external: true,
            href: this.licenseVars['URL_DOWNLOAD_COLOMBIA'],
            classImg: 'height40',
            src: this.licenseVars['IMG_DOWNLOAD_ANDROID_COLOMBIA'],
            target: '_blank',
            show: true
          }
        ];
        break;
      case LicenseTypes.Panama:
        headerDownloadLinks = [
          {
            altImg: 'RealMadrid',
            event: false,
            external: false,
            href: null,
            classImg: this.deviceService.isMobile() ? 'height55' : 'height70',
            src: this.generalVars['IMG_CODERE_PASTILLA_REALMADRID'],
            show: true
          },
          {
            event: false,
            external: true,
            href: this.deviceService.isMobile() ? this.licenseVars['URL_ANDROID_DOWNLOAD'] : null,
            classImg: this.deviceService.isMobile() ? 'height55' : 'height70',
            src: this.licenseVars['IMG_ANDROID_DOWNLOAD'],
            target: '_blank',
            show: !this.globalVars.isNative && !navigator.userAgent.includes('Mac OS')
          }
        ];
        break;
      case LicenseTypes.ArgentinaMendoza:
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaPBA:
      case LicenseTypes.ArgentinaCordoba:
        headerDownloadLinks = [];
        break;
    }
    return headerDownloadLinks;
  }

  private get imagesHeader(): FooterImage[] {
    let imagesHeader: FooterImage[];
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        imagesHeader = [];
        break;
      case LicenseTypes.Colombia:
        imagesHeader = [];
        break;
      case LicenseTypes.Panama:
        imagesHeader = [];
        break;
      case LicenseTypes.ArgentinaMendoza:
        imagesHeader = [
          {
            alt: 'Codere-River',
            class: 'maxh84',
            src: this.licenseVars['IMG_CODERERIVER']
          },
          {
            alt: 'Codere-Real Madrid',
            class: 'maxh84',
            src: this.licenseVars['IMG_CODERE_PASTILLA_REALMADRID']
          }
        ];
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        imagesHeader = [
          {
            alt: 'Codere-River',
            class: 'maxh84',
            src: this.licenseVars['IMG_CODERERIVER']
          },
          {
            alt: 'Codere-Real Madrid',
            class: 'maxh84',
            src: this.licenseVars['IMG_CODERE_PASTILLA_REALMADRID']
          }
        ];
        break;
      case LicenseTypes.ArgentinaPBA:
        imagesHeader = [
          {
            alt: 'Codere-River',
            class: 'maxh84',
            src: this.licenseVars['IMG_CODERERIVER']
          },
          {
            alt: 'Codere-Real Madrid',
            class: 'maxh84',
            src: this.licenseVars['IMG_CODERE_PASTILLA_REALMADRID']
          }
        ];
        break;
    }
    return imagesHeader;
  }

  private get formatLegal(): TypeRowTextLegal[] {
    let rowsLegal: TypeRowTextLegal[] = [];

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        rowsLegal = [
          {
            class: 'copyFooter paddingb0 txtlegal backgroundblack',
            type: 'array',
            anchors_images: [
              [
                {
                  src: this.licenseVars['IMG_JUEGACON'],
                  class: 'logoplaywith',
                  alt: 'Juega con moderación'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_RGIAJ'],
                  target: '_blank',
                  class: 'handLogo',
                  src: this.licenseVars['IMG_HANDlOGO'],
                  altImg: 'ordenadcionjuego.es'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_JUEGASEGURO'],
                  target: '_blank',
                  class: 'juegoSeg',
                  src: this.licenseVars['IMG_JUEGASEGURO'],
                  altImg: 'juegoseguro.es'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_JUGARBIEN'],
                  target: '_blank',
                  class: 'jugarBien',
                  src: this.licenseVars['IMG_JUGARBIEN'],
                  altImg: 'jugarBien.es'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_LOGO_18'],
                  target: '_blank',
                  class: 'more18',
                  src: this.licenseVars['IMG_LOGO_18'],
                  alt: '+18'
                },
                {
                  src: this.licenseVars['IMG_SINDIVERSION'],
                  class: 'marginl15',
                  alt: 'Sin diversión no hay juego'
                }
              ]
            ]
          },
          {
            class: 'copyFooter paddingb0 txtlegal',
            type: 'span',
            innerHTML: 'ftCondTextNacional',
            preTitle: '',
            titleToTranslate: ''
          }
        ];
        break;

      case LicenseTypes.Colombia:
        rowsLegal = [
          {
            class: 'copyFooter backgroundblack',
            type: 'array',
            anchors_images: [
              [
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_COL_JUEGOS'],
                  target: '_blank',
                  class: '',
                  src: this.licenseVars['IMG_LOGO_COL_JUEGOS'],
                  altImg: 'Coljuegos',
                  classImg: 'coljuegos coljuegosie margint30- m-bottom-0'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_COL_JUEGOS'],
                  target: '_blank',
                  class: '',
                  src: this.licenseVars['IMG_LOGO_JUEGA_BIEN_CO'],
                  altImg: 'Juegabien.co',
                  classImg: 'coljuegos coljuegosie margint30- m-bottom-0 marginl6'
                }
              ],
              [
                {
                  src: this.generalVars['IMG_18_RED'],
                  class: 'more18 more18-colombia',
                  alt: ''
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_JUGADORES_ANONIMOS_COLOMBIA'],
                  target: '_blank',
                  class: 'anonymousText',
                  altImg: '',
                  title: 'regtxtpaso1',
                  translatePipe: true
                }
              ]
            ]
          },
          {
            class: 'copyFooter paddingb0 txtlegal row',
            type: 'span',
            innerHTML: 'legalFooterCO'
          }
        ];
        break;

      case LicenseTypes.Panama:
        rowsLegal = [
          {
            class: 'copyFooter footerPanamaAge paddingb0 txtlegal row',
            type: 'noSpan',
            innerHTML: '',
            preTitle: '',
            titleToTranslate: 'legalFooterPaMay18'
          },
          {
            class: 'copyFooter paddingb0 txtlegal row',
            type: 'span',
            innerHTML: '',
            preTitle: '',
            titleToTranslate: 'legalFooterPa'
          }
        ];
        break;
      case LicenseTypes.ArgentinaMendoza:
        rowsLegal = [
          {
            class: 'copyFooter footerarimg paddingb0 txtlegal backgroundblack mdz',
            type: 'array',
            anchors_images: [
              [
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_JUEGO_RESPONSABLE'],
                  target: '_blank',
                  class: 'logoplaywith',
                  classImg: '',
                  src: this.licenseVars['IMG_JUEGO_RESPONSABLE'],
                  altImg: 'Juego Responsable'
                },
                {
                  class: 'imgcol paddingr5 width50',
                  src: this.licenseVars['IMG_MAS18'],
                  altImg: 'Mayor 18'
                },
                {
                  external: false,
                  event: false,
                  href: this.licenseVars['URL_PREAUTOEXCLUSION'],
                  target: '_blank',
                  class: '',
                  classImg: 'marginl15 marginr15',
                  src: this.licenseVars['IMG_PREAUTOEXCLUSION'],
                  altImg: 'Pre Autoexclusión'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_GOBIERNO_MENDOZA'],
                  target: '_blank',
                  class: '',
                  classImg: 'paddingr10',
                  src: this.licenseVars['IMG_GOBIERNO_MENDOZA'],
                  altImg: 'Saber Jugar'
                },

                {
                  class: '',
                  classImg: 'imglotba',
                  src: this.licenseVars['IMG_JUGA_SEGURO'],
                  altImg: 'Juga seguro'
                }
              ]
            ]
          },
          {
            class: 'copyFooter paddingb0 row',
            type: 'span',
            innerHTML: `<div class="txtf500">${this.translateService.instant(
              'regtxtlegalmdz'
            )}</div> <div class="txtlegal legalmza">${this.translateService.instant('regtxtlegal3')}</div>`
          }
        ];
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        rowsLegal = [
          {
            class: 'copyFooter footerarimg paddingb0 txtlegal backgroundblack backglotba',
            type: 'array',
            anchors_images: [
              [
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_HELP_LOGO_0800_ARGCABA'],
                  target: '_blank',
                  class: 'logoplaywith',
                  classImg: '',
                  src: this.licenseVars['IMG_LINEAGRATUITA'],
                  altImg: 'Línea gratuita'
                },
                {
                  external: false,
                  event: true,
                  href: DOMAIN + SELFTEXCLUSION_PAHT,
                  target: '',
                  class: '',
                  classImg: '',
                  src: this.licenseVars['IMG_AUTOEXCLUSION'],
                  altImg: 'Autoexclusión',
                  typeEvent: 'selfExclusion'
                },
                {
                  class: 'imgcol width40',
                  src: this.licenseVars['IMG_MAS18'],
                  altImg: 'Mayor 18'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_LOTERIASABERJUGAR'],
                  target: '_blank',
                  class: '',
                  classImg: 'imglotba',
                  src: this.licenseVars['IMG_LOTERIA_SABERJUGAR'],
                  altImg: 'LOTERIA Y SABER JUGAR'
                }
              ]
            ]
          },
          {
            class: 'copyFooter paddingb0 row',
            type: 'span',
            innerHTML: `<div class="txtf500">${this.translateService.instant(
              'regtxtlegal'
            )}</div> <div class="txtlegal">${this.translateService.instant('regtxtlegal2')}</div>`
          }
        ];
        break;
      case LicenseTypes.ArgentinaPBA:
        rowsLegal = [
          {
            class: 'copyFooter footerarimg paddingb0 txtlegal backgroundblack backglotba',
            type: 'array',
            anchors_images: [
              [
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_HELP_LOGO_0800_ARGPBA'],
                  target: '_blank',
                  class: 'logoplaywith',
                  classImg: '',
                  src: this.licenseVars['IMG_LINEAGRATUITA'],
                  altImg: 'Línea gratuita'
                },
                {
                  class: 'imgcol paddingr5 width50',
                  src: this.licenseVars['IMG_MAS18'],
                  altImg: 'Mayor 18'
                },
                {
                  external: false,
                  event: false,
                  href: this.licenseVars['URL_SELFEXCLUSION'],
                  target: '',
                  class: '',
                  classImg: 'marginl15 marginr15',
                  src: this.licenseVars['IMG_AUTOEXCLUSION'],
                  altImg: 'Autoexclusión'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_LOTBA'],
                  target: '_blank',
                  class: '',
                  classImg: 'imglotba',
                  src: this.licenseVars['IMG_LOTBA'],
                  altImg: 'LOTBA'
                },
                {
                  external: true,
                  event: false,
                  href: this.licenseVars['URL_SABERJUGAR'],
                  target: '_blank',
                  class: '',
                  classImg: 'logosj',
                  src: this.licenseVars['IMG_SABERJUGAR'],
                  altImg: 'Saber Jugar'
                }
              ]
            ]
          },
          {
            class: 'copyFooter paddingb0 row',
            type: 'span',
            innerHTML: `<div class="txtf500">${this.translateService.instant(
              'regtxtlegal'
            )}</div> <div class="txtlegal">${this.translateService.instant('regtxtlegal2')}</div>`
          }
        ];
        break;
    }

    return rowsLegal;
  }

  private get getTimer(): boolean {
    let timer = false;

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        timer = true;
        break;
      case LicenseTypes.Colombia:
        timer = false;
        break;
      case LicenseTypes.Panama:
        timer = false;
        break;

      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaPBA:
      case LicenseTypes.ArgentinaCordoba:
      case LicenseTypes.ArgentinaMendoza:
        timer = false;
        break;
    }

    return timer;
  }

  private get formatDeposits(): FooterImage[] {
    let imagesDeposit: FooterImage[] = [];

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        imagesDeposit = [
          {
            alt: 'Bizum',
            class: 'bizum',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_BIZUM']
          },
          {
            alt: 'Book Codere',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_LOCAL_CODERE']
          },
          {
            alt: 'Visa',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_VISA']
          },
          {
            alt: 'Mastercad',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_MASTERCARD']
          },
          {
            alt: 'Transferencia',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_TRANSFERENCIA']
          },
          {
            alt: 'Neteller',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_NETELLER']
          },
          {
            alt: 'Skrill',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_SKRILL']
          },
          {
            alt: 'Paypal',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_PAYPAL']
          },
          {
            alt: 'Paysafecard',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_PAYSAFECARD']
          },
          {
            alt: 'Halcash',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_HALCASH']
          },
          {
            alt: 'Rapid Transfer',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_RAPIDTRANSFER']
          },
          {
            alt: 'Transferencia Inmediata',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_TRANSFER_INM']
          }
        ];
        break;
      case LicenseTypes.Colombia:
        imagesDeposit = [
          {
            alt: 'Book Codere',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_LOCAL_CODERE']
          },
          {
            alt: 'Visa',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_VISA']
          },
          {
            alt: 'MasterCard',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_MASTERCARD']
          },
          {
            alt: 'PSE',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_PSE']
          },
          {
            alt: 'PayU',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_PAYU']
          },

          {
            alt: 'Dimonex',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_DIMONEX']
          },
          {
            alt: 'Almacenes Exito',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_ALMAMCENES_EXITO']
          },
          {
            alt: 'DaviPlata',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_DAVIPLATA']
          },
          {
            alt: 'Puntored',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_PUNTORED']
          },
          {
            alt: 'efecty',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_EFECTY']
          },
          {
            alt: 'TPaga',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_TPAGA']
          },
          {
            alt: 'Nequi',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_NEQUI']
          }
        ];
        break;
      case LicenseTypes.Panama:
        imagesDeposit = [];
        break;
      case LicenseTypes.ArgentinaMendoza:
        imagesDeposit = [];
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        imagesDeposit = [
          {
            alt: 'Transferencia',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_TRANSFERENCIA'],
            show: false
          },
          {
            alt: 'Mastercad',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_MASTERCARD']
          },
          {
            alt: 'DEBIN',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_DEBIN']
          },
          {
            alt: 'MercadoPago',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_MERCADOPAGO']
          }
        ];
        break;
      case LicenseTypes.ArgentinaPBA:
        imagesDeposit = [
          {
            alt: 'Transferencia',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_TRANSFERENCIA']
          },
          {
            alt: 'Mastercad',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.generalVars['IMG_MASTERCARD']
          },
          {
            alt: 'DEBIN',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_DEBIN']
          },
          {
            alt: 'MercadoPago',
            class: '',
            divContainerCss: 'footericondeposit--container',
            src: this.licenseVars['IMG_MERCADOPAGO']
          }
        ];
        break;
    }

    return imagesDeposit;
  }

  private get formatDescriptionHelp(): Description {
    let descriptionHelp: Description;

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        descriptionHelp = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col paddingl15',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'ftHelp',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + HELPPAGE_PAHT,
              target: '',
              title: 'ayuda',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'help',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('tycweb'),
              target: '',
              title: 'tcweb',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 't&cweb',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('webdataprotection'),
              target: '',
              title: 'webdataprotection',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'webdataprotection',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + SELFTEXCLUSION_PAHT,
              target: '',
              title: 'uPJRAut',
              translatePipe: true,
              show: this.globalVars.user.logged,
              class: '',
              typeEvent: 'selfExclusion',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + CONTACTPAGE_PATH,
              target: '',
              title: 'tContac',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'contactPage',
              sign: '-',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.Colombia:
        descriptionHelp = {
          divAnchorClass: '',
          divContainerClass: 'col paddingl15',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'ftHelp',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + HELPPAGE_PAHT,
              target: '',
              title: 'helparea',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'help',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('webdataprotection'),
              target: '',
              title: 'webdataprotection',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'webdataprotection',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + CONTACTPAGE_PATH,
              target: '',
              title: 'contactarea',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'contactPage',
              sign: '-',
              relnofollow: 'nofollow'
            },

            {
              event: true,
              external: false,
              href: '',
              target: '',
              title: 'Chat Online',
              translatePipe: false,
              show: true,
              class: 'footer-link',
              typeEvent: 'openChat',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_COLOMBIA_WHATSAPP'],
              target: '_blank',
              class: '',
              translatePipe: false,
              title: 'WhatsApp:' + this.licenseVars['TLF_COLOMBIA_WHATSAPP'],
              show: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_COLOMBIA_TELEGRAM'],
              target: '_blank',
              class: '',
              translatePipe: false,
              title: 'Telegram',
              show: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.isMobile ? this.licenseVars['URL_COLOMBIA_TLF'] : 'nolink',
              target: '_blank',
              class: '',
              translatePipe: false,
              title:
                this.translateService.instant('v2_phone') + ': ' + this.translateService.instant('contact_casinotlf'),
              show: true,
              sign: '-'
            },
            {
              event: false,
              external: false,
              href: this.generalVars['URL_CONTACTO_EMAIL'],
              target: '',
              class: '',
              translatePipe: false,
              title: this.translateService.instant('ftContac') + ` apuestas@codere.com`,
              show: true,
              sign: '-',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.Panama:
        descriptionHelp = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'ftHelp',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + HELPPAGE_PAHT,
              target: '',
              title: 'ayuda',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'help',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('webdataprotection'),
              target: '',
              title: 'webdataprotection',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'webdataprotection',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + LANGUAGE_PAHT,
              target: '',
              title: 'iSubT',
              translatePipe: true,
              show: !this.globalVars.user?.logged,
              class: '',
              typeEvent: 'language',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.generalVars['URL_CONTACTO_EMAIL'],
              target: '',
              class: '',
              translatePipe: false,
              title: this.translateService.instant('ftContac') + ` apuestas@codere.com`,
              show: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_PANAMA_WHATSAPP'],
              target: '_blank',
              src: this.generalVars['IMG_WHATSAPP_LINKS_HELP'],
              classImg: 'icowhatsapp',
              class: '',
              translatePipe: false,
              title: this.licenseVars['TLF_PANAMA_WHATSAPP'],
              show: true,
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_PANAMA_LOCAL_PHONE'],
              target: '_blank',
              src: this.licenseVars['IMG_PANAMA_LOCAL_PHONE'],
              classImg: 'icowhatsapp',
              class: '',
              translatePipe: false,
              title: this.licenseVars['TLF_PANAMA_LOCAL_PHONE'],
              show: true,
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaMendoza:
        descriptionHelp = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col-3 paddingl15',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'ftHelp',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + LANGUAGE_PAHT,
              target: '',
              title: 'iSubT',
              translatePipe: true,
              show: !this.globalVars.user.logged,
              class: '',
              typeEvent: 'language',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + HELPPAGE_PAHT,
              target: '',
              title: 'ayuda',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'help',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: 'nolink',
              target: '',
              class: '',
              translatePipe: false,
              title: this.licenseVars['URL_TELCODEREAR'],
              show: true,
              relnofollow: 'nofollow',
              sign: ''
            },
            {
              event: false,
              external: false,
              href: this.generalVars['URL_CONTACTO_EMAIL'],
              target: '',
              class: '',
              translatePipe: true,
              title: this.translateService.instant('ftContac') + ` apuestas@codere.com`,
              show: true,
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_ARGMZA_WHATSAPP'],
              target: '_blank',
              src: this.generalVars['IMG_WHATSAPP_LINKS_HELP'],
              classImg: 'icowhatsapp',
              class: '',
              translatePipe: false,
              title: this.licenseVars['TLF_ARGMZA_WHATSAPP'],
              show: true,
              spancss: '',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        descriptionHelp = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col paddingl15',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'ftHelp',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + HELPPAGE_PAHT,
              target: '',
              title: 'ayuda',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'help',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: 'nolink',
              target: '',
              class: '',
              translatePipe: false,
              title: this.licenseVars['URL_TELCODEREAR'],
              show: true,
              relnofollow: 'nofollow',
              sign: ''
            },
            {
              event: true,
              external: false,
              href: DOMAIN + LANGUAGE_PAHT,
              target: '',
              title: 'iSubT',
              translatePipe: true,
              show: !this.globalVars.user?.logged,
              class: '',
              typeEvent: 'language',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.generalVars['URL_CONTACTO_EMAIL'],
              target: '',
              class: '',
              translatePipe: true,
              title: this.translateService.instant('ftContac') + ` apuestas@codere.com`,
              show: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_ARGCABA_WHATSAPP'],
              target: '_blank',
              src: this.generalVars['IMG_WHATSAPP_LINKS_HELP'],
              classImg: 'icowhatsapp',
              class: '',
              translatePipe: false,
              title: this.licenseVars['TLF_ARGCABA_WHATSAPP'],
              show: true,
              spancss: '',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaPBA:
        descriptionHelp = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col paddingl15',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'ftHelp',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + HELPPAGE_PAHT,
              target: '',
              title: 'ayuda',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'help',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + LANGUAGE_PAHT,
              target: '',
              title: 'iSubT',
              translatePipe: true,
              show: !this.globalVars.user.logged,
              class: '',
              typeEvent: 'language',
              sign: '-'
            },
            {
              event: false,
              external: false,
              href: this.generalVars['URL_CONTACTO_EMAIL'],
              target: '',
              class: '',
              translatePipe: false,
              title: this.translateService.instant('ftContac') + ` apuestas@codere.com`,
              show: true,
              sign: '-'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_ARGPBA_WHATSAPP'],
              target: '_blank',
              src: this.generalVars['IMG_WHATSAPP_LINKS_HELP'],
              classImg: 'icowhatsapp',
              class: '',
              translatePipe: false,
              title: this.licenseVars['TLF_ARGPBA_WHATSAPP'],
              show: true,
              spancss: '',
              beforeText: this.translateService.instant('telfarg')
            }
          ]
        };
        break;
    }

    return descriptionHelp;
  }

  private get formatDescriptionNavSport(): Description {
    let descriptionNavSport: Description;
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        descriptionNavSport = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'navSport',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infodeposits'),
              target: '',
              title: 'inscripW',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'infoDeposit',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('howbet'),
              target: '',
              title: 'uPBet',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'howBet',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + CUOTA_PAHT,
              target: DOMAIN + CUOTA_PAHT,
              title: 'bettingOptions',
              translatePipe: true,
              show: !this.globalVars.user?.logged,
              class: 'footer-link',
              typeEvent: 'cuota',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_APUESTAS_DEPORTIVAS'],
              target: '_blank',
              title: 'recommendedBets',
              translatePipe: true,
              show: true,
              class: '',
              tileCasePipe: true,
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_SPORTS_EVENTS'],
              target: '_blank',
              title: 'sportsevents',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['BLOG_CODERE'],
              target: '_blank',
              title: 'Blog Codere',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.Colombia:
        descriptionNavSport = {
          divAnchorClass: '',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'navSport',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infodeposits'),
              target: '',
              title: 'inscripW',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'infoDeposit',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('howbet'),
              target: '',
              title: 'uPBet',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'howBet',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + CUOTA_PAHT,
              target: '',
              title: 'bettingOptions',
              translatePipe: true,
              show: !this.globalVars.user?.logged,
              class: 'footer-link',
              typeEvent: 'cuota',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_BLOG_CODERE_COLOMBIA'],
              target: '_blank',
              title: 'Blog Codere',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.Panama:
        descriptionNavSport = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'navSport',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infodeposits'),
              target: '',
              title: 'inscripW',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'infoDeposit',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('howbet'),
              target: '',
              title: 'uPBet',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'howBet',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + CUOTA_PAHT,
              target: '',
              title: 'bettingOptions',
              translatePipe: true,
              show: !this.globalVars.user?.logged,
              class: 'footer-link',
              typeEvent: 'cuota',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_BLOG_CODERE_PANAMA'],
              target: '_blank',
              title: 'Blog Codere',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaMendoza:
        descriptionNavSport = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col-3 paddingl15 colborder',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'navSport',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infodeposits'),
              target: '',
              title: 'inscripW',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'infoDeposit',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('howbet'),
              target: '',
              title: 'uPBet',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'howBet',
              sign: '-',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        descriptionNavSport = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'navSport',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infodeposits'),
              target: '',
              title: 'inscripW',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'infoDeposit',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('howbet'),
              target: '',
              title: 'uPBet',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'howBet',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_BLOG_CODERE_ARGCABA'],
              target: '_blank',
              title: 'Blog Codere',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaPBA:
        descriptionNavSport = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'navSport',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infodeposits'),
              target: '',
              title: 'inscripW',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'infoDeposit',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('howbet'),
              target: '',
              title: 'uPBet',
              translatePipe: true,
              show: true,
              class: '',
              typeEvent: 'howBet',
              relnofollow: 'nofollow',
              sign: '-'
            }
          ]
        };
        break;
    }
    return descriptionNavSport;
  }

  private get formatDescripcionLinks(): Description {
    let descripcionLinks: Description;
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        descripcionLinks = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          preTitle: 'LINKS ',
          needNotLogin: false,
          title: '',
          stringToTranslate: 'SHigh',
          information: [
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_DOWNLOADAPP'],
              target: '_blank',
              title: 'downloadApp',
              translatePipe: true,
              show: this.globalVars.DEVICE_TYPE == 'web',
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_CODERE_ESTADISTICAS_RESULTADOS'],
              target: '_blank',
              title: 'v2_resultsAndStats',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO'],
              target: '_blank',
              title: 'slotheaderCasino',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO_EN_VIVO'],
              target: '_blank',
              title: 'Casino en Vivo',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_RULETA'],
              target: '_blank',
              title: 'SGRulet',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_SLOTS'],
              target: '_blank',
              title: 'titheaderslots',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_BLACKJACK'],
              target: '_blank',
              title: 'Blackjack',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_JACKPOT'],
              target: '_blank',
              title: 'Jackpots',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: true,
              external: false,
              typeEvent: 'newCodereCard',
              href: DOMAIN + CODERECARDPAGE_PAHT,
              target: '',
              title: 'circleLoyaltyCard',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.Colombia:
        descripcionLinks = {
          divAnchorClass: '',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          preTitle: 'LINKS ',
          needNotLogin: false,
          title: '',
          stringToTranslate: 'SHigh',
          information: [
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_RESULTADOS_ESTADISTICAS_CO'],
              target: '_blank',
              title: 'v2_resultsAndStats',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + DOWNLOADAPP_PAHT,
              target: '',
              title: 'downloadApp',
              translatePipe: true,
              show: true,
              class: 'footer-link',
              typeEvent: 'downloadApp',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CRONSCASINO_COLOMBIA'],
              target: '_blank',
              title: 'Casino',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO_EN_VIVO'],
              target: '_blank',
              title: 'Casino en Vivo',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_RULETA_COLOMBIA'],
              target: '_blank',
              title: 'SGRulet',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_SLOTS_COLOMBIA'],
              target: '_blank',
              title: 'titheaderslots',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_BLACKJACK_COLOMBIA'],
              target: '_blank',
              title: 'Blackjack',
              translatePipe: false,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_JACKPOTS_COLOMBIA'],
              target: '_blank',
              title: 'Jackpots',
              translatePipe: false,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.Panama:
        descripcionLinks = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col',
          divTitleClass: 'color-primary',
          preTitle: 'LINKS ',
          needNotLogin: false,
          title: '',
          stringToTranslate: 'SHigh',
          information: [
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_RESULTADOS_ESTADISTICAS_PA'],
              target: '_blank',
              title: 'v2_resultsAndStats',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CASINO_PA'],
              target: '_blank',
              title: 'Casino',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO_EN_VIVO'],
              target: '_blank',
              title: 'Casino en Vivo',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_RULETA_PA'],
              target: '_blank',
              title: 'SGRulet',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_SLOTS_PA'],
              target: '_blank',
              title: 'titheaderslots',
              translatePipe: true,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_BLACKJACK_PA'],
              target: '_blank',
              title: 'Blackjack',
              translatePipe: false,
              show: true,
              class: 'text-capitalized',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_COUTAS_DEPORTIVAS_PANAMA'],
              target: '_blank',
              title: 'Cuotas Deportivas',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_EVENTOS_DEPORTIVOS_PANAMA'],
              target: '_blank',
              title: 'sportsevents',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_PANAMA_NFL'],
              target: '_blank',
              title: 'NFL',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_PANAMA_BEISBOL'],
              target: '_blank',
              title: 'Béisbol',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_PANAMA_FUTBOL'],
              target: '_blank',
              title: 'Fútbol',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaMendoza:
        descripcionLinks = {
          divAnchorClass: '',
          divContainerClass: 'col paddingl15',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: '',
          information: []
        };
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        descripcionLinks = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          preTitle: 'LINKS ',
          needNotLogin: false,
          title: '',
          stringToTranslate: 'SHigh',
          information: [
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_RESULTADOS_ESTADISTICAS_ARGCABA'],
              target: '_blank',
              title: 'v2_resultsAndStats',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO_ARGCABA'],
              target: '_blank',
              title: 'slotheaderCasino',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO_EN_VIVO'],
              target: '_blank',
              title: 'Casino en Vivo',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_RULETA_ARGCABA'],
              target: '_blank',
              title: 'SGRulet',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_SLOTS_ARGCABA'],
              target: '_blank',
              title: 'titheaderslots',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_BLACKJACK_ARGCABA'],
              target: '_blank',
              title: 'Blackjack',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaPBA:
        descripcionLinks = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          divTitleClass: 'color-primary',
          preTitle: 'LINKS ',
          needNotLogin: false,
          title: '',
          stringToTranslate: 'SHigh',
          information: [
            {
              event: false,
              external: false,
              href: this.licenseVars['URL_RESULTADOS_ESTADISTICAS_ARGPBA'],
              target: '_blank',
              title: 'v2_resultsAndStats',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_CASINO_ARGCABA'],
              target: '_blank',
              title: 'slotheaderCasino',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_RULETA_ARGCABA'],
              target: '_blank',
              title: 'SGRulet',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_SLOTS_ARGCABA'],
              target: '_blank',
              title: 'titheaderslots',
              translatePipe: true,
              show: true,
              class: '',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CODERE_BLACKJACK_ARGCABA'],
              target: '_blank',
              title: 'Blackjack',
              translatePipe: false,
              show: true,
              class: '',
              sign: '-'
            }
          ]
        };
        break;
    }

    return descripcionLinks;
  }

  private get formatDescription(): Description {
    let description: Description;
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        description = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          preTitle: '',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          stringToTranslate: 'aboutus',
          title: '',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('juegorespnac'),
              class: '',
              preTitle: '',
              show: true,
              title: 'safergame',
              translatePipe: true,
              typeEvent: 'juegoResp',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_OP_CODERE_ONLINE'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'ftJuegoAu',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CONTRATO_NATIONAL'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'ftContrato',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('reglas'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftReglas',
              translatePipe: true,
              typeEvent: 'reglas',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_POLITICA_NATIONAL'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'playerdataprotection',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infocookie'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftCookies',
              translatePipe: true,
              typeEvent: 'infoCookie',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_POLITICA_PRIVACIDAD_NATIONAL'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'ftPolitica',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.Colombia:
        description = {
          divAnchorClass: '',
          divContainerClass: 'col colborder',
          preTitle: '',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          stringToTranslate: 'aboutus',
          title: '',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('juegorespcol'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftJuego',
              translatePipe: true,
              typeEvent: 'juegoResp',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CONTRATO_COLOMBIA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'ftContrato',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_REGLAS_COLOMBIA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'ftReglas',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('politicapriv'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftPolitica',
              translatePipe: true,
              typeEvent: 'PoliticaPriv',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infocookie'),
              class: 'footer-link',
              preTitle: '',
              show: true,
              title: 'ftCookies',
              translatePipe: true,
              typeEvent: 'infoCookie',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_ACUERDO_COLOMBIA'],
              target: '_blank',
              class: 'txtcenter',
              preTitle: '',
              show: true,
              title: this.licenseVars['TXT_REGLAMENTO_COLOMBIA_COLJ'],
              translatePipe: false,
              sign: '-',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.Panama:
        description = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col paddingl33',
          preTitle: '',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          stringToTranslate: 'aboutus',
          title: '',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('juegoresppa'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftJuego',
              translatePipe: true,
              typeEvent: 'juegoResp',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CONTRATO_PANAMA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: 'ftContrato',
              translatePipe: true,
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('reglas'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftReglas',
              translatePipe: true,
              typeEvent: 'reglas',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('politicapriv'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftPolitica',
              translatePipe: true,
              typeEvent: 'PoliticaPriv',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infocookie'),
              class: 'footer-link',
              preTitle: '',
              show: true,
              title: 'ftCookies',
              translatePipe: true,
              typeEvent: 'infoCookie',
              sign: '-',
              relnofollow: 'nofollow'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaMendoza:
        description = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col-3 colborder padding25',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          preTitle: '',
          title: '',
          stringToTranslate: 'aboutus',
          information: [
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_JUEGO_RESPONSABLE_MENDOZA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftJuego')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CONTRATO_ARGMZA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftContrato')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('reglas'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftReglas',
              translatePipe: true,
              typeEvent: 'reglas',
              sign: '-',
              relnofollow: 'nofollow'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_POLITICA_ARGMZA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftPolitica')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_POLITICA_COOKIES'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftCookies')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaCordoba:
        description = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          preTitle: '',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          stringToTranslate: 'aboutus',
          title: '',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('juegoresparg'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftJuego',
              translatePipe: true,
              typeEvent: 'juegoResp',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CONTRATO_ARGCABA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftContrato')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            },

            {
              event: false,
              external: true,
              href: this.licenseVars['URL_POLITICA_ARGCABA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftPolitica')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('reglas'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftReglas',
              translatePipe: true,
              typeEvent: 'reglas',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infocookie'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftCookies',
              translatePipe: true,
              typeEvent: 'infoCookie',
              relnofollow: 'nofollow',
              sign: '-'
            }
          ]
        };
        break;
      case LicenseTypes.ArgentinaPBA:
        description = {
          divAnchorClass: 'marginl10',
          divContainerClass: 'col colborder',
          preTitle: '',
          divTitleClass: 'color-primary',
          needNotLogin: false,
          stringToTranslate: 'aboutus',
          title: '',
          information: [
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('juegoresparg'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftJuego',
              translatePipe: true,
              typeEvent: 'juegoResp',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: false,
              external: true,
              href: this.licenseVars['URL_CONTRATO_ARGCABA'],
              target: '_blank',
              class: '',
              preTitle: '',
              show: true,
              title: `${this.translateService.instant('ftContrato')} ${this.translateService.instant(
                'and'
              )} ${this.translateService.instant('ftPolitica')}`,
              translatePipe: false,
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('reglas'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftReglas',
              translatePipe: true,
              typeEvent: 'reglas',
              relnofollow: 'nofollow',
              sign: '-'
            },
            {
              event: true,
              external: false,
              href: DOMAIN + INFORMATIONPAGE_PATH + this.getTypeEvent('infocookie'),
              class: '',
              preTitle: '',
              show: true,
              title: 'ftCookies',
              translatePipe: true,
              typeEvent: 'infoCookie',
              relnofollow: 'nofollow',
              sign: '-'
            }
          ]
        };
        break;
    }

    return description;
  }

  private get cssClassBody(): string {
    let bodyClass: string = '';

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
        bodyClass = 'txtwhite txtionfooter row';
        break;
      case LicenseTypes.Colombia:
        bodyClass = 'txtwhite txtionfooter row';
        break;
      case LicenseTypes.Panama:
        bodyClass = 'txtwhite txtionfooter row';
        break;
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaPBA:
      case LicenseTypes.ArgentinaCordoba:
        bodyClass = 'txtwhite txtionfooter row';
        break;
      case LicenseTypes.ArgentinaMendoza:
        bodyClass = 'txtwhite txtionfooter row justcenter';
        break;
    }

    return bodyClass;
  }

  private get isRegistro() {
    return this.router.url.includes(`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroNewPage}`);
  }

  private get newImgs(): Item[] {
    let newImgs: Item[] = [];

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Panama:
        newImgs = [
          {
            extrernal: false,
            type: 'innerText',
            containerClass: 'textCenter panamaFirtsText',
            text: this.translateService.instant('compulsivegambling')
          },
          {
            extrernal: false,
            type: 'innerText',
            containerClass: 'textCenter',
            text: this.translateService.instant('gamingcontrolboard')
          },
          {
            extrernal: true,
            href: this.licenseVars['URL_JUGARRESPONSABLEJUNTACONTROL'],
            target: '_blank',
            imgClass: 'imglotba',
            img: this.licenseVars['IMG_JUGARRESPONSABLEJUNTACONTROL'],
            type: 'img-link',
            altImg: 'JUNTA CONTROL JUGAR RESPONSABLE'
          }
        ];
        break;
    }

    return newImgs;
  }

  private get newSocialsLinks(): Item[] {
    let newSocialsLinks: Item[] = [];

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Panama:
        newSocialsLinks = [
          {
            extrernal: true,
            type: 'img-text-link',
            altImg: 'phone',
            img: 'assets/global/img/phone.png',
            imgClass: 'socialIcon',
            containerClass: 'socialLink',
            text: ' ' + this.translateService.instant('tlffooterpa'),
            textClass: 'textSocial',
            target: '_blank',
            href: this.licenseVars['URL_PANAMA_TLF']
          },
          {
            extrernal: true,
            type: 'img-text-link',
            altImg: 'mail',
            img: 'assets/global/img/mail.png',
            imgClass: 'socialIcon',
            containerClass: 'socialLink',
            text: ' ' + this.translateService.instant('emailfooterpa'),
            textClass: 'textSocial',
            target: '_blank',
            href: this.licenseVars['URL_EMAIL_JCJJUEGORESPONSABLE']
          },
          {
            extrernal: false,
            type: 'img-text',
            altImg: 'instagram',
            img: 'assets/global/img/instagram.png',
            imgClass: 'socialIcon',
            containerClass: 'socialLink',
            text: this.translateService.instant('jcjpma'),
            textClass: 'textSocial'
          },
          {
            extrernal: false,
            type: 'img-text',
            altImg: 'mapIcon',
            img: 'assets/global/img/mapIcon.png',
            imgClass: 'socialIcon',
            containerClass: 'socialLink',
            text: 'Ave.Perú, Calle 35, Edificio de Finanzas, Piso 7',
            textClass: 'textSocial'
          }
        ];
        break;
    }

    return newSocialsLinks;
  }

  private getTypeEvent(event: InformationsTypes) {
    return event.toString();
  }
}
