import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@providers/BaseService';
import { UserService } from '@providers/UserService';
import { IAuthenticationService } from '@interfaces/IAuthenticationService';

import { LoginRequest, LoginRequestExtended } from '@models/LoginServices/LoginRequest';
import { LoginResponse } from '@models/LoginServices/LoginResponse';
import { LogoutResponse } from '@models/LoginServices/LogoutResponse';

import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService implements IAuthenticationService {
  //#region Inject Services
  userService = inject(UserService);
  //#endregion
  constructor() {
    super();
  }

  login(username: string, password: string, persistCookie?: boolean): Observable<LoginResponse> {
    const afToken: string = this.getRFToken();
    if (!persistCookie) {
      persistCookie = false;
    }

    const creds: LoginRequestExtended = {
      loginName: username,
      password: password,
      persistCookie: persistCookie,
      deviceType: this.globalVars.PLATFORM,
      __RequestVerificationToken: afToken
    };

    const url: string = `${this.globalVars.URLBASE_LOGINSERVICES}/login/web`;
    return this.myPost(url, creds).pipe(
      map((user: LoginResponse) => {
        // Convert string to dates
        user.lastLogin = user.lastLogin ? new Date(user.lastLogin) : user.lastLogin;
        user.lastPassword = user.lastPassword ? new Date(user.lastPassword) : user.lastPassword;

        return user;
      }),
      tap((user: LoginResponse) => {
        if (user.success) {
          this.userService.getCustomizations().subscribe(
            (custom) =>
              // **+** this.globalVars.cuotaAccept(custom.customizations);
              user
          );
        } else return user;
      })
    );
  }

  nativeLogin(username: string, password: string): Observable<any> {
    const creds: LoginRequest = {
      loginName: username,
      password: password
    };

    const url: string = `${this.globalVars.URLBASE_LOGINSERVICES}/login/native`;
    return this.myPost(url, creds);
  }

  nativeAutoLogin(username: string, encryptedPassword: string): Observable<any> {
    const creds: LoginRequest = {
      loginName: username,
      password: encryptedPassword
    };

    const url: string = `${this.globalVars.URLBASE_LOGINSERVICES}/login/native/auto`;
    return this.myPost(url, creds);
  }

  logout(): Observable<LogoutResponse> {
    const url: string = `${this.globalVars.URLBASE_LOGINSERVICES}/logout`;
    return this.myGet(url);
  }

  private getRFToken = (): string => {
    // Gets the requestverificationtoken from DOM
    try {
      const token: HTMLInputElement = <HTMLInputElement>document.getElementsByName('__RequestVerificationToken')[0];
      return token.value;
    } catch (err) {
      return '';
    }
  };
}
