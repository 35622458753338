import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize, map, Observable, throwError } from 'rxjs';

import { BaseService } from './BaseService';
import { I_SignupPaForm, I_SignupPaResponse } from '@models/I_SignupPADecoupling';

export interface DecouplingResponse<T> {
  value: T | null;
  isSuccess: boolean;
  isFailure: boolean;
  error: ErrorDecoupling;
}
declare const codereSharedJS;
export interface ErrorDecoupling {
  code: string;
  description: string;
  type: number;
}

@Injectable({
  providedIn: 'root'
})
export class DecouplingUserServiceMSOService extends BaseService {
  loadingCtrl = inject(LoadingController);
  loading: HTMLIonLoadingElement;
  loadingCounter: number = 0;
  isQA = false;

  constructor() {
    super();
    this.isQA = codereSharedJS.debug.isQA();
  }

  public signupPa(data: I_SignupPaForm): Observable<I_SignupPaResponse> {
    return this.customPost<I_SignupPaResponse>(
      `${this.globalVars.DOMAIN}services.accounts.signup${this.isQA ? '.pa' : ''}/signup`,
      data,
      {},
      true
    );
  }

  private customHandleError(errorResponse: HttpErrorResponse): Observable<ErrorDecoupling | undefined> {
    return throwError(() => {
      const { status, error } = errorResponse;

      switch (status) {
        case 401:
        case 403:
          this.events.publish('user:logout:soft');
          return undefined;
        case 410:
          this.events.publish('user:serverfailmaintenace', error);
          return undefined;
      }

      const newErr: ErrorDecoupling = {
        code: error.error?.code,
        type: error.error?.type,
        description: error.error?.description
      };
      return newErr;
    });
  }

  private customPipe<T>(observable: Observable<any>, loading = false) {
    if (loading) {
      this.showLoader();
    }
    return observable.pipe(
      map((response) => {
        const data = response as DecouplingResponse<T>;

        if (data.isSuccess === false) {
          throwError(() => data.error);
        } else {
          return data.value || null;
        }
      }),
      finalize(() => {
        if (loading) {
          this.hideLoader();
        }
      })
    );
  }

  private customGet<T>(url: string, loading = false): Observable<T> {
    return this.customPipe<T>(this.myGet(url, false, this.customHandleError), loading);
  }
  private customPost<T>(url: string, data: any, options: any, loading = false): Observable<T> {
    return this.customPipe<T>(this.myPost(url, data, options, false, this.customHandleError), loading);
  }
  private customPut<T>(url: string, data: any, options: any, loading = false): Observable<T> {
    return this.customPipe<T>(this.myPut(url, data, options, false, this.customHandleError), loading);
  }
  private customDelete<T>(url: string, loading = false): Observable<T> {
    return this.customPipe<T>(this.myDelete(url, false, this.customHandleError), loading);
  }

  private async showLoader() {
    if (this.loadingCounter === 0) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        backdropDismiss: false,
        cssClass: 'is-loader',
        translucent: true
      });
      await this.loading.present();
    }
    this.loadingCounter++;
  }

  private async hideLoader() {
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.loadingCounter = 0;
      await this.loading.dismiss();
    }
  }
}
