// eslint-disable-next-line @typescript-eslint/no-empty-function

import { Injectable, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
export const minHeight = 768;
@Injectable({ providedIn: 'root' })
export class DeviceService {
  platform = inject(Platform);
  toMatchMobile = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  isDesktop(): boolean {
    return !this.isMobile() && this.platform.width() > minHeight;
  }

  isMobile(): boolean {
    return (
      this.toMatchMobile.some((toMatchItem) => navigator.userAgent.match(toMatchItem)) &&
      this.platform.width() <= minHeight
    );
  }

  getDevicePlatform = (): string => (Capacitor.getPlatform() === 'web' ? 'Desktop' : 'Mobile');
}
