import { IonicModule, NavController } from '@ionic/angular';
import { Component, inject } from '@angular/core';

/* import { GlobalVars } from "../../providers/globalVars"; */
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { CommonModule, Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';
import { CASINO_PATHS } from '@shared-constants/routes';

/*
  Generated class for the LoadingPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/

@Component({
  selector: 'loading-page',
  templateUrl: './loading.html', // 'loading.html'
  styleUrls: ['./loading.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class LoadingPage {
  isCasinoPage = false;

  globalVars!: GlobalVarsModel;
  nav = inject(NavController);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  location = inject(Location);

  isDesktop = true;
  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
      if (this.globalVars.FEATURES.MSO_NewReBranding) {
        this.isCasinoPage = this.location.path().includes(CASINO_PATHS.BASE);
      }
    });
  }
}
