import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
// Components
import { CasinoCardNewComponent } from '../../casino-card-new/casino-card-new.component';
// Interfaces and abstracts
import { AbstractLoobyFacadeService } from '../../../../abstracts/abstract-looby-facade-service';
// Services and providers
import { NewBaseService } from '../../../../providers/newBase.service';
import { DeviceService } from '../../../../services/device.service';
import { LobbyFacadeService } from '../../../../services/casino/loobyFacade.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { Game } from '@models/lobby/casinoDataModels';
import { IonicModule, IonImg } from '@ionic/angular';
import { SlotName } from '../../../../pipes/casino/lobby/slot-name.pipe';
import { ParseMoney } from '../../../../pipes/parseMoney';

@Component({
  selector: 'lb-calendar-section',
  templateUrl: './calendar-section.html',
  styleUrls: ['./calendar-section.scss'],
  imports: [CommonModule, CasinoCardNewComponent, IonicModule, SlotName, ParseMoney],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService
    }
  ]
})
export class CalendarSectionComponent {
  @Input() section: any;
  @Output() turnPlay: EventEmitter<any> = new EventEmitter();
  isDesktop = false;
  globalVars!: GlobalVarsModel;
  baseImage: string;
  //#region Inject Services
  lobbyFacadeService = inject(AbstractLoobyFacadeService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  //#endregion

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    if (this.globalVars) {
      this.baseImage = this.globalVars.LOBBY_IMAGES;
    }
  }

  getImage(game) {
    const shape = game.gameTileSize.toLowerCase().includes('square') ? 'Square' : 'Rectangle';
    const imageType = game.imageType || 'jpg';
    const image = game.Name.replace(/ /g, '') + '_' + shape + `.${imageType}`;
    game.Image = this.baseImage + image;
    game.defaultImg = this.baseImage + 'default_' + shape + `.${imageType}`;
    return game.Image;
  }
  errorImageHandler(ionImgElement: IonImg, game) {
    ionImgElement.src = game.defaultImg;
  }

  playGame(game) {
    if (game.active) {
      this.turnPlay.emit(game);
      this.lobbyFacadeService.playGameOutCasino(game);
    }
  }
}
