<div class="cs-local-card background-color-white py-2">
  <div class="cs-local-card--title color-primary p-1">{{ localConfig?.userName }}</div>
  <div *ngIf="showFullName()" class="cs-local-card--title p-1">{{ userFullName | titlecase }}</div>
  <div
    class="barcodeTarget"
    id="Div11"
    style="padding: 0px; overflow: auto; width: 264px"
    [innerHTML]="localConfig?.barcode"
  ></div>
  <div class="cs-local-card--title color-primary p-1">{{ localConfig?.code }}</div>

  <div *ngIf="!localConfig?.typeDeposit && localConfig?.amountCharge > 0" class="cs-local-card--amount p-1">
    {{'tImp' | translate | uppercase}}<b> {{ localConfig?.amountCharge | parseMoney}}</b> {{localConfig?.amountTaxes}}
  </div>

  <button
    *ngIf="showDeleteCodeButton()"
    class="cs-action-button background-color-primary"
    tappable
    (click)="setCancelWithdrawal()"
  >
    <span class="cs-action-button--text color-light"> {{'delCod' | translate}} </span>
  </button>
</div>
<div class="cs-local-info p-1">
  <div class="cs-local-info--title py-1">
    <span *ngIf="!localConfig?.typeDeposit"> {{'informationchargelocal' | translate}} </span>
    <span *ngIf="localConfig?.typeDeposit"> {{'informationdepositlocal' | translate}} </span>
  </div>
  <div class="cs-local-info--item p-1 text-left">
    <span><strong>1. </strong></span>
    <span *ngIf="!localConfig?.typeDeposit && license !== 4">{{'tCobLoc1' | translate}}</span>
    <span *ngIf="!localConfig?.typeDeposit && license === 4"
      >{{'v2_tCobLoc2' | translate}} <b (click)="setOpenCodereLocals()">{{'clickHere' | translate}}.</b></span
    >
    <span *ngIf="localConfig?.typeDeposit"
      >{{'tDepLoc0' | translate}}<b (click)="setOpenCodereLocals()">{{'clickHere' | translate}}</b></span
    >
  </div>
  <div class="cs-local-info--item p-1 text-left">
    <span><strong>2. </strong></span>
    <span *ngIf="!localConfig?.typeDeposit && license !== 4"
      >{{'tCobLoc2' | translate}} <b (click)="setOpenCodereLocals()">{{'clickHere' | translate}}.</b></span
    >
    <span *ngIf="!localConfig?.typeDeposit && license === 4">{{'v2_tCobLoc3' | translate}}</span>
    <span *ngIf="localConfig?.typeDeposit">{{'tDepLoc2' | translate}}</span>
  </div>
  <div class="cs-local-info--item p-1 text-left">
    <span><strong>3. </strong></span>
    <span *ngIf="!localConfig?.typeDeposit">{{'tCobLoc3' | translate}}</span>
    <span *ngIf="localConfig?.typeDeposit">{{'tDepLoc3' | translate}}</span>
  </div>
  <div *ngIf="!localConfig?.typeDeposit" class="cs-local-info--item p-1 text-left">
    <span><strong>4. </strong></span>
    <span>{{'tCobLoc4' | translate}}</span>
  </div>
</div>
