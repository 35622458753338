import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MenuController, ModalController, AlertController, IonicModule, PopoverController } from '@ionic/angular';
import { TrackingService } from '@providers/TrackingService';
import { BalancesService } from '../../../providers/BalancesService';
import { MessageService } from '../../../providers/MessageService';
import { LicenseTypes } from '../../../models/MasterData';
import { UserService } from '../../../providers/UserService';
import { NativeService } from '../../../providers/NativeService';
import { EventTypes } from '../../../models/TrackingEvents';
import { CommonModule, Location } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { EventsService } from '../../../providers/EventsService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { UserServiceMSO } from '../../../providers/UserServiceMSO';
import { ParseMoney } from '../../../pipes/parseMoney';
import { MSO_PATHS, CASINO_PATHS } from '../../../lib/constants/routes';
import { Utils } from '../../../utils/Utils';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, interval } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, PipesModule, TranslateModule, ParseMoney],
  selector: 'codere-navbar',
  templateUrl: './codere-navbar.html',
  styleUrls: ['./codere-navbar.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CodereNavbar implements OnInit, OnDestroy {
  //#region Inect Services
  trackingService = inject(TrackingService);
  menu = inject(MenuController);
  alertController = inject(AlertController);
  modalController = inject(ModalController);
  userService = inject(UserService);
  nativeService = inject(NativeService);
  balancesService = inject(BalancesService);
  messageService = inject(MessageService);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  modalCtrl = inject(ModalController);
  events = inject(EventsService);
  router = inject(Router);
  location = inject(Location);
  userServiceMSO = inject(UserServiceMSO);
  popoverController = inject(PopoverController);

  //#endregion
  @Input() titulo: string;
  timeColombia: any;
  idInterval: number;
  isAlertAr: boolean;
  timeAlertAr: number;
  messageCount: number;
  headerImg: { imgSrc: string; imgClass: { sport: string; casino: string }; imgSrcCas: string } = {
    imgSrc: '',
    imgSrcCas: '',
    imgClass: { sport: '', casino: '' }
  };
  isCasinoUrl: boolean = false;
  globalVars!: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  currentPage: string;
  popoverName: string;
  localTime: string;

  private subscriptions: Subscription[] = [];
  constructor() {
    this.subscriptions.push(
      this.newBaseService.getVars.subscribe((data) => {
        this.globalVars = data;
      }),
      this.balancesService.getPopoverName().subscribe((data) => {
        this.popoverName = data;
      })
    );

    this.isAlertAr = false;
    this.timeAlertAr = 0;

    this.setTimeCol().then((value) => {
      if (value) {
        this.timeColombia = value;
      } else {
        this.timeColombia = '';
      }
    });
    this.idInterval = window.setInterval(() => {
      this.setTimeCol().then((value) => {
        if (value) {
          this.timeColombia = value;
        } else {
          this.timeColombia = '';
        }
      });
    }, 300);

    this.messageService.messageCount.subscribe((data) => {
      this.messageCount = data;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    if (this.globalVars.FEATURES.MSO_LocalDateTimer) {
      this.subscriptions.push(
        interval(1000).subscribe(() => {
          this.localTime = this.utils.getLocalTime();
        })
      );
    }
    if (this.globalVars.FEATURES.MSO_NewReBranding) {
      this.subscriptions.push(
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
          this.isCasinoUrl = event['url'].startsWith(`/${CASINO_PATHS.BASE}`);
        })
      );
    }
    this.changeLogo();
    const segments = this.router.url.split('/');
    this.currentPage = segments[segments.length - 1].split('?')[0];
  }

  async toggleSidebarMenu(menu: string) {
    await this.menu.enable(true, menu);
    await this.menu.toggle(menu);
  }

  openModalLogin() {
    //abre una modal con la pantalla de login
    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (!this.globalVars.isCookiesAccept) {
        if (this.globalVars.licenseType === LicenseTypes.Nacional) {
          this.globalVars.rootScope.alertCookies.pageRef().nativeElement.querySelector('.footer-link').click();
        } else {
          this.globalVars.rootScope.openAlertCookies();
        }
        this.globalVars.actionAfterCookiesAccept = 'login';
        return;
      }
    }
    this.globalVars.afterLoginUrl = null;
    this.nativeService.checkTouchId(() => {
      // this.router.navigate(['/'], {});
    });
  }

  openRegistro() {
    this.globalVars.rootScope.openRegistro();
  }

  openCallMe() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CallMePage]), { animate: false };
  }

  navUserMessages() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.UserMessagesPage]);
  }

  backToHome() {
    this.router.navigate(['/'], {});
  }

  refreshBalance() {
    this.trackingService.track({ eventType: EventTypes.RefreshBalance, description: 'Refrescar saldo' });
    this.balancesService.refreshBalance();
  }

  showBalanceDetails(event: any): void {
    if (!this.balancePopoverAvailable()) return;
    this.trackingService.track({
      eventType: EventTypes.ShowBalanceDetails,
      description: 'Muestra detalles del balance'
    });
    this.events.publish('popover:open', this.balancesService);
    this.balancesService.showBalanceDetails(event, this.popoverController);
  }

  async setTimeCol(): Promise<any> {
    if (this.globalVars?.user?.logged && this.globalVars.sessionInitDate !== null) {
      const now: Date = new Date();
      const init: Date = this.globalVars.sessionInitDate;
      const sessionTimeResult = now.getTime() - init?.getTime();
      const sessionTime = new Date(sessionTimeResult);

      const hora = sessionTime.getUTCHours();
      const minutos = sessionTime.getMinutes();
      const minutosStr = minutos < 10 ? '0' + minutos.toString() : minutos.toString();
      const segundos =
        sessionTime.getSeconds() < 10 ? '0' + sessionTime.getSeconds().toString() : sessionTime.getSeconds().toString();
      // for testing
      if (hora > this.timeAlertAr && this.globalVars.licenseType === LicenseTypes.ArgentinaCaba && !this.isAlertAr) {
        this.isAlertAr = true;
        const alert = await this.alertController.create({
          backdropDismiss: false,
          cssClass: 'alertAr',
          message: `<img src="assets/global/img/argentina/timealert.gif" alt="Tiempo jugado">`,
          buttons: [{ text: '10' }]
        });
        alert.present();
        let i = 10;
        const intervalTime = setInterval(() => {
          const btn = document.querySelector('.alertAr .button-inner');
          if (btn) {
            btn.innerHTML = '0' + --i;
          }
          if (i === 0) {
            clearInterval(intervalTime);
            this.isAlertAr = false;
            alert.dismiss();
          }
        }, 1000);
      }

      return hora.toString() + ':' + minutosStr + ':' + segundos;
    }
  }

  goBack() {
    this.location.back();
  }

  get isRegistro() {
    const isReg = this.globalVars.rootScope.registerPages.some((page) => this.router.url.includes(page));
    return isReg;
  }

  balancePopoverAvailable(): boolean {
    const allLobbyCasinoPages = [
      CASINO_PATHS.SlotsPage,
      CASINO_PATHS.OmnichannelPage,
      CASINO_PATHS.AviatorPage,
      CASINO_PATHS.VIPPage,
      CASINO_PATHS.CasinoEnVivoPage
    ];
    const single = 'singleGame=true';
    const isSingle = this.router.url.includes(single);
    if (this.globalVars.LICENSE_TYPE === LicenseTypes.Panama) {
      return this.balancesService.inCasinoPanama() && this.globalVars.FEATURES.balanceCasinoDetails;
    } else {
      return (
        !allLobbyCasinoPages.some((element) => this.router.url.includes(element)) &&
        !isSingle &&
        this.globalVars.FEATURES.balanceCasinoDetails
      );
    }
  }

  changeLogo() {
    this.userServiceMSO.getLogoHeader().subscribe((data) => {
      const datenow = new Date();
      const dateend = new Date(data.dateEnd);
      const datestar = new Date(data.dateStart);

      if (datenow >= datestar && datenow <= dateend) {
        this.headerImg = {
          imgClass: { sport: data.classImgMobileSpecial, casino: data.classImgMobileSpecialCasino },
          imgSrc: data.specialImg,
          imgSrcCas: data.specialImgCas
        };
      } else {
        this.headerImg = {
          imgClass: { sport: data.classImgMobile, casino: data.classImgCasinoMobile },
          imgSrc: data.commonImg,
          imgSrcCas: data.commonImgCas
        };
      }
    });
  }
}
