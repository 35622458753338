/* eslint-disable eqeqeq */
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, inject } from '@angular/core';
import { C_NodeTicket } from '@models/C_ResponseTickets';
import { ParseMoney } from '@pipes/parseMoney';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SbBetSelectionComponent } from '../sb-bet-selection/sb-bet-selection';

import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { Constants } from '@utils/constants';

@Component({
  selector: 'sb-bet-tile',
  templateUrl: './sb-bet-tile.html',
  styleUrls: ['./sb-bet-tile.scss'],
  host: {
    class: 'sb-bet-tile',
    mode: 'accumulator'
  },
  standalone: true,
  imports: [CommonModule, FormsModule, SbBetSelectionComponent, ParseMoney, ExtractDatePipe, TranslateModule]
})
export class SbBetTileComponent {
  @Input() ticket: C_NodeTicket;
  @Input() token: string;
  @Input() typeCard: string = 'sb-bet-tile--card';
  @Input() filterOpened: boolean;
  @Input() selectedFilter: any;
  @Output() selectBet: EventEmitter<any> = new EventEmitter();
  @Output() cashout: EventEmitter<any> = new EventEmitter();
  @Output() openTicket: EventEmitter<any> = new EventEmitter();
  @Output() eventSelection: EventEmitter<any> = new EventEmitter();

  private cardRef: HTMLElement;
  MisApuestasNewModelFeatureFlagEnabled = false;
  BetbuilderCategoryName4MisApuestas = 'Sencilla';
  betbuilderTicketType = 'MatchAcca';
  betbuilderMode = Constants.betbuilderName;

  globalVars!: GlobalVarsModel;
  translate = inject(TranslateService);
  _element = inject(ElementRef);
  _renderer = inject(Renderer2);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.MisApuestasNewModelFeatureFlagEnabled = this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled;
    });
    this.BetbuilderCategoryName4MisApuestas = this.translate.instant('SPO_MatchaccaCategoryName4MisApuestas');
  }

  getTicketStatus(ticketStatusNumber: string) {
    switch (ticketStatusNumber) {
      case '1':
        return 'color-primary';
      case '2':
        return 'color-lost';
      case '3':
        return 'color-lost';
      case '4':
        return 'color-secondary';
      case '5':
        return 'color-progress';
    }
    return ticketStatusNumber;
  }

  openSelections(ticketNumber, result) {
    result.accumulatorOpened = !result.accumulatorOpened;
    if (result.accumulatorOpened) {
      const betSelected = {
        ticketNumber: ticketNumber,
        dateTicket: this.ticket
      };
      this.openTicket.emit(betSelected);
    }
  }

  payOutTicket(ticketNumber, result) {
    const params = {
      ticketNumber: ticketNumber,
      result: result
    };
    this.cashout.emit(params);
  }

  selectEvent(selection) {
    if (selection.ClearingStatusNumber == '4' && !this.filterOpened) {
      this.eventSelection.emit(selection);
    }
  }

  canShowTile(result) {
    if (this.MisApuestasNewModelFeatureFlagEnabled) {
      this.getTicketStatusDescription(result.TicketStatusNumber);
      this.getTicketBetTypeDescription(result.BetTypeNumber);
      this.getCashOutStatusDescription(result.CashOutStatusNumber);
    }
    if (this.selectedFilter) {
      return this.selectedFilter.value === 0 && result.ticketStatusNumber === '4';
    } else {
      return false;
    }
  }

  private ticketStatusDescription = [
    { id: '0', description: 'Cancelada' },
    { id: '1', description: 'Ganada' },
    { id: '2', description: '' },
    { id: '3', description: 'Perdida' },
    { id: '4', description: 'Abierta' },
    { id: '5', description: 'Cobrada' }
  ];

  ticketStatus: string = '';
  public getTicketStatusDescription(id: string): void {
    const value = this.ticketStatusDescription.find((v) => v.id == id);
    this.ticketStatus = value != undefined ? value.description : '';
  }

  private ticketBetTypeDescription = [
    { id: '1', description: 'Sencilla' },
    { id: '2', description: 'Acumulada' },
    { id: '3', description: 'Múltiple' },
    { id: '4', description: 'Betbuilder' }
  ];

  ticketBetType: string = '';
  public getTicketBetTypeDescription(id: string): void {
    const value = this.ticketBetTypeDescription.find((v) => v.id == id);
    this.ticketBetType = value != undefined ? value.description : '';
  }

  private ticketCashOutStatusDescription = [
    { id: '0', description: '' },
    { id: '1', description: 'Ganada' },
    { id: '2', description: '' },
    { id: '3', description: 'Perdida' },
    { id: '4', description: 'Abierta' },
    { id: '5', description: 'Cobrada' }
  ];
  ticketCashOutStatus: string = '';
  public getCashOutStatusDescription(id: string): void {
    const value = this.ticketCashOutStatusDescription.find((v) => v.id == id);
    this.ticketCashOutStatus = value != undefined ? value.description : '';
  }

  public convertString2Date(value: string): Date {
    return new Date(value);
  }

  getTruncateValue(accumulatorOpened) {
    return accumulatorOpened ? 200 : 37;
  }
}
