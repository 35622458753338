import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { DeviceService } from '../services/device.service';
import { NewBaseService } from '../providers/newBase.service';

export const RegisterGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    let globalVars!: GlobalVarsModel;
    const newBaseService = inject(NewBaseService);
    const deviceService = inject(DeviceService);
    const router = inject(Router);
    newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      globalVars = data;
    });

    if (deviceService.isMobile()) {
      return true;
    } else {
      if (!globalVars.rootScope.modalRegOpen) {
        router.navigate(['/']);
        globalVars.rootScope.openRegisterByLicense();
      }
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};
