<ng-container *ngIf="isDesktop">
  <div
    class="omnichannel-header"
    *ngIf="globalVars?.FEATURES?.Casino_OmnichannelHeaderPC?.toLowerCase() !== 'csv'; else slider"
  >
    <img [src]="globalVars?.FEATURES?.Casino_OmnichannelHeaderPC" alt="header" />
  </div>
  <ng-template #slider>
    <codere-slider
      *ngIf="!!facade?.slotsBanners?.length && !globalVars?.FEATURES.NewCarouselActivated"
      [slides]="facade?.slotsBanners"
      [controls]="isDesktop"
    >
    </codere-slider>
    <codere-slider-csv
      *ngIf="!!facade?.slotsBanners?.length && globalVars?.FEATURES.NewCarouselActivated"
      [banners]="facade?.slotsBanners"
    >
    </codere-slider-csv>
  </ng-template>
  <div class="omnichannel-lobby">
    <div
      class="omnichannel-lobby__regularView"
      *ngIf="activeFilterIndex === 0"
      [ngStyle]="{ 'background-image': 'url(' + globalVars?.FEATURES?.Casino_OmnichannelBackgroundPC + ')'}"
    >
      <h2 class="omnichannel-lobby__title">{{category.Name}}</h2>
      <div class="omnichannel-lobby__containerGames has-scroll-vertical">
        <ng-container *ngFor="let game of category?.Games;let i =index">
          <casino-card-slots tappable class="swiper-slide" [game]="game" [grid]="true" (onPlay)="playGame($event)">
          </casino-card-slots>
        </ng-container>
      </div>
    </div>
    <div class="omnichannel-lobby__searchResult has-scroll-vertical" *ngIf="activeFilterIndex !== 0">
      <ng-container *ngFor="let game of category?.Games;let i =index">
        <casino-card-new
          *ngIf="!game?.live"
          tappable
          class="swiper-slide"
          [game]="game"
          [grid]="true"
          [showGameTicker]="false"
          (onPlay)="playGame($event)"
          [isJpLobby]="!!omnichannellobby?.JackpotId ? true : false"
          [setStreaming]="game.streamUrl ? true : false"
        >
        </casino-card-new>
        <casino-live-card-new
          *ngIf="game?.live"
          tappable
          class="swiper-slide"
          [grid]="true"
          [game]="game"
          (onPlay)="playGame($event)"
          [setStreaming]="game.streamUrl ? true : false"
        >
        </casino-live-card-new>
      </ng-container>
    </div>
    <div class="omnichannel-lobby__noGames" *ngIf="omnichannellobby?.Games?.length === 0">
      <h3>No hay juegos disponibles</h3>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isDesktop">
  <div class="omnichannel-header">
    <img [src]="globalVars?.FEATURES?.Casino_OmnichannelHeaderMobile" alt="header" />
  </div>
  <div class="omnichannel-lobby">
    <div
      class="omnichannel-lobby__regularView"
      *ngIf="activeFilterIndex === 0"
      [ngStyle]="{ 'background-image': 'url(' + globalVars?.FEATURES?.Casino_OmnichannelBackgroundMobile + ')'}"
    >
      <h2 class="omnichannel-lobby__title">{{category.Name}}</h2>
      <div class="omnichannel-lobby__containerGames has-scroll-vertical">
        <ng-container *ngFor="let game of category?.Games;let i =index">
          <casino-card-slots tappable class="swiper-slide" [game]="game" [grid]="true" (onPlay)="playGame($event)">
          </casino-card-slots>
        </ng-container>
      </div>
    </div>
    <div class="omnichannel-lobby__searchResult has-scroll-vertical" *ngIf="activeFilterIndex !== 0">
      <ng-container *ngFor="let game of category?.Games;let i =index">
        <casino-card-new
          *ngIf="!game?.live"
          tappable
          class="swiper-slide"
          [game]="game"
          [grid]="true"
          [showGameTicker]="false"
          (onPlay)="playGame($event)"
          [isJpLobby]="!!omnichannellobby?.JackpotId ? true : false"
          [setStreaming]="game.streamUrl ? true : false"
        >
        </casino-card-new>
        <casino-live-card-new
          *ngIf="game?.live"
          tappable
          class="swiper-slide"
          [grid]="true"
          [game]="game"
          (onPlay)="playGame($event)"
          [setStreaming]="game.streamUrl ? true : false"
        >
        </casino-live-card-new>
      </ng-container>
    </div>
    <div class="omnichannel-lobby__noGames" *ngIf="omnichannellobby?.Games?.length === 0">
      <h3>No hay juegos disponibles</h3>
    </div>
  </div>
</ng-container>
