<section *ngIf="games?.length">
  <div
    [ngClass]="{ backgroundImg: category?.BackgroundImageUrl !== '' }"
    [ngStyle]="backgroundImg !== '' && { 'background-image': backgroundImg }"
  >
    <swiper-container init="false" #swiper [ngClass]="{ 'is-narrow-content': category?.BackgroundImageUrl !== '' }">
      <!-- gradient left -->
      <div *ngIf="isGradientLeftAvailable" class="casino-category-slider--overlay is-left fade-in"></div>

      <!-- content -->
      <ng-container *ngIf="type === lobbyConstants.standard || type === lobbyConstants.ranking">
        <swiper-slide *ngFor="let game of games; let i = index" [ngClass]="getCustomClass(game)">
          <casino-card-new
            *ngIf="i < vi && !game?.live"
            tappable
            [isJpLobby]="!!category?.JackpotId ? true : false"
            [game]="game"
            [setStreaming]="game.streamUrl ? true : false"
            (turnPlay)="btPlayDetail($event)"
          >
          </casino-card-new>
          <casino-live-card-new
            *ngIf="game?.live"
            tappable
            [game]="game"
            [setStreaming]="game.streamUrl ? true : false"
            (turnPlay)="btPlayDetail($event)"
          >
          </casino-live-card-new>
          <div [hidden]="i < vi" class="casino-card swiper-slide smallsquare"></div>
        </swiper-slide>
      </ng-container>

      <ng-container *ngIf="type === lobbyConstants.vertical">
        <swiper-slide *ngFor="let game of games; let i = index" class="bigSquareVertical-slide">
          <casino-card-new
            *ngIf="i < vi && !game?.live"
            tappable
            [isJpLobby]="!!category?.JackpotId ? true : false"
            [game]="game"
            [setStreaming]="game.streamUrl ? true : false"
            [isVertical]="true"
            class="vertical"
            (turnPlay)="btPlayDetail($event)"
          >
          </casino-card-new>
          <div [hidden]="i < vi" class="casino-card swiper-slide smallsquare"></div>
        </swiper-slide>
      </ng-container>

      <ng-container *ngIf="type === lobbyConstants.verticalBig">
        <swiper-slide *ngFor="let game of games; let i = index" class="swiper-slide-custom">
          <casino-card-new
            *ngIf="i < vi && !game?.live"
            tappable
            [isJpLobby]="!!category?.JackpotId ? true : false"
            [game]="game"
            [setStreaming]="game.streamUrl ? true : false"
            [isVertical]="true"
            class="vertical"
            (turnPlay)="btPlayDetail($event)"
          >
          </casino-card-new>
          <div [hidden]="i < vi" class="casino-card swiper-slide smallsquare"></div>
        </swiper-slide>
      </ng-container>

      <ng-container *ngIf="type === lobbyConstants.bigSquare">
        <swiper-slide *ngFor="let game of games; let i = index" class="bigSquare-slide">
          <casino-card-new
            *ngIf="i < vi && !game?.live"
            tappable
            [isJpLobby]="!!category?.JackpotId ? true : false"
            [game]="game"
            [setStreaming]="game.streamUrl ? true : false"
            class="bigSquare"
            (turnPlay)="btPlayDetail($event)"
          >
          </casino-card-new>
          <div [hidden]="i < vi" class="casino-card swiper-slide smallsquare"></div>
        </swiper-slide>
      </ng-container>

      <ng-container *ngIf="type === lobbyConstants.bigSquareVertical">
        <swiper-slide
          *ngFor="let game of games; let i = index"
          [ngClass]="game.gameTileSize === 'Vertical' ? 'bigSquareVertical-slide' : 'bigSquare-slide'"
        >
          <casino-card-new
            *ngIf="i < vi && !game?.live"
            tappable
            [isJpLobby]="!!category?.JackpotId ? true : false"
            [game]="game"
            [setStreaming]="game.streamUrl ? true : false"
            [ngClass]="game.gameTileSize === 'Vertical' ? 'vertical' : 'bigSquare'"
            [isVertical]="game.gameTileSize === 'vertical'"
            (turnPlay)="btPlayDetail($event)"
          >
          </casino-card-new>
          <div [hidden]="i < vi" class="casino-card swiper-slide smallsquare"></div>
        </swiper-slide>
      </ng-container>

      <!-- controls -->
    </swiper-container>
  </div>
  <!-- gradient right -->
  <div *ngIf="isGradientRightAvailable" class="casino-category-slider--overlay is-right fade-in"></div>
  <ng-container *ngIf="controls">
    <button
      class="swiper-button-prev slide-control prev"
      [class]="'swiper-button-prev-' + id"
      tappable
      ion-button
      [ngClass]="{ 'slider-button-left': category?.BackgroundImageUrl !== '' }"
    >
      <ion-icon name="chevron-back-outline"></ion-icon>
    </button>
    <button
      *ngIf="controls"
      class="swiper-button-next slide-control next"
      [class]="'swiper-button-next-' + id"
      tappable
      ion-button
      [ngClass]="{ 'slider-button-rigth': category?.BackgroundImageUrl !== '' }"
    >
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
  </ng-container>
</section>
