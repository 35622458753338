<div class="container">
  <ion-grid>
    <ion-row class="header">
      <ion-col class="colIcon">
        <div class="title">{{'v2_registerStep4' | translate}}</div>
        <div class="iconContainer" (click)="this.closeModal({},'Canceled')">
          <ion-icon name="close-outline"></ion-icon>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="paddinglr10">
      <ion-col [size]="deviceService.isMobile() ? 2 : 1">
        <ng-container *ngIf="deviceService.isMobile(); else checkDesktop">
          <ion-toggle aria-label="BtnAcepted" mode="ios" [(ngModel)]="accepted"> </ion-toggle>
        </ng-container>
        <ng-template #checkDesktop>
          <ion-radio-group [allowEmptySelection]="true" (ionChange)="changeRadio()">
            <ion-radio aria-label="BtnAcepted" mode="md"></ion-radio>
          </ion-radio-group>
        </ng-template>
      </ion-col>
      <ion-col [size]="deviceService.isMobile() ? 10 : 11">
        <p [innerHTML]="'alertCatPromo01' | translate"></p>
        <p [innerHTML]="'alertCatPromo02' | translate"></p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
          mode="md"
          [disabled]="!accepted"
          shape="round"
          [ngClass]="{'notChecked' : !accepted}"
          (click)="acceptBtn()"
          >{{'tAccept' | translate}}</ion-button
        >
      </ion-col>
    </ion-row>
    <ion-row class="margint15">
      <ion-col>
        <a (click)="toggleOpenTyC($event)">{{'InviteFriendtxt06' | translate }}</a>
        <div
          id="tycContainer"
          [class]="{'expanded': isOpen, 'collapsed': !isOpen}"
          [@expandCollapse]="isOpen ? 'expanded' : 'collapsed'"
          [innerHTML]="'tycprizepromo' | translate"
        ></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
