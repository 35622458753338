<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content>
    <codere-slider
      *ngIf="!!lstBanners?.length && sliderVisible && !globalVars?.FEATURES.NewCarouselActivated"
      [slides]="lstBanners"
      [controls]="isDesktop"
      (clickedSlide)="clickBanner($event)"
    >
    </codere-slider>
    <codere-slider-csv
      *ngIf="!!lstNewBanners?.length && showCarousel && sliderVisible && globalVars?.FEATURES.NewCarouselActivated"
      [banners]="lstNewBanners"
    ></codere-slider-csv>

    <!-- Temporary promotion -->
    <div padding *ngIf="globalVars?.user?.logged && globalVars?.FEATURES.TemporaryPromoEnabled">
      <div class="has-background-dark temporary-promotion--container">
        <div class="temporary-promotion--image">
          <img [src]="temporaryPromotionConfig?.bannerMobileImage" alt="" />
        </div>
        <ion-buttons padding-horizontal class="temporary-promotion--buttons">
          <button ion-button clear start tappable (click)="openPromos()">+Info</button>
          <button class="background-color-secondary" ion-button end tappable (click)="openTemporaryPromotion()">
            {{temporaryPromotionConfig?.openPromotionButtonText}}
          </button>
        </ion-buttons>
      </div>
    </div>

    <!-- Marquee Desktop -->
    <sb-marquee *ngIf="marqueeData?.length > 0" [marqueeData]="marqueeData"> </sb-marquee>

    <!-- Ahora en directo Desktop -->
    <ng-container *ngIf="liveEvents$ | async as liveEvents">
      <div class="sb-grid-header--onlive background-color-regular" *ngIf="liveEvents.length > 0">
        <h2 class="sb-grid-header--onlive-title color-dark">{{ 'nowLive' | translate }}</h2>
        <span
          *ngIf="LiveEventsCount > -1"
          class="sb-grid-header--bets-counter color-secondary"
          tappable
          (click)="navigate(null, 'live', null)"
        >
          +{{ LiveEventsCount }}
          <span class="codere-icon icon-arrow-right"></span>
        </span>
      </div>
      <ng-container *ngFor="let sport of liveEvents">
        <div class="sb-grid-header--sport background-color-regular">
          <i [ngClass]="'icon-' + sport?.SportHandle" class="sb-grid-header--sport-icon codere-icon color-dark"></i>
          <h2 class="sb-grid-header--sport-title color-dark">{{ sport?.Name | titleCase }}</h2>
        </div>

        <sb-grid-item
          *ngFor="let event of sport?.Events;let index = index;trackBy: trackBy"
          [event]="event"
          (navigate)="navigate($event, 'live-market', sport?.key)"
        >
          <sb-grid-content-teams [event]="event" content> </sb-grid-content-teams>

          <sb-overview-score-sets
            *ngIf="event?.overviewScoreboard === 'sets'"
            [sportHandle]="event?.SportHandle"
            [scoreboard]="event?.liveData"
            scoreboard
          >
          </sb-overview-score-sets>

          <sb-overview-score-regular
            *ngIf="event?.overviewScoreboard === 'regular'"
            [scoreboard]="event?.liveData"
            scoreboard
          >
          </sb-overview-score-regular>

          <div
            class="sb-grid-item--bets-group"
            *ngFor="let game of event?.Games"
            [class]="'has-' + event?.Games?.length + '-groups'"
            bet-display
            sb-markets
            tappable
            [game]="event?.DefaultGame"
            [betType]="game?.DisplayTypeName"
            (click)="navigate(event, 'market', sport?.key, game?.GameType)"
          >
            <sb-button
              class="sb-button background-color-button"
              *ngFor="let result of game?.Results; let index = index; trackBy: utils?.trackBy"
              [type]="game?.GameType"
              [typeName]="game?.DisplayTypeName"
              [game]="result"
              [results]="game?.Results"
              [index]="index"
              (bet)="bet($event, event, game?.Name, '1')"
              [locked]="result?.Locked"
              [home]="event.teamHome"
              [away]="event.teamAway"
              [sport]="event?.SportHandle"
              [selected]="newTicketFacade?.hasResult(result.NodeId)"
            >
            </sb-button>
          </div>

          <span
            *ngIf="!!event?.ChildrenCount && event?.mode !== 'expanded'"
            class="sb-grid-header--bets-counter color-secondary"
            counter-button
            tappable
            (click)="navigate(event, 'live-market', sport?.key)"
          >
            +{{ event?.ChildrenCount }}
            <span class="codere-icon icon-arrow-right"></span>
          </span>
        </sb-grid-item>
      </ng-container>
    </ng-container>

    <!-- Marquee Betbuilder Desktop -->
    <sb-marquee-betbuilder *ngIf="betbuilderEventsData?.length > 0" [betbuilderEventsData]="betbuilderEventsData">
    </sb-marquee-betbuilder>

    <!-- Apuestas Destacadas Desktop -->
    <ng-container *ngIf="sportFilters?.length > 0" #highlights>
      <div class="sb-grid-header--more-bet background-color-regular">
        <h2 class="sb-grid-header--more-bet-title color-dark">Apuestas Destacadas</h2>
      </div>

      <div class="ion-segment-wrapper">
        <ion-segment [value]="sportFilterSelected?.SportHandle" class="ion-button--reduced" [scrollable]="true">
          <ion-segment-button
            [value]="button?.SportHandle"
            layout="icon-start"
            *ngFor="let button of sportFilters"
            (click)="setCurrentFilter(button)"
          >
            <i [ngClass]="'icon-' + button?.SportHandle" class="codere-icon"></i>
            <ion-label>{{ button?.SportName }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>

      <sb-grid-item
        *ngFor="let event of sportEvents?.Events; trackBy: utils?.trackBy"
        [event]="event"
        [mode]="event?.mode"
        [special]="sportbookService?.isSpecialMarket(event?.LeagueName)"
        (navigate)="navigate($event, 'market', sportEvents?.SportHandle)"
      >
        <ng-container *ngTemplateOutlet="event?.mode === 'teams' ? teams : expanded; context: { event: event }" content>
        </ng-container>
        <div
          class="sb-grid-item--bets-group"
          *ngFor="let game of event?.Games"
          [class]="'has-' + event?.Games?.length + '-groups'"
          bet-display
          sb-markets
          tappable
          [game]="game"
          [desktop]="isDesktop"
          [betType]="game?.DisplayTypeName"
          (click)="navigate(event, 'market', sportEvents?.SportHandle, game?.GameType)"
        >
          <sb-button
            class="sb-button background-color-button"
            *ngFor="let result of game?.Results; let index = index"
            [type]="game?.GameType"
            [typeName]="game?.DisplayTypeName"
            [game]="result"
            [results]="game?.Results"
            [index]="index"
            (bet)="bet($event, event, game?.Name, '2')"
            [locked]="result?.Locked"
            [home]="event?.teamHome"
            [away]="event?.teamAway"
            [sport]="event?.SportHandle"
            [selected]="newTicketFacade?.hasResult(result.NodeId)"
          >
          </sb-button>
        </div>
        <span
          *ngIf="!!event?.ChildrenCount && event?.mode !== 'expanded'"
          class="sb-grid-header--bets-counter color-secondary"
          counter-button
          tappable
          (click)="navigate(event, 'market', sportEvents?.SportHandle)"
        >
          +{{ event?.ChildrenCount }}
          <span class="codere-icon icon-arrow-right"></span>
        </span>
      </sb-grid-item>
    </ng-container>
    <ng-container>
      <p *ngIf="sportEvents?.Events.length < 1 && sportFilters?.length > 0">
        No hay apuestas destacadas en estos momentos.
      </p>
    </ng-container>
    <ng-container>
      <codere-footer></codere-footer>
    </ng-container>
  </ion-content>

  <!--Overview Pages-->
  <ng-template #teams let-event="event" content>
    <sb-grid-content-teams [event]="event" content></sb-grid-content-teams>
  </ng-template>
  <ng-template #expanded let-event="event" content>
    <sb-grid-content-expanded [title]="event?.Name" [date]="event?.StartDate | extractDate" content>
    </sb-grid-content-expanded>
  </ng-template>
</ng-container>

<ng-template #mobileView>
  <ion-content
    class="getting-started"
    (ionScroll)="onScroll($event)"
    [ngClass]="{'no-scroll': globalVars?.iosScrollHide}"
  >
    <div class="sb-sticky">
      <sb-navbar theme="sportbook" [hasBackButton]="false" [hasLeftSection]="false">
        <sb-navbar-item
          *ngFor="let carousel of carousels$ | async; index let isLast = last"
          [item]="carousel"
          tappable
          (click)="clickItemCarrouselSportBook(carousel)"
          [ngClass]="{'lastElement': isLast}"
        >
        </sb-navbar-item>
      </sb-navbar>
    </div>
    <codere-slider
      *ngIf="!!lstBanners?.length && sliderVisible && !globalVars?.FEATURES.NewCarouselActivated"
      [slides]="lstBanners"
      [controls]="isDesktop"
      (clickedSlide)="clickBanner($event)"
    ></codere-slider>
    <codere-slider-csv
      *ngIf="!!lstNewBanners?.length && showCarousel && sliderVisible && globalVars?.FEATURES.NewCarouselActivated"
      [banners]="lstNewBanners"
    ></codere-slider-csv>

    <div padding *ngIf="globalVars?.user?.logged && globalVars?.FEATURES.TemporaryPromoEnabled">
      <div class="has-background-dark temporary-promotion--container">
        <div class="temporary-promotion--image">
          <img [src]="temporaryPromotionConfig.bannerMobileImage" alt="" />
        </div>
        <ion-buttons padding-horizontal class="temporary-promotion--buttons">
          <button ion-button clear start tappable (click)="openPromos()">+Info</button>
          <button class="background-color-secondary" ion-button end tappable (click)="openTemporaryPromotion()">
            {{temporaryPromotionConfig?.openPromotionButtonText}}
          </button>
        </ion-buttons>
      </div>
    </div>

    <!-- Marquee -->
    <sb-marquee *ngIf="marqueeData?.length > 0" [marqueeData]="marqueeData"> </sb-marquee>

    <!-- Ahora en directo MOBILE -->
    <ng-container *ngIf="liveEvents$ | async as liveEvents">
      <div class="sb-grid-header--onlive background-color-regular">
        <h2 class="sb-grid-header--onlive-title color-dark">{{ 'nowLive' | translate }}</h2>
        <span
          *ngIf="LiveEventsCount > -1"
          class="sb-grid-header--bets-counter color-secondary"
          tappable
          (click)="navigate(null, 'live')"
        >
          +{{ LiveEventsCount }}
          <span class="codere-icon icon-arrow-right"></span>
        </span>
      </div>
      <ng-container *ngFor="let sport of liveEvents">
        <div class="sb-grid-header--sport background-color-regular">
          <i [ngClass]="'icon-' + sport?.SportHandle" class="sb-grid-header--sport-icon codere-icon color-dark"></i>
          <h2 class="sb-grid-header--sport-title color-dark">{{ sport?.Name | titleCase }}</h2>
        </div>
        <sb-grid-item
          *ngFor="let event of sport?.Events; let index = index; trackBy: trackBy"
          [event]="event"
          (navigate)="navigate($event, 'live-market', sport)"
          [attr.data-NodeId]="event?.NodeId"
        >
          <sb-grid-content-teams [event]="event" content></sb-grid-content-teams>
          <sb-overview-score-sets
            *ngIf="event?.overviewScoreboard === 'sets'"
            [sportHandle]="event?.SportHandle"
            [scoreboard]="event?.liveData"
            scoreboard
          >
          </sb-overview-score-sets>
          <sb-overview-score-regular
            *ngIf="event?.overviewScoreboard === 'regular'"
            [scoreboard]="event?.liveData"
            scoreboard
          >
          </sb-overview-score-regular>

          <div
            class="sb-grid-item--bets-group"
            *ngFor="let game of event?.Games"
            [class]="'has-' + event?.Games?.length + '-groups'"
            bet-display
            [betType]="game?.DisplayTypeName"
            [game]="event?.DefaultGame"
            sb-markets
          >
            <sb-button
              class="sb-button background-color-button"
              *ngFor="let result of game?.Results; let index = index; trackBy: trackBy"
              [type]="game?.GameType"
              [typeName]="game?.DisplayTypeName"
              [game]="result"
              [results]="game?.Results"
              [index]="index"
              (bet)="bet($event, event, game?.Name, '1')"
              [locked]="result?.Locked"
              [home]="event.teamHome"
              [away]="event.teamAway"
              [selected]="newTicketFacade?.hasResult(result.NodeId)"
            >
            </sb-button>
          </div>
        </sb-grid-item>
      </ng-container>
    </ng-container>

    <sb-marquee-betbuilder *ngIf="betbuilderEventsData?.length > 0" [betbuilderEventsData]="betbuilderEventsData">
    </sb-marquee-betbuilder>

    <ng-container *ngIf="sportFilters?.length > 0">
      <div class="sb-grid-header--more-bet background-color-regular">
        <h2 class="sb-grid-header--more-bet-title color-dark">Apuestas Destacadas</h2>
      </div>

      <div class="ion-segment-wrapper">
        <ion-segment [value]="sportFilterSelected?.SportHandle" [scrollable]="true" mode="md">
          <ion-segment-button
            [value]="button?.SportHandle"
            layout="icon-start"
            *ngFor="let button of sportFilters"
            (click)="setCurrentFilter(button)"
          >
            <i [ngClass]="'icon-' + button?.SportHandle" class="codere-icon"></i>
            <ion-label>{{ button?.SportName }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>

      <ng-container *ngFor="let event of sportEvents?.Events; trackBy: utils?.trackBy">
        <sb-grid-item
          *ngIf="!event?.isSixPack"
          [event]="event"
          [mode]="event?.mode"
          [hasBets]="!!event?.Games?.length"
          [special]="sportbookService?.isSpecialMarket(event?.LeagueName)"
          (navigate)="navigate($event, 'market')"
        >
          <!--template outlet for content-->
          <ng-container *ngTemplateOutlet="event?.mode === 'teams' ? teams : expanded; context: {event: event}" content>
          </ng-container>
          <sb-overview-score-sets
            *ngIf="event?.overviewScoreboard === 'sets'"
            [sportHandle]="event?.SportHandle"
            [scoreboard]="event?.liveData"
            scoreboard
          >
          </sb-overview-score-sets>
          <sb-overview-score-regular
            *ngIf="event?.overviewScoreboard === 'regular'"
            [scoreboard]="event?.liveData"
            scoreboard
          >
          </sb-overview-score-regular>

          <div
            class="sb-grid-item--bets-group"
            [ngClass]="'has-' + event?.Games?.length + '-groups'"
            [game]="game"
            *ngFor="let game of event?.Games; let index = index"
            bet-display
            [betType]="game?.DisplayTypeName"
            sb-markets
          >
            <sb-button
              class="sb-button background-color-button"
              *ngFor="let result of game?.Results; let index = index; trackBy: utils?.trackBy"
              [type]="game?.GameType"
              [typeName]="game?.DisplayTypeName"
              [game]="result"
              [results]="game?.Results"
              [index]="index"
              [locked]="result?.Locked"
              [home]="event?.teamHome"
              [sport]="event?.SportHandle"
              [away]="event?.teamAway"
              [sport]="event?.SportHandle"
              (bet)="bet($event, event, game?.Name, '2')"
              [selected]="newTicketFacade?.hasResult(result.NodeId)"
            >
            </sb-button>
          </div>
        </sb-grid-item>
        <!-- SIX PACK -->
        <ng-container *ngIf="event?.isSixPack">
          <sb-grid-header
            [titleLeft]="'Ganador'"
            [titleCenter]="'Handicap'"
            [titleRight]="'Totales'"
            [market]="event?.sixPack"
          ></sb-grid-header>
          <sb-grid-item
            [mode]="event?.sixPack?.displayMode"
            [event]="event?.sixPack"
            [betType]="event?.sixPack?.DisplayTypeName"
            [special]="sportbookService?.isSpecialMarket(event?.LeagueName)"
            (navigate)="navigate($event,'market', sportEvents?.key)"
          >
            <sb-grid-content-single [title]="event?.sixPack?.teamHome" [subtitle]="event?.sixPack?.pitcherHome" content>
            </sb-grid-content-single>
            <div class="sb-grid-item--bets-group" bet-display [betType]="event?.sixPack?.DisplayTypeName" sb-markets>
              <sb-button
                class="sb-button background-color-button"
                *ngFor="let result of (event?.sixPack?.combinedResults)[0]?.results as game; let indexCombinedBets = index; trackBy: utils?.trackBy"
                [typeName]="event?.sixPack?.DisplayTypeName"
                [type]="result?.GameType"
                [game]="result"
                [isCombined]="event?.sixPack?.isCombined"
                [results]="event?.sixPack?.GroupResults"
                [index]="indexCombinedBets"
                [locked]="result?.Locked"
                [home]="event?.sixPack?.teamHome"
                [away]="event?.sixPack?.teamAway"
                [sport]="event?.SportHandle"
                (bet)="bet($event, event, result?.Name, '3')"
                bets
                [selected]="newTicketFacade?.hasResult(result.NodeId)"
              >
              </sb-button>
            </div>
          </sb-grid-item>
          <!-- marcos -->

          <sb-grid-item
            *ngIf="event?.isSixPack"
            [mode]="event?.sixPack?.displayMode"
            [event]="event?.sixPack"
            [betType]="event?.sixPack?.DisplayTypeName"
            [special]="sportbookService?.isSpecialMarket(event?.LeagueName)"
            class="sb-item-container-margin-bot"
            (navigate)="navigate(event?.sixPack)"
          >
            <sb-grid-content-single [title]="event?.sixPack?.teamAway" [subtitle]="event?.sixPack?.pitcherAway" content>
            </sb-grid-content-single>
            <div class="sb-grid-item--bets-group" bet-display [betType]="event?.sixPack?.DisplayTypeName" sb-markets>
              <sb-button
                class="sb-button background-color-button"
                *ngFor="let result of (event?.sixPack?.combinedResults)[1]?.results; let indexCombinedBets = index; trackBy: utils?.trackBy"
                [typeName]="event?.sixPack?.DisplayTypeName"
                [type]="result?.GameType"
                [game]="result"
                [isCombined]="event?.sixPack?.isCombined"
                [results]="event?.sixPack?.GroupResults"
                [locked]="result?.Locked"
                [index]="indexCombinedBets"
                [home]="event?.sixPack?.teamHome"
                [away]="event?.sixPack?.teamAway"
                [sport]="event?.SportHandle"
                (bet)="bet($event, event, result?.Name, '4')"
                bets
                [selected]="newTicketFacade?.hasResult(result.NodeId)"
              >
              </sb-button>
            </div>
          </sb-grid-item>
        </ng-container>
      </ng-container>
      <ng-container>
        <p *ngIf="sportEvents?.Events.length < 1">No hay apuestas destacadas en estos momentos.</p>
      </ng-container>
    </ng-container>
    <ng-container>
      <codere-footer></codere-footer>
    </ng-container>
  </ion-content>
  <!--Overview Pages-->
  <ng-template #teams let-event="event" content>
    <sb-grid-content-teams [event]="event" content></sb-grid-content-teams>
  </ng-template>
  <ng-template #expanded let-event="event" content>
    <sb-grid-content-expanded [title]="event?.Name" [date]="event?.StartDate | extractDate" content>
    </sb-grid-content-expanded>
  </ng-template>
</ng-template>
