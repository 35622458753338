<div class="divionic7">
  <ion-header>
    <div class="wb-header">
      <div class="m-0 color-light text-center font2">{{headerTitle}}</div>
      <ion-icon name="close-circle-outline" (click)="isOptIn ? closeModal() : dismissModal()"></ion-icon>
    </div>
  </ion-header>

  <ion-content>
    <ng-container *ngIf="targetPromo && !isOptIn">
      <div class="text-center">
        <div class="subtitleHeader mt-15">{{targetPromo.eventName}}</div>
        <!-- <div class="boldtxt font1_8">TU APUESTA GRATIS DE {{targetPromo.cantidad  | parseMoney:[1]}} TE ESPERA</div> -->
        <div class="boldtxt font1_5">{{targetPromo.displayBody}}</div>
        <div class="font1_5">{{targetPromo.keyTerms}}</div>
        <div class="allbonus">
          <ng-container *ngIf="!isDecline; else declined">
            <div class="m-0"><button (click)="setdecline()" class="grey">No me interesa</button></div>
            <div class="btn"><button (click)="optIn()">{{targetPromo.buttonText}}</button></div>
          </ng-container>
          <ng-template #declined>
            <div class="m-0"><button (click)="decline()" class="btnred">No me interesa</button></div>
            <div class="btn"><button (click)="recallLater()" class="btngreen">Recordar más tarde</button></div>
          </ng-template>
        </div>
        <div class="grey tyc">
          <a [href]="targetPromo.termsLink" target="_blank" (click)="onTyC()">{{ 'InviteFriendtxt06' | translate}}</a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="targetPromo && isOptIn">
      <div class="text-center">
        <div class="subtitleHeader mt-15">{{targetPromo.eventName}}</div>
        <!-- <div class="boldtxt font1_8">TU APUESTA GRATIS DE {{targetPromo.cantidad  | parseMoney:[1]}} TE ESPERA</div> -->
        <div><img src="assets/global/img/icon-check-green.png" alt="OK" class="imgcheck" /></div>
        <div class="font1_5">{{targetPromo.confirmationText}}</div>
        <div class="allbonus">
          <div class="m-0 btn">
            <button (click)="closeandgoPromotions()" class="greenbtn">Confirmar y cerrar</button>
          </div>
        </div>
      </div>
    </ng-container>
  </ion-content>
</div>
