<ion-content
  *ngIf="isDesktop; else mobileView"
  class="playtech-iframe"
  [ngClass]="{ 'platform-core': isDesktop, 'platform-mobile': !isDesktop }"
>
  <button class="closeModal" (click)="close()">
    <ion-icon name="close-outline"></ion-icon>
  </button>
  <iframe #iframe [src]="url" frameborder="0" (load)="reSize(this)"></iframe>
</ion-content>

<ng-template #mobileView>
  <ion-content class="playtech-iframe mobile" [ngClass]="{ 'platform-core': isDesktop, 'platform-mobile': !isDesktop }">
    <div class="fakeModal">
      <button class="closeX" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <iframe #iframe [src]="url" frameborder="0" (load)="setScroll(this)"></iframe>
    </div>
  </ion-content>
</ng-template>
