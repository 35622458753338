<span
  class="sb-ticket-stake--label sb-ticket--button-title color-dark"
  [ngStyle]="{ 'display' : finalRow ? 'block' : 'none' }"
>
  {{ stakeLabel }}
</span>
<!-- todo pending to fix the issue [disabled]="getTicketHasFreebets(ticket)" -->
<input
  class="sb-ticket--button-stake sb-ticket--button-title text-uppercase color-grey-200 background-color-muted border-color-grey-200"
  [(ngModel)]="stakeAmount"
  type="number"
  [disabled]="disabled"
  #stake
/>
<span class="sb-ticket--stake-title color-dark"> {{ stakeAmountAux | async | parseStakeMoney }} </span>
