const DOMAIN = 'https://dm.apuestas.codere.es';
const JACKPOTSSERVICES = DOMAIN + '/JackpotServices.AppService';
const URLBASE_REPORTS = DOMAIN + '/csbgonlinereports/';
export const environment = {
  COUNTRY: 'es',
  ENVIRONMENT: 'qa',
  CORDOVA: false,
  GLOBALS: {
    domainC: 'codere.es',
    APP_VERSION: '2022.12.27-15.36.00',
    LICENSE_TYPE: 0,
    ENVIRONMENT: 'mobile',
    COOKIES: {
      customOK: true,
      analyticsOK: false,
      advertismentOK: false
    },
    ANDROID_SENDER_ID: '118071133273',
    XTREME_PUSH_APPKEY: '60Aau6_eRAqJufjbP6D3sVVIvv6xPMy9',
    URLBASE_USERSERICE_STAND_ALONE: DOMAIN + '/user/api/data/',
    CORDOVA: 0,
    DOMAIN: DOMAIN,
    URLBASE: DOMAIN + '/csbgonline/',
    URLBASE_REPORTS: URLBASE_REPORTS,
    FREEBET_BONUS: DOMAIN + '/FreebetBonusEs/',
    URLBASE_BANNERS: DOMAIN,
    URLBASE_COMMON_API: '/DynTemplateGen/Api/', //obtencion de literales de aplicacion DynTemplateGen
    URLBASE_TRANSACTIONS_API: '/PaymentsTransactionsApiQAEs/', //Transaccions a traves de TransactionsApi
    URLBASE_OPERATIONS_API: '/payments/operations/', //operations utilizado en el endpoint para traer los JSONs de paymentMethods.
    URLBASE_VERIDAS_API: DOMAIN + '/veridas/',
    URLBASE_VERIDAS_MODELS: DOMAIN + '/deportes/assets/js/veridas/public/models',
    URLBASE_STREAMING: DOMAIN + '/StreamingApi/',
    URLBASE_USERSERVICE: DOMAIN + '/UserServiceApi/',
    URLBASE_LOGINSERVICES: DOMAIN + '/LoginServicesEsApi',
    HIGHLIGHTS_RT: 10 * 1000,
    LOBBY_JACKPOTS: DOMAIN + '/Jackpot/',
    // LOBBY_JACKPOTS_TICKERS : DOMAIN + '/JackpotServices.AppService/api/NewJackpot/',
    JACKPOTSSERVICES: JACKPOTSSERVICES,
    LOBBY_JACKPOTS_TICKERS: JACKPOTSSERVICES + '/api/NewJackpot/',
    LIVEGAMEDATA: DOMAIN + '/LiveGameData',
    DYNAMICYIELD: URLBASE_REPORTS + 'slotgames/GetGamesDY?mobile=',
    RECENTGAMES: URLBASE_REPORTS + 'slotgames/GetGamesRPG?mobile=',
    URLBASE_BALANCESERVICE_API: '/BalanceServiceApi/',
    TK_CLOSE: 3 * 1000,
    DEBUG_MODE: false,
    //refresh times
    TK_RT: 3 * 1000,
    TK_DESK_RT: 5 * 1000,
    LIVE_RT: 5 * 1000,
    HOME_RT: 10 * 1000,
    //devicetype es web o nativo( ANDROID - IOS - cordova )
    DEVICE_TYPE: 'web',
    BROWSER: '',
    //url NotAllowedCountry
    FORBIDDEN_REDIRECT: DOMAIN + '/csbgonline/home/NotAllowedCountry',
    COUNTRY_AVAILABLE: true,
    //TransactionsApi
    TRANSAPI_PointofSalePayment: true,
    TRANSAPI_BankAccountPayment: true,
    TRANSAPI_HalCashPayment: true,
    TRANSAPI_BgtCardPayment: true,
    TRANSAPI_PointofSaleDeposit: true,
    TRANSAPI_ElectronicCardDeposit: true,
    TRANSAPI_TeleingreosDeposit: true,
    TRANSAPI_PaySafeCardDeposit: true,
    TRANSAPI_BgtCardDeposit: true,
    TRANSAPI_CRUDoverTransactions: true,
    EPGCREDITCARDENABLED: true,
    LOBBY_IMAGES: 'https://www.codere.es/lobby_tiles/',
    URLBASE_CASINO_BONUSES: DOMAIN + '/csbgonlinecasinobonuses',
    URLBASE_CODERE_EVENT_BUS: 'https://coderesbgonlineeventbusapi.azurewebsites.net/api',
    URL_NEW_USER_EXTRACT: '/SummaryPlayerServicesApiES/api/v1/balance/extract/',
    URL_NEW_USER_SUMMARY: '/SummaryPlayerServicesApiES/api/v1/balance/summary/',
    BASEDOMAIN: DOMAIN,
    NAVIGATIONSERVICEBASEURL: 'https://coderenavigationserviceredundant.azurewebsites.net/api/',
    BETSLIPSERVICEBASEURL: 'https://dev02.preproduction02.pbs-master.com/dig-codere/betslipservice-gatewayv3/api/v3/',
    PRODUCTION: false,
    HTTP_ERRORS_THAT_RESTART_APP: [102, 103, 400, 401, 402, 403, 405, 500],
    ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT: ['betslipservice'],
    HTTP_ERROR_DESCRIPTION: 'Http error an call',
    TIMEOUT_INTERCEPTOR_URLS_2_INSPECT: ['betslipservice'],
    ENABLE_TIMEOUT_FOR_BACKEND_CALLS: true,
    TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS: 5000,
    TIMEOUT_ERROR_DESCRIPTION: 'Timeout Error',
    ENABLE_TRACE_CALL_TIME: false,
    SPORTS_MISC: DOMAIN + '/SportsMisc/api/',
    GET_TICKETS_URL: 'https://coderesportsmyticketsqa.azurewebsites.net/api/home/',
    ROYALDEGREE: 'https://newarchpocapimanagement.azure-api.net',
    HCP_URL: 'https://dm.apuestas.codere.es/DeportesDevEs/hcp/hcp.json',
    PROVIDER_IMAGES: 'https://www.codere.es/IconsCasino/',
    URL_GEOLOCATION_API: 'https://coderesbgonlinegeoip.azurewebsites.net/ips/currentRequestCountryIsoCode',
    URLBASE_MYBETS: 'https://coderesbgonlinebettingservicehub20180308112504.azurewebsites.net',
    CODERE_FANTASY: DOMAIN + '/CodereFantasy/api/',
    CODERE_FANTASY_HP_URL: 'https://codere.dgafantasy-dev.com/league?operatorKey=codere'
  }
};
