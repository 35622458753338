/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { TrackingService } from './TrackingService';
import { EventsSortMethods } from '../models/MasterData';
import * as models from '../models/index';
import * as storeModels from '../models/sports/store/models/sportsbook.model';

import { orderBy } from '../utils/utils.functions';
import { SportBookService } from './SportBookService';
import { BaseServiceExt } from './BaseServiceExt';
import { Constants } from '../utils/constants';
import { SmartMarketInput } from '../models/sports/smartMarket.model';
import { DeviceService } from '../../../shared/src/services/device.service';
import { Router } from '@angular/router';
import { StaticStoreService } from './static-store.service';

@Injectable({ providedIn: 'root' })
export class SportService extends BaseServiceExt {
  eventStatisticsId$: BehaviorSubject<string> = new BehaviorSubject('');
  showSportradar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authorizationHeader$: BehaviorSubject<string> = new BehaviorSubject('');
  isFormula1$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMobile = false;
  isDesktop = false;

  //#region
  sportBookService = inject(SportBookService);
  trackingService = inject(TrackingService);
  deviceService = inject(DeviceService);
  router = inject(Router);
  staticStoreService = inject(StaticStoreService);
  //#endregion
  EnVivoSportsOrder: string[] = ['Fútbol', 'Tenis', 'Baloncesto'];

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    //con hacerlo aquí (es la primera que se instancia) ya lo cogen el resto de servicios.
    //TO-DO estar atento a ver cuando implementan esta funcionalidad para incorporarlo en nuestro proyecto.
    //var http:Http;

    // **+** TODO: revisar funcionalidad comentario BAU
    // const _build = (<any>http)._backend._browserXHR.build;
    // (<any>http)._backend._browserXHR.build = () => {
    //   const _xhr = _build();
    //   _xhr.withCredentials = this.globalVars.XMLHttpRequest_WITHCREDENTIALS;
    //   return _xhr;
    // };
  }

  getLeftMenu(): Observable<any> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getMenuLeftUrl}`;
    const headers = { language: this.globalVars.Idioma };
    return this.myGet(url, false, { headers });
  }

  getCountries(parentId: string): Observable<models.C_Country[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getCountriesUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = { parentid: parentId };

    return this.myGet(url, false, { headers, params }).pipe(
      map((countries) => {
        const c = countries.map((country) => {
          const countryCode: string = country.CountryCode;
          const d = country.Leagues.map((league) => {
            league = models.C_League.parse(league);
            league.SportHandle = country.SportHandle;
            league.CountryCode = countryCode;
            return league;
          });
          Object.assign(country.Leagues, d);
          return country;
        });
        return c;
      })
    );
  }

  getHighlightCountries(parentId: string, nodes: number): Observable<models.C_HighLight[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getHighlightsUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      parentid: parentId,
      ...(nodes > 0 && { nodes: nodes.toString() })
    };
    return this.myGet(url, false, { headers, params });
  }

  /**
   * Pais liga page grouped enpoints
   * @param parentId
   * @returns { highlights, countries }
   */
  getPaisLigaInfo(parentId: string) {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getPaisLigaInfoUrl}?parentId=${parentId}&languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url, false);
  }

  getEvents(args): Observable<models.C_Event[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getEventsUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      parentId: args.parentId,
      ...(args.category && args.category !== -1 && { category: args.category }),
      ...((!args.category || (args.category === -1 && args.sportHandle)) && {
        gameTypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(args.sportHandle))
      })
    };

    return this.myGet2(url, { headers, params }, true).pipe(
      map((leagueEvents: models.C_Event[]) => {
        leagueEvents = leagueEvents.map((event) => {
          event = models.C_Event.parse(event);
          event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          event.hasSixPack(args.category);

          const eventGameTypes = this.getEventGameTypes(event);
          event.setGamesByPlatform(eventGameTypes, this.isMobile, args.category > 0);

          if (event.isSixPack) {
            event.sixPack = this.sportBookService.getSixPackMarket(event, eventGameTypes);
          }

          event.Games = event.Games.map((game: models.C_Game) => {
            const g = models.C_Game.parse(game);
            g.Results = Object.assign([], orderBy(game.Results, 'SortOrder'));
            return g;
          });
          return event;
        });

        if (args.eventsSortMethod === EventsSortMethods.None) return leagueEvents;

        return this.sortLeagueEvents(leagueEvents);
      })
    );
  }

  private sortLeagueEvents(leagueEvents) {
    return leagueEvents.sort((a, b) => {
      if (a.Priority > b.Priority || (a.Priority === b.Priority && a.StartDate < b.StartDate)) return -1;
      if (b.Priority > a.Priority || (b.Priority === a.Priority && b.StartDate < a.StartDate)) return 1;
      return 0;
    });
  }

  GetCategoriesByLeague(args): Observable<models.C_LeagueCategory[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getCategoriesByLeagueUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = { parentid: args.idLeague };

    return this.myGet(url, true, { headers, params }).pipe(
      map((categories) => {
        if (this.isMobile || !categories) {
          return categories;
        }
        const filters = this.globalVars.gameTypes.getOne(args.sportHandle, args.countryCode, args.isLive) || [];
        return categories.filter((category) => filters.indexOf(parseInt(category.CategoryId)) === -1);
      })
    );
  }

  getGamesNoLiveEvent(parentId: string, league: string): Observable<models.C_GameGroupByName[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getGamesNoLiveUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = { parentid: parentId };
    return this.myGet(url, null, { headers, params }).pipe(
      map((games: any[]) => {
        const alternatives = ['nba', 'nfl'];
        games.forEach((game) => this.getGameResultsOrdered(game));
        let groupedMarkets: models.C_GameGroupByName[] = this.sportBookService.groupMarkets(games, '');
        if (league && alternatives.indexOf(league.toLowerCase()) !== -1) {
          groupedMarkets = this.sportBookService.doAlternatives(games, groupedMarkets);
        }
        groupedMarkets.forEach((grouped) => grouped.sort());
        return groupedMarkets.sort((a, b) => b.Priority - a.Priority);
      })
    );
  }

  getGamesNoLiveEventAndCategoryInfos(
    parentId: string,
    league: string,
    categoryId?: string
  ): Observable<models.C_GameGroupByName[]> {
    let url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getGamesNoLiveAndCategoryInfosUrl}?parentid=${parentId}`;

    if (categoryId) url = `${url}&categoryInfoId=${categoryId}`;
    return this.myGet(url).pipe(
      map((data: any) => {
        const gamesAndCategoriesArr = {};

        const alternatives = ['nba', 'nfl'];
        data.Games.forEach((game) => this.getGameResultsOrdered(game));
        let groupedMarkets: models.C_GameGroupByName[] = this.sportBookService.groupMarkets(data.Games, '');
        if (league && alternatives.indexOf(league.toLowerCase()) !== -1) {
          groupedMarkets = this.sportBookService.doAlternatives(data.Games, groupedMarkets);
        }
        groupedMarkets.forEach((grouped) => grouped.sort());

        return groupedMarkets.sort((a, b) => b.Priority - a.Priority);
      })
    );
  }

  getMarketCategories(parentId: string) {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getCategoryNoLiveInfosUrl}?parentid=${parentId}`;
    return this.myGet(url).pipe(map((data: any) => data));
  }

  getMarketGamesByCategory(parentId: string, categoryInfoId: string | number, league: string) {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getGamesNoLiveByCategoryInfoUrl}?parentid=${parentId}&categoryInfoId=${categoryInfoId}`;
    return this.myGet(url).pipe(
      map((data: any) => {
        const alternatives = ['nba', 'nfl'];
        data.forEach((game) => this.getGameResultsOrdered(game));
        let groupedMarkets: models.C_GameGroupByName[] = this.sportBookService.groupMarkets(data, '');
        if (league && alternatives.indexOf(league.toLowerCase()) !== -1) {
          groupedMarkets = this.sportBookService.doAlternatives(data, groupedMarkets);
        }
        groupedMarkets.forEach((grouped) => grouped.sort());

        return groupedMarkets.sort((a, b) => b.Priority - a.Priority);
      })
    );
  }

  getGameResultsOrdered(game: models.C_Game): any {
    const g = models.C_Game.parse(game);
    g.Results = Object.assign([], orderBy(game.Results, 'SortOrder'));
    return g;
  }

  getCountriesByDate(sporthandle: string, dayDifference: number): Observable<models.C_Country[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getCountriesByDateUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      utcOffsetHours: Math.round(new Date().getTimezoneOffset() / 60) * -1,
      dayDifference: dayDifference,
      sporthandle
    };

    return this.myGet(url, null, { headers, params });
  }

  getHighligthEventsBySportHandle(args): Observable<models.C_Event[]> {
    const count = args.count || 20;
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getHiglightEventsBySportHandleUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      sporthandle: args.sportHandle,
      ...(count > 0 && { count: count.toString() }),
      gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(args.sportHandle))
    };

    return this.myGet(url, false, { headers, params }).pipe(
      map((leagueEvents: models.C_Event[]) => {
        const events = leagueEvents.map((event) => {
          event = models.C_Event.parse(event);
          event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          event.hasSixPack();
          const eventGameTypes = this.getEventGameTypes(event);
          event.setGamesByPlatform(eventGameTypes, this.isMobile);

          return event;
        });
        return events;
      })
    );
  }

  getEventsByDate(args): Observable<models.C_Event[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getEventsByDateUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      utcOffsetHours: Math.round(new Date().getTimezoneOffset() / 60) * -1,
      dayDifference: args.dayDifference,
      parentid: args.parentId,
      gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(args.sportHandle))
    };

    return this.myGet(url, true, { headers, params }).pipe(
      map((events) =>
        events.map((event: models.C_Event) => {
          event = models.C_Event.parse(event);
          event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          event.hasSixPack();
          const eventGameTypes = this.getEventGameTypes(event);
          event.setGamesByPlatform(eventGameTypes, this.isMobile);
          if (event.isSixPack) {
            event.sixPack = this.sportBookService.getSixPackMarket(event, eventGameTypes);
          }

          return event;
        })
      )
    );
  }

  GetLiveEventsBySportHandle(sportHandler?: string): Observable<models.C_SportsLive[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getLiveEventsBySportHandleUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      ...(sportHandler && { sportHandle: sportHandler }),
      gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(sportHandler))
    };

    return this.myGet(url, true, { headers, params }).pipe(
      map((data) => {
        const sports: any[] = data.map((sport: models.C_SportsLive) => {
          const s = models.C_SportsLive.parse(sport);
          s.Events = orderBy(s.Events, '').map((event) => {
            const ev = models.C_EventLive.parse(event);
            ev.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
            const eventGameTypes = this.getEventGameTypes(ev);
            ev.setGamesByPlatform(eventGameTypes, this.isMobile);
            return ev;
          });

          return s;
        });

        const sortCriteria = this.EnVivoSportsOrder;
        if (!sortCriteria) return sports;

        const sportsAux: any[] = [];
        sortCriteria.forEach((key) => {
          const found: any = sports.find((a) => a.Name === key);
          if (found !== undefined) {
            sportsAux.push(found);
          }
        });

        const rest = sports.filter((item) => sportsAux.indexOf(item) === -1);
        sportsAux.push(...rest);
        return sportsAux;
      })
    );
  }

  getGamesLiveEvent2(args): Observable<models.C_EventLive> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getGamesLiveByCategoryInfoUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = { parentid: args.parentid, categoryInfoId: args.categoryInfoId };

    return this.myGet(url, true, { headers, params }).pipe(
      map((data) => {
        if (!data) {
          return;
        }
        data.Event = models.C_EventLive.parse(data.Event);
        // const ev = models.C_EventLive.parse(data.Event);
        data.Event.setHomeAndAway();
        data.Event.setDetailScoreboardType(data.Event);
        data.Event.Games.forEach((game) => {
          this.getGameResultsOrdered(game);
          this.setLockedResults(data.Event);
        });

        let groupedMarkets = this.sportBookService.groupMarkets(data.Event.Games, args.leagueName);

        const alternatives = ['nba', 'nfl'];
        if (args.leagueName && alternatives.indexOf(args.leagueName.toLowerCase()) !== -1) {
          groupedMarkets = this.sportBookService.doAlternatives(data.Event.Games, groupedMarkets);
        }

        groupedMarkets.forEach((grouped) => {
          grouped.sort();
        });

        data.Event.Games = groupedMarkets.sort((a, b) => b.Priority - a.Priority);
        return data;
      })
    );
  }

  /**
   * !!! DEPRECATED - ELIMINATE WHEN NOT NEEDED
   */
  getGamesLiveEvent(args): Observable<models.C_EventLive> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getGamesLiveUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = { parentid: args.parentid };

    return this.myGet(url, true, { headers, params }).pipe(
      map((event) => {
        if (!event) {
          return;
        }
        const ev = models.C_EventLive.parse(event);
        ev.setHomeAndAway();
        ev.setDetailScoreboardType();
        ev.Games.forEach((game) => {
          this.getGameResultsOrdered(game);
          this.setLockedResults(ev);
        });

        let groupedMarkets = this.sportBookService.groupMarkets(ev.Games, args.leagueName);

        const alternatives = ['nba', 'nfl'];
        if (args.leagueName && alternatives.indexOf(args.leagueName.toLowerCase()) !== -1) {
          groupedMarkets = this.sportBookService.doAlternatives(ev.Games, groupedMarkets);
        }

        groupedMarkets.forEach((grouped) => {
          grouped.sort();
        });

        ev.Games = groupedMarkets.sort((a, b) => b.Priority - a.Priority);

        return ev;
      })
    );
  }

  //HORSES & GREYHOUNDS

  // !!! DELETE WHEN NOT NEEDED
  // getNextRaces(sport: string, nodes?: number): Observable<models.C_Horses[]> {
  //   /*
  //       falta limite de nodes
  //        */

  //   const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getNextRaces}?sporthandle=${sport}&languageCode=${this.globalVars.Idioma}`;
  //   return this.myGet(url).pipe(
  //     map((races) => {
  //       const next = [];
  //       races.forEach((race: models.C_Horses) => {
  //         const r = new models.C_Horses(
  //           race.Name,
  //           race.NodeId,
  //           race.ParentNodeId,
  //           race.EventNodeTypeId,
  //           race.Priority,
  //           race.SportHandle,
  //           race.ChildrenCount,
  //           race.CountryCode,
  //           race.IsActive,
  //           race.OddsAvailability,
  //           race.PaddockId,
  //           race.StartDate,
  //           race.GameTypeId,
  //           race.Races,
  //           race.Locked,
  //           race.StreamingEnabled
  //         );
  //         next.push(r);
  //       });

  //       return next;
  //     })
  //   );
  // }

  getNextRacesBuffer(params: any): Observable<models.C_Horses[]> {
    /*
        falta limite de nodes
         */
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getNextRaces}?sporthandle=${params.sport}&languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url, params.hideloader).pipe(
      map((races) => {
        const next = [];
        races.forEach((race: models.C_Horses) => {
          const r = new models.C_Horses(
            race.Name,
            race.NodeId,
            race.ParentNodeId,
            race.EventNodeTypeId,
            race.Priority,
            race.SportHandle,
            race.ChildrenCount,
            race.CountryCode,
            race.IsActive,
            race.OddsAvailability,
            race.PaddockId,
            race.StartDate,
            race.GameTypeId,
            race.Races,
            race.Locked,
            race.StreamingEnabled
          );
          next.push(r);
        });

        return next;
      })
    );
  }

  getRacesbyPaddock(sport: string): Observable<models.C_Horses[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getRacesbyPaddock}?sporthandle=${sport}&languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url).pipe(
      map((races) => {
        const next = [];
        races.forEach((race: models.C_Horses) => {
          const r = new models.C_Horses(
            race.Name,
            race.NodeId,
            race.ParentNodeId,
            race.EventNodeTypeId,
            race.Priority,
            race.SportHandle,
            race.ChildrenCount,
            race.CountryCode,
            race.IsActive,
            race.OddsAvailability,
            race.PaddockId,
            race.StartDate,
            race.GameTypeId,
            race.Races,
            race.Locked,
            race.StreamingEnabled
          );
          next.push(r);
        });

        return next;
      })
    );
  }

  getRaces(args: any): Observable<models.C_Horses[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${
      args.byPaddock ? Constants.getRacesByPaddockUrl : Constants.getNextRacesUrl
    }`;
    const headers = { language: this.globalVars.Idioma };
    const params = { sporthandle: args.sportHandle };

    return this.myGet(url, args.hideloader, { headers, params }).pipe(
      map((races) => {
        const next = [];
        races.forEach((race: models.C_Horses) => {
          const r = new models.C_Horses(
            race.Name,
            race.NodeId,
            race.ParentNodeId,
            race.EventNodeTypeId,
            race.Priority,
            race.SportHandle,
            race.ChildrenCount,
            race.CountryCode,
            race.IsActive,
            race.OddsAvailability,
            race.PaddockId,
            race.StartDate,
            race.GameTypeId,
            race.Races,
            race.Locked,
            race.StreamingEnabled
          );
          next.push(r);
        });

        return next;
      })
    );
  }

  getRacesInfo(sportHandle: any): Observable<any> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getRacesInfo}?sporthandle=${sportHandle}&languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url, false);
  }

  GetCompleteRace(args: any): Observable<models.C_Race> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getCompleteRaceUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      paddockId: args.paddockId,
      raceId: args.raceId
    };
    return this.myGet(url, args.hidePreload, { headers, params });
  }

  // getHomeEvent(sportHandle: string = null): Observable<models.C_EventMostBet[]> {
  //   const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getHighlightsEventsUrl}`;
  //   const headers = { language: this.globalVars.Idioma };
  //   const params = {
  //     sportHandle,
  //     gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(sportHandle))
  //   };

  //   if (this.staticStoreService.callPolling) {
  //     return this.myGet(url, true, { headers, params }).pipe(
  //       map((sports: models.C_EventMostBet[]) => {
  //         console.log(sports);
  //         return this.sportBookService.updatedHomeHighlightsEvents(sports, this.isMobile);
  //       })
  //     );
  //   } else {
  //     return of([]);
  //   }
  // }

  getHomeLiveEventCount(
    includeLiveCount = true
  ): Observable<{ events: Array<models.C_SportsLive>; liveEventsCount: number }> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getHomeLiveEvents}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      includeLiveCount,
      gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getAll())
    };

    if (this.staticStoreService.callPolling) {
      return this.myGet(url, true, { headers, params }).pipe(
        map((data) => {
          const events = data.LiveSport.map((sport: models.C_SportsLive) => {
            const s = models.C_SportsLive.parse(sport);
            s.marketNames = models.C_League.getMarketNames(s.Events, this.globalVars.gameTypes.getSport(s.SportHandle));
            s.Events = s.Events.map((event: models.C_EventLive) => {
              event = models.C_EventLive.parse(event);
              const eventGameTypes = this.getEventGameTypes(event);
              event.setGamesByPlatform(eventGameTypes, this.isMobile);
              return event;
            });

            return s;
          });

          return { liveEventsCount: data.LiveEventsCount, events: events };
        })
      );
    } else {
      return of({
        events: [],
        liveEventsCount: 0
      });
    }
  }

  getUpdateOdds(odds: string[]): Observable<any> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.updateOddsUrl}`;
    const headers = { language: this.globalVars.Idioma };
    return this.myPost(url, odds, { headers }, true);
  }

  private getEventGameTypes(event) {
    return this.globalVars.gameTypes.getOne(
      event.SportHandle,
      event.CountryCode,
      event.isLive,
      event.ParentNodeId,
      event.SportHandle === 'basketball' ? event.Games.map((game: models.C_Game) => game.GameType) : []
    );
  }

  getStreamingUrl(statisticsId: string, provider = 'betradar'): Observable<any> {
    const mobile = provider === 'betradar' ? true : this.isMobile;
    const url =
      this.globalVars.URLBASE_STREAMING +
      Constants.getStreamingURL +
      '&statisticsId=' +
      statisticsId +
      '&provider=' +
      provider +
      '&mobile=' +
      mobile;
    return this.myGet(url);
  }

  getStreamingXbnet(statisticsId: string): Observable<any> {
    const url = this.globalVars.URLBASE_STREAMING + Constants.getXbNetStreamingURL + '?statisticsId=' + statisticsId;
    return this.myGet(url);
  }

  getStreamingBetRadarUrl(streamingId: string): Observable<any> {
    const mobile = this.isMobile;
    const url =
      this.globalVars.URLBASE_STREAMING +
      Constants.getBetRadarStreamingInfo +
      '?eventId=' +
      streamingId +
      '&isMobile=' +
      mobile;
    return this.myGet(url);
  }

  /**
   * streaming para sport media stream
   * @param streamId  : el id del stream
   */
  getSMSStreamingUrl(streamId): Observable<any> {
    const url =
      this.globalVars.URLBASE_STREAMING +
      Constants.getSMSStreamingURL +
      '?isMobile=' +
      this.isMobile +
      '&idEvent=' +
      streamId;
    return this.myGet(url);
  }

  getIMGVideoStreamingURL(streamingId: string): Observable<any> {
    const url = this.globalVars.URLBASE_STREAMING + Constants.getIMGStreamingURL + '?streamingId=' + streamingId;
    return this.myGet2(url);
  }

  getWatchBetVideoStreamingURL(streamingId: string): Observable<any> {
    const url =
      this.globalVars.URLBASE_STREAMING + Constants.getStreamingIdWatchBetToken + '?streamingId=' + streamingId;
    return this.myGet2(url);
  }

  getStreamingImgUrl(token: string, timestamp: string, id: number): Observable<any> {
    const operatorID = this.globalVars.FEATURES.IMGOperatorId;
    const urlBase = this.globalVars.FEATURES.IMGUrlBase + Constants.IMGStreamUrl;
    const url =
      urlBase +
      id +
      '/stream/?operatorId=' +
      operatorID +
      '&auth=' +
      token +
      '&timestamp=' +
      timestamp +
      '&format=json&hlsv2=true';
    return this.imgGet(url);
  }

  getStreamingWatchBetUrl(eventId: string, userMd5: string, md5: string): Observable<any> {
    const partnerID = '8408';
    let url;

    if (location.protocol === 'http:') {
      url =
        'http://www.mobile.codere.performgroup.com/streaming/wab/multiformat/index.html?partnerId=' +
        partnerID +
        '&eventId=' +
        eventId +
        '&userId=' +
        userMd5 +
        '&key=' +
        md5;
    } else {
      url =
        'https://secure.mobile.codere.performgroup.com/streaming/wab/multiformat/index.html?partnerId=' +
        partnerID +
        '&eventId=' +
        eventId +
        '&userId=' +
        userMd5 +
        '&key=' +
        md5;
    }
    return this.watchbetGet(url);
  }

  protected watchbetGet(url: string, hideLoader?: boolean): Observable<any> {
    // eslint-disable-next-line no-empty
    if (hideLoader) {
    } else {
      this.events.publish('loading:start');
    }

    return this.httpClient.get(url, { responseType: 'text' }).pipe(
      map((res: any) => {
        this.trackingService.track({
          eventType: models.EventTypes.WatchAndBetServerResponse,
          secondParameter: url,
          description: res
        });

        return this.utils.xml2json(res);
      }),
      catchError(this.handleErrorImg),
      finalize(() => {
        // eslint-disable-next-line no-empty
        if (hideLoader) {
        } else {
          this.events.publish('loading:finish');
        }
      })
    );
  }

  getssisStreamingUrl(statisticsId: string, provider = 'betradar', country: string): Observable<any> {
    const url =
      this.globalVars.URLBASE_STREAMING +
      Constants.getStreamingURL +
      '?statisticsId=' +
      statisticsId +
      '&provider=' +
      provider +
      '&country=' +
      country;
    return this.myGet(url);
  }

  getNewSisStreamingUrl(streamId = ''): Observable<any> {
    let url = `${this.globalVars.URLBASE_STREAMING}${Constants.getSISStreamingURL}`;
    if (streamId != '') {
      url = `${url}?streamId=${streamId}`;
    }
    return this.myGet(url);
  }

  getBetGeniusStreamingUrl(streamId = ''): Observable<any> {
    let url = `${this.globalVars.URLBASE_STREAMING}${Constants.getGeniusStreamingURL}`;
    if (streamId != '') {
      url = `${url}?streamingId=${streamId}`;
    }
    return this.myGet(url);
  }

  composeIframeUrl(token: string, timestamp: string, id: number): string {
    const operatorID = this.globalVars.FEATURES.IMGOperatorId;
    const urlBase = `${this.globalVars.FEATURES.IMGUrlBase}${Constants.IMGStreamUrl}`;
    const url = urlBase + id + '/player/?operatorId=' + operatorID + '&auth=' + token + '&timestamp=' + timestamp;
    return url;
  }

  getLiveSport_Event(): Observable<models.C_SportsLive[]> {
    const url = this.globalVars.NAVIGATIONSERVICEBASEURL + 'home/getliveevents?languageCode=' + this.globalVars.Idioma;
    return this.myGet(url, true);
  }

  getLastMinute(): Observable<models.C_Event[]> {
    let url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getLastMinute}?languageCode=${this.globalVars.Idioma}`;

    const gameTypes = this.globalVars.gameTypes.getAll();
    url += '&gametypes=' + this.globalVars.gameTypes.serialize(gameTypes);

    return this.myGet(url, true).pipe(
      map((events: models.C_Event[]) =>
        events.map((event: models.C_Event) => {
          event = models.C_Event.parse(event);
          event.hasSixPack();
          event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          const auxGameTypes = [];
          if (event.SportHandle === 'basketball') {
            event.Games.map((game: models.C_Game) => {
              auxGameTypes.push(game.GameType);
            });
          }
          const eventGameTypes = this.globalVars.gameTypes.getOne(
            event.SportHandle,
            event.CountryCode,
            event.isLive,
            event.ParentNodeId,
            auxGameTypes
          );
          event.setGamesByPlatform(eventGameTypes, this.isMobile);
          if (event.hasSixPack) {
            event.sixPack = this.sportBookService.getSixPackMarket(event, eventGameTypes);
          }
          return event;
        })
      )
    );
  }

  getGameByNodeId(nodeId: string): Observable<any> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.JGetNode}?nodeid=${nodeId}&languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url);
  }

  private setLockedResults(ev: models.C_Event | models.C_EventLive) {
    ev.Games.forEach((game) => {
      if (!game) {
        return;
      }
      return game.Results.map((result) => (result.Locked = game.Locked || ev.Locked));
    });
  }

  private setHomeAndAway(Name: string, game) {
    let pitcherHome: string;
    let pitcherAway: string;
    if (!Name) {
      return;
    }
    const regexCleanPitcherName = /\S [A-Za-z]+/g;
    const regex = new RegExp(/\((.*?)\)/, 'g');
    if (Name.match(regex)) {
      [pitcherHome, pitcherAway] = Name.match(regex);
      if (pitcherHome && pitcherAway) {
        Name = Name.replace(pitcherHome, '').replace(pitcherAway, '');

        pitcherHome = pitcherHome.match(regexCleanPitcherName)[0];
        pitcherAway = pitcherAway.match(regexCleanPitcherName)[0];
      }
    }

    [game.teamHome, game.teamAway] = game.Name.split(' - ');
    return [game.teamHome, game.teamAway];
  }
  getHomeMainLeagueEvent(): Observable<Array<models.C_Event>> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.geHomeMainLeague}?languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url, true);
  }

  getRacesItems(sportHandle: string): Observable<models.SidebarSportItem> {
    return forkJoin([this.getRaces({ sportHandle, byPaddock: true }), this.getRaces({ sportHandle })]).pipe(
      map((res) => new models.SidebarSportItem(res[0], res[1]))
    );
  }

  getSportItems(sportNodeId: string): Observable<models.SidebarSportItem> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getSubmenuLeftUrl}?parentid=${sportNodeId}&nodes=5`;
    return this.myGet(url, true);
  }

  getBetsConfigData(): Observable<models.C_BetsData[]> {
    let url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.betsConfigData}`;
    const date = new Date().getTime();

    if (!this.globalVars.FEATURES.UseBetConfigDataBackEndpoint)
      url = `assets/sportsConfiguration/betsConfigData.json?v=${date}`;

    return this.myGet(url, true);
  }

  getGameTypesConfigData(): Observable<models.C_GameTypes> {
    const date = new Date().getTime();
    const url = `assets/sportsConfiguration/gameTypesData.json?v=${date}`;

    return this.myGet(url, true).pipe(
      map((data) => {
        this.globalVars.gameTypesConfigData = new models.C_GameTypes(data);
        this.globalVars.gameTypes = new models.C_GameTypes(data);
        return new models.C_GameTypes(data);
      })
    );
  }

  getPlayerPropsLeagues(): Observable<models.C_PlayerPropsLeagues[]> {
    //     103852954,  // ESP - La Liga
    //     103846129,  // ENG - Premier League
    //     103860942,  // GER - Bundesliga
    //     103856868,  // ITA - Serie A
    //     3381766890,  // NBA
    //     978215466,  // MLB
    //     978215262,  // MLB - Mexico
    //     1025083770, // NFL
    //     103849007,  // Soccer - Champions League
    //     2819844606, // NHL - Regular season
    //     2819833720  // BASKET - Euroliga

    const date = new Date().getTime();
    const url = `assets/sportsConfiguration/playerPropsLeagues.json?v=${date}`;

    return this.myGet(url, true).pipe(
      map((data: models.C_PlayerPropsLeagues[]) => {
        const leagues: models.C_PlayerPropsLeagues[] = [];
        data.map((league) => {
          leagues.push(new models.C_PlayerPropsLeagues(league));
        });

        return leagues;
      })
    );
  }

  public deepLinkToEvent(eventData: any) {
    const nodeId = eventData['Parent']['Parent'].NodeId;
    let parentNodeId = '';

    switch (eventData.SportHandle) {
      case 'horse_racing':
      case 'greyhound_racing':
        parentNodeId = eventData['Parent']['Parent']['Parent']['Parent'].NodeId;
        this.deepLinkToRaceEvent(parentNodeId, nodeId, eventData.SportHandle);
        break;
      default:
        parentNodeId = eventData['Parent']['Parent']['Parent'].NodeId;
        this.deepLinkToSportEvent(parentNodeId, nodeId);
        break;
    }
  }

  public deepLinkFromMarquee(parentNodeId: string, nodeId: string) {
    this.deepLinkToSportEvent(parentNodeId, nodeId);
  }

  private deepLinkToSportEvent(parentId: string, nodeId: string) {
    this.getEvents({ parentId }).subscribe((data) => {
      const eventSelected = data.find((item) => item.NodeId === nodeId);
      if (eventSelected) {
        let componentName;
        eventSelected.isLive ? (componentName = 'NowLiveDetailPage') : (componentName = 'MercadosPage');
        this.router.navigate([componentName], {
          state: { params: eventSelected }
        });
      }
    });
  }

  private deepLinkToRaceEvent(parentNodeId: string, nodeId: string, sportHandle: string) {
    this.globalVars.SportSelected = sportHandle === 'horse_racing' ? 'Carreras de Caballos' : 'Carrera de galgos';
    this.getRacesbyPaddock(sportHandle).subscribe((data) => {
      const paddockSelected = data.find((item) => item.ParentNodeId === parentNodeId);
      const raceSelected = paddockSelected.Races.find((item) => item.NodeId === nodeId);
      if (raceSelected) {
        // TODO
        // this.globalVars.gNav.push(HorsesMarketPage, { race: raceSelected });
      }
    });
  }

  addSmartMarketItem(items: any): Observable<SmartMarketInput> {
    let url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.betbuilderAddItemUrl}`;
    items.forEach((element, i) => {
      const add = i !== 0 ? '&' : '';
      url = `${url}${add}resultNodeIds=${element.NodeId}`;
    });
    return this.myGet2(url, false);
  }

  betbuilderCheck(getData): Observable<any> {
    const data = new Observable((observer) => {
      const response = getData;

      observer.next(response);
      observer.complete();
    });
    return data;
  }

  addBetbuilderItem(betbuilderId: string): Observable<any> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getBetbuilderResposeByBetBuilderIdUrl}${betbuilderId}`;
    return this.myGet2(url, false);
  }

  loadAuthorizationHeader() {
    this.getAuthorizationHeader().pipe(map((data) => this.saveAuthorizationHeader(data)));
  }

  getAuthorizationHeader() {
    const date = new Date().getTime();
    const url = `assets/sportsConfiguration/betsConfigData.json?v=${date}`;

    return this.myGet(url, false);
  }

  saveAuthorizationHeader(state) {
    if (state !== null && state !== '') {
      this.authorizationHeader$.next(state);
      localStorage.setItem('X-State-AuthHeader', state);
    }
  }

  getEventsCountry(
    parentId: string,
    category: any = null,
    sportHandle?: string,
    countryCode?: string,
    eventsSortMethod: EventsSortMethods = EventsSortMethods.Default
  ): Observable<models.C_Event[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getEventsUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      parentId: parentId,
      ...(category && category !== -1 && { category: category }),
      ...((!category || (category === -1 && sportHandle)) && {
        gameTypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(sportHandle))
      })
    };

    return this.myGet2(url, { headers, params }, false).pipe(
      map((leagueEvents: models.C_Event[]) => {
        leagueEvents = leagueEvents.map((event) => {
          event = models.C_Event.parse(event);
          event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          event.hasSixPack(category);
          const auxGameTypes = [];
          // if (event.SportHandle === "basketball" || event.SportHandle === "ice_hockey") {
          if (event.SportHandle === 'basketball') {
            event.Games.map((game: models.C_Game) => {
              auxGameTypes.push(game.GameType);
            });
          }
          const eventGameTypes = this.globalVars.gameTypes.getOne(
            event.SportHandle,
            event.CountryCode,
            event.isLive,
            event.ParentNodeId,
            auxGameTypes
          );
          event.setGamesByPlatform(eventGameTypes, this.isMobile, category > 0);

          if (event.isSixPack) {
            event.sixPack = this.sportBookService.getSixPackMarket(event, eventGameTypes);
          }

          event.Games = event.Games.map((game: models.C_Game) => {
            const g = models.C_Game.parse(game);
            g.Results = Object.assign([], orderBy(game.Results, 'SortOrder'));
            return g;
          });
          return event;
        });

        switch (eventsSortMethod) {
          case EventsSortMethods.None:
            return leagueEvents;
          case EventsSortMethods.Default:
          default:
            return leagueEvents.sort((a, b) => {
              if (a.Priority > b.Priority || (a.Priority === b.Priority && a.StartDate < b.StartDate)) return -1;

              if (b.Priority > a.Priority || (b.Priority === a.Priority && b.StartDate < a.StartDate)) return 1;

              return 0;
            });
        }
      })
    );
  }

  getEventsCountryBuffer(args): Observable<models.C_Event[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getEventsUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      parentId: args.parentId,
      ...(args.category && args.category !== -1 && { category: args.category }),
      ...((!args.category || (args.category === -1 && args.sportHandle)) && {
        gameTypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(args.sportHandle))
      })
    };

    return this.myGet2(url, { headers, params }, true).pipe(
      map((leagueEvents: models.C_Event[]) => {
        leagueEvents = leagueEvents.map((event) => {
          event = models.C_Event.parse(event);
          event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          event.hasSixPack(args.category);
          const auxGameTypes = [];
          if (event.SportHandle === 'basketball') {
            event.Games.map((game: models.C_Game) => {
              auxGameTypes.push(game.GameType);
            });
          }
          const eventGameTypes = this.globalVars.gameTypes.getOne(
            event.SportHandle,
            event.CountryCode,
            event.isLive,
            event.ParentNodeId,
            auxGameTypes
          );
          event.setGamesByPlatform(eventGameTypes, this.isMobile, args.category > 0);

          if (event.isSixPack) {
            event.sixPack = this.sportBookService.getSixPackMarket(event, eventGameTypes);
          }

          event.Games = event.Games.map((game: models.C_Game) => {
            const g = models.C_Game.parse(game);
            g.Results = Object.assign([], orderBy(game.Results, 'SortOrder'));
            return g;
          });
          return event;
        });

        switch (args.eventsSortMethod) {
          case EventsSortMethods.None:
            return leagueEvents;
          case EventsSortMethods.Default:
          default:
            return leagueEvents.sort((a, b) => {
              if (
                a.Priority > b.Priority ||
                (a.Priority === b.Priority && a.StartDate < b.StartDate) ||
                (a.Priority === b.Priority && a.StartDate === b.StartDate && a.Name < b.Name)
              )
                return -1;
              if (
                b.Priority > a.Priority ||
                (b.Priority === a.Priority && b.StartDate < a.StartDate) ||
                (b.Priority === a.Priority && b.StartDate === a.StartDate && b.Name < a.Name)
              )
                return 1;
              return 0;
            });
        }
      })
    );
  }

  /**
   *! deprecated
   * @param sporthandle
   * @returns
   */
  getSportBySportHandler(sporthandle: string): Observable<models.C_Country[]> {
    const url =
      this.globalVars.URLBASE +
      'home/GetSportBySportHandle?sporthandle=' +
      sporthandle +
      '&languageCode=' +
      this.globalVars.Idioma;
    return this.myGet(url);
  }

  getMarqueeData(): Observable<models.MarqueeItem[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getMarquee}`;
    if (this.staticStoreService.callPolling) return this.myGet(url);
    else return of([]);
  }

  getBetBuilderEvents(): Observable<models.MarqueeItem[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getGetBetBuilderEvents}`;
    if (this.staticStoreService.callPolling) return this.myGet(url);
  }

  getCodereFantasyCode(): Observable<any> {
    const url = `${this.globalVars.DOMAIN}/CodereFantasy/api${Constants.getCodereFantasyCode}`;
    return this.myGet2(url);
  }
  /**
   * ! SPORTS DATA SIMPLIFICATION
   */

  getSportsSportCountriesData(parentId: string): Observable<any> {
    return forkJoin([this.getHighlightCountries(parentId, 6), this.getCountries(parentId)]).pipe(
      map((res) => new storeModels.M_SportCountriesData(parentId, res[0], res[1]))
    );
  }

  getHomeData(params) {
    const headers = { language: this.globalVars.Idioma };
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${Constants.getHomeInfo}`;
    return this.myGet(url, true, { headers, params });
  }
}
