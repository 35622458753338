<div class="sb-betbuilder--block background-color-dark" *ngIf="!errorMessage">
  <div class="sb-betbuilder--item-left">
    <p class="sb-betbuilder--title color-light">{{ messageText }}</p>
  </div>
  <div class="sb-betbuilder--item-right">
    <button
      *ngIf="cuotaText"
      class="sb-betbuilder--button color-light background-color-primary border-color-primary"
      (click)="placeBet()"
    >
      <span class="color-light"> Cuota: {{ cuotaText | formatOdd}} </span>
      <b class="color-light text-16"> Añadir al Ticket </b>
    </button>
  </div>
</div>

<div class="sb-betbuilder--block background-color-dark" *ngIf="errorMessage">
  <div class="sb-betbuilder--item-left">
    <p class="sb-betbuilder--title color-light">{{ errorMessage }}</p>
  </div>
</div>
