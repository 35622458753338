/* eslint-disable eqeqeq */
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Component, Input, inject } from '@angular/core';
import { FormatOdd } from '@pipes/formatOdd.pipe';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { TruncateStringPipe } from '@pipes/truncateString.pipe';
import { NewBaseService } from '@providers/newBase.service';
import { Constants } from '@utils/constants';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, FormatOdd, ExtractDatePipe, TruncateStringPipe],
  selector: 'sb-bet-selection',
  templateUrl: './sb-bet-selection.html',
  styleUrls: ['./sb-bet-selection.scss']
})
export class SbBetSelectionComponent {
  @Input() event: any;
  @Input() betTypeNumber: string;
  @Input() ticketStatusNumber: string;
  @Input() ticketStatus: string;
  @Input() matrixExist: boolean = false;
  @Input() mode: string;
  @Input() accumulatorOpened: boolean;
  @Input() truncateValue: number;
  @Input() potentialWinnings: number;
  @Input() stake: number;

  betbuilderItems: any;
  betbuilderMode = Constants.betbuilderName;
  hideBetbuilderElements: boolean;
  MisApuestasNewModelFeatureFlagEnabled: boolean = false;

  SPO_MisApuestasNewModelFeatureFlagEnabled = false;
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.MisApuestasNewModelFeatureFlagEnabled = this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled;
    });
  }

  ngOnChanges() {
    /* console.log('AqUIIIII',this.event); */
    if (this.MisApuestasNewModelFeatureFlagEnabled) {
      this.getClearingStatusDescription(this.event.ClearingStatusNumber);
    }
    if (this.mode == Constants.betbuilderName) {
      this.betbuilderItems = this.accumulatorOpened ? 100 : 2;
    }
  }

  getSelectionStatus(ticketStatusNumber: string) {
    switch (ticketStatusNumber) {
      case '1':
        return 'color-primary';
      case '2':
        return 'color-lost';
      case '3':
        return 'color-lost';
      case '4':
        return 'color-secondary';
      case '5':
        return 'color-progress';
    }
    return ticketStatusNumber;
  }

  filterName(name) {
    return name.replace('<ST>', '');
  }

  checkSportName(sportName) {
    return (
      sportName === 'Galgos' ||
      sportName === 'Caballos' ||
      sportName.toLowerCase().search('horse') >= 0 ||
      sportName.toLowerCase().search('greyhound') >= 0
    );
  }

  private ticketClearingStatusDescription = [
    { id: '0', description: '' },
    { id: '1', description: 'Ganada' },
    { id: '2', description: '' },
    { id: '3', description: 'Perdida' },
    { id: '4', description: 'Abierta' },
    { id: '5', description: 'Cobrada' }
  ];
  ticketClearingStatus: string = '';
  public getClearingStatusDescription(id: string): void {
    const value = this.ticketClearingStatusDescription.find((v) => v.id == id);
    this.ticketClearingStatus = value != undefined ? value.description : '';
  }

  public getOddSum(): number {
    let sum = 0;
    this.event.forEach((e) => {
      sum += e.Odd;
    });
    return sum;
  }

  public getQuota(): number {
    return this.potentialWinnings / this.stake;
  }
}
