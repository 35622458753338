import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule, NavParams } from '@ionic/angular';
import { CasinoBalanceDetails } from '../../../models/csbgonlinecasinobonuses/Balance';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { ParseMoney } from '../../../pipes/parseMoney';
import { CASINO_PATHS } from '../../../lib/constants/routes';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';

@Component({
  selector: 'balance-detail',
  templateUrl: './balance-detail.html',
  styleUrls: ['./balance-detail.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ParseMoney]
})
export class BalanceDetail implements OnInit, OnDestroy {
  private subscription: Subscription;
  private subscriptionDetails: Subscription;
  @Input() details$: Observable<null | CasinoBalanceDetails>;
  details: null | CasinoBalanceDetails;
  isCasino: boolean = false;
  isMobile: boolean = false;
  isPanama: boolean = false;
  isMultiprovider: boolean = false;
  globalVars!: GlobalVarsModel;
  loading = true;

  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);

  /**
   * This vie is created from BalancesService.showBalanceDetails, which sends casino balance details in navParams
   * @param navParams
   */

  constructor(navParams: NavParams) {
    this.subscription = this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();
    this.isCasino = navParams.get('isCasino');
    this.isPanama = navParams.get('isPanama');
    this.isMultiprovider = this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses;
  }

  ngOnInit(): void {
    this.subscriptionDetails = this.details$?.subscribe((details) => {
      this.details = details;
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionDetails?.unsubscribe();
  }

  async goToLobbyAndCat() {
    const params = this.isMobile
      ? JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectMOB)
      : JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectPC);

    this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], { queryParams: params });
  }
}
