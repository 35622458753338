/* eslint-disable */
import { Component, Output, EventEmitter, ViewChild, ElementRef, Input, HostBinding, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription, BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { LineItem } from '../../../models/C_LineItem';
import { C_TicketApuestasNew, TicketMethods } from '../../../models/C_TicketApuestas';
import { ParseStakeMoney } from '../../../pipes/parseStakeMoney';
import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';

@Component({
  selector: 'sb-ticket-stake',
  templateUrl: './ticket-stake.html',
  styleUrls: ['./ticket-stake.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ParseStakeMoney]
})
export class TicketStakeComponent {
  @HostBinding('class.sb-ticket-stake')
  @Input()
  stakeAmount: string = '';
  @Input() currency: string;
  @Input() minBetValue: string;
  @Input() ticket: C_TicketApuestasNew;
  @Input() lineItem: LineItem = null;
  @Input() finalRow: number = null;
  @Input() stakeAmountAux: BehaviorSubject<string> = new BehaviorSubject(null);
  @Input() disabled: boolean;
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() unselect: EventEmitter<any> = new EventEmitter();
  @ViewChild('stake') stake: ElementRef;
  stakeLabel: string = 'Apuesta';

  private subscription$: Subscription = new Subscription();
  private _selected: boolean = false;
  private _prevStake: string;
  parseStakeMoney = inject(ParseStakeMoney);

  private newTicketFacade = inject(NewTicketFacade);

  constructor() {}

  ngOnChanges() {
    if (this.newTicketFacade.changingStake$.getValue()) return;
    if (this.lineItem && !this.finalRow) {
      this.stakeAmountAux.next(this.lineItem.Amount.toString());
      this.stakeAmount = this.lineItem.Amount.toString();
      this.newTicketFacade.changingStake$.next(false);
    } else if (this.finalRow) {
      this.stakeAmountAux.next(this.finalRow.toString());
      this.stakeAmount = this.finalRow.toString();
      this.newTicketFacade.changingStake$.next(false);
    }
  }

  ngAfterViewInit() {
    //Observable.fromEvent --> fromEvent
    const update$ = fromEvent(this.stake.nativeElement, 'keyup')
      .pipe(
        tap((event: any) => {
          this.stakeAmountAux.next(event.target.value);
          this.newTicketFacade.changingStake$.next(true);
        })
      )
      .pipe(
        distinctUntilChanged(),
        debounceTime(2000),
        filter(Boolean),
        filter((amount) => !Number.isNaN(amount))
      )
      .subscribe((event: any) => {
        let inputAmount = event.target.value;
        let amount;

        if (!!this.ticket) {
          switch (this.ticket.BetType) {
            case 2: // Multi
              amount = this.setSystemAmountController(inputAmount);
              break;
            case 1: // Acumulator
            default: // Singles
              amount = this.setSingleAmountController(inputAmount);
              break;
          }
        }

        let systemId = this.systemId();
        let data = {
          amount,
          betType: this.ticket.BetType,
          systemId: systemId,
          lineItem: this.lineItem,
          lineItems: this.newTicketFacade.getAllItems(),
          multiSingleAmount: TicketMethods.getNumberOfSelections(this.ticket)
        };

        this.update.emit(data);

        if (this.finalRow && +this.stakeAmount <= +this.minBetValue && data.betType == 0) {
          let betAmount = +this.minBetValue * TicketMethods.getNumberOfSelections(this.ticket);
          this.stakeAmountAux.next(betAmount.toString());
          this.stakeAmount = betAmount.toString();
        }
      });

    const select$ = fromEvent(this.stake.nativeElement, 'focus')
      .pipe(
        tap((e: any) => {
          this._prevStake = this.stakeAmount;
          this.stakeAmount = null;
          e.target.value = null;
          this.stakeAmountAux.next(null);
        }),
        filter((_) => !this._selected)
      )
      .subscribe(() => {
        this.newTicketFacade.changingStake$.next(true);
        this._selected = true;
        this.select.emit();
      });

    const blur$ = fromEvent(this.stake.nativeElement, 'blur').subscribe(() => {
      if (!this.stakeAmount) {
        this.stakeAmount = this._prevStake;
        this.newTicketFacade.changingStake$.next(false);
      }
      this.stakeAmountAux.next(this.stakeAmount.toString());
      this._selected = false;
      this.unselect.emit();
    });

    this.subscription$.add(update$);
    this.subscription$.add(select$);
    this.subscription$.add(blur$);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  @HostBinding('class.currency-right') get currencyEuro() {
    return this.currency === '€';
  }

  @HostBinding('class.currency-left') get currencyDollar() {
    return this.currency === '$';
  }

  @HostBinding('class.text-small')
  get prueba() {
    return this.stakeAmount && this.stakeAmount.toString().length > 6;
  }

  setSingleAmountController(inputAmount: string) {
    let amount = parseFloat(inputAmount) > parseFloat(this.minBetValue) ? inputAmount.toString() : this.minBetValue;
    this.stakeAmount = amount;
    this.stakeAmountAux.next(amount);
    return amount;
  }

  setSystemAmountController(inputAmount: string) {
    let minValue = this.ticket.SystemSelected.numberOfBetWays * parseFloat(this.minBetValue);
    let amount = parseFloat(inputAmount) > minValue ? inputAmount.toString() : minValue.toString();
    this.stakeAmount = amount;
    this.stakeAmountAux.next(amount);
    return amount;
  }

  systemId() {
    let systemId: number;

    switch (this.ticket.BetType) {
      case 0:
        systemId = 1;
        break;
      case 1:
        systemId = this.ticket.StakeGroups.Accumulator.systemId;
        break;
      case 2:
        systemId = this.ticket.StakeGroups.System.systemId;
        break;

      default:
        break;
    }

    return systemId;
  }
  getTicketHasFreebets(ticket: C_TicketApuestasNew): boolean {
    return TicketMethods.getHasFreebet(ticket);
  }
}
