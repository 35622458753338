<!-- eslint-disable -->
<div class="sb-ticket--filter background-color-regular">
  <ng-content select="sb-filter" [elementDisabled]="keyboard"></ng-content>
</div>

<div class="my-bets-betslip--empty-state background-color-dark color-grey-400" *ngIf="canShowEmpty">
  {{ emptyMessage }}
</div>

<div class="my-bets-betslip--container">
  <ion-list
    class="my-bets-betslip--content background-color-dark"
    [hidden]="!utilsMyBetsService?.arrTickets || utilsMyBetsService?.arrTickets?.length == 0"
    *ngFor="let ticket of utilsMyBetsService?.arrTickets"
    no-lines
  >
    <div *ngIf="ticket?.TicketsDatetime && ticket?.betHistoryInfo?.length > 0" class="my-bets-betslip--date">
      {{ ticket?.TicketsDatetime | extractDate: [1] }}
    </div>

    <sb-bet-tile
      [selectedFilter]="selectedFilter"
      [ticket]="ticket"
      [typeCard]="'sb-bet-tile--bet-slip'"
      (openTicket)="utilsMyBetsService?.getTicket($event)"
      (eventSelection)="utilsMyBetsService?.tryToGoToEvent($event)"
      (cashout)="utilsMyBetsService?.payOutTicket($event)"
    >
    </sb-bet-tile>
  </ion-list>
  <div *ngIf="showMoreActive" class="my-bets-betslip--show-more background-color-dark">
    <button class="my-bets-betslip--show-more-button color-light border-color-white" (click)="showMore()">
      <span class="my-bets-betslip--show-more-text text-uppercase">Ver más</span>
    </button>
  </div>
</div>
