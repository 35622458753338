/* eslint-disable */
import { Component, Input, Output, EventEmitter, SimpleChanges, HostBinding, ElementRef } from '@angular/core';
import { TicketSelectionMode } from '../../../models/sports/ticket-selection-mode.enum';
import { C_TicketApuestasNew, TicketMethods } from '../../../models/C_TicketApuestas';
import { SmartLineItem } from '../../../models/C_SmartLineItem';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';
import { ParseStakeMoney } from '../../../pipes/parseStakeMoney';
import { TranslateModule } from '@ngx-translate/core';
import { TicketStakeComponent } from '../ticket-stake/ticket-stake';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ticket-betbuilder',
  templateUrl: './ticket-betbuilder.html',
  styleUrls: ['./ticket-betbuilder.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, FormatOdd, TranslateModule, ParseStakeMoney, TicketStakeComponent]
})
export class TicketBetbuilderComponent {
  @Input() smartLineItem: SmartLineItem;
  @Input() mode: TicketSelectionMode;
  @Input() isTicketClosed: boolean = false;
  @Input() isDesktop: boolean;
  @Input() license: number;
  @Input() minBetValue: string;
  @Input() currency: string;
  @Input() keyboard: boolean;
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled: boolean;
  @Output() updateAmount: EventEmitter<any> = new EventEmitter();
  @Output() selectElement: EventEmitter<any> = new EventEmitter();
  @Output() unselect: EventEmitter<any> = new EventEmitter();
  @Output() deleteSmartMarket: EventEmitter<SmartLineItem> = new EventEmitter();
  @Output() scrollTo: EventEmitter<any> = new EventEmitter();
  keyEvent: EventEmitter<any> = new EventEmitter();
  potentialWin: any;
  currencyLeft: boolean = false;

  constructor(private _host: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.smartLineItem) return;
    this.potentialWin = (this.smartLineItem.AmountLocal * this.smartLineItem.Odd).toFixed(2);
  }

  ngAfterViewInit() {
    this.currencyLeft = this.currency === '$';
  }

  public remove() {
    this.deleteSmartMarket.emit(this.smartLineItem);
  }

  public selectAmount() {
    if (!this.smartLineItem) return;
    if (this.smartLineItem.Selected && this.keyboard) return;
    this.selectElement.emit(this.smartLineItem);
    this.scrollTo.emit(this._host.nativeElement.offsetTop);
  }

  public unselectLineItem() {
    if (!this.smartLineItem) return;
    this.unselect.emit(this.smartLineItem);
  }

  public updateStake(event: any) {
    this.selectAmount();
    this.updateAmount.emit(event);
  }

  get multiSingleActivated() {
    return this.ticket.BetType === 0 && TicketMethods.getNumberOfSelections(this.ticket) >= 2 && !this.isTicketClosed;
  }

  @HostBinding('attr.mode')
  get selectionState() {
    if (!this.smartLineItem) return;
    let state = null;
    const lineErrors = this.smartLineItem.LineErrorMessages || [];
    if (lineErrors.length) {
      state = 'selection-error';
    }

    if (this.smartLineItem.GameLocked) {
      state = 'selection-locked';
    }

    return state;
  }
}
