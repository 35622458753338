<ng-container *ngIf="!isMobile; else mobileView">
  <ion-grid class="tablet-common-grid">
    <ion-row no-padding no-margin>
      <ion-col [class.leftMenuOpen]="globalVars?.leftMenuOpen" no-padding>
        <ion-content [class.leftMenuOpen]="globalVars?.leftMenuOpen">
          <div class="sb-sticky">
            <sb-navbar
              theme="sportbook"
              class="sb-navbar--sub-page"
              [hasBackButton]="true"
              [shrinkable]="false"
              [scrollArea]="content"
            >
              <h4 class="sb-back--title color-light">
                <span *ngIf="!!sportsbookFacade.selectedMarket">
                  {{ sportsbookFacade.selectedMarket?.SportName }} /
                </span>
                {{ sportsbookFacade.selectedMarket?.LeagueName }}
              </h4>
            </sb-navbar>

            <sb-prematch-scoreboard [scoreboard]="scoreboard"> </sb-prematch-scoreboard>
            <sb-filter
              *ngIf="categories && categories?.length > 1"
              [filters]="categories"
              [selected]="currentCategory$ | async"
              (filter)="changeCategory($event)"
            >
            </sb-filter>

            <sb-betbuilder
              *ngIf="currentCategory$?.value?.CategoryId == betbuilderCatId"
              [cuotaText]="betbuilderCuotaText"
              [messageText]="betbuilderMessageText"
              [errorMessage]="betbuilderErrorMessage"
              [MASelections]="betbuilderSelection"
              (placeBetbuilderBet)="sendAddSmartMarketItem()"
            >
            </sb-betbuilder>
          </div>

          <!-- <div *ngIf="betSenseData?.iframeEnabled" [hidden]="!betSenseData?.iframeVisible">
            <iframe [src]="betSenseData?.iframeUrl" class="betsense-iframe"> </iframe>
          </div> -->

          <ion-grid *ngIf="marketBets$ | async as marketBets" class="sb-grid-desktop">
            <ion-row>
              <ion-col class="sb-dropdown--container" size="6">
                <ng-container *ngFor="let marketBet of marketBets; let index = index; let even = even; let odd = odd">
                  <sb-dropdown
                    [mode]="dropdownMode.MARKET_DESKTOP"
                    *ngIf="even"
                    [title]="marketBet?.Name"
                    [contentType]="marketBet?.DisplayTypeName"
                    [index]="-1"
                  >
                    <sb-grid-header
                      *ngIf="marketBet?.isCombined || marketBet?.isHandicap"
                      [titleLeft]="marketBet?.teamHome"
                      [titleCenter]="marketBet?.titleCenter"
                      [titleRight]="marketBet?.teamAway"
                      [market]="marketBet"
                    >
                    </sb-grid-header>

                    <sb-grid-item
                      *ngIf="!marketBet?.isCombined || marketBet?.isHandicap"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      (navigate)="navigate(marketBet)"
                      [betType]="marketBet?.DisplayTypeName"
                    >
                      <div
                        [betType]="marketBet?.DisplayTypeName"
                        *ngIf="gametypes.indexOf(marketBet?.GameType) == -1"
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                      >
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '1', getPosition(marketBet?.GroupResults.length, index))"
                          [selected]="checkSelected(result?.NodeId)"
                          mode="expanded"
                          [home]="scoreboard?.home"
                          [away]="scoreboard?.away"
                          [sport]="market?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>

                      <div
                        [betType]="marketBet?.DisplayTypeName"
                        *ngIf="gametypes.indexOf(marketBet?.GameType) != -1"
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                      >
                        <div class="sb-grid-item--column is-final-score">
                          <sb-button
                            *ngFor="let result of marketBet?.GroupResults[0]; let index = index"
                            [type]="marketBet?.GameType"
                            [typeName]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [locked]="result?.Locked"
                            [index]="index"
                            (bet)="bet($event, marketBet, marketBet?.Name, '2')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>

                        <div class="sb-grid-item--column is-final-score">
                          <sb-button
                            *ngFor="let result of marketBet?.GroupResults[1]; let index = index"
                            [type]="marketBet?.GameType"
                            [typeName]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [locked]="result?.Locked"
                            [index]="index"
                            (bet)="bet($event, marketBet, marketBet?.Name, '3')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>

                        <div class="sb-grid-item--column is-final-score">
                          <sb-button
                            *ngFor="let result of marketBet?.GroupResults[2]; let index = index"
                            [type]="marketBet?.GameType"
                            [typeName]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [locked]="result?.Locked"
                            [index]="index"
                            (bet)="bet($event, marketBet, marketBet?.Name, '4')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </div>
                    </sb-grid-item>

                    <!-- COMBINED BETS HARD-->
                    <span *ngFor="let market of marketBet?.combinedResults; let indexCombinedResults = index">
                      <sb-grid-item
                        *ngIf="marketBet?.isHardCombined"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[0]" content>
                        </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          [betType]="marketBet?.DisplayTypeName"
                          bet-display
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of (market?.results)[0]"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]=""
                            (bet)="bet($event, marketBet, marketBet?.Name, '5')"
                            [selected]="checkSelected(result?.NodeId)"
                            [locked]="result?.Locked"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>
                      <sb-grid-item
                        *ngIf="marketBet?.isHardCombined"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[1]" content>
                        </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          [betType]="marketBet?.DisplayTypeName"
                          bet-display
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of (market?.results)[1]"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]=""
                            [locked]="result?.Locked"
                            (bet)="bet($event, marketBet, marketBet?.Name, '6')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>

                      <!-- COMBINED BETS  EASY-->
                      <sb-grid-item
                        *ngIf="marketBet?.isSoftCombined"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single [title]="market?.spov" content> </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          [betType]="marketBet?.DisplayTypeName"
                          bet-display
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of market?.results; let i = index"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]=""
                            (bet)="bet($event, marketBet, marketBet?.Name == 'Goleadores' ? getName(i, marketBet?.Name) : marketBet?.Name , '7')"
                            [selected]="checkSelected(result?.NodeId)"
                            [locked]="result?.Locked"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>
                      <!--SIX PACK GRID-->
                      <sb-grid-item
                        *ngIf="marketBet?.isSixPack"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" content>
                        </sb-grid-content-single>

                        <sb-button
                          *ngFor="let result of market?.results; let indexCombinedBets = index"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [locked]="result?.Locked"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [index]="indexCombinedBets"
                          (bet)="bet($event, marketBet, marketBet?.Name, '8')"
                          [selected]="checkSelected(result?.NodeId)"
                          [home]="scoreboard?.home"
                          [away]="scoreboard?.away"
                          [sport]="market?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </sb-grid-item>
                    </span>
                  </sb-dropdown>
                </ng-container>
              </ion-col>

              <ion-col class="sb-dropdown--container" size="6">
                <ng-container *ngFor="let marketBet of marketBets; let index = index; let even = even; let odd = odd">
                  <sb-dropdown
                    [mode]="dropdownMode.MARKET_DESKTOP"
                    *ngIf="odd"
                    [title]="marketBet?.Name"
                    [contentType]="marketBet?.DisplayTypeName"
                    [index]="-1"
                  >
                    <sb-grid-header
                      *ngIf="marketBet?.isCombined || marketBet?.isHandicap"
                      [titleLeft]="marketBet?.teamHome"
                      [titleCenter]="marketBet?.titleCenter"
                      [titleRight]="marketBet?.teamAway"
                      [market]="marketBet"
                    >
                    </sb-grid-header>

                    <sb-grid-item
                      *ngIf="!marketBet?.isCombined || marketBet?.isHandicap"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      (navigate)="navigate(marketBet)"
                      [betType]="marketBet?.DisplayTypeName"
                    >
                      <div
                        [betType]="marketBet?.DisplayTypeName"
                        *ngIf="gametypes.indexOf(marketBet?.GameType) == -1"
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                      >
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults; let index = index"
                          [type]="marketBet?.GameType"
                          [locked]="result?.Locked"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '9')"
                          [selected]="checkSelected(result?.NodeId)"
                          mode="expanded"
                          [home]="scoreboard?.home"
                          [away]="scoreboard?.away"
                          [sport]="market?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>

                      <div
                        [betType]="marketBet?.DisplayTypeName"
                        *ngIf="gametypes.indexOf(marketBet?.GameType) !== -1"
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                      >
                        <div class="sb-grid-item--column is-final-score">
                          <sb-button
                            *ngFor="let result of marketBet?.GroupResults[0]; let index = index"
                            [type]="marketBet?.GameType"
                            [typeName]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [locked]="result?.Locked"
                            [index]="index"
                            (bet)="bet($event, marketBet, marketBet?.Name, '10')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>

                        <div class="sb-grid-item--column is-final-score">
                          <sb-button
                            *ngFor="let result of marketBet?.GroupResults[1]; let index = index"
                            [type]="marketBet?.GameType"
                            [typeName]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [locked]="result?.Locked"
                            [index]="index"
                            (bet)="bet($event, marketBet, marketBet?.Name, '11')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>

                        <div class="sb-grid-item--column is-final-score">
                          <sb-button
                            *ngFor="let result of marketBet?.GroupResults[2]; let index = index"
                            [type]="marketBet?.GameType"
                            [typeName]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [locked]="result?.Locked"
                            [index]="index"
                            (bet)="bet($event, marketBet, marketBet?.Name, '12')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </div>
                    </sb-grid-item>

                    <!-- COMBINED BETS HARD-->
                    <span *ngFor="let market of marketBet?.combinedResults; let indexCombinedResults = index">
                      <sb-grid-item
                        *ngIf="marketBet?.isHardCombined"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[0]" content>
                        </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          bet-display
                          [betType]="marketBet?.DisplayTypeName"
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of (market?.results)[0]"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [locked]="result?.Locked"
                            [results]=""
                            (bet)="bet($event, marketBet, marketBet?.Name, '13')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>
                      <sb-grid-item
                        *ngIf="marketBet?.isHardCombined"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[1]" content>
                        </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          [betType]="marketBet?.DisplayTypeName"
                          bet-display
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of (market?.results)[1]"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [locked]="result?.Locked"
                            [results]=""
                            (bet)="bet($event, marketBet, marketBet?.Name, '14')"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>
                      <!-- COMBINED BETS  EASY-->
                      <sb-grid-item
                        *ngIf="marketBet?.isSoftCombined"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" content>
                        </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          bet-display
                          [betType]="marketBet?.DisplayTypeName"
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of market?.results; let i = index"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]=""
                            [locked]="result?.Locked"
                            (bet)="bet($event, marketBet, marketBet?.Name, '15', getName(i, marketBet?.Name))"
                            [selected]="checkSelected(result?.NodeId)"
                            mode="expanded"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>
                      <!--SIX PACK GRID-->
                      <sb-grid-item
                        *ngIf="marketBet?.isSixPack"
                        [mode]="marketBet?.displayMode"
                        [event]="marketBet"
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" content>
                        </sb-grid-content-single>

                        <div
                          class="sb-grid-item--bets-group"
                          bet-display
                          [betType]="marketBet?.DisplayTypeName"
                          sb-markets
                        >
                          <sb-button
                            *ngFor="let result of market?.results; let indexCombinedBets = index"
                            [type]="marketBet?.DisplayTypeName"
                            [game]="result"
                            [isCombined]="marketBet?.isCombined"
                            [results]="marketBet?.GroupResults"
                            [index]="indexCombinedBets"
                            [locked]="result?.Locked"
                            (bet)="bet($event, marketBet, marketBet?.Name, '16')"
                            [selected]="checkSelected(result?.NodeId)"
                            [home]="scoreboard?.home"
                            [away]="scoreboard?.away"
                            [sport]="market?.SportHandle"
                            bets
                          >
                          </sb-button>
                        </div>
                      </sb-grid-item>
                    </span>
                  </sb-dropdown>
                </ng-container>
              </ion-col>
            </ion-row>
          </ion-grid>

          <codere-footer></codere-footer>
        </ion-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <codere-navbar></codere-navbar>
    <sb-navbar theme="sportbook" [hasBackButton]="true">
      <h4 class="sb-back--title color-light">
        {{ sportsbookFacade.selectedMarket?.SportName }} / {{ sportsbookFacade.selectedMarket?.LeagueName }}
      </h4>
    </sb-navbar>
    <sb-prematch-scoreboard [scoreboard]="scoreboard"></sb-prematch-scoreboard>
  </ion-header>

  <ion-content (ionScroll)="onScroll($event)" [ngClass]="{'no-scroll': globalVars?.iosScrollHide}" scrollEvents="true">
    <div class="sb-sticky sb-sticky-border-bottom">
      <div class="widgets" *ngIf="showSportRadar()">
        <div class="sr-widget sr-widget-1" #stickyWidgetSIR></div>
        <div class="sr-widget sr-widget-2" #standardWidgetSIR></div>
      </div>
      <div class="widgets" *ngIf="globalVars?.FEATURES.SPO_LMTFormula1 && showFormula1()">
        <div class="sr-widget sr-widget-1 mobileF1Widget" #stickyWidgetSIR2></div>
        <div class="sr-widget sr-widget-2" #standardWidgetSIR2></div>
      </div>
      <!-- <sb-tabs
        *ngIf="categories && categories?.length > 1"
        [categories]="categories"
        [color]="'regular'"
        [selectedTab]="currentCategory$ | async"
        (selectTab)="currentCategory$?.next($event)"
      ></sb-tabs> -->
      <sb-filter
        [filters]="categories$ | async"
        [selected]="currentCategory$ | async"
        (filter)="changeCategory($event)"
      ></sb-filter>

      <sb-betbuilder
        *ngIf="currentCategory$?.value?.CategoryId == betbuilderCatId"
        [cuotaText]="betbuilderCuotaText"
        [messageText]="betbuilderMessageText"
        [errorMessage]="betbuilderErrorMessage"
        [MASelections]="betbuilderSelection"
        (placeBetbuilderBet)="sendAddSmartMarketItem()"
      >
      </sb-betbuilder>
    </div>

    <!-- <div *ngIf="betSenseData?.iframeEnabled" [hidden]="!betSenseData?.iframeVisible">
      <iframe [src]="betSenseData?.iframeUrl" class="betsense-iframe"></iframe>
    </div> -->

    <ion-list class="events-list" *ngIf="marketBets$ | async as marketBets">
      <sb-dropdown
        *ngFor="let marketBet of marketBets; let index = index"
        [index]="-1"
        [title]="marketBet?.Name"
        [contentType]="marketBet?.DisplayTypeName"
        [mode]="marketBet"
      >
        <sb-grid-header
          *ngIf="marketBet?.isCombined || marketBet?.isHandicap"
          [titleLeft]="marketBet?.teamHome"
          [titleCenter]="marketBet?.titleCenter"
          [titleRight]="marketBet?.teamAway"
          [market]="marketBet"
        ></sb-grid-header>

        <sb-grid-item
          *ngIf="!marketBet?.isCombined || marketBet?.isHandicap"
          [mode]="marketBet?.displayMode"
          [event]="marketBet"
        >
          <div
            *ngIf="gametypes.indexOf(marketBet?.GameType) == -1"
            class="sb-grid-item--bets-group"
            bet-display
            sb-markets
            [betType]="marketBet?.DisplayTypeName"
          >
            <sb-button
              *ngFor="let result of marketBet?.GroupResults; let index = index"
              [type]="marketBet?.GameType"
              [typeName]="marketBet?.DisplayTypeName"
              [game]="result"
              [isCombined]="marketBet?.isCombined"
              [results]="marketBet?.GroupResults"
              [index]="index"
              (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-1')"
              [selected]="checkSelected(result?.NodeId)"
              [locked]="result?.Locked"
              mode="expanded"
              [home]="scoreboard?.home"
              [away]="scoreboard?.away"
              [sport]="market?.SportHandle"
              bets
            >
            </sb-button>
          </div>

          <div
            *ngIf="gametypes.indexOf(marketBet?.GameType) !== -1"
            class="sb-grid-item--bets-group"
            [betType]="marketBet?.DisplayTypeName"
            bet-display
            sb-markets
          >
            <div class="sb-grid-item--column is-final-score">
              <sb-button
                *ngFor="let result of marketBet?.GroupResults[0]; let index = index"
                [type]="marketBet?.GameType"
                [typeName]="marketBet?.DisplayTypeName"
                [game]="result"
                [isCombined]="marketBet?.isCombined"
                [results]="marketBet?.GroupResults"
                [locked]="result?.Locked"
                [index]="index"
                (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-2')"
                [selected]="checkSelected(result?.NodeId)"
                mode="expanded"
                [home]="scoreboard?.home"
                [away]="scoreboard?.away"
                [sport]="market?.SportHandle"
                bets
              >
              </sb-button>
            </div>

            <div class="sb-grid-item--column is-final-score">
              <sb-button
                *ngFor="let result of marketBet?.GroupResults[1]; let index = index"
                [type]="marketBet?.GameType"
                [typeName]="marketBet?.DisplayTypeName"
                [game]="result"
                [isCombined]="marketBet?.isCombined"
                [results]="marketBet?.GroupResults"
                [locked]="result?.Locked"
                [index]="index"
                (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-2')"
                [selected]="checkSelected(result?.NodeId)"
                mode="expanded"
                [home]="scoreboard?.home"
                [away]="scoreboard?.away"
                [sport]="market?.SportHandle"
                bets
              >
              </sb-button>
            </div>

            <div class="sb-grid-item--column is-final-score">
              <sb-button
                *ngFor="let result of marketBet?.GroupResults[2]; let index = index"
                [type]="marketBet?.GameType"
                [typeName]="marketBet?.DisplayTypeName"
                [game]="result"
                [isCombined]="marketBet?.isCombined"
                [results]="marketBet?.GroupResults"
                [locked]="result?.Locked"
                [index]="index"
                (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-3')"
                [selected]="checkSelected(result?.NodeId)"
                mode="expanded"
                [home]="scoreboard?.home"
                [away]="scoreboard?.away"
                [sport]="market?.SportHandle"
                bets
              >
              </sb-button>
            </div>
          </div>
        </sb-grid-item>

        <!-- COMBINED BETS-->
        <span *ngFor="let market of marketBet?.combinedResults; let indexCombinedResults = index">
          <!-- COMBINED BETS HARD-->
          <sb-grid-item
            *ngIf="marketBet?.isHardCombined"
            [mode]="marketBet?.displayMode"
            [event]="marketBet"
            [betType]="marketBet?.DisplayTypeName"
          >
            <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[0]" content>
            </sb-grid-content-single>
            <div class="sb-grid-item--bets-group" bet-display sb-markets [betType]="marketBet?.DisplayTypeName">
              <sb-button
                *ngFor="let result of (market?.results)[0]"
                [type]="marketBet?.DisplayTypeName"
                [game]="result"
                [isCombined]="marketBet?.isCombined"
                [results]=""
                (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-4')"
                [selected]="checkSelected(result?.NodeId)"
                [locked]="result?.Locked"
                mode="expanded"
                [home]="scoreboard?.home"
                [away]="scoreboard?.away"
                [sport]="market?.SportHandle"
                bets
              >
              </sb-button>
            </div>
          </sb-grid-item>
          <sb-grid-item
            *ngIf="marketBet?.isHardCombined"
            [mode]="marketBet?.displayMode"
            [event]="marketBet"
            [betType]="marketBet?.DisplayTypeName"
          >
            <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[1]" content>
            </sb-grid-content-single>

            <div class="sb-grid-item--bets-group" bet-display sb-markets [betType]="marketBet?.DisplayTypeName">
              <sb-button
                *ngFor="let result of (market?.results)[1]"
                [type]="marketBet?.DisplayTypeName"
                [game]="result"
                [isCombined]="marketBet?.isCombined"
                [results]=""
                [locked]="result?.Locked"
                (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-5')"
                [selected]="checkSelected(result?.NodeId)"
                mode="expanded"
                [sport]="market?.SportHandle"
                [home]="scoreboard?.home"
                [away]="scoreboard?.away"
                bets
              >
              </sb-button>
            </div>
          </sb-grid-item>
          <!-- COMBINED BETS  EASY-->
          <sb-grid-item
            *ngIf="marketBet?.isSoftCombined"
            [mode]="marketBet?.displayMode"
            [event]="marketBet"
            [betType]="marketBet?.DisplayTypeName"
          >
            <sb-grid-content-single
              *ngIf="marketBet?.isCombined"
              [title]="market?.spov"
              content
            ></sb-grid-content-single>

            <div class="sb-grid-item--bets-group" bet-display sb-markets [betType]="marketBet?.DisplayTypeName">
              <sb-button
                *ngFor="let result of market?.results; let i = index"
                [type]="marketBet?.DisplayTypeName"
                [game]="result"
                [isCombined]="marketBet?.isCombined"
                [results]=""
                (bet)="bet($event, marketBet, marketBet?.Name, 'MOBILE-6', getName(i, marketBet?.Name))"
                [selected]="checkSelected(result?.NodeId)"
                [locked]="result?.Locked"
                mode="expanded"
                [home]="scoreboard?.home"
                [away]="scoreboard?.away"
                [sport]="market?.SportHandle"
                bets
              >
              </sb-button>
            </div>
          </sb-grid-item>
        </span>
      </sb-dropdown>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
