<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content>
    <iframe data-tap-disabled="true" class="iframeFullScreen" [src]="srcUrl" scrolling="yes"></iframe>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-toolbar class="terms">
      <ion-grid style="margin: 10px">
        <ion-row class="ion-justify-content-center">
          <ion-col size="6" style="padding-right: 1px">
            <ion-button expand="full" color="light" class="btnCancel" (click)="accept(false)"
              >{{'tCancel' | translate }}</ion-button
            >
          </ion-col>
          <ion-col size="6" style="padding-left: 1px">
            <!-- Mejora: Cambio en ion-button LEIDO Y ACEPTO por {{'tAccept' | translate }}  -->
            <ion-button expand="full" class="btnAccept" (click)="accept(true)"> {{'tAccept' | translate }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-content>
    <iframe data-tap-disabled="true" class="iframeFullScreen" [src]="srcUrl" scrolling="yes"></iframe>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-toolbar class="terms">
      <ion-grid style="margin: 10px">
        <ion-row class="ion-justify-content-center">
          <ion-col size="6" style="padding-right: 1px">
            <ion-button expand="full" size="small" color="light" class="btnCancel" (click)="accept(false)"
              >{{'tCancel' | translate }}</ion-button
            >
          </ion-col>
          <ion-col size="6" style="padding-left: 1px">
            <!-- Mejora: Cambio en ion-button LEIDO Y ACEPTO por {{'tAccept' | translate }} -->
            <ion-button expand="full" size="small" class="btnAccept" (click)="accept(true)"
              >{{'tAccept' | translate }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ng-template>
