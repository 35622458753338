<ng-container *ngIf="!isMobile && isEmpty">
  <ion-header>
    <div class="cs-header background-color-dark p-1">
      <h4 class="m-0 color-light"></h4>
      <i item-right class="color-light codere-icon icon-close" (click)="closeModal()"></i>
    </div>
  </ion-header>
  <ion-content [ngClass]="!scroll ? 'scroll-hidden background-light':''">
    <codere-footer *ngIf="isMobile"></codere-footer>
  </ion-content>
  <cs-document-indications
    (emitAccessFiles)="emitAccessFiles($event)"
    (emitAccessCamera)="emitAccessCamera($event)"
    *ngIf="cabaInstructions"
    [stepInstructions]="stepInstructions"
    (closeSelfieModal)="closeSelfieModal()"
  ></cs-document-indications>
</ng-container>

<ng-container *ngIf="!isMobile && !isEmpty">
  <ion-header>
    <div class="cs-header background-color-dark p-1">
      <h4 class="m-0 color-light">{{headerSection}}</h4>
      <i item-right class="color-light codere-icon icon-close" (click)="closeModal()"></i>
    </div>
    <sb-filter
      [switch]="true"
      [filters]="filters$ | async"
      [selected]="currentFilter$ | async"
      (filter)="changeFilter($event)"
      [currentPage]="'cashier'"
    >
    </sb-filter>
  </ion-header>
  <ion-content #content class="scroll-hidden">
    <div class="cs-full-h" [ngSwitch]="currentSection$ | async">
      <div class="cs-full-h" *ngSwitchCase="'deposit'">
        <page-cashier-deposit
          [paymentOptions]="paymentOptions$ | async"
          [sectionStep]="currentStep$ | async"
          (setSectionStep)="setSectionStep($event)"
          (paymentSelection)="setPayment($event)"
          (sendToDocuments)="setFilter(filters[3])"
          (trackingEvent)="setTrackingEvent($event)"
          (trackingTrack)="setTrackingTrack($event)"
          (keyboardOpen)="keyboardOpen($event)"
        >
        </page-cashier-deposit>
      </div>
      <div class="cs-full-h" *ngSwitchCase="'withdrawals'">
        <page-cashier-withdrawals
          [sectionStep]="currentStep$ | async"
          (setSectionStep)="setSectionStep($event)"
          (paymentSelection)="setPayment($event)"
          (sendToDocuments)="setFilter(filters[3])"
          (isLocalTicket)="setLocalTicket($event)"
          (trackingEvent)="setTrackingEvent($event)"
          (keyboardOpen)="keyboardOpen($event)"
        >
        </page-cashier-withdrawals>
      </div>
      <div *ngSwitchCase="'history'">
        <page-cashier-history
          (filterSelected)="scrollToTop()"
          (trackingEvent)="setTrackingEvent($event)"
          [myActivityFilter]="myActivityFilter"
        >
        </page-cashier-history>
      </div>
      <div *ngSwitchCase="'documents'">
        <page-cashier-documents
          #cashierDocuments
          (trackingTrack)="setTrackingTrack($event)"
          (openInstructionsModal)="openInstructionsModal($event)"
        >
        </page-cashier-documents>
      </div>
    </div>
    <codere-footer *ngIf="isMobile"></codere-footer>
  </ion-content>
  <cs-document-indications
    (emitAccessFiles)="emitAccessFiles($event)"
    (emitAccessCamera)="emitAccessCamera($event)"
    *ngIf="cabaInstructions"
    [stepInstructions]="stepInstructions"
    (closeSelfieModal)="closeSelfieModal()"
  ></cs-document-indications>
</ng-container>

<ng-container *ngIf="isMobile && !isEmpty">
  <ion-header>
    <sb-navbar
      [hasBackButton]="true"
      [fakeButton]="false"
      [nav]="navCtrl"
      [subPage]="true"
      (backSubPage)="backNavbar()"
      theme="sportbook"
      [currentPage]="'cashier'"
    >
      <h4 class="sb-back--title m-right-0 color-light">{{ headerSection}}</h4>
      <div class="cs-nav-breadcrumb" *ngIf="paymentSelected">
        <h4 class="sb-back--title mx-1 color-light">/</h4>
        <div
          class="cs-nav-breadcrumb--image"
          [ngClass]="(paymentSelected?.image == 'iconChargeLocal') ? 'cs-nav-breadcrumb--image-small' : ''"
        >
          <img src="assets/global/img/{{paymentSelected?.image}}.png" alt="" />
        </div>
        <h4 class="sb-back--title mx-1 color-light">{{ paymentSelected?.name }}</h4>
      </div>
    </sb-navbar>
    <sb-filter
      [switch]="true"
      [filters]="filters$ | async"
      [selected]="currentFilter$ | async"
      (filter)="changeFilter($event)"
      [currentPage]="'cashier'"
    >
    </sb-filter>
  </ion-header>
  <ion-content #content>
    <div class="cs-full-h" [ngSwitch]="currentSection$ | async">
      <div class="cs-full-h" *ngSwitchCase="'deposit'">
        <page-cashier-deposit
          [paymentOptions]="paymentOptions$ | async"
          [sectionStep]="currentStep$ | async"
          (setSectionStep)="setSectionStep($event)"
          (paymentSelection)="setPayment($event)"
          (sendToDocuments)="setFilter(filters[3])"
          (trackingEvent)="setTrackingEvent($event)"
          (trackingTrack)="setTrackingTrack($event)"
          (keyboardOpen)="keyboardOpen($event)"
        >
        </page-cashier-deposit>
      </div>
      <div class="cs-full-h" *ngSwitchCase="'withdrawals'">
        <page-cashier-withdrawals
          [sectionStep]="currentStep$ | async"
          (setSectionStep)="setSectionStep($event)"
          (paymentSelection)="setPayment($event)"
          (sendToDocuments)="setFilter(filters[3])"
          (isLocalTicket)="setLocalTicket($event)"
          (trackingEvent)="setTrackingEvent($event)"
          (keyboardOpen)="keyboardOpen($event)"
        >
        </page-cashier-withdrawals>
      </div>
      <div *ngSwitchCase="'history'">
        <page-cashier-history
          (filterSelected)="scrollToTop()"
          (trackingEvent)="setTrackingEvent($event)"
          [myActivityFilter]="myActivityFilter"
        >
        </page-cashier-history>
      </div>
      <div *ngSwitchCase="'documents'">
        <page-cashier-documents
          #cashierDocuments
          (trackingTrack)="setTrackingTrack($event)"
          [userFromIosApp]="userFromIos"
          (openInstructionsModal)="openInstructionsModal($event)"
        >
        </page-cashier-documents>
      </div>
    </div>
    <codere-footer [currentPage]="'cashier'"></codere-footer>
  </ion-content>
  <cs-document-indications
    (emitAccessFiles)="emitAccessFiles($event)"
    (emitAccessCamera)="emitAccessCamera($event)"
    *ngIf="cabaInstructions"
    [stepInstructions]="stepInstructions"
    (closeSelfieModal)="closeSelfieModal()"
  ></cs-document-indications>
</ng-container>
