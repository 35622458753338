import { Bonus } from '@models/csbgonlinecasinobonuses/Bonus';
import { StateKeys } from '@models/csbgonlinecasinobonuses/bonus-enums';
import { IonContent, IonicModule, NavController } from '@ionic/angular';
import { ViewChild, Component, Inject, forwardRef, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@providers/UserService';
import { TrackingService } from '@providers/TrackingService';
import { SlotsBonusesService } from '@providers/SlotsBonusesService';
import { Utils } from '@utils/Utils';
import { EventsService } from '@providers/EventsService';
import { DeviceService } from '@services/device.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SlotBonusDetail } from '../../components/slot-bonus-detail/slot-bonus-detail';
import { LobbyCasinoPage } from '@shared-casino-pages/lobby-casino/lobby-casino';
import { SlotsBonusDetailPage } from '../slots-bonus-detail/slots-bonus-detail';

@Component({
  selector: 'slots-bonus-page',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CodereFooterComponent,
    UserSectionTitle,
    SlotBonusDetail,
    TranslateModule,
    CodereNavbar
  ],
  templateUrl: './slots-bonus.html',
  styleUrls: ['./slots-bonus.scss']
})
export class SlotsBonusPage {
  @ViewChild(IonContent) content: IonContent;
  bonuses: Array<Bonus>;
  loadPending: boolean;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean;

  nav = inject(NavController);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  slotsBonusesService = inject(SlotsBonusesService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  router = inject(Router);
  deviceService = inject(DeviceService);

  constructor() {
    // @Inject(forwardRef(() => TrackingService))
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();

    this.bonuses = [];
    this.loadPending = true;
    this.getMyBonus();
  }

  ionViewWillEnter() {
    // todo this.globalVars.changePage(SlotsBonusPage);
    this.trackingService.trackEvent(['AccesToSlotsBonus', '', '', '', 'event']);
    this.events.publish('footer:refresh', this.content); // **+** Events y Content está deprecado
  }

  ionViewDidLoad() {
    this.events.publish('footer:refresh', this.content); // **+** Events y Content está deprecado
  }

  getMyBonus() {
    this.slotsBonusesService.getPlayerBonuses().subscribe(
      (data: Bonus[]) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            if (this.isValidState(data[i])) {
              this.content.scrollToTop(); // **+** Content está deprecado
              this.bonuses.push(data[i]);
            }
          }
          this.loadPending = false;
        }
      },
      (err) => {
        this.loadPending = false;
      }
    );
  }

  refresh() {
    this.bonuses = [];
    this.getMyBonus();
  }

  private isValidState(bonus: Bonus): boolean {
    return (
      //eslint-disable-next-line
      bonus.StateKey == StateKeys.Active || bonus.StateKey == StateKeys.Accepted || bonus.StateKey == StateKeys.Issue
    );
  }

  goToSlots() {
    this.trackingService.trackEvent(['GoToSlotsfromBonusPage', '', '', '', 'event']);
    let lobby = this.globalVars.FEATURES.Lobbies.find((lb: any) => lb.LobbyName.toLowerCase().indexOf('playtech') >= 0);
    lobby === undefined ? (lobby = this.globalVars.FEATURES.Lobbies[0].LobbyName) : (lobby = lobby.LobbyName);

    this.router.navigate(['/' + LobbyCasinoPage], { queryParams: lobby });
  }

  bonoDetails(bonus: any) {
    this.router.navigate(['/' + SlotsBonusDetailPage], { queryParams: { bono: bonus } });
  }
}
