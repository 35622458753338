<form class="cs-transfer p-1 w-100" [formGroup]="formHalcash">
  <div
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formHalcash?.get('pincode').valid"
    [class.error]="formHalcash?.get('pincode').invalid && formHalcash?.get('pincode').touched"
  >
    <input
      class="cs-transfer--input w-100 p-left-1"
      type="number"
      maxlength="4"
      placeholder="{{'tKey' | translate}}"
      formControlName="pincode"
    />
    <ion-icon class="p-right-1" name="information-circle-outline" tappable (click)="showInputInfo('claveSecreta')">
    </ion-icon>
  </div>

  <div
    class="cs-transfer--field background-color-white m-bottom-1 valid"
    [class.error]="formHalcash?.get('tlf').invalid && formHalcash?.get('tlf').touched"
  >
    <input
      class="cs-transfer--input w-100 p-left-1"
      type="text"
      placeholder="{{'pIMvl' | translate}}"
      formControlName="tlf"
    />
  </div>

  <div class="text-center p-bottom-2">
    <button
      class="cs-action-button background-color-primary"
      tappable
      (click)="emitWithdrawal()"
      [disabled]="!formHalcash?.valid"
    >
      <span class="cs-action-button--text color-light"> {{ 'uPCCob' | translate }} </span>
      <i class="color-light codere-icon icon-arrow-right"></i>
    </button>
  </div>
</form>
