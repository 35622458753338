import { C_Event, C_League, C_LeagueCategory, C_TodayLeagues } from '@models/index';
import { createAction, props } from '@ngrx/store';
import {
  eventDataModel,
  homeDataModel,
  marketDataModel,
  sportCountriesDataModel,
  todayDataModel
} from '../models/sportsbook.model';

// HOME
export const initHome = createAction('[Home Page] Init');
export const fetchHome = createAction('[Home Page] Fetch');
export const setHomeHighlighsSelectedSport = createAction(
  '[Home Page] Set Highlights Selected Sport',
  props<{ sport: string }>()
);
export const setHome = createAction(
  '[Home Page] Set',
  props<{ currentHomeData: homeDataModel; prevHomeData: homeDataModel }>()
);
export const stopHomePagePolling = createAction('[Home Page] Stop Home Polling');

// SPORT COUNTRIES
export const fetchSportCountries = createAction('[SportCountries Page] Fetch SportCountries', props<C_League>());
export const setSportCountries = createAction(
  '[SportCountries Page] Set SportCountries',
  props<sportCountriesDataModel>()
);

// EVENT
export const fetchEventMainCategories = createAction(
  '[Event Page] Fetch Event League Categories',
  props<eventDataModel>()
);
export const fetchEventGames = createAction('[Event Page] Fetch Event Games', props<eventDataModel>());
export const fetchEventSpecialMarketGames = createAction(
  '[Event Page] Fetch Event Special Market Games',
  props<eventDataModel>()
);
export const fetchEventMarketsCategories = createAction(
  '[Event Page] Fetch Event Markets Categories',
  props<eventDataModel>()
);
export const setEventSpecialMarketGames = createAction(
  '[Event Page] Set Event Special Market Games',
  props<eventDataModel>()
);
export const setEventMainSelectedCategory = createAction(
  '[Event Page] Set Event Main Selected Category',
  props<C_LeagueCategory>()
);
export const setEventGames = createAction('[Event Page] Set Event Games', props<eventDataModel>());
export const setEventMarketSelectedCategory = createAction(
  '[Event Page] Set Event Market Selected Category',
  props<eventDataModel>()
);
export const stopEventPagePolling = createAction('[Event Page] Stop Event Polling');
export const clearEvent = createAction('[Event Page] Clear Event Sports');

// MARKET
export const fetchMarketCategories = createAction('[Market Page] Fetch Categories', props<C_Event>());
export const setMarketSelectedCategory = createAction(
  '[Market Page] Set Market Selected Category',
  props<marketDataModel>()
);
export const fetchMarkets = createAction('[Market Page] Fetch Markes', props<marketDataModel>());
export const setMarkets = createAction('[Market Page] Set', props<marketDataModel>());
export const clearMarkets = createAction('[Market Page] Clear Market Sports');

// TODAY
export const fetchTodayCategories = createAction('[Today Page] Fetch Categories');
export const fetchTodaysDateSportLeagues = createAction(
  '[Today Page] Fetch Date Sport Leagues',
  props<todayDataModel>()
);
export const fetchTodayLeagueEvents = createAction('[Today Page] Fetch League Sport Events', props<C_TodayLeagues>());
export const setTodaySelectedCategory = createAction(
  '[Today Page] Set Today Selected Category',
  props<todayDataModel>()
);
export const setTodaySelectedDay = createAction(
  '[Today Page] Set Today Selected Day',
  props<{ selectedDay: number }>()
);
export const setTodaySelectedSport = createAction('[Today Page] Set Today Selected Sport', props<C_LeagueCategory>());
export const setToday = createAction('[Today Page] Set', props<todayDataModel>());
export const stopTodayPagePolling = createAction('[Today Page] Stop Today Polling');
export const clearToday = createAction('[Today Page] Clear Today Sports');

// RACE
export const fetchRace = createAction('[Race Page] Fetch Data', props<{ raceType: string; endOfToday: any }>());
export const setRace = createAction('[Race Page] Set Data', props<any>());
export const clearRace = createAction('[Race Page] Clear Race Data');

// LIVE
export const initLive = createAction('[Live Page] Init Data');
export const fetchLive = createAction('[Live Page] Fetch Data', props<{ sport: string }>());
export const setLive = createAction('[Live Page] Set Data', props<any>());
export const setLiveSelectedNav = createAction('[Live Page] Set Selected Nav', props<any>());
export const setLiveSelecteCategory = createAction('[Live Page] Set Selected Category', props<any>());
export const stopLivePagePolling = createAction('[Live Page] Stop Live Polling');
export const clearLive = createAction('[Live Page] Clear Live Sports');
