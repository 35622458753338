<div class="alert-wrapper background-color-white">
  <span class="cs-pending-payments--title color-alert">{{ 'textCancelCobOnDep' | translate }}</span>

  <div class="cs-pending-payments--container">
    <div class="cs-pending-payments--item m-bottom-2" *ngFor="let payment of pendingPayments">
      <div class="cs-pending-payments--header sb-alert--title text-center color-dark border-color-dark w-100">
        {{ 'tCanCobT1' | translate }} {{ payment?.Metodo }}
      </div>

      <div class="cs-pending-payments--row">
        <span class="sb-alert--text color-muted">{{ 'hDate' | translate }}:</span>
        <span class="sb-alert--subtitle color-dark">{{ payment?.Fecha | extractDate:[1] }}</span>
      </div>

      <div class="cs-pending-payments--row">
        <span class="sb-alert--text color-muted">{{ 'tImp' | translate }}:</span>
        <span class="sb-alert--subtitle color-dark">{{ payment?.Cantidad | parseMoney }}</span>
      </div>

      <div class="cs-pending-payments--row">
        <span class="sb-alert--text color-muted">{{ 'tRef' | translate }}</span>
        <span class="sb-alert--subtitle color-dark">{{ payment?.Referencia }}</span>
      </div>
      <div class="alert-button--column-group">
        <button class="sb-alert--button" tappable (click)="cancelPayment(payment)">
          {{ 'tCanCobCam' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="m-top-1 text-center">
    <span class="cs-pending-payments--back color-secondary" tappable (click)="close()"
      >{{ 'v2_volver' | translate }} a {{'uPCajero'|translate}}</span
    >
  </div>
</div>
