import { Component, ViewChild, inject, ChangeDetectorRef, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  NavController,
  NavParams,
  ModalController,
  MenuController,
  IonContent,
  Platform,
  IonicModule
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Observable, combineLatest, BehaviorSubject, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Pipes
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { TitleCasePipe } from '@pipes/titlecase/title-case.pipe';

// Directives
import { BetDisplayDirective } from '../../directives/bet-display/bet-display';

// Routes
import { CASINO_PATHS, MSO_PATHS, SPORTS_PATHS } from '../../lib/constants/routes';

// Components
import { CodereSliderComponent } from '../../components/mso/slider/codere-slider/codere-slider';
import { CodereSliderCsvComponent } from '../../components/mso/slider/codere-slider-csv/codere-slider-csv';
import { SbNavbarComponent } from '../../components/common/sb-navbar/sb-navbar';
import { SbNavbarItemComponent } from '../../components/common/sb-navbar-item/sb-navbar-item';
import { SbOverviewScoreSetsComponent } from '../../components/sports/sb-overview-score-sets/sb-overview-score-sets';
import { SbGridContentExpandedComponent } from '../../components/sports/sb-grid-content-expanded/sb-grid-content-expanded';
import { SbGridItemComponent } from '../../components/sports/sb-grid-item/sb-grid-item';
import { SbGridContentTeamsComponent } from '../../components/sports/sb-grid-content-teams/sb-grid-content-teams';
import { SbOverviewScoreRegularComponent } from '../../components/sports/sb-overview-score-regular/sb-overview-score-regular';
import { SbFilterMostBetComponent } from '../../components/sports/sb-filter-mostbet/sb-filter-mostbet';
import { SbGridContentSingleComponent } from '../../components/sports/sb-grid-content-single/sb-grid-content-single';
import { SbGridHeaderComponent } from '../../components/sports/sb-grid-header/sb-grid-header';
import { SbMarqueeComponent } from '../../components/sports/sb-marquee/sb-marquee';
import { SbMarqueeBetbuilderComponent } from '../../components/sports/sb-marquee-betbuilder/sb-marquee-betbuilder';
import { SbButtonComponent } from '../../components/sports/sb-button/sb-button';
import { CodereFooterComponent } from '../../components/mso/footer/codere-footer/codere-footer';

// Models
import { LicenseTypes } from '@models/MasterData';
import { SbNavbarItem } from '../../models/sportbook/sb-navbar-item.model';
import {
  C_Banners,
  C_Event,
  C_League,
  C_EventMostBet,
  CookieNames,
  I_NewCarousel,
  MarqueeItem,
  BetbuilderItem
} from '../../models/';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';

// Stores
import { NewTicketFacade } from '../../models/sports/store/facades/ticket.facade';
import { SportsbookFacade } from '../../models/sports/store/facades/sportsbook.facade';

// Services
import { NewBaseService } from '../../providers/newBase.service';
import { UserService } from '../../providers/UserService';
import { UpdatesService } from '../../providers/UpdatesService';
import { SportBookService } from '../../providers/SportBookService';
import { UserMigrationService } from '../../providers/UserMigrationService';
import { BufferFacade } from '../../providers/facades/buffer.facade';
import { TicketService } from '../../providers/TicketService';
import { CookiesService } from '../../providers/CookiesService';
import { EventsService } from '../../providers/EventsService';
import { SportService } from '../../providers/SportService';
import { StaticStoreService } from '../../providers/static-store.service';
import { DeviceService } from '../../services/device.service';
import { MobileBehaviorService } from '../../services/sports/mobileBehavior.service';

// Utils
import { Utils } from '../../utils/Utils';
import * as _ from '../../utils/utils.functions';

// Others
import { Constants } from '@constants';
import { temporaryPromotionConfig } from '../../assets/js/temporaryPromotions';
import { TrackingInfo, BasePage } from '../base/BasePage';
import { ITemporaryPromotionConfig } from '../../declarations/declarations';
import { homeDataModel } from '@models/sports/store/models/sportsbook.model';
import { SportsbookState } from '@models/sports/store/reducers/sportsbook.reducers';

@Component({
  selector: 'home-page',
  templateUrl: './home.html',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SbButtonComponent,
    SbNavbarComponent,
    SbNavbarItemComponent,
    SbGridItemComponent,
    TitleCasePipe,
    ExtractDatePipe,
    BetDisplayDirective,
    CodereSliderComponent,
    CodereSliderCsvComponent,
    SbGridContentTeamsComponent,
    SbOverviewScoreSetsComponent,
    SbOverviewScoreRegularComponent,
    SbGridContentExpandedComponent,
    SbGridContentSingleComponent,
    SbFilterMostBetComponent,
    SbGridHeaderComponent,
    SbMarqueeComponent,
    SbMarqueeBetbuilderComponent,
    CodereFooterComponent
  ],
  providers: [ExtractDatePipe, NavParams],
  styleUrls: ['./home.scss']
})
export class HomePage extends BasePage implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;

  @ViewChild('highlights') public highlights: ElementRef;

  // General
  globalVars!: GlobalVarsModel;
  polling$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  temporaryPromotionConfig: ITemporaryPromotionConfig = temporaryPromotionConfig;

  // Carrousel
  carousels$: Observable<SbNavbarItem[]>;
  showCarousel = true;
  sliderVisible = false;
  lstCarrousel: Array<any>;
  lstBanners: C_Banners[] = [];
  lstNewBanners: I_NewCarousel[] = [];

  MainLeagueEvents: Array<C_Event>;
  mainLeague: C_League;
  mainLeagueName = '';
  mainLeagueIcon = '';

  // Marquee
  marqueeData: MarqueeItem[] = [];

  // Directos
  LiveEventsCount: number = 0;
  liveEvents$: Observable<any>;

  // Betbuilder Marque
  betbuilderEventsData: BetbuilderItem[] = [];

  //Más Apostados New Behaviour
  sportFilters = [];
  sportFilterSelected = null;
  sportEvents: C_EventMostBet;
  betEvents$: Observable<any>;
  filtersMostBet$: BehaviorSubject<any[]>;
  currentFilter$: BehaviorSubject<any>;
  selectedMostBet$: Observable<C_EventMostBet>;
  currentSport$: BehaviorSubject<string>;

  newBaseService = inject(NewBaseService);
  extractDate = inject(ExtractDatePipe);
  events = inject(EventsService);
  platform = inject(Platform);
  navParams = inject(NavParams);
  utils = inject(Utils);
  nav = inject(NavController);
  menu = inject(MenuController);
  modalController = inject(ModalController);
  navCtrl = inject(NavController);
  bufferFacade = inject(BufferFacade);
  updatesService = inject(UpdatesService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  deviceService = inject(DeviceService);
  mobileBehavior = inject(MobileBehaviorService);
  sportsbookFacade = inject(SportsbookFacade);

  staticStoreService = inject(StaticStoreService);

  newTicketFacade = inject(NewTicketFacade);
  userService = inject(UserService);
  sportService = inject(SportService);
  ticketService = inject(TicketService);
  sportbookService = inject(SportBookService);
  cookiesService = inject(CookiesService);
  userMigrationService = inject(UserMigrationService);
  isMobile = false;
  isDesktop = false;
  homeSub$: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        })
      )
      .subscribe();

    this.isDesktop = this.deviceService.isDesktop();
    this.sliderVisible = this.globalVars.licenseType == LicenseTypes.Nacional;

    if (this.route?.queryParams) {
      this.route.queryParams.subscribe((params) => {
        if (params['codepromotion']) {
          this.globalVars.codePromotion = params['codepromotion'];
          this.cookiesService.setCookie(
            CookieNames.CoderePromotionCode,
            this.globalVars.codePromotion,
            3 * 24 * 60 * 60 * 1000,
            true
          );
        }
        if (params['addbet']) {
          //añadir apuesta al ticket
          //!CHECK METHOD IN MASTER
          this.addTicketBetFromHome(params['addbet']);
        }
        if (params['rpassword']) {
          //ir a recordar contraseña
          this.globalVars.gotorecovery = true;
          this.openLogin();
        }
        if (params['openlogin']) {
          const logincallback = () => {
            const urlafterlogin: string = params['afterloginurl'];
            if (urlafterlogin && this.globalVars.user.logged) {
              this.router.navigateByUrl(urlafterlogin);
            }
          };
          this.openLogin(logincallback);
        }
        if (
          (params['NodeId'] || params['nodeid']) &&
          (params[`Name`] || params['name']) &&
          (params[`SportName`] || params['sportname']) &&
          (params[`SportHandle`] || params['sporthandle'])
        ) {
          const league = {
            Name: params[`Name`] ? params[`Name`] : params['name'],
            NodeId: params['NodeId'] ? params['NodeId'] : params['nodeid'],
            SportHandle: params[`SportHandle`] ? params[`SportHandle`] : params['sporthandle']
          };
          this.globalVars.SportSelected = params[`SportName`] ? params[`SportName`] : params['sportname'];
          this.router.navigate(['/EventoPage'], {
            state: { params: league },
            queryParams: { animate: !this.isDesktop }
          });
        }
      });
    }

    this.menu.enable(false);

    this.MainLeagueEvents = [];
    this.lstCarrousel = [];
    this.globalVars.SportHandleSelected = '';

    this.events.subscribe('user:licenseChange', () => {
      // triggers when the license has changed, in login/logout/etc...
      this.loadBanners(true);
      this.loadCarrousel(true);
    });
    this.mainLeague = new C_League([], '', '', '', 0, '', false);

    this.filtersMostBet$ = new BehaviorSubject(null);
    this.currentFilter$ = new BehaviorSubject(null);
    this.currentSport$ = new BehaviorSubject(null);

    this.homeSub$ = this.sportsbookFacade.sportsbook$.subscribe((data: SportsbookState) => {
      this.marqueeData = data?.homeData?.currentHomeData?.marquee;
      this.LiveEventsCount = data?.homeData?.currentHomeData?.homeLiveEvents?.LiveEventsCount;
      this.liveEvents$ = of(data?.homeData?.currentHomeData?.homeLiveEvents?.LiveSport);
      this.betbuilderEventsData = data?.homeData?.currentHomeData?.betBuilder;
      this.betEvents$ = of(data?.homeData?.currentHomeData?.highlightsEvents);
      if (
        !this.sportbookService.compareTwoObjectArrays(
          this.sportFilters,
          data?.homeData?.currentHomeData?.highlightsEvents?.sports
        )
      ) {
        this.sportFilters = data?.homeData?.currentHomeData?.highlightsEvents?.sports;
      }
      this.sportFilterSelected = data?.homeData?.currentHomeData?.highlightsEvents?.selectedSport;
      this.sportEvents = data?.homeData?.currentHomeData?.highlightsEvents?.events.find(
        (i) => i.SportHandle == this.sportFilterSelected.SportHandle
      );
    });
  }

  ionViewWillEnter() {
    this.viewLoad();
    if (this.globalVars?.firstLoad) {
      this.trackingService.trackEvent(['loadHomePage', '', '', 'Carga de home', 'event']);
      this.globalVars.firstLoad = false;
    } else {
      this.trackingService.trackEvent(['goToIndexPage', '', '', 'Ir a la pagina index', 'event']);
    }
  }

  viewLoad() {
    this.loadBanners(!this.sliderVisible);
    if (!this.isDesktop) {
      this.loadCarrousel();
    }
    this.sportsbookFacade.initHomeData();
  }

  ionViewDidEnter() {
    if (this.globalVars.forceToOpenLogin && !this.globalVars.user.logged) {
      this.globalVars.forceToOpenLogin = false;
      this.openLogin();
    }
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: '/home',
      description: 'Home page',
      additionalData: null
    };
  }

  clickItemCarrouselSportBook(item: any) {
    const [link, label, additionalData] = [item.navigate, item.label, item.actionData];
    const linkPage = {
      page: link.split('?')[0],
      params: link.split('?')[1]
    };

    if (additionalData === 'LobbyCasino') {
      const lobbies = this.globalVars.rootScope.lobbies;
      const lobby = lobbies.find((lb) => lb.LobbyName === item.navigate);
      if (lobby) {
        this.globalVars.rootScope.openLobby(lobby);
      } else {
        this.router.navigate([`/${CASINO_PATHS.BASE}/${item.label}Page`], { queryParams: { lobby: item.navigate } });
      }
      this.trackingService.trackEvent(['OpenLobbyFromCarrusel', link, label, link, 'event']);
    } else if (additionalData === 'LobbyJackpots') {
      this.globalVars.rootScope.openJackpots();
    } else if (link === 'misApuestasPage') {
      this.router.navigate([`/${SPORTS_PATHS.MisApuestasPage}`], {
        state: { params: this.globalVars.rootScope.storedParams }
      });
    } else if (link === MSO_PATHS.ContactPage || link === MSO_PATHS.PromotionsPage) {
      this.router.navigate([`/${MSO_PATHS.BASE}/${item.navigate}`], {
        state: { params: this.globalVars.rootScope.storedParams }
      });
    } else {
      this.globalVars.rootScope.storedParams = this.globalVars.rootScope.getObjectParam(linkPage.params);
      this.globalVars.rootScope.tryToGoPage(linkPage.page);
      this.router.navigate([`/${linkPage.page}`], { state: { params: this.globalVars.rootScope.storedParams } });
    }
    this.globalVars.SportSelected = label;
    this.trackingService.trackEvent(['AccessFromCarrusel', label, linkPage.page, link, 'event']);
  }

  loadCarrousel(clearCache: boolean = false) {
    this.carousels$ = combineLatest(
      this.userService.getCarrousel(),
      this.globalVars.sportItemsId$,
      (carrousel: any, sportItemsId: any) => {
        const navbarItems = [];
        if (!sportItemsId) {
          return navbarItems;
        }
        carrousel?.items?.forEach((carrouselItem) => {
          let actionLink = '';
          switch (carrouselItem?.actionData) {
            case 'soccer':
            case 'basketball':
            case 'tennis':
            case 'american_football':
            case 'baseball':
              actionLink = this.setActionLink(carrouselItem?.actionLink, carrouselItem?.actionData, sportItemsId);
              break;
            case Constants.tournamentLiteral:
              actionLink = carrouselItem?.actionLink;
              break;
            default:
              actionLink = carrouselItem?.actionLink;
              break;
          }

          navbarItems.push(
            new SbNavbarItem(
              carrouselItem.label,
              carrouselItem.img,
              null,
              null,
              actionLink,
              null,
              null,
              carrouselItem.actionData
            )
          );
        });
        return navbarItems;
      }
    );
  }

  setActionLink(actionLink: string, sporthandle: any, sportItemsId: any) {
    const newLink = `${actionLink}?SportHandle=${sporthandle}&NodeId=${sportItemsId[sporthandle]?.id}&Name=${sportItemsId[sporthandle]?.name}`;
    return newLink;
  }

  loadBanners(clearCache = false) {
    if (clearCache || (this.globalVars.lstBanners && this.globalVars.lstBanners.length === 0)) {
      this.lstNewBanners = [];
      this.lstBanners = [];
      const sharepointImagesList: string = this.isDesktop ? 'bannerswebappPC' : 'Bannerswebapp';
      if (this.globalVars.FEATURES.NewCarouselActivated) {
        this.userService
          .getNewBanners(sharepointImagesList)
          .then((data: any) => {
            this.globalVars.lstBanners = data || [];
            this.lstNewBanners = data || [];
            this.sliderVisible = true;
            //refresh footer
            // this.events.publish('footer:refresh', this.content);
            // this.content.scrollToTop();
            this.changeDetectorRef.detectChanges();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.userService.getBanners(sharepointImagesList).subscribe({
          next: (data: any) => {
            this.lstBanners = data || [];
            this.globalVars.lstBanners = data || [];
            this.sliderVisible = true;
            //refresh footer
            // this.events.publish('footer:refresh', this.content);
            // this.content.scrollToTop();
            this.changeDetectorRef.detectChanges();
          },
          error: (error: any) => {
            console.error(error);
          }
        });
      }
    } else {
      this.sliderVisible = true;
    }
  }

  clickBanner(theBanner: C_Banners): void {
    if (theBanner.Target === '_self') {
      if (theBanner.Url.search('http') !== -1) {
        if (theBanner.Url.search(this.globalVars.DOMAIN) !== -1) {
          if (theBanner.Url.search('#') !== -1) {
            const num = theBanner.Url.search('#');
            const url = theBanner.Url.substring(num + 2);
            this.globalVars.rootScope.extTryToGoPage(url);
          }
        }
      } else {
        if (theBanner.Url.search('#') !== -1) {
          const num = theBanner.Url.search('#');
          const url = theBanner.Url.substring(num + 2);
          this.globalVars.rootScope.extTryToGoPage(url);
        } else if (theBanner.Url.search('addbet') !== -1) {
          const equal = theBanner.Url.search('=');
          const bets = theBanner.Url.substring(equal + 1);
          this.addTicketBetFromHome(bets);
        }
      }
    } else {
      window.open(theBanner.Url);
    }
  }

  openModalUser() {
    this.menu.open('right');
  }

  gotoLiveEvents() {
    this.router.navigate(['/DirectosPage']);
  }

  gotoMainLeague() {
    this.globalVars.SportSelected = '';
    this.router.navigate(['/EventoPage'], {
      state: { params: this.mainLeague },
      queryParams: { animate: !this.isDesktop }
    });
    this.trackingService.trackEvent([
      'SelectLeague_HomeMain',
      this.mainLeague.Name,
      this.mainLeague.SportHandle,
      this.mainLeague.NodeId,
      'event'
    ]);
  }

  bet(game: any, event: any, gameName: string, type: string) {
    const data = {
      ...game,
      EventDate: this.extractDate.transform(event.StartDate),
      EventName: event.Name,
      GameName: gameName
    };
    this.newTicketFacade.checkSelection(data, data.NodeId);
  }

  async openLogin(callback?: () => void) {
    if (this.globalVars.user.logged) {
      this.events.publish('load:afterlogin');
      return;
    }
    this.globalVars.rootScope.openLogin(callback);
  }

  public navigate(market: any, route?: string, sport?: any, gameType?: any) {
    if (gameType) {
      return;
    }

    if (market && !market.SportName && market.SportHandle === 'basketball') market.SportName = 'Baloncesto';

    switch (route) {
      case 'live':
        this.router.navigate(['/DirectosPage']);
        break;
      case 'market':
      case 'live-market':
        this.globalVars.SportSelected = market.SportName || sport;
        const component = market.isLive ? 'NowLiveDetailPage' : 'MercadosPage';
        const eventType = market.isLive ? 'pushEventLive' : 'pushEvent';
        this.router.navigate([`/${component}`], {
          state: { params: market }
        });
        this.trackingService.trackEvent([eventType, market.NodeId, window.location.hash, market.Name, 'event']);
        break;
    }
  }

  trackBy(index, item) {
    return item.NodeId;
  }

  addTicketBetFromHome(itemsString: string) {
    const items = itemsString.split('-');
    for (let i = 0; i < items.length; i++) {
      const game = { NodeId: items[i], IsLive: false };
      this.bet(game, null, '', 'addTicketBetFromHome');
    }

    this.trackingService.trackEvent(['AddBetExt', itemsString, '', 'A\u00F1adir via banner apuesta por id', 'event']);
  }

  openPromos() {
    this.router.navigate(['/PromotionsPage'], {
      queryParams: { url: this.temporaryPromotionConfig.promosInfoPageUrl }
    });
  }

  async openTemporaryPromotion() {
    this.trackingService.trackEvent([
      'OpenTemporaryPromotionFromHome',
      '',
      this.route.component.name, // TODO REVISAR QUE FUNCIONA  this.globalVars.currentPage.name
      '',
      'event'
    ]);
    if (this.isDesktop) {
      const modal = await this.modalController.create({
        component: 'ExternalPage',
        componentProps: {
          url: this.temporaryPromotionConfig.temporaryPromotionSiteUrl
        }
      });
      modal.present();
      modal.onDidDismiss().then((moreinfo) => {
        if (moreinfo) {
          this.router.navigate(['/PromotionsPage'], {
            queryParams: { url: this.temporaryPromotionConfig.promosInfoPageUrl }
          });
        }
      });
    } else {
      this.router.navigate(['/ExternalPage'], {
        queryParams: { url: this.temporaryPromotionConfig.temporaryPromotionSiteUrl }
      });
    }
  }

  setCurrentFilter(filter) {
    this.sportsbookFacade.setHomeHighlighsSelectedSport(filter.SportHandle);
  }

  onScroll(e) {
    this.mobileBehavior.scrolling$.next(true);
  }

  ionViewDidLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.sportsbookFacade.stopHomePagePolling();
  }
}
