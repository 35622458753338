/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, inject } from '@angular/core';
// TODO ELIMINAR Comprobar importación
import * as signalR from '@microsoft/signalr';
import { CONSTANTS } from '../constants';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { EventsService } from './EventsService';
import { NewBaseService } from './newBase.service';
import { BehaviorSubject } from 'rxjs';
import { AlertController, AlertOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { EventTypes } from '@models/TrackingEvents';
import { TrackingService } from './TrackingService';

@Injectable({ providedIn: 'root' })
export class SignalRService {
  public data: any;
  public broadcaster: EventEmitter<any> = new EventEmitter();
  private messages: string[] = []; //messages that we are listening
  public hubConnection: signalR.HubConnection = null;
  public hubConnections: signalR.HubConnection[] = [];
  public potChanged$ = new BehaviorSubject({ jackpotState: null });
  _globals!: GlobalVarsModel;

  isclosefake = false;

  //#region
  newBaseService = inject(NewBaseService);
  events = inject(EventsService);
  alertCtrl = inject(AlertController);
  translate = inject(TranslateService);
  httpClient = inject(HttpClient);
  sanitizer = inject(DomSanitizer);
  trackingService = inject(TrackingService);

  //#endregion

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this._globals = data;
    });
    this.events.subscribe('LobbyCasinoPage:true', (page) => {
      this.stopAll();
    });
  }

  //Connect to a hub
  public startConnection(type) {
    if (!this.hubConnections[type]) {
      let url;
      switch (type) {
        case CONSTANTS.jackpot:
          url = this._globals.urlJackpotServices + '/hub';
          break;
        case CONSTANTS.royalDegree:
          if (this._globals?.user?.id) {
            url = `${this._globals.urlRoyalDegree}/playernotificationwinningshub?userName=${this._globals.user.id}`;
          }
          break;
        default:
          url = this._globals.urlLiveGameData + '/hub';
      }
      if (url) {
        this.hubConnections[type] = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Debug)
          .withUrl(url)
          .withAutomaticReconnect()
          .build();

        this.hubConnections[type]
          .start()
          .then(() => console.info('Connection started: ' + type))
          .catch((err) => console.error('Error while starting connection: ' + err));
      }
    }
  }

  //add data events to be monitorized ttrough the broadcaster
  public addDataListener(name: string, type: any) {
    if (this.messages.indexOf(name) != -1) return; //already suscripted
    if (!this.hubConnections[type]) return; // evita un error de consola en AR
    this.messages.push(name);
    this.hubConnections[type].on(name, (data) => {
      const event = { type: name, payload: data };
      this.broadcaster.emit(event);
    });
  }

  //invoke a method in the server
  public invokeMethod = (method, type, data = null) =>
    this.hubConnections[type].invoke(method, data).catch((err) => console.error(err));

  public stop(type) {
    if (this.hubConnections[type]) {
      const tot = this.messages.length;
      for (let i = 0; i < tot; i++) this.hubConnections[type].off(this.messages[i]);
      this.messages = [];
      this.hubConnections[type].stop();
      this.hubConnections[type] = null;
    }
  }
  public stopAll() {
    this.stop(CONSTANTS.jackpot);
    this.stop(CONSTANTS.liveGames);
  }

  showPopupRoyalDegree(data: any) {
    if (this.isGameOpen()) {
      const gameWindow: Window = this._globals?.slotWindow?.window;
      const dataToSend = {
        text: this.getText(data?.transactionOrigin),
        id: data?.rowKey,
        eventType: 'winNotification'
      };
      gameWindow.postMessage(dataToSend, '*');
      gameWindow.addEventListener('message', (event) => {
        if (event?.data?.name === 'markMessageAsRead') {
          this.markMessageAsRead(event?.data?.id, data?.transactionOrigin);
        }
      });
    } else {
      this.openPopUp(data);
    }
    this.trackingService.track({
      eventType: EventTypes.playerNotificationWinig,
      description: `Player notification wining with row = ${data?.row} and origin = ${data?.transactionOrigin}`
    });
  }

  isGameOpen(): boolean {
    return !!this._globals.slotWindow && !!this._globals.slotWindow.window;
  }

  async openPopUp(data: any): Promise<void> {
    const msgAux: string = this.getText(data.transactionOrigin);
    const msg: any = this.sanitizer.bypassSecurityTrustHtml(msgAux);
    const alertOpt: AlertOptions = {
      header: this.translate.instant('playresponsibly'),
      cssClass: 'alertDNIExpired',
      message: '' + msg.changingThisBreaksApplicationSecurity,
      buttons: [
        {
          text: this.translate.instant('entender'),
          handler: () => {
            this.markMessageAsRead(data.rowKey, data.transactionOrigin);
          }
        }
      ]
    };
    const alert = await this.alertCtrl.create(alertOpt);
    const imgHeader = document.createElement('img');
    imgHeader.classList.add('icon-circle-info');
    imgHeader.src = 'assets/global/img/icon-circle-info.svg';
    const child = alert.getElementsByClassName('alert-title')[0];
    child.parentNode.insertBefore(imgHeader, child);
    await alert.present();
  }

  getText(transactionOrigin: string): string {
    let message = this.translate.instant('netwinsDefaultMessage');
    switch (transactionOrigin) {
      case 'Casino':
        message = this.translate.instant('netwinsCasinoMessage');
        break;
      case 'Betting':
        message = this.translate.instant('netwinsSportsMessage');
        break;
      case 'Cashier':
      default:
        break;
    }
    message = message.replace('\\n', '<br>');
    return message;
  }

  markMessageAsRead(rowKey: string, origin: string): void {
    const URL = `${this._globals.DOMAIN}/SportsMisc/api/PlayerNotification/MarkNotificationAsRead?notificationId=${rowKey}`;
    this.httpClient.post(URL, {}).subscribe();
    this.trackingService.track({
      eventType: EventTypes.playerNotificationWinig,
      description: `Player notification wining with row = ${rowKey} and origin = ${origin}`
    });
  }
}
