/* eslint-disable  */
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@providers/BaseService';
import { LogService } from '@providers/LogService';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';

export interface I_CodereCardQuestion {
  request: string;
  response: string;
}

@Injectable({ providedIn: 'root' })
export class CodereCardService extends BaseService {
  logService = inject(LogService);

  constructor() {
    super();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  getCodereCardQuestion(): Observable<I_CodereCardQuestion[]> {
    const date = new Date().getTime();
    const url: string = `assets/codereCard/codereCardQuestion.json?v=${date}`;
    return this.myGet(url, true);
  }
}
