/* eslint-disable complexity */
/* eslint-disable max-lines */
import { BehaviorSubject } from 'rxjs';
import { C_Features } from './C_Features';
// import { C_User } from "./C_User";
import { LicenseTypes } from './MasterData';
// import { C_Features } from './C_Features';
import { INTERFACE } from '../interfaces/general';
import { CApplicationState } from './CApplicationState';
import { C_Customization } from './C_Customization';
import { C_GameTypes } from './C_GameTypes';
import { C_User } from './C_User';
import { FundsOrigin } from '@providers/payments/BankService';
// import { C_Banners } from './C_banners';
// import { I_NewCarousel } from './I_NewCarousel';
// import { C_GameTypes } from './C_GameTypes';
// import { C_Evento } from './C_Evento';
// import { C_BetsData } from './C_BetsData';
// import { Bonus } from './csbgonlinecasinobonuses/Bonus';
// import { CApplicationState } from './CApplicationState';

// const coderebgOnline = 'https://coderesbgonlinebettingservicehub20180308112504.azurewebsites.net';
// Ruta correcta para myBets
const coderebgOnline = 'https://coderesbgonlinebettingservicehub20180308-coderesbgonlinebet.azurewebsites.net';

/* README
  //PointofSalePayment: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_PointofSalePayment
  //BankAccountPayment: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_BankAccountPayment
  //HalCashPayment: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_HalCashPayment
  //BgtCardPayment: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_BgtCardPayment
  //PointofSaleDeposit: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_PointofSaleDeposit
  //ElectronicCardDeposit: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_ElectronicCardDeposit
  //TeleingreosDeposit: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_TeleingreosDeposit
  //PaynetDeposit: boolean;   //**+**  VARIABLE INICIAL:TRANSAPI_TeleingreosDeposit
  //PaySafeCardDeposit: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_PaySafeCardDeposit
  //BgtCardDeposit: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_BgtCardDeposit
  //CRUDoverTransactions: boolean; //**+**  VARIABLE INICIAL:TRANSAPI_CRUDoverTransactions
  //MAIN_PAGE: string;  NO SE UTILIZARA
  //MAIN_COMPONENT: any;  NO SE UTILIZARA
    //  mainPageApp: string; /** SE ELIMINAR POR LA NUEVA ESTRUCTURA
  // mainPageComponent: any; /** SE ELIMINAR POR LA NUEVA ESTRUCTURA
  // currentPage: any; /** SE ELIMINAR POR LA NUEVA ESTRUCTURA
  // afterRegisterGoBackToPage: any; //**+** Usa un componente el cual ya no sera factible por la nueva estructura
  //callbackAfterLoginAfterCookiesAccep: (data: any, role: string) => void | undefined;
  // pages: Array<tPage>;  /** SE ELIMINAR POR LA NUEVA ESTRUCTURA

  Estos se pueden obtener mediante capacitor no son necesarios
    isMobile: boolean;
    isDesktop: boolean;
    isNative: boolean;
    devicePlatform: string;

  //DinamicYieldUrl: string; //**+**  VARIABLE INICIAL:DYNAMICYIELD
  //RecentGamesUrl: string;  //**+**  VARIABLE INICIAL:RECENTGAME
    //lobbyImgs: string; //**+**  VARIABLE INICIAL:LOBBY_IMAGES
  //lobbyJackpotsUrl: string;  //**+**  VARIABLE INICIAL:LOBBY_JACKPOTS
  //lobbyJackpotsTickersUrl: string;  //**+**  VARIABLE INICIAL:LOBBY_JACKPOTS_TICKERS
  //urlJackpotServices: string; //**+**  VARIABLE INICIAL:JACKPOTSSERVICES
  //urlLiveGameData: string;//**+**  VARIABLE INICIAL:LIVEGAMEDATA
    //UrlStreamingApiBase: string; //**+**  VARIABLE INICIAL:URLBASE_STREAMING
  //UrlUserServiceApiBase: string; //**+**  VARIABLE INICIAL:URLBASE_USERSERVICE
  //UrlUserServiceStandAlone: string;//**+**  VARIABLE INICIAL:URLBASE_USERSERICE_STAND_ALONE
  //UrlBaseLoginServices: string;//**+**  VARIABLE INICIAL: URLBASE_LOGINSERVICES
    //LoaderInterceptorShowUrl //**+** MOVIDO
  //ExceptionInterceptorNoShowError // **+** MOVIDO
  //navigationDestinyPage: any; //**+** ES UN COMPONENTE DESTINO
    //debugMode: boolean; //**+**  VARIABLE INICIAL:DEBUG_MODE
  //AppVersion: string; //**+** VARIABLE INICIAL:APP_VERSION

  redundancia.
      // sports
    if(!!GLOBALS.BASEDOMAIN){
      this.baseDomain = GLOBALS.BASEDOMAIN;
    }
    if(!!GLOBALS.NAVIGATIONSERVICEBASEURL){
      this.navigationServiceBaseUrl = GLOBALS.NAVIGATIONSERVICEBASEURL;
    }
    if(!!GLOBALS.BETSLIPSERVICEBASEURL){
      this.betslipServiceBaseUrl = GLOBALS.BETSLIPSERVICEBASEURL;
    }
    if(!!GLOBALS.PRODUCTION){
      this.production = GLOBALS.PRODUCTION;
    }

    if(!!GLOBALS.HTTP_ERRORS_THAT_RESTART_APP){
      this.HttpErrorsThatRestartApp = GLOBALS.HTTP_ERRORS_THAT_RESTART_APP;
    }
    if(!!GLOBALS.ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT){
      this.ErrorTrackerInterceptorUrls2Inspect = GLOBALS.ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT;
    }

    if(!!GLOBALS.HTTP_ERROR_DESCRIPTION){
      this.HttpErrorDescription = GLOBALS.HTTP_ERROR_DESCRIPTION;
    }

    if(!!GLOBALS.TIMEOUT_INTERCEPTOR_URLS_2_INSPECT){
      this.TimeoutInterceptorUrls2Inspect = GLOBALS.TIMEOUT_INTERCEPTOR_URLS_2_INSPECT;
    }
    if(!!GLOBALS.ENABLE_TIMEOUT_FOR_BACKEND_CALLS){
      this.EnableTimeoutForBackendCalls = GLOBALS.ENABLE_TIMEOUT_FOR_BACKEND_CALLS;
    }
    if(!!GLOBALS.TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS){
      this.TimeoutForBackendCallsInMSecs = GLOBALS.TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS;
    }
    if(!!GLOBALS.TIMEOUT_ERROR_DESCRIPTION){
      this.TimeoutErrorDescription = GLOBALS.TIMEOUT_ERROR_DESCRIPTION;
    }
    if(!!GLOBALS.ENABLE_TRACE_CALL_TIME){
      this.EnableTraceTimeCall = GLOBALS.ENABLE_TRACE_CALL_TIME;
    }
    if(!!GLOBALS.GET_TICKETS_URL){
      this.GetTicketsUrl = GLOBALS.GET_TICKETS_URL;
    }
    // end sports
*/
export class GlobalVarsModel {
  currentPage: any = null;
  ENVIRONMENT: string; // **+** Creado
  URLBASE_STREAMING: string;
  DOMAIN: string; //DomainBase
  URLBASE: string; //UrlBase
  URLBASEUAT!: string;
  URLBASE_REPORTS: string; // **+** Remplazo de UrlBaseReports
  URLBASE_BANNERS: string;
  URLBASE_TRANSACTIONS_API: string; // **+** Remplazo de UrlTransactionsApiBase
  URLBASE_OPERATIONS_API: String;
  URLBASE_BALANCESERVICE_API: string;
  URLBASE_COMMON_API: string;
  DEBUG_MODE: boolean;
  DEVICE_TYPE: string; // deviceType
  TK_RT: number;
  TK_DESK_RT: number;
  TK_CLOSE: number;
  LIVE_RT: number;
  HOME_RT: number;
  HIGHLIGHTS_RT: number;
  VERIDAS_POLLING_RT: number;
  LICENSE_TYPE: number;
  BROWSER: string;
  TRANSAPI_PointofSalePayment: boolean; // **+** Remplazo de PointofSalePayment
  TRANSAPI_BankAccountPayment: boolean; // **+** Remplazo de BankAccountPayment
  TRANSAPI_HalCashPayment: boolean; // **+** Remplazo de HalCashPayment
  TRANSAPI_BgtCardPayment: boolean; // **+** Remplazo de BgtCardPayment
  PLATFORM: string;
  TRANSAPI_PointofSaleDeposit: boolean; // **+** Remplazo de PointofSaleDeposit
  TRANSAPI_ElectronicCardDeposit: boolean; // **+** Remplazo de ElectronicCardDeposit
  TRANSAPI_TeleingreosDeposit: boolean; // **+** Remplazo de TeleingreosDeposit
  TRANSAPI_PaySafeCardDeposit: boolean; // **+** Remplazo de PaySafeCardDeposit
  TRANSAPI_BgtCardDeposit: boolean; // **+** Remplazo de BgtCardDeposit
  TRANSAPI_CRUDoverTransactions: boolean; // **+** Remplazo de CRUDoverTransactions
  XMLHttpRequest_WITHCREDENTIALS: boolean; // **+** tipo boolean en el proyecto antiguo
  EPGCREDITCARDENABLED: boolean; // **+** Remplazo de EpgCreditCardEnabled
  ANDROID_SENDER_ID: string;
  CUSTOM_INSIGHT_URI: string;
  URL_GEOLOCATION_API: string;
  APP_VERSION: string;
  LOBBY_IMAGES: string;
  PROVIDER_IMAGES: string;
  LOBBY_JACKPOTS: string;
  LOBBY_JACKPOTS_TICKERS: string;
  URLBASE_VERIDAS_API: string; // **+** Remplazo de UrlVeridasApiBase
  URLBASE_CASINO_BONUSES: string; // UrlBaseCasinoBonuses
  URLBASE_CODERE_EVENT_BUS: string; // UrlBaseCodereEventBus
  URLBASE_USERSERVICE: string;
  URLBASE_SPORSTMISC: string;
  URLBASE_USERSERICE_STAND_ALONE: string;
  URLBASE_VERIDAS_MODELS: string;
  URLBASE_REGAR: string; /**NUEVO  UrlRegAR */
  URLBASE_REGMZA: string;
  URLBASE_REGPBA: string;
  URLBASE_REGMX: string; /**NUEVO UrlRegMx  */
  URLBASE_LOGINSERVICES: string;
  URLBASE_REGCONEW: string; /**NUEVO UrlRegCONew */
  JACKPOTSSERVICES: string;
  LIVEGAMEDATA: string;
  DYNAMICYIELD: string;
  RECENTGAMES: string;
  FREEBET_BONUS: string;
  COOKIES: INTERFACE.iCookies; // **+** Remplazo de cookies
  URLBASE_MYBETS: string;
  CORDOVA: number;
  FEATURES: C_Features;
  BASEDOMAIN: string;
  NAVIGATIONSERVICEBASEURL: string;
  BETSLIPSERVICEBASEURL: string;
  PRODUCTION: boolean;
  HTTP_ERRORS_THAT_RESTART_APP: number[];
  ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT: string[];
  HTTP_ERROR_DESCRIPTION: string;
  TIMEOUT_INTERCEPTOR_URLS_2_INSPECT: string[];
  ENABLE_TIMEOUT_FOR_BACKEND_CALLS: boolean;
  TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS: number;
  TIMEOUT_ERROR_DESCRIPTION: string;
  ENABLE_TRACE_CALL_TIME: boolean;
  GET_TICKETS_URL: string;
  FORBIDDEN_REDIRECT: string; /** SE GENERA EN SCRIPTS PERO EN EL MODELO ANTIGUO NO SE CREO */
  COUNTRY_AVAILABLE: boolean; /** SE GENERA EN SCRIPTS PERO EN EL MODELO ANTIGUO NO SE CREO */
  DEVICE_MODEL: string; // deviceModel
  APPSFLYER_APPID: string;
  ROYALDEGREE: string;
  CODERE_FANTASY: string;
  CODERE_FANTASY_HP_URL: string;
  //Others
  UrlBaseBettingServiceHub: string;
  Idioma: string;
  TicketOpened: boolean;
  SportSelected: string;
  SportHandleSelected: string;
  betslipServiceBaseUrl: string;
  LeagueSelected: string;
  amountBet: number;
  hideBetFooterBar: boolean;
  oddType: string;
  user: C_User;
  extraUserData: INTERFACE.iextraUserData;
  isCookiesAccept: boolean;
  actionAfterCookiesAccept: 'login' | 'reg' | null;
  comeFromLogin: boolean;
  optinPopupOpen: boolean;
  isProhibited: boolean;
  originFunds: string | FundsOrigin = null;
  fiveLoginOK: boolean;
  onRegisterProductType: string;
  minBetValue: number;
  currencySymbol: string;
  currencyISOCode: string; // function licenseTypeForCountry set or change this var
  currencyLiteral: string;
  isGeolocalized: boolean;
  isCaba!: boolean; // **+** se inicia manualmente /Argentina specific, if the user is in Ciudad Autonoma de Buenos Aires
  isOrdenCongelamientoFondos: boolean;
  slotsAdviceIn!: number; // **+** se inicia manualmente
  limits!: INTERFACE.iCalendar;
  afterLoginUrl: any;
  afterLoginParamsUrl!: any;
  afterLoginParams: any;
  partner: string;
  ticketStatusChanged!: boolean; // **+** se inicia manualmente
  styleFooter: any;
  forceToOpenLogin: boolean;
  modalTicket: any; // **+** se inicia manualmente
  sessionInitDate!: Date; // **+** se inicia manualmente
  licenseType: number;
  freeBetIdSelected: string;
  leftMenuOpen: boolean;
  platforms: Array<string>;
  lstBanners: Array<any>; // **+**  Array<C_Banners> | Array<I_NewCarousel>
  enableBack: boolean;
  codePromotion!: string; // **+** se inicia manualmente
  defaultPromotion: boolean;
  depositsValue: number;
  rootScope: any;
  firstLoad: boolean;
  videoPlayerWindow!: boolean; // **+** se inicia manualmente
  videoPlayerOnTicket: boolean;
  DyClientApiKey!: string; //**+** No se utiliza.
  DyClientEngagementUrl!: string; //**+** No se utiliza.
  timerSlots: any; // **+** se inicia manualmente
  renewSelfExclusion: boolean;
  UrlTransactionsApiBase: string;
  UrlOperationsApiBase: string;
  UrlCommonApiBase: string;
  browser: string;
  isNative: boolean;
  miniGame: any;
  hasTicket: boolean;
  cuotaAccept: string;
  playingMiniGame: boolean;
  ApplicationState: CApplicationState; // **+** CApplicationState
  valueTown: string;
  loginOpen: boolean;
  nativeIOSVersion: string;
  nativeIOSTouchIdAvailable: boolean;
  predictNumberEvents: number;
  predictBet: boolean;
  enabledPredictBet: boolean;
  isDeposit!: boolean; // **+** se inicia manualmente
  openFromRegister: boolean;
  friendsID: string;
  licenseTicketType: string;
  gotorecovery: boolean;
  typeMoreinfo: string;
  headerHeight!: number; // **+** se inicia manualmente
  menuOpen: boolean;
  backButton: boolean;
  //puntos Mundial
  pointsWC: number;
  positionWC: number;
  evoPaymentsidTransaccion: string;
  evoPaymentsAmount: string;
  evoPaymentsNewButton: boolean;
  navigateToSlotsGame: boolean;
  slotsGameType: string;
  eventProps: any;
  XTREME_PUSH_APPKEY: string;
  strVersion: string;
  //selection method pay
  openSelectionChoose!: number; // **+** se inicia manualmente
  paymentEnablednotSelected: Array<any>;
  casinoCalendar: any;
  withoutWinlineCard: boolean;
  XPMessageBadge: number;
  gameTypes: C_GameTypes = new C_GameTypes();
  cEvent: any; // **+** C_Evento
  betsData: any[]; // **+** C_BetsData
  AvailableBonuses!: any[]; // **+** se inicia manualmente Bonus
  ItemSelected: any[]; //revisar type
  UrlBalanceServiceApiBase: string;
  UrlFreebetBonus: string;
  walletActive: boolean;
  clabeBanksWithDrawalEnabled: boolean;
  isAlreadyCheckBonusHome: boolean;
  isMirror: boolean;
  mvpData: INTERFACE.iMvpData;
  countryAvailable: boolean;
  iosScrollHide: boolean;
  currentPageHashValue: string;
  registry: string;
  cvuGenerated: boolean;
  showNavbar: boolean = true;
  paymentMethod!: string;
  paymentType!: string;
  urlBaseBanners: string;
  //SIN USAR EN EL INIT
  sportItemsId$: BehaviorSubject<any> = new BehaviorSubject(null);
  slotWindow: any; //**+** SE INICIA MANUAL
  nativeDevice: any; //**+** SE INICIA MANUAL
  //Agregados
  navigationDestinyPage!: any;
  mainPageApp: string;
  mainPageComponent: any;
  gameTypesConfigData: C_GameTypes = new C_GameTypes();
  //#region inspect this functions
  callbackAfterLoginAfterCookiesAccep: (data: any, role: string) => void | undefined;
  //#endregion
  // eslint-disable-next-line max-statements

  SportsMiscUrl: string;

  GOOGLE_MAPS_URL: string;
  GOOGLE_MAPS_API_KEY: string;

  urlJackpotServices: string;
  urlRoyalDegree: string;
  urlLiveGameData: string;
  HCP_URL: string;
  gameFromQuickLaunch: boolean;

  constructor(data?: Partial<GlobalVarsModel>) {
    this.mainPageApp = data?.mainPageApp || '/home';
    this.mainPageComponent = data?.mainPageComponent || '/home';
    //
    this.cvuGenerated = false;
    this.currentPageHashValue = '';
    this.countryAvailable = false;
    this.actionAfterCookiesAccept = null;
    this.isCookiesAccept = false;
    this.LeagueSelected = '';
    this.hasTicket = true;
    this.withoutWinlineCard = true;
    this.isAlreadyCheckBonusHome = false;
    this.isMirror = false;
    this.gameTypes = new C_GameTypes(); // **+** Error en los modelos
    this.gameTypesConfigData = new C_GameTypes(); // **+** Error en los modelos
    this.ApplicationState = new CApplicationState(); // **+** Error en los modelos
    // this.cEvent = new C_Evento();// **+** Error en los modelos
    this.betsData = [];
    this.XPMessageBadge = 0;
    this.strVersion = '';
    this.paymentEnablednotSelected = [];
    this.menuOpen = false;
    this.backButton = false;
    this.openFromRegister = false;
    this.playingMiniGame = false;
    this.cuotaAccept = '2';
    this.platforms = [];
    this.isProhibited = false;
    this.originFunds = null;
    this.fiveLoginOK = false;
    this.onRegisterProductType = null;
    this.freeBetIdSelected = '';
    this.currencySymbol = '€';
    this.currencyISOCode = '';
    this.currencyLiteral = '';
    this.isOrdenCongelamientoFondos = false;
    //
    this.ENVIRONMENT = data?.ENVIRONMENT || '';
    this.DEVICE_MODEL = data?.DEVICE_MODEL || '';
    this.URLBASE = data?.URLBASE || '';
    this.PLATFORM = data?.PLATFORM || 'Desktop';
    this.APP_VERSION = data?.APP_VERSION || '2022.12.27-15.36.00';
    this.LICENSE_TYPE = data?.LICENSE_TYPE || 0;
    this.COOKIES = data?.COOKIES || {
      customOK: true,
      analyticsOK: true,
      advertismentOK: true
    };
    this.CORDOVA = data?.CORDOVA || 0;
    this.DOMAIN = data?.BASEDOMAIN || data?.DOMAIN || 'http://localhost:4200'; // || 'https://m.apuestas.codere.es';
    this.URLBASE_REPORTS = data?.URLBASE_REPORTS || this.DOMAIN + '/csbgonlinereports/';
    this.FREEBET_BONUS = data?.FREEBET_BONUS || this.DOMAIN + '/FreebetBonus/';
    this.URLBASE_BANNERS = data?.URLBASE_BANNERS || 'https://m.apuestas.codere.es'; //No se uso la constante domain porque esta url no cambiara
    this.URLBASE_COMMON_API = data?.URLBASE_COMMON_API || '/DynTemplateGen/Api/'; //obtencion de literales de aplicacion DynTemplateGen
    this.URLBASE_TRANSACTIONS_API = data?.URLBASE_TRANSACTIONS_API || '/TransactionsApi/ '; //Transaccions a traves de TransactionsApi
    this.URLBASE_OPERATIONS_API = data?.URLBASE_OPERATIONS_API || '/payments/operations/';
    this.URLBASE_VERIDAS_API = data?.URLBASE_VERIDAS_API || this.DOMAIN + '/veridas/';
    this.URLBASE_VERIDAS_MODELS =
      data?.URLBASE_VERIDAS_MODELS || this.DOMAIN + '/deportes/assets/js/veridas/public/models';
    this.URLBASE_STREAMING = data?.URLBASE_STREAMING || this.DOMAIN + '/StreamingApi/';
    this.URLBASE_USERSERVICE = data?.URLBASE_USERSERVICE || this.DOMAIN + '/UserServiceApi/';
    this.URLBASE_SPORSTMISC = data?.URLBASE_SPORSTMISC || this.DOMAIN + '/SportsMisc/api/';
    this.URLBASE_USERSERICE_STAND_ALONE = data?.URLBASE_USERSERICE_STAND_ALONE || this.DOMAIN + '/user/api/data/';
    this.URLBASE_LOGINSERVICES = data?.URLBASE_LOGINSERVICES || this.DOMAIN + '/LoginServicesApi';
    this.LOBBY_JACKPOTS = data?.LOBBY_JACKPOTS || this.DOMAIN + '/Jackpot/';
    this.JACKPOTSSERVICES = data?.JACKPOTSSERVICES || this.DOMAIN + '/JackpotServices.AppService';
    this.LOBBY_JACKPOTS_TICKERS = data?.LOBBY_JACKPOTS_TICKERS || this.JACKPOTSSERVICES + '/api/NewJackpot/';
    this.LIVEGAMEDATA = data?.LIVEGAMEDATA || this.DOMAIN + '/LiveGameData';
    this.DYNAMICYIELD = data?.DYNAMICYIELD || this.URLBASE_REPORTS + 'slotgames/GetGamesDY?mobile=';
    this.RECENTGAMES = data?.RECENTGAMES || this.URLBASE_REPORTS + 'slotgames/GetGamesRPG?mobile=';
    this.URLBASE_BALANCESERVICE_API = data?.URLBASE_BALANCESERVICE_API || '/BalanceServiceApi/';
    this.DEBUG_MODE = data?.DEBUG_MODE || false;
    this.HIGHLIGHTS_RT = data?.HIGHLIGHTS_RT || 5000;
    this.TK_CLOSE = data?.TK_CLOSE || 5000;
    this.TK_RT = data?.TK_RT || 5000;
    this.TK_DESK_RT = data?.TK_DESK_RT || 8000;
    this.LIVE_RT = data?.LIVE_RT || 4000;
    this.HOME_RT = data?.HOME_RT || 5000;
    this.VERIDAS_POLLING_RT = data?.VERIDAS_POLLING_RT || 20000;
    this.DEVICE_TYPE = data?.DEVICE_TYPE || 'web';
    this.BROWSER = data?.BROWSER || '';
    this.FORBIDDEN_REDIRECT = data?.FORBIDDEN_REDIRECT || this.DOMAIN + '/csbgonline/home/NotAllowedCountry';
    this.COUNTRY_AVAILABLE = data?.COUNTRY_AVAILABLE || true;
    this.TRANSAPI_PointofSalePayment = data?.TRANSAPI_PointofSalePayment || true;
    this.TRANSAPI_BankAccountPayment = data?.TRANSAPI_BankAccountPayment || true;
    this.TRANSAPI_HalCashPayment = data?.TRANSAPI_HalCashPayment || true;
    this.TRANSAPI_BgtCardPayment = data?.TRANSAPI_BgtCardPayment || true;
    this.TRANSAPI_PointofSaleDeposit = data?.TRANSAPI_PointofSaleDeposit || true;
    this.TRANSAPI_ElectronicCardDeposit = data?.TRANSAPI_ElectronicCardDeposit || true;
    this.TRANSAPI_TeleingreosDeposit = data?.TRANSAPI_TeleingreosDeposit || true;
    this.TRANSAPI_PaySafeCardDeposit = data?.TRANSAPI_PaySafeCardDeposit || true;
    this.TRANSAPI_BgtCardDeposit = data?.TRANSAPI_BgtCardDeposit || true;
    this.TRANSAPI_CRUDoverTransactions = data?.TRANSAPI_CRUDoverTransactions || true;
    this.EPGCREDITCARDENABLED = data?.EPGCREDITCARDENABLED || true;
    this.LOBBY_IMAGES = data?.LOBBY_IMAGES || 'https://www.codere.es/lobby_tiles/';
    this.PROVIDER_IMAGES = data?.PROVIDER_IMAGES || 'https://www.codere.es/IconsCasino/';
    this.URLBASE_CASINO_BONUSES = data?.URLBASE_CASINO_BONUSES || this.DOMAIN + '/csbgonlinecasinobonuses';
    this.URLBASE_CODERE_EVENT_BUS =
      data?.URLBASE_CODERE_EVENT_BUS || 'https://coderesbgonlineeventbusapi.azurewebsites.net/api';
    this.URLBASE_MYBETS =
      data?.URLBASE_MYBETS || 'https://coderesbgonlinebettingservicehub20180308-coderesbgonlinebet.azurewebsites.net';
    this.XTREME_PUSH_APPKEY = data?.XTREME_PUSH_APPKEY || '';
    this.ANDROID_SENDER_ID = data?.ANDROID_SENDER_ID || '';
    this.URLBASE_REGAR = data?.URLBASE_REGAR || '';
    this.URLBASE_REGMZA = data?.URLBASE_REGMZA || '';
    this.URLBASE_REGMX = data?.URLBASE_REGMX || '';
    this.URLBASE_REGCONEW = data?.URLBASE_REGCONEW || '';

    //OTHERS
    this.UrlBaseBettingServiceHub = data?.URLBASE_MYBETS || coderebgOnline;
    this.casinoCalendar = null;
    this.clabeBanksWithDrawalEnabled = false;
    this.walletActive = false;
    this.Idioma = 'es';
    this.amountBet = 1;
    this.UrlTransactionsApiBase = this.URLBASE_TRANSACTIONS_API ? this.DOMAIN + this.URLBASE_TRANSACTIONS_API : '';
    this.UrlOperationsApiBase = this.URLBASE_OPERATIONS_API ? this.DOMAIN + this.URLBASE_OPERATIONS_API : '';
    this.UrlCommonApiBase = this.URLBASE_COMMON_API ? this.DOMAIN + this.URLBASE_COMMON_API : '';
    this.XMLHttpRequest_WITHCREDENTIALS = data?.XMLHttpRequest_WITHCREDENTIALS || false;
    this.UrlFreebetBonus = this.FREEBET_BONUS || this.DOMAIN + '/CheckReceiveMailMvc/orderform/';
    this.UrlBalanceServiceApiBase = this.DOMAIN + this.URLBASE_BALANCESERVICE_API;
    this.browser = this.BROWSER ? this.BROWSER.toLowerCase() : '';
    this.enableBack = false;
    this.oddType = 'dec';
    this.isGeolocalized = false;
    this.LICENSE_TYPE = data?.LICENSE_TYPE || 0;
    this.partner = '';
    this.licenseType = 0;
    this.licenseTypeForCountry(this.LICENSE_TYPE);
    this.ItemSelected = [];
    this.mvpData = {
      token: '0',
      login: '0',
      register: '0',
      deposit: '0'
    };
    this.extraUserData = {
      documentationVerification: false,
      graceTime: false,
      limitsExpired: false,
      professionOrPrpMissing: '',
      isRevocationPage: false,
      tycSelfie: false,
      csrfControl: null,
      acceptanceComunication: false,
      pptNotVerification: false,
      dniExpired: false,
      haveProfesion: false,
      isForbiddenInMendoza: false
    };
    this.comeFromLogin = false;
    this.optinPopupOpen = false;
    this.user = new C_User(false);
    this.isNative = false;
    this.TicketOpened = false;
    this.SportSelected = '';
    this.SportHandleSelected = '';
    this.hideBetFooterBar = true;
    this.urlBaseBanners = this.URLBASE_BANNERS + '/cacheservices/home/GetSliderImages';
    this.afterLoginUrl = null;
    this.forceToOpenLogin = false;
    this.leftMenuOpen = true;
    this.lstBanners = [];
    this.renewSelfExclusion = false;
    this.firstLoad = true;
    this.minBetValue = this.setMinVAlue(this.LICENSE_TYPE);
    this.defaultPromotion = true;
    this.depositsValue = 0;
    this.valueTown = '';
    this.loginOpen = false;
    this.nativeIOSTouchIdAvailable = true;
    this.nativeIOSVersion = '0';
    this.APPSFLYER_APPID = data?.APPSFLYER_APPID || '';
    this.FEATURES = data?.FEATURES
      ? { ...data.FEATURES, AppsFlyerAppId: this.APPSFLYER_APPID }
      : new C_Features(this.APPSFLYER_APPID);
    // this.predictNumberEvents = parseInt(this.FEATURES?.predictNumberEvents);
    this.predictNumberEvents = 0;
    this.predictBet = false;
    this.enabledPredictBet = false;
    this.licenseTicketType = '';
    this.gotorecovery = false;
    this.friendsID = '';
    this.typeMoreinfo = '';
    this.navigateToSlotsGame = false;
    this.videoPlayerOnTicket = false;
    this.registry = this.setRegistry(this.LICENSE_TYPE);
    this.iosScrollHide = false;

    //UNUSED
    this.pointsWC = 0;
    this.positionWC = 0;

    this.evoPaymentsidTransaccion = '';
    this.evoPaymentsAmount = '';
    this.evoPaymentsNewButton = false;
    this.slotsGameType = 'slot';
    this.eventProps = null;

    this.NAVIGATIONSERVICEBASEURL = data?.NAVIGATIONSERVICEBASEURL || '';
    this.BETSLIPSERVICEBASEURL = data?.BETSLIPSERVICEBASEURL || '';
    this.PRODUCTION = data?.PRODUCTION || false;
    this.HTTP_ERRORS_THAT_RESTART_APP = data?.HTTP_ERRORS_THAT_RESTART_APP || [];
    this.ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT = data?.ERROR_TRACKER_INTERCEPTOR_URLS_2_INSPECT || [];
    this.HTTP_ERROR_DESCRIPTION = this.HTTP_ERROR_DESCRIPTION || '';
    this.TIMEOUT_INTERCEPTOR_URLS_2_INSPECT = data?.TIMEOUT_INTERCEPTOR_URLS_2_INSPECT || [];
    this.ENABLE_TIMEOUT_FOR_BACKEND_CALLS = data?.ENABLE_TIMEOUT_FOR_BACKEND_CALLS || false;
    this.TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS = data?.TIMEOUT_FOR_BACKEND_CALLS_IN_MSECS || null;
    this.TIMEOUT_ERROR_DESCRIPTION = data?.TIMEOUT_ERROR_DESCRIPTION || '';
    this.ENABLE_TRACE_CALL_TIME = data?.ENABLE_TRACE_CALL_TIME || false;
    this.GET_TICKETS_URL = data?.GET_TICKETS_URL || '';
    this.URLBASE_REGPBA = data?.URLBASE_REGPBA || '';

    this.CUSTOM_INSIGHT_URI = data?.CUSTOM_INSIGHT_URI || 'https://coderesbgonlinegeoip.azurewebsites.net';
    this.URL_GEOLOCATION_API = 'https://coderesbgonlinegeoip.azurewebsites.net/ips/currentRequestCountryIsoCode';

    this.GOOGLE_MAPS_URL = data?.GOOGLE_MAPS_URL || '';
    this.GOOGLE_MAPS_API_KEY = data?.GOOGLE_MAPS_API_KEY || '';
    this.SportsMiscUrl = data?.SportsMiscUrl || this.DOMAIN + '/SportsMisc/api/';
    this.urlJackpotServices = data.JACKPOTSSERVICES;
    this.urlLiveGameData = data.LIVEGAMEDATA;
    this.urlRoyalDegree = data.ROYALDEGREE;
    this.HCP_URL = data.HCP_URL;
    this.CODERE_FANTASY = data.CODERE_FANTASY;
    this.CODERE_FANTASY_HP_URL = data.CODERE_FANTASY_HP_URL;
  }

  licenseTypeForCountry(LICENSE_TYPE: number) {
    switch (LICENSE_TYPE) {
      case LicenseTypes.ArgentinaCaba:
        (this.oddType = 'dec'), (this.licenseType = LicenseTypes.ArgentinaCaba), (this.currencyISOCode = 'ARS');
        break;
      case LicenseTypes.ArgentinaPBA:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.ArgentinaPBA;
        this.currencyISOCode = 'ARS';
        break;
      case LicenseTypes.ArgentinaMendoza:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.ArgentinaMendoza;
        this.currencyISOCode = 'ARS';
        break;
      case LicenseTypes.Mexico:
        this.oddType = 'ame';
        this.licenseType = LicenseTypes.Mexico;
        this.currencyISOCode = 'MXN';
        this.minBetValue = 1;
        break;
      case LicenseTypes.Colombia:
        this.oddType = 'dec';
        this.licenseType = LicenseTypes.Colombia;
        this.currencyISOCode = 'COP';
        break;
      case LicenseTypes.Panama:
        this.oddType = 'ame';
        this.licenseType = LicenseTypes.Panama;
        this.currencyISOCode = 'USD';
        break;
      default:
        this.licenseType = LicenseTypes.Nacional;
        this.currencyISOCode = 'EUR';
    }
  }

  setRegistry(LICENSE_TYPE: number): string {
    if (LICENSE_TYPE === LicenseTypes.Nacional) return 'Standard';
    if (LICENSE_TYPE === LicenseTypes.ArgentinaCaba || this.licenseType === LicenseTypes.ArgentinaPBA)
      return 'Standard';
    return '';
  }

  setMinVAlue(LICENSE_TYPE: number) {
    switch (LICENSE_TYPE) {
      case LicenseTypes.Mexico:
        return 1;
      case LicenseTypes.ArgentinaPBA:
      case LicenseTypes.ArgentinaCaba:
        return 100;
      default:
        return 0.2;
    }
  }

  setCuotaAccept(value: string) {
    this.cuotaAccept = value;
    switch (value) {
      case '0':
        this.user.customization = new C_Customization(true, true);
        break;
      case '1':
        this.user.customization = new C_Customization(true, false);
        break;
      case '2':
        this.user.customization = new C_Customization(false, false);
        break;
    }
  }
}
